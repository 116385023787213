class AbstractMetadata {
    public name: string = "";

    getName(): string {
        return this.name;
    };

    setName(name: string) {
        this.name = name;
    };
}

export default AbstractMetadata;
