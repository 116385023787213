import AbstractMetadata from "../AbstractMetadata";
import EnterpriseObjectMemberMetadata from "./EnterpriseObjectMemberMetadata";

class EnterpriseObjectMetadata extends AbstractMetadata {
    public enterpriseComponentMembers: Map<string, EnterpriseObjectMemberMetadata> = new Map()
    public primaryEnterpriseComponent: string = "";

    getPrimaryEnterpriseComponent(): string {
        return this.primaryEnterpriseComponent;
    };

    getEnterpriseComponentMembers(): Map<string, EnterpriseObjectMemberMetadata>  {
        return this.enterpriseComponentMembers;
    };

}

export default EnterpriseObjectMetadata;