import React from "react";
import "../Navigation/css/NavBar.scss";
import {Black} from "../../theme/styles";
import {Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow} from "@mui/material";


export default function RateLimitation() {

    return (
        <div style={{paddingLeft: "20px", paddingRight: "30px"}}>
            <p style={{fontSize: "24px", fontWeight: "400", color: Black}}>Rate Limitation</p>
            <p style={{fontSize: "16px", fontWeight: "400", color: Black}}>Enlil enforces the following rate limitations.</p>

            <TableContainer component={Paper}>
                <Table sx={{ minWidth: 450, border: "2px solid #EEEEEE"}}>
                    <TableHead>
                        <TableRow style={{height: "40px", backgroundColor: "#FAFAFA", borderRight: "1px solid #FAFAFA"}}>
                            <TableCell size='small' width="20%" style={{maxWidth: "100px", borderRight: "2px solid #EEEEEE", color: "#000000"}}><b>Topic</b></TableCell>
                            <TableCell size='small' width="100%" align="left"><b>Rate</b></TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        <TableRow key={1} sx={{ '&:last-child td, &:last-child th': { border: 1 } }}>
                            <TableCell size='small' component="th" scope="row" style={{borderRight: "2px solid #EEEEEE"}}>GET Requests</TableCell>
                            <TableCell size='small' align="left">20,000 requests from a particular IP Address per hour.</TableCell>
                        </TableRow>
                        <TableRow key={1} sx={{ '&:last-child td, &:last-child th': { border: 1 } }}>
                            <TableCell size='small' component="th" scope="row" style={{borderRight: "2px solid #EEEEEE"}}>POST/PUT/PATCH Requests</TableCell>
                            <TableCell size='small' align="left">200 requests from a particular IP Address per hour.</TableCell>
                        </TableRow>
                        <TableRow key={1} sx={{ '&:last-child td, &:last-child th': { border: 1 } }}>
                            <TableCell size='small' component="th" scope="row" style={{borderRight: "2px solid #EEEEEE"}}>Concurrency Limitation</TableCell>
                            <TableCell size='small' align="left">3 active GET/PUT/POST/PATCH requests executing simultaneously with a maximum of 10 waiting requests. No request may wait for more than 10 seconds.</TableCell>
                        </TableRow>
                        <TableRow key={1} sx={{ '&:last-child td, &:last-child th': { border: 1 } }}>
                            <TableCell size='small' component="th" scope="row" style={{borderRight: "2px solid #EEEEEE"}}>Payload size limitation</TableCell>
                            <TableCell size='small' align="left">No PUT/POST/PATCH request may have a payload of more than 6 MB. No GET request may return more than 6 MB.</TableCell>
                        </TableRow>
                        <TableRow key={1} sx={{ '&:last-child td, &:last-child th': { border: 1 } }}>
                            <TableCell size='small' component="th" scope="row" style={{borderRight: "2px solid #EEEEEE"}}>Time limitation</TableCell>
                            <TableCell size='small' align="left">No GET/PUT/POST/PATCH request may execute for more than 29 seconds</TableCell>
                        </TableRow>
                        <TableRow key={1} sx={{ '&:last-child td, &:last-child th': { border: 1 } }}>
                            <TableCell size='small' component="th" scope="row" style={{borderRight: "2px solid #EEEEEE"}}>IDOC specific rate limitation</TableCell>
                            <TableCell size='small' align="left">Each IDOC has a maximum number of records which the primary object may return. Additional records may be retrieved by paginating using the LIMIT and OFFSET parameters. See IDOC specific documentation.</TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
            </TableContainer>
        </div>
    )
}