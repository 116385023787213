import {useContext} from "react";
import {AppContext} from "../util/AppContext";

export function useAppContext() {
    return useContext(AppContext);
}

export function withAppContext(Component) {
    return (props) => {
        const appContext = useAppContext();
        return <Component appContext={appContext} {...props} />;
    };
}
