import React from "react";
import "../Navigation/css/NavBar.scss";
import {Black} from "../../theme/styles";
import css_self from "../../pages/css/Pages.module.scss";
import {Link} from "@mui/material";
import {useNavigate} from "react-router-dom";
import attachmentEnterpriseObject from '../../assets/attachmentEnterpriseObject.png';

export default function Attachments() {

    const navigate = useNavigate();

    return (
        <div style={{paddingLeft: "20px", paddingRight: "30px"}}>
            <p style={{fontSize: "24px", fontWeight: "400", color: Black}}>Attachments</p>
            <p style={{fontSize: "16px", fontWeight: "400", color: Black}}>REST APIs support the handling of attachments in Enlil.</p>
            <ul style={{fontSize: "14px", fontWeight: "400", color: Black}}>
                <li style={{marginTop: "20px"}}>In Enlil, attachments may be associated to each document (Part, Equipment, etc.) as well as with each revision of those documents. This family of APIs allows for users to create, update and retrieve information about of those attachments as well as the attachment content.</li>
                <li style={{marginTop: "20px"}}>To download an existing attachment, already uploaded to Enlil, use the following APIs/IDOCs:
                    <li style={{marginTop: "20px", marginLeft: "20px"}}><Link className={css_self.Link4} onClick={(event) => {
                        navigate("/api-docs-tools/api-explorer/attachments-get")
                    }}>attachment GET</Link> (v1.Attachment-001) - Provide the USID of the attachment and use the GetURL parameters to retrieve the attachment.</li>
                    <li style={{marginTop: "20px", marginLeft: "20px"}}><Link className={css_self.Link4} onClick={(event) => {
                        navigate("/api-docs-tools/api-explorer/parts-get")
                    }}>part GET</Link> (v1.Part-002) - Provide the USID of the part. All attachments associated to that part are located at ShifamedMessage.ListOfPart.Part[index].ListOfAttachment.Attachment
                    </li>
                    <li style={{marginTop: "20px", marginLeft: "20px"}}><Link className={css_self.Link4} onClick={(event) => {
                        navigate("/api-docs-tools/api-explorer/parts-get")
                    }}>part GET</Link> (v1.Part-003) - Provide the USID of the part. All attachments associated to that part are located at ShifamedMessage.ListOfPart.Part[index].ListOfAttachment.Attachment
                    </li>
                    <li style={{marginTop: "20px", marginLeft: "20px"}}><Link className={css_self.Link4} onClick={(event) => {
                        navigate("/api-docs-tools/api-explorer/part-revisions-get")
                    }}>part_revision GET</Link> (v1.PartRevision-002) - Provide the USID of the part revision. All attachments associated to that part revision are located at ShifamedMessage.ListOfPartRevision.PartRevision[index].ListOfAttachment.Attachment
                    </li>
                    <li style={{marginTop: "20px", marginLeft: "20px"}}><Link className={css_self.Link4} onClick={(event) => {
                        navigate("/api-docs-tools/api-explorer/part-revisions-get")
                    }}>part_revision GET</Link> (v1.PartRevision-003) - Provide the USID of the part revision. All attachments associated to that part revision are located at ShifamedMessage.ListOfPartRevision.PartRevision[index].ListOfAttachment.Attachment
                    </li>
                </li>
                <li style={{marginTop: "20px"}}>To create an new attachment, use one of the following APIs.<br/><br/>
                    <span style={{lineHeight: "1.5em"}}>
                    <Link className={css_self.Link4} onClick={() => {
                        navigate("/api-docs-tools/api-explorer/parts-put")
                    }}>part PUT</Link><br/>
                    <Link className={css_self.Link4} onClick={() => {
                        navigate("/api-docs-tools/api-explorer/part-revisions-put")
                    }}>part_revision PUT</Link> API.
                    </span>
                    <br/><br/>

                        Enlil provides the following IDOC format to create attachments.
                    <li style={{marginTop: "20px", marginLeft: "20px"}}>v1.Part-003</li>
                    <li style={{marginTop: "20px", marginLeft: "20px"}}>v1.PartRevision-003</li>
                </li>
                <li style={{marginTop: "20px"}}>To update an existing attachment, either before or after uploading the attachment to Enlil, use the <Link className={css_self.Link4} onClick={(event) => {
                    navigate("/api-docs-tools/api/attachments-put")
                }}>attachment PUT</Link> API. Enlil provides the following IDOC format to update attachments.
                    <li style={{marginTop: "20px", marginLeft: "20px"}}>v1.Attachment-001</li>
                </li>
            </ul>
            <br/>
            <br/>
            <p style={{fontSize: "16px", fontWeight: "400", color: Black}}>The Enterprise Object model for attachments is detailed below. Other Enlil entities (Equipment, Material, etc.) have a similar logical schema.</p>
            <img src={attachmentEnterpriseObject} alt={"Model"}/>
        </div>
    )
}