import React from "react";
import "../Navigation/css/NavBar.scss";
import {Black} from "../../theme/styles";

export default function Employees() {

    return (
        <div style={{paddingLeft: "20px", paddingRight: "30px"}}>
            <p style={{fontSize: "24px", fontWeight: "400", color: Black}}>Employees</p>
            <p style={{fontSize: "16px", fontWeight: "400", color: Black}}>REST APIs support the handling of employees in Enlil.</p>
            <ul style={{fontSize: "14px", fontWeight: "400", color: Black}}>
                <li style={{marginTop: "20px"}}>In Enlil, employees are the users within a company who are enabled to login to Enlil. This family of APIs allows for the retrieval of employee related information.</li>
            </ul>
            <br/>
            <br/>
            <p style={{fontSize: "16px", fontWeight: "400", color: Black}}>The logical ERD below helps visualize the employee relationships in Enlil.</p>
        </div>
    )
}