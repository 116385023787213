import React, {useEffect} from "react";
import enlilImage from "../../assets/logo-5cd08655.svg";
import css_self from "./css/NavBar.module.scss";
import "./css/NavBar.scss";
import {Avatar, Box, Button, IconButton, InputAdornment, Link, Menu, MenuItem, Popover, TextField, Typography} from "@mui/material";
import {Blue3, Grey2, Grey17, Grey6, White, Grey7} from "../../theme/styles";
import {useNavigate} from "react-router-dom";
import {Apps, ArrowDropDown, BugReport, Check, Checklist, DeveloperMode, DirectionsRun, EmojiObjects, Engineering, FactCheck, Feed, HelpOutlineOutlined, Hive, Language, Logout, Menu as MenuIcon, PermIdentity, School, Search} from "@mui/icons-material";
import useAppContext from "../../util/AppContext";
import useBreakpoint from "../../util/useBreakpoint";
import Login from "../Login/Login";
import EventBus from "../../util/EventBus";

type MenuSelection = {id: string, target: EventTarget & HTMLButtonElement}

export default function NavBar() {
    const navigate = useNavigate();
    const [showLogin, setShowLogin] = React.useState<boolean>(false);
    const [menu, setMenu] = React.useState<MenuSelection | null>(null);
    const appContext = useAppContext();

    const sessionUpdateListener = () => {
        // @ts-ignore
        setShowLogin(appContext?.sessionContext.showLoginDialog);
    };

    useEffect(() => {
       // @ts-ignore
        EventBus.session.addListener("update", sessionUpdateListener);

       // @ts-ignore
        return () => EventBus.session.removeListener("update", sessionUpdateListener);
    }, []);

    let adornments = {
        endAdornment: (
            <InputAdornment position="end">
                <IconButton onClick={() => {}}>
                    <Search/>
                </IconButton>
            </InputAdornment>
        )
    };

    let content = null;
    let breakpoint = useBreakpoint();

    if (breakpoint.getBreakPointName() === "xl" || breakpoint.getBreakPointName() === "lg") {
        content = (
            <div style={{display: "flex", flexDirection: "column", justifyContent: "flex-start"}}>
                <div style={{display: "flex", flexDirection: "row", justifyContent: "flex-start", paddingLeft: 20, paddingRight: 5, borderBottomWidth: "1px", borderBottomStyle: "solid", borderBottomColor: Grey2, backgroundColor: White}}>
                    <div key={1} style={{display: "flex", flexDirection: "row", justifyContent: "flex-start", height: "50px"}}>
                        <div key={1} style={{paddingTop: "0px", paddingRight: "2px", marginLeft: "-5px"}}><img className={css_self.EnlilLogo} src={enlilImage} alt="Home"/></div>
                        <div key={2} style={{paddingTop: "5px", marginLeft: "65px", marginRight: "4px"}}><Typography style={{fontSize: 24, fontWeight: 400, color: Blue3}}>|</Typography></div>
                        <div key={3} style={{paddingTop: "7px", marginLeft: "3px"}}><Typography style={{fontSize: 24, fontWeight: 400, color: Blue3}}>Developer</Typography></div>
                        <div style={{paddingTop: "5px", marginLeft: "80px"}}>
                            <Button className={css_self.NavMenuButton} variant="text" onClick={(e) => navigate("/home")}>Home</Button>
                        </div>
                        {appContext?.sessionContext.valid() ? (
                            <div style={{paddingTop: "5px", marginLeft: "30px"}}>
                                <Button className={css_self.NavMenuButton} variant="text" onClick={(e) => navigate("/dashboard")}>Dashboard</Button>
                            </div>
                        ) : null }
                        <div style={{paddingTop: "5px", marginLeft: "30px", minWidth: "113px"}}>
                            <Button className={css_self.NavMenuButton} variant="text" onClick={(e) => navigate("/api-docs-tools")}>API Docs & Tools</Button>
                        </div>
                        <div style={{paddingTop: "5px", marginLeft: "30px"}}>
                            <Button className={css_self.NavMenuButton} variant="text" onClick={(e) => navigate("/community")}>Community</Button>
                        </div>
                    </div>
                    <div style={{display: "flex", flexDirection: "row", justifyContent: "flex-start", flexGrow: 1, height: "40px", paddingTop: "6px", marginLeft: "30px"}}>
                        <TextField fullWidth style={{width: "100%"}} id="outlined-read-only-input" size="small" placeholder="Ask Enlil..." InputProps={adornments}/>
                    </div>
                    <div key={2} style={{display: "flex", flexDirection: "row", justifyContent: "flex-end", height: "40px", minWidth: "260px", marginLeft: "30px", paddingTop: "3px"}}>
                        <div style={{width: "90px", paddingTop: "2px"}}>
                            <Button className={css_self.NavMenuButton} variant="text"
                                    onClick={(e) => setMenu( {id: "version", target: e.currentTarget})}
                                    endIcon={<ArrowDropDown />}
                            >
                                v1.0.0
                            </Button>
                            {menu?.id === "version" ? (
                                <Menu anchorEl={menu?.target}
                                      anchorOrigin={{vertical: "bottom", horizontal: "center"}}
                                      transformOrigin={{vertical: -9, horizontal: "center"}}
                                      open={true}
                                      onClose={() => setMenu(null)}
                                      TransitionProps={{
                                          onExited: () => setMenu(null),
                                      }}
                                      MenuListProps={{
                                          ...{"data-cc-component": "NavMenu"},
                                          disablePadding: true,
                                          style: {"minWidth": "100px"},
                                      }}
                                      PaperProps={{
                                          elevation: 2,
                                      }}
                                >
                                    <MenuItem key={1} className={css_self.Standard} onClick={(e) => {}}>
                                        <div style={{display: "block", marginLeft: "10px"}}>
                                            <div style={{marginTop: "0px"}}>
                                                <Typography style={{fontSize: "12px", fontWeight: "400", color: Grey7}}>v1.0.0</Typography>
                                            </div>
                                        </div>
                                    </MenuItem>
                                    <MenuItem key={1} className={css_self.Standard} onClick={(e) => {}}>
                                        <div style={{display: "block", marginLeft: "10px"}}>
                                            <div style={{marginTop: "0px"}}>
                                                <Typography style={{fontSize: "12px", fontWeight: "400", color: Grey7}}>v2.0.0 (unreleased)</Typography>
                                            </div>
                                        </div>
                                    </MenuItem>
                                </Menu>
                            ) : null}
                        </div>
                        <div style={{width: "50px", paddingLeft: "3px", paddingTop: "2px", height: "40px"}}>
                            <IconButton className={css_self.NavMenuButton} onClick={(e) => setMenu({id: (menu?.id === "sites" ? "" : "sites"), target: e.currentTarget})}>
                                <Language/>
                            </IconButton>
                        </div>
                        <div style={{width: "40px", paddingLeft: "5px", paddingTop: "1px", height: "40px"}}>{renderAccountNavigation()}</div>
                        <div style={{width: "40px", paddingLeft: "5px", paddingTop: "1px", height: "40px"}}>{renderAppsNavigation()}</div>
                    </div>
                </div>
                {renderSiteNavigation()}
            </div>
        )
    } else {
        content = (
            <>
                <div style={{display: "flex", flexDirection: "row", justifyContent: "flex-start", paddingLeft: 10, paddingRight: 5, borderBottomWidth: "1px", borderBottomStyle: "solid", borderBottomColor: Grey2, backgroundColor: White, height: "51px"}}>
                    <div key={1} style={{marginTop: "3px"}}>{renderMenuNavigation()}</div>
                    <div key={2} style={{display: "flex", flexDirection: "row", justifyContent: "center"}}>
                        <div>
                            <img className={css_self.EnlilLogo} src={enlilImage} alt="Home"/>
                        </div>
                        <div style={{paddingLeft: "70px", paddingTop: "4px"}}>
                            <Typography style={{fontSize: 24, fontWeight: 400, color: Blue3}}>|</Typography>
                        </div>
                        <div>
                            <Typography style={{paddingLeft: "10px", paddingTop: "6px", fontSize: 24, fontWeight: 400, color: Blue3}}>Developer</Typography>
                        </div>
                    </div>
                    <div key={3} style={{display: "flex", flexDirection: "row", justifyContent: "flex-end"}}>
                        <div style={{width: "40px", paddingLeft: "0px", marginTop: "3px", height: "40px"}}>
                            <IconButton onClick={() => {}}>
                                <Search/>
                            </IconButton>
                        </div>
                        <div style={{width: "40px", paddingLeft: "5px", marginTop: "5px", height: "40px"}}>{renderAppsNavigation()}</div>
                    </div>
                </div>
                {menu?.id === "menu" ? (
                    <div style={{display: "flex", flexDirection: "column", justifyContent: "flex-start", height: "234px"}}>
                        <div style={{width: "100%"}}>
                            <div key={"help"} className={css_self.Mobile} onClick={() => {
                                navigate("/help");
                            }}>
                                <div style={{display: "flex", flexDirection: "row"}}>
                                    <PermIdentity style={{marginTop: "-2px", color: Grey6}}/>
                                    <Typography style={{marginLeft: "10px", marginTop: "1px", fontSize: "15px", fontWeight: "400", color: Grey17}}>{appContext?.sessionContext.valid() ? "Log out" : "Log in"}</Typography>
                                </div>
                            </div>
                            <div key={"apidocstools"} className={css_self.Mobile} onClick={() => {
                                navigate("/apidocstools");
                            }}>
                                <Typography style={{fontSize: "15px", fontWeight: "400", color: Grey17}}>API Docs & Tools</Typography>
                            </div>
                            <div key={"community"} className={css_self.Mobile} onClick={() => {
                                navigate("/community");
                            }}>
                                <Typography style={{fontSize: "15px", fontWeight: "400", color: Grey17}}>Community</Typography>
                            </div>
                            <div key={"getEnlil"} className={css_self.Mobile}>
                                <Link href="https://www.enlil.com" target="_blank" style={{fontSize: "15px", fontWeight: "400", color: Grey17}}>Get Enlil</Link>
                            </div>
                            <div key={"getV24.1.0"} className={css_self.Mobile} onClick={() => {
                                navigate("/community");
                            }}>
                                <Typography style={{fontSize: "15px", fontWeight: "400", color: Grey17}}>API v24.1.0</Typography>
                            </div>
                        </div>
                    </div>
                ) : null}
            </>
        )
    }

    return (
        <>{content}</>
    );

    function renderSiteNavigation() {
        let content = null;

        if (menu?.id === "sites") {
            content = (
                <div style={{display: "flex", flexDirection: "column", padding: "10px"}}>
                    <div style={{marginBottom: "30px"}}>
                        <IconButton className={css_self.AppMenuButton}
                                    style={{width: "100%"}}
                                    onClick={() => {
                                        navigate("/apidocstools/apiexplorer");
                                    }}
                        >
                            <div style={{display: "flex", flexDirection: "row", justifyContent: "flex-start", height: "50px", width: "100%"}}>
                                <div style={{display: "flex", flexDirection: "row", justifyContent: "flex-start", width: "20%", minWidth: "300px"}}>
                                    <div style={{paddingTop: "20px", paddingRight: "2px"}}><img className={css_self.EnlilLogo} src={enlilImage} alt="Home"/></div>
                                    <div style={{paddingTop: "25px", marginLeft: "65px", marginRight: "4px"}}><Typography style={{fontSize: 24, fontWeight: 400, color: Blue3}}>|</Typography></div>
                                    <div style={{paddingTop: "27px", marginLeft: "3px"}}><Typography style={{fontSize: 24, fontWeight: 400, color: Blue3}}>BPM</Typography></div>
                                </div>
                                <div style={{paddingTop: "7px", width: "80%"}}>
                                    <Typography style={{fontSize: "14px", fontWeight: "400", color: "#000000", textAlign: "left"}}>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</Typography>
                                </div>
                            </div>
                        </IconButton>
                    </div>
                    <div style={{marginBottom: "30px"}}>
                        <IconButton className={css_self.AppMenuButton}
                                    onClick={() => {
                                        navigate("/apidocstools/apiexplorer");
                                    }}
                        >
                            <div style={{display: "flex", flexDirection: "row", justifyContent: "flex-start", height: "50px", width: "100%"}}>
                                <div style={{display: "flex", flexDirection: "row", justifyContent: "flex-start", width: "20%", minWidth: "300px"}}>
                                    <div style={{paddingTop: "20px", paddingRight: "2px"}}><img className={css_self.EnlilLogo} src={enlilImage} alt="Home"/></div>
                                    <div style={{paddingTop: "25px", marginLeft: "65px", marginRight: "4px"}}><Typography style={{fontSize: 24, fontWeight: 400, color: Blue3}}>|</Typography></div>
                                    <div style={{paddingTop: "27px", marginLeft: "3px"}}><Typography style={{fontSize: 24, fontWeight: 400, color: Blue3}}>Developer</Typography></div>
                                </div>
                                <div style={{paddingTop: "7px", width: "80%"}}>
                                    <Typography style={{fontSize: "14px", fontWeight: "400", color: "#000000", textAlign: "left"}}>Platea cubilia fusce aptent pulvinar mauris velit. Aaliquam tellus iaculis augue urna. Mus felis praesent urna maecenas pulvinar suscipit hendrerit finibus. Nisl aliquet varius ut dictum fusce, sapien pharetra erat duis. Sapien ante iaculis dictumst luctus aliquet at. Tortor duis mollis cras; nullam aliquam consectetur hendrerit. Est cursus ultrices ac malesuada; pellentesque mattis. Nec ipsum in accumsan platea quam. Taciti sem purus magnis ultricies dui cras laoreet magnis.</Typography>
                                </div>
                            </div>
                        </IconButton>
                    </div>
                    <div style={{marginBottom: "30px"}}>
                        <IconButton className={css_self.AppMenuButton}
                                    onClick={() => {
                                        navigate("/apidocstools/apiexplorer");
                                    }}
                        >
                            <div style={{display: "flex", flexDirection: "row", justifyContent: "flex-start", height: "50px", width: "100%"}}>
                                <div style={{display: "flex", flexDirection: "row", justifyContent: "flex-start", width: "20%", minWidth: "300px"}}>
                                    <div style={{paddingTop: "20px", paddingRight: "2px"}}><img className={css_self.EnlilLogo} src={enlilImage} alt="Home"/></div>
                                    <div style={{paddingTop: "25px", marginLeft: "65px", marginRight: "4px"}}><Typography style={{fontSize: 24, fontWeight: 400, color: Blue3}}>|</Typography></div>
                                    <div style={{paddingTop: "27px", marginLeft: "3px"}}><Typography style={{fontSize: 24, fontWeight: 400, color: Blue3}}>Document Control</Typography></div>
                                </div>
                                <div style={{paddingTop: "7px", width: "80%"}}>
                                    <Typography style={{fontSize: "14px", fontWeight: "400", color: "#000000", textAlign: "left"}}>Mus netus duis cras consectetur magnis platea ad. Dis platea tempor netus duis sapien curae quam. Suspendisse enim vivamus ridiculus tellus porttitor dapibus nibh. Erat sem maecenas; eros urna ullamcorper malesuada ante? Suspendisse commodo habitant ridiculus; sollicitudin nibh mauris. Tristique rhoncus in ac lacinia molestie neque hendrerit mi.</Typography>
                                </div>
                            </div>
                        </IconButton>
                    </div>
                    <div style={{marginBottom: "30px"}}>
                        <IconButton className={css_self.AppMenuButton}
                                    onClick={() => {
                                        navigate("/apidocstools/apiexplorer");
                                    }}
                        >
                            <div style={{display: "flex", flexDirection: "row", justifyContent: "flex-start", height: "50px", width: "100%"}}>
                                <div style={{display: "flex", flexDirection: "row", justifyContent: "flex-start", width: "20%", minWidth: "300px"}}>
                                    <div style={{paddingTop: "20px", paddingRight: "2px"}}><img className={css_self.EnlilLogo} src={enlilImage} alt="Home"/></div>
                                    <div style={{paddingTop: "25px", marginLeft: "65px", marginRight: "4px"}}><Typography style={{fontSize: 24, fontWeight: 400, color: Blue3}}>|</Typography></div>
                                    <div style={{paddingTop: "27px", marginLeft: "3px"}}><Typography style={{fontSize: 24, fontWeight: 400, color: Blue3}}>ETL</Typography></div>
                                </div>
                                <div style={{paddingTop: "7px", width: "80%"}}>
                                    <Typography style={{fontSize: "14px", fontWeight: "400", color: "#000000", textAlign: "left"}}>Sit mollis accumsan augue iaculis bibendum magnis. Tincidunt suspendisse penatibus at nibh rutrum ornare torquent. Cras leo sem torquent natoque libero non tincidunt. Aliquet dis urna sollicitudin eros maximus ante facilisis. Natoque metus cubilia aptent at nam cursus eget. Rhoncus dictumst sollicitudin tellus sodales blandit litora facilisi vivamus justo. Mus suscipit diam aenean sagittis nullam enim vulputate nec.</Typography>
                                </div>
                            </div>
                        </IconButton>
                    </div>
                    <div style={{marginBottom: "30px"}}>
                        <IconButton className={css_self.AppMenuButton}
                                    onClick={() => {
                                        navigate("/apidocstools/apiexplorer");
                                    }}
                        >
                            <div style={{display: "flex", flexDirection: "row", justifyContent: "flex-start", height: "50px", width: "100%"}}>
                                <div style={{display: "flex", flexDirection: "row", justifyContent: "flex-start", width: "20%", minWidth: "300px"}}>
                                    <div style={{paddingTop: "20px", paddingRight: "2px"}}><img className={css_self.EnlilLogo} src={enlilImage} alt="Home"/></div>
                                    <div style={{paddingTop: "25px", marginLeft: "65px", marginRight: "4px"}}><Typography style={{fontSize: 24, fontWeight: 400, color: Blue3}}>|</Typography></div>
                                    <div style={{paddingTop: "27px", marginLeft: "3px"}}><Typography style={{fontSize: 24, fontWeight: 400, color: Blue3}}>Equipment</Typography></div>
                                </div>
                                <div style={{paddingTop: "7px", width: "80%"}}>
                                    <Typography style={{fontSize: "14px", fontWeight: "400", color: "#000000", textAlign: "left"}}>Lorem porta auctor tempus molestie a vestibulum. Est purus tempus class nec egestas magnis. Ante rhoncus vulputate bibendum fames ut; inceptos auctor. Velit aliquam placerat iaculis varius porta; justo per enim. Molestie maecenas penatibus eu feugiat convallis risus fames efficitur. Lacinia elementum aptent lobortis nam, condimentum netus pretium facilisis. Facilisi urna diam id platea nisl felis eu molestie elit. Suscipit montes morbi pretium gravida fringilla consequat natoque.</Typography>
                                </div>
                            </div>
                        </IconButton>
                    </div>
                    <div style={{marginBottom: "30px"}}>
                        <IconButton className={css_self.AppMenuButton}
                                    onClick={() => {
                                        navigate("/apidocstools/apiexplorer");
                                    }}
                        >
                            <div style={{display: "flex", flexDirection: "row", justifyContent: "flex-start", height: "50px", width: "100%"}}>
                                <div style={{display: "flex", flexDirection: "row", justifyContent: "flex-start", width: "20%", minWidth: "300px"}}>
                                    <div style={{paddingTop: "20px", paddingRight: "2px"}}><img className={css_self.EnlilLogo} src={enlilImage} alt="Home"/></div>
                                    <div style={{paddingTop: "25px", marginLeft: "65px", marginRight: "4px"}}><Typography style={{fontSize: 24, fontWeight: 400, color: Blue3}}>|</Typography></div>
                                    <div style={{paddingTop: "27px", marginLeft: "3px"}}><Typography style={{fontSize: 24, fontWeight: 400, color: Blue3}}>Project Milestones</Typography></div>
                                </div>
                                <div style={{paddingTop: "7px", width: "80%"}}>
                                    <Typography style={{fontSize: "14px", fontWeight: "400", color: "#000000", textAlign: "left"}}>Ac suspendisse feugiat ornare mus feugiat sed urna cursus. Hendrerit tincidunt ornare fames lacinia tortor scelerisque vehicula. Quam maecenas at commodo nisl mattis pharetra vivamus eget iaculis. Ex ipsum porta dapibus habitasse ac elit sodales. Nascetur lorem dui justo diam facilisi malesuada. Class commodo vulputate congue pretium sapien facilisi erat vivamus integer. Amet proin proin proin imperdiet elementum sed mollis scelerisque.</Typography>
                                </div>
                            </div>
                        </IconButton>
                    </div>
                    <div style={{marginBottom: "30px"}}>
                        <IconButton className={css_self.AppMenuButton}
                                    onClick={() => {
                                        navigate("/apidocstools/apiexplorer");
                                    }}
                        >
                            <div style={{display: "flex", flexDirection: "row", justifyContent: "flex-start", height: "50px", width: "100%"}}>
                                <div style={{display: "flex", flexDirection: "row", justifyContent: "flex-start", width: "20%", minWidth: "300px"}}>
                                    <div style={{paddingTop: "20px", paddingRight: "2px"}}><img className={css_self.EnlilLogo} src={enlilImage} alt="Home"/></div>
                                    <div style={{paddingTop: "25px", marginLeft: "65px", marginRight: "4px"}}><Typography style={{fontSize: 24, fontWeight: 400, color: Blue3}}>|</Typography></div>
                                    <div style={{paddingTop: "27px", marginLeft: "3px"}}><Typography style={{fontSize: 24, fontWeight: 400, color: Blue3}}>Purchase Orders</Typography></div>
                                </div>
                                <div style={{paddingTop: "7px", width: "80%"}}>
                                    <Typography style={{fontSize: "14px", fontWeight: "400", color: "#000000", textAlign: "left"}}>Augue at erat aliquam; elit curabitur ut sociosqu arcu. Venenatis dui convallis metus nostra pellentesque sapien. Etiam habitant magnis consequat ex efficitur. Penatibus a sed dapibus faucibus bibendum dis tempus vitae. Vulputate diam proin laoreet nec nullam a. Eleifend quam semper torquent pharetra mollis magnis placerat dictum urna. Quam gravida nisi mus ridiculus convallis torquent consectetur imperdiet sem. Potenti urna ultrices enim nostra; gravida at aptent. Varius aliquet scelerisque id quisque a. Turpis vulputate non lobortis; rhoncus vehicula efficitur.</Typography>
                                </div>
                            </div>
                        </IconButton>
                    </div>
                    <div style={{marginBottom: "30px"}}>
                        <IconButton className={css_self.AppMenuButton}
                                    onClick={() => {
                                        navigate("/apidocstools/apiexplorer");
                                    }}
                        >
                            <div style={{display: "flex", flexDirection: "row", justifyContent: "flex-start", height: "50px", width: "100%"}}>
                                <div style={{display: "flex", flexDirection: "row", justifyContent: "flex-start", width: "20%", minWidth: "300px"}}>
                                    <div style={{paddingTop: "20px", paddingRight: "2px"}}><img className={css_self.EnlilLogo} src={enlilImage} alt="Home"/></div>
                                    <div style={{paddingTop: "25px", marginLeft: "65px", marginRight: "4px"}}><Typography style={{fontSize: 24, fontWeight: 400, color: Blue3}}>|</Typography></div>
                                    <div style={{paddingTop: "27px", marginLeft: "3px"}}><Typography style={{fontSize: 24, fontWeight: 400, color: Blue3}}>Receiving</Typography></div>
                                </div>
                                <div style={{paddingTop: "7px", width: "80%"}}>
                                    <Typography style={{fontSize: "14px", fontWeight: "400", color: "#000000", textAlign: "left"}}>Dictum metus molestie accumsan massa pulvinar nulla. Id suscipit ullamcorper ipsum condimentum aliquam neque consequat aliquet. Ante netus facilisis aliquet tincidunt tempor. Aliquam potenti nec mauris, nostra primis a. Lacinia nibh sociosqu aliquam taciti duis. Felis lacus leo dictumst dictum duis orci est. Hendrerit tempus nascetur purus odio parturient leo. Lectus sem ac etiam accumsan nostra nisi gravida. Tristique feugiat tincidunt cubilia phasellus cras bibendum eleifend.</Typography>
                                </div>
                            </div>
                        </IconButton>
                    </div>
                </div>
            )
        }

        return content;
    }

    function stringAvatar(name: string) {
        return name
            .split(" ")
            .map(word => word[0])
            .join("")
            .substring(0, 2);
    }

    function renderAccountNavigation() {

        let accountMarkup = (
            <div>
                <IconButton className={css_self.NavMenuButton} onClick={(e) => {
                    if (appContext) { // @ts-ignore
                        appContext.sessionContext.showLoginDialog = true;
                    }
                    setShowLogin(true);
                }}>
                    <PermIdentity/>
                </IconButton>
            </div>
        )
        if (appContext?.sessionContext.valid()) {
            accountMarkup = (
                <Button className={css_self.NavMenuButton} variant="text" onClick={(e) => setMenu({id: "account", target: e.currentTarget})}>
                    <Avatar sx={{marginTop: "2px", bgcolor: Blue3, width: 35, height: 35}}>{stringAvatar(appContext?.sessionContext.getName())}</Avatar>
                </Button>
            )
        }

        return (
            <div>
                {showLogin ? <Login/> : null}
                {accountMarkup}
                {menu?.id === "account" ? (
                    <Menu anchorEl={menu.target}
                          anchorOrigin={{vertical: "bottom", horizontal: "right"}}
                          transformOrigin={{vertical: -10, horizontal: "center"}}
                          open={true}
                          onClose={() => setMenu(null)}
                          TransitionProps={{
                              onExited: () => setMenu(null),
                          }}
                          MenuListProps={{
                              ...{"data-cc-component": "NavMenu"},
                              disablePadding: true,
                              style: {"minWidth": "200px"},
                          }}
                          PaperProps={{
                              elevation: 2,
                          }}
                    >
                        <MenuItem key={"account"} disabled className={css_self.Standard}>
                            <Typography component="div" style={{fontSize: "15px", fontWeight: "400", color: Grey17}}>Company: <b>{appContext?.sessionContext.getCompanyName()}</b></Typography>
                        </MenuItem>
                        <MenuItem key={"name"} disabled className={css_self.Standard}>
                            <Typography component="div" style={{fontSize: "15px", fontWeight: "400", color: Grey17}}>Hello, <b>{appContext?.sessionContext.getName()}</b></Typography>
                        </MenuItem>
                        <MenuItem divider key={"help"} className={css_self.Standard} onClick={() => {
                            setMenu(null);
                            navigate("/help");
                        }}>
                            <HelpOutlineOutlined style={{color: Grey6}} />
                            <Typography style={{fontSize: "15px", fontWeight: "400", color: Grey17}}>Help</Typography>
                        </MenuItem>
                        <MenuItem divider key={"logout"} className={css_self.Standard} onClick={() => {
                            setMenu(null);
                            navigate("/logout");
                        }}>
                            <Logout style={{color: Grey6}} />
                            <Typography style={{fontSize: "15px", fontWeight: "400", color: Grey17}}>Log out</Typography>
                        </MenuItem>
                    </Menu>
                ) : null}
            </div>
        );
    }

    function renderAppsNavigation() {
        return (
            <div>
                <IconButton className={css_self.NavMenuButton} onClick={(e) => setMenu({id: "apps", target: e.currentTarget})}>
                    <Apps/>
                </IconButton>
                {menu?.id === "apps" ? (
                    <Popover anchorEl={menu.target}
                             anchorOrigin={{vertical: "bottom", horizontal: "right"}}
                             transformOrigin={{vertical: -12, horizontal: "left"}}
                             open={true}
                             onClose={() => setMenu(null)}
                             TransitionProps={{
                                 onExited: () => setMenu(null),
                             }}
                             PaperProps={{
                                 elevation: 2,
                             }}
                    >
                        <Box sx={{p: 2, bgcolor: "background.paper", width: "375px", height: "550px"}}>
                            <div style={{display: "flex", flexDirection: "column", padding: "10px"}}>
                                <div style={{display: "flex", flexDirection: "row", alignContent: "flex-start"}}>
                                    <div style={{width: "125px", display: "flex", flexDirection: "column", justifyContent: "center", marginLeft: "auto", marginRight: "auto"}}>
                                        <IconButton className={css_self.AppMenuButton}
                                            onClick={() => {
                                                navigate("/api-docs-tools/api-explorer");
                                            }}
                                        >
                                            <Engineering sx={{color: Blue3, width: 45, height: 45}}/>
                                        </IconButton>
                                        <Typography style={{fontSize: "14px", fontWeight: "400", color: "#000000", textAlign: "center"}}>API Explorer</Typography>
                                    </div>
                                    <div style={{width: "125px", display: "flex", flexDirection: "column", justifyContent: "center", marginLeft: "auto", marginRight: "auto"}}>
                                        <IconButton className={css_self.AppMenuButton}
                                            onClick={() => {
                                                navigate("/api-docs-tools/getting-started");
                                            }}
                                        >
                                            <DirectionsRun sx={{color: Blue3, width: 45, height: 45}}/>
                                        </IconButton>
                                        <Typography style={{fontSize: "14px", fontWeight: "400", color: "#000000", textAlign: "center"}}>Getting Started</Typography>
                                    </div>
                                    <div style={{width: "125px", display: "flex", flexDirection: "column", justifyContent: "center", marginLeft: "auto", marginRight: "auto"}}>
                                        <IconButton className={css_self.AppMenuButton}
                                            onClick={() => {
                                                navigate("/api-docs-tools/learn");
                                            }}
                                        >
                                            <School sx={{color: Blue3, width: 45, height: 45}}/>
                                        </IconButton>
                                        <Typography style={{fontSize: "14px", fontWeight: "400", color: "#000000", textAlign: "center"}}>Learn</Typography>
                                    </div>
                                </div>
                                <div style={{marginTop: "20px", display: "flex", flexDirection: "row", alignContent: "flex-start"}}>
                                    <div style={{width: "125px", display: "flex", flexDirection: "column", justifyContent: "center", marginLeft: "auto", marginRight: "auto"}}>
                                        <IconButton className={css_self.AppMenuButton}
                                            onClick={() => {
                                                navigate("/api-docs-tools/develop");
                                            }}
                                        >
                                            <DeveloperMode sx={{color: Blue3, width: 45, height: 45}}/>
                                        </IconButton>
                                        <Typography style={{fontSize: "14px", fontWeight: "400", color: "#000000", textAlign: "center"}}>Develop</Typography>
                                    </div>
                                    <div style={{width: "125px", display: "flex", flexDirection: "column", justifyContent: "center", marginLeft: "auto", marginRight: "auto"}}>
                                        <IconButton className={css_self.AppMenuButton}
                                            onClick={() => {
                                                navigate("/api-docs-tools/use-cases");
                                            }}
                                        >
                                            <EmojiObjects sx={{color: Blue3, width: 45, height: 45}}/>
                                        </IconButton>
                                        <Typography style={{fontSize: "14px", fontWeight: "400", color: "#000000", textAlign: "center"}}>Use Cases</Typography>
                                    </div>
                                    <div style={{width: "125px", display: "flex", flexDirection: "column", justifyContent: "center", marginLeft: "auto", marginRight: "auto"}}>
                                        <IconButton className={css_self.AppMenuButton}
                                            onClick={() => {
                                                navigate("/api-docs-tools/known-issues");
                                            }}
                                        >
                                            <BugReport sx={{color: Blue3, width: 45, height: 45}}/>
                                        </IconButton>
                                        <Typography style={{fontSize: "14px", fontWeight: "400", color: "#000000", textAlign: "center"}}>Known Issues</Typography>
                                    </div>
                                </div>
                                <div style={{marginTop: "20px", display: "flex", flexDirection: "row", justifyContent: "flex-start"}}>
                                    <div style={{width: "125px", display: "flex", flexDirection: "column", justifyContent: "center", marginLeft: "auto", marginRight: "auto"}}>
                                        <IconButton className={css_self.AppMenuButton}
                                            onClick={() => {
                                                navigate("/api-docs-tools/reference");
                                            }}
                                        >
                                            <Checklist sx={{color: Blue3, width: 45, height: 45}}/>
                                        </IconButton>
                                        <Typography style={{fontSize: "14px", fontWeight: "400", color: "#000000", textAlign: "center"}}>Reference</Typography>
                                    </div>
                                    <div style={{width: "125px", display: "flex", flexDirection: "column", justifyContent: "center", marginLeft: "auto", marginRight: "auto"}}>
                                        <IconButton className={css_self.AppMenuButton}
                                            onClick={() => {
                                                navigate("/api-docs-tools/change-history");
                                            }}
                                        >
                                            <FactCheck sx={{color: Blue3, width: 45, height: 45}}/>
                                        </IconButton>
                                        <Typography style={{fontSize: "14px", fontWeight: "400", color: "#000000", textAlign: "center"}}>Change History</Typography>
                                    </div>
                                    <div style={{width: "125px", display: "flex", flexDirection: "column", justifyContent: "center", marginLeft: "auto", marginRight: "auto"}}>
                                    </div>
                                </div>
                            </div>
                            <div style={{marginTop: "20px", borderTop: "1px solid #EEEEEE", display: "flex", flexDirection: "row", justifyContent: "flex-start"}}>
                                <div style={{width: "125px", display: "flex", flexDirection: "column", justifyContent: "center", marginLeft: "auto", marginRight: "auto"}}>
                                    <IconButton className={css_self.AppMenuButton}
                                        onClick={() => {
                                            navigate("/community/enlilanswers");
                                        }}
                                    >
                                        <Check sx={{color: Blue3, width: 45, height: 45}}/>
                                    </IconButton>
                                    <Typography style={{fontSize: "14px", fontWeight: "400", color: "#000000", textAlign: "center"}}>Enlil Answers</Typography>
                                </div>
                                <div style={{width: "125px", display: "flex", flexDirection: "column", justifyContent: "center", marginLeft: "auto", marginRight: "auto"}}>
                                    <IconButton className={css_self.AppMenuButton}
                                        onClick={() => {
                                            navigate("/community/aiplayground");
                                        }}
                                    >
                                        <Engineering sx={{color: Blue3, width: 45, height: 45}}/>
                                    </IconButton>
                                    <Typography style={{fontSize: "14px", fontWeight: "400", color: "#000000", textAlign: "center"}}>AI Playground</Typography>
                                </div>
                                <div style={{width: "125px", display: "flex", flexDirection: "column", justifyContent: "center", marginLeft: "auto", marginRight: "auto"}}>
                                    <IconButton className={css_self.AppMenuButton}
                                        onClick={() => {
                                            navigate("/community/discussions");
                                        }}
                                    >
                                        <Feed sx={{color: Blue3, width: 45, height: 45}}/>
                                    </IconButton>
                                    <Typography style={{fontSize: "14px", fontWeight: "400", color: "#000000", textAlign: "center"}}>Discussions</Typography>
                                </div>
                            </div>
                            <div style={{marginTop: "20px", display: "flex", flexDirection: "row", alignContent: "flex-start"}}>
                                <div style={{width: "125px", display: "flex", flexDirection: "column", justifyContent: "center", marginLeft: "auto", marginRight: "auto"}}>
                                    <IconButton className={css_self.AppMenuButton}
                                        onClick={() => {
                                            navigate("/community/blogs");
                                        }}
                                    >
                                        <Hive sx={{color: Blue3, width: 45, height: 45}}/>
                                    </IconButton>
                                    <Typography style={{fontSize: "14px", fontWeight: "400", color: "#000000", textAlign: "center"}}>Blogs</Typography>
                                </div>
                                <div style={{width: "125px", display: "flex", flexDirection: "column", justifyContent: "center", marginLeft: "auto", marginRight: "auto"}}>
                                </div>
                                <div style={{width: "125px", display: "flex", flexDirection: "column", justifyContent: "center", marginLeft: "auto", marginRight: "auto"}}>
                                </div>

                            </div>
                        </Box>
                    </Popover>
                ) : null}
            </div>
        );
    };

    function renderMenuNavigation() {
        return (
            <div>
                <IconButton className={css_self.NavMenuButton} onClick={(e) => setMenu(menu === null ? {id: "menu", target: e.currentTarget} : null)}>
                    <MenuIcon/>
                </IconButton>
            </div>
        );
    };

}
