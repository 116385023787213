import {useEffect} from "react";

// This hook provides a custom CSS variable --cloudcath-wh
// which is used for 1vh inconsistencies on mobile browsers.
// https://css-tricks.com/the-trick-to-viewport-units-on-mobile/#css-custom-properties-the-trick-to-correct-sizing
export function useCssPropertyWindowHeight(deps) {

    function updateProperty() {
        document.documentElement.style.setProperty(
            "--cloudcath-wh",
            `${window.innerHeight * 0.01}px`,
        );
    }

    window.addEventListener("resize", updateProperty);

    // eslint-disable-next-line react-hooks/exhaustive-deps
    return useEffect(updateProperty, deps !== undefined ? deps : []);
}
