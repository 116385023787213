import EnlilAxios from "../../../../util/EnlilAxios";
import {V1DeveloperCredentialIDOCToArray} from "../../../../util/Payload";
import EnterpriseComponent from "../../EnterpriseComponent";


class DeveloperCredentialEnterpriseComponent extends EnterpriseComponent {
    performQuery = (page, pageSize, foreignKey) => {
        page = page || 1;
        pageSize = pageSize || this.getDefaultCacheSize();

        this.notifyLoadStartListeners();

        let fk = {};
        if (this.enterpriseObject !== null) {
            let relationship = this.enterpriseObject.getRelationshipForEnterpriseComponent(this);

            fk[relationship.getDestinationAttribute()] = foreignKey;
        }

        EnlilAxios({
            method: "GET",
            url: "t2/v1/developer_credentials",
            json: true,
            params: {
                "Limit": pageSize,
                "Offset": (page - 1) * pageSize,
                "Sort": this.getFormattedSortSpecification(),
                ...(foreignKey ? fk : {}),
                ...this.getSearchSpecification(),
            },
            headers: {
                "idoc-response": this.getIDOCResponse() ?? "v1.DeveloperCredential-001",
            },
        }).then(response => {
            this.fetchEnterpriseComponentSuccess(V1DeveloperCredentialIDOCToArray(response.data), page, pageSize);
        }).catch(error => {
            this.fetchEnterpriseComponentFailed(error);
        });
    };

    enterpriseComponentPutIDOC = () => {
        let payload = {};

        payload.ShifamedMessage = {};
        payload.ShifamedMessage["-idoc"] = "v1.DeveloperCredential-001";
        payload.ShifamedMessage.DeveloperCredential = this.preparePUTPayload();

        return payload;
    };

    performUpdate() {
        let payload = this.enterpriseComponentPutIDOC();

        let options = {
            method: "PUT",
            url: "t2/v1/developer_credentials",
            json: true,
            data: payload,
            headers: {
                "idoc-request": "v1.DeveloperCredential-001",
                "idoc-response": "v1.DeveloperCredential-001",
            },
        };
        this.notifyUpdateStartListeners();
        EnlilAxios(options)
            .then(response => {
                this.updateEnterpriseComponentSuccess(V1DeveloperCredentialIDOCToArray(response.data));
            })
            .catch(error => {
                this.updateEnterpriseComponentFailed(error);
            });
    };

    enterpriseComponentPostIDOC = () => {
        let payload = {};

        let developerCredential = {};

        developerCredential["USID"] = this.getAttributeValue("USID");
        developerCredential["ApplicationUSID"] = this.getEnterpriseObject().getEnterpriseComponent("DeveloperApplication").getAttributeValue("USID");
        developerCredential["APIKey"] = this.getAttributeValue("APIKey");
        developerCredential["SecretKey"] = this.getAttributeValue("SecretKey");
        developerCredential["Active"] = true;

        payload.ShifamedMessage = {};
        payload.ShifamedMessage["-idoc"] = "v1.DeveloperCredential-001";
        payload.ShifamedMessage.DeveloperCredential = developerCredential;

        return payload;
    };

    performInsert() {
        let payload = this.enterpriseComponentPostIDOC();

        let options = {
            method: "POST",
            url: "t2/v1/developer_credentials",
            json: true,
            data: payload,
            headers: {
                "idoc-request": "v1.DeveloperCredential-001",
                "idoc-response": "v1.DeveloperCredential-001",
            },
        };
        this.notifyInsertStartListeners();
        EnlilAxios(options)
            .then(response => {
                this.insertEnterpriseComponentSuccess(V1DeveloperCredentialIDOCToArray(response.data));
            })
            .catch(error => {
                this.insertEnterpriseComponentFailed(error);
            });
    };

}

export default DeveloperCredentialEnterpriseComponent;