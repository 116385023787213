import EnterpriseComponentAttributeMetadata from "../../EnterpriseComponentAttributeMetadata";
import EnterpriseComponentMetadata from "../../EnterpriseComponentMetadata";

class DeveloperEndpointEnterpriseComponentMetadata extends EnterpriseComponentMetadata {

    constructor() {
        super();

        this.name = "DeveloperEndpointConfiguration";
        this.defaultCacheSize = 50;

        this.attributes["DeveloperEndpointUSID"] = new EnterpriseComponentAttributeMetadata(
            "DeveloperEndpointUSID", // name
            false, // NoCopy
            false, // NoUpdate
            false, // Required
            false, // RequiredForQuery
            null, // Picklist
            null, // Scale
            null, // Precision
            null, // length
            null, // minLength
            null, // pattern
            "USID", // Type
            null, // default value
            false, // computed
        );
        this.attributes["ConfigurationName"] = new EnterpriseComponentAttributeMetadata(
            "ConfigurationName", // name
            false, // NoCopy
            false, // NoUpdate
            true, // Required
            false, // RequiredForQuery
            null, // Picklist
            null, // Scale
            null, // Precision
            null, // length
            null, // minLength
            null, // pattern
            "String", // Type
            null, // default value
            false, // computed
        );
        this.attributes["ConfigurationValue"] = new EnterpriseComponentAttributeMetadata(
            "ConfigurationValue", // name
            false, // NoCopy
            false, // NoUpdate
            true, // Required
            false, // RequiredForQuery
            null, // Picklist
            null, // Scale
            null, // Precision
            null, // length
            null, // minLength
            null, // pattern
            "String", // Type
            null, // default value
            false, // computed
        );
        this.attributes["CreatedDatetime"] = new EnterpriseComponentAttributeMetadata(
            "CreatedDatetime", // name
            false, // NoCopy
            true, // NoUpdate
            true, // Required
            false, // RequiredForQuery
            null, // Picklist
            null, // Scale
            null, // Precision
            null, // length
            null, // minLength
            null, // pattern
            "Datetime", // Type
            null, // default value
            false, // computed
        );
        this.attributes["LastModifiedDatetime"] = new EnterpriseComponentAttributeMetadata(
            "LastModifiedDatetime", // name
            false, // NoCopy
            true, // NoUpdate
            true, // Required
            false, // RequiredForQuery
            null, // Picklist
            null, // Scale
            null, // Precision
            null, // length
            null, // minLength
            null, // pattern
            "Datetime", // Type
            null, // default value
            false, // computed
        );
        this.attributes["Action"] = new EnterpriseComponentAttributeMetadata(
            "Action", // name
            false, // NoCopy
            true, // NoUpdate
            true, // Required
            false, // RequiredForQuery
            null, // Picklist
            null, // Scale
            null, // Precision
            null, // length
            null, // minLength
            null, // pattern
            null, // Type
            null, // default value
            false, // computed
        );
        this.attributes["USID"] = new EnterpriseComponentAttributeMetadata(
            "USID", // name
            false, // NoCopy
            true, // NoUpdate
            true, // Required
            false, // RequiredForQuery
            null, // Picklist
            null, // Scale
            null, // Precision
            36, // length
            null, // minLength
            null, // pattern
            "UUID", // Type
            null, // default value
            false, // computed
        );
    }
}

export default DeveloperEndpointEnterpriseComponentMetadata;
