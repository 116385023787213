import {v4 as uuidv4} from "uuid";
import EventBus from "../../util/EventBus";
import EnterpriseComponentAttribute from "./EnterpriseComponentAttribute";
import enterpriseComponentMetadataClassFactory from "../../metadata/EnterpriseComponentMetadataClassFactory";

class EnterpriseComponent {
    /*
    public MODE_NEW = 100;
    public MODE_QUERY = 101;
    public MODE_IDLE = 102;
    public MODE_RESULTS = 103;
    public MODE_DELETE = 104;
    public MODE_UPDATE = 105;
    */

    constructor(name) {
        this.MODE_NEW = 100;
        this.MODE_QUERY = 101;
        this.MODE_IDLE = 102;
        this.MODE_RESULTS = 103;
        this.MODE_DELETE = 104;
        this.MODE_UPDATE = 105;

        let metadataClass = enterpriseComponentMetadataClassFactory(name);
        // @ts-ignore
        this.enterpriseComponentMetadata = new (metadataClass)(name);

        let metadataAttributes = this.enterpriseComponentMetadata.getAttributes();
        this.attributes = {};

        for (const key of Object.keys(metadataAttributes)) {
            let md = metadataAttributes[key];

            this.attributes[md.getName()] = new EnterpriseComponentAttribute(this, md);
        }

        this.visibilityMode = null;
        this.visibilityValue = null;

        this.resultsBuffer = new Map();
        this.queryBuffer = new Map();
        this.recordPointer = -1;
        this.pagePointer = -1;
        this.enterpriseObject = null;
        this.isComponentValid = false;

        this.recordWillChangeListeners = [];
        this.recordChangedListeners = [];
        this.invalidListeners = [];
        this.startNewRecordModeListeners = [];
        this.startEditRecordModeListeners = [];
        this.singleRecordRefreshStartedListeners = [];
        this.singleRecordRefreshCompletedListeners = [];
        this.insertRollbackCompletedListeners = [];
        this.loadStartedListeners = [];
        this.updateRollbackCompletedListeners = [];
        this.queryRollbackCompletedListeners = [];
        this.loadCompletedListeners = [];
        this.valueChangedListeners = [];
        this.updateStartedListeners = [];
        this.updateCompletedListeners = [];
        this.insertStartedListeners = [];
        this.insertCompletedListeners = [];
        this.deleteStartedListeners = [];
        this.deleteCompletedListeners = [];
        this.newQueryListeners = [];
        this.newDeleteListeners = [];

        this.searchSpecification = {};
        this.sortSpecification = null;
        this.totalRowcount = -1;
        this.waiting = false;
        this.activeTimeout = null;
        this.mode = this.MODE_IDLE;
        this.supportingData = {};
        this.idocRequest = null;
        this.idocResponse = null;
        this.error = null;

        this.store = {}
    };

    addRecordWillChangeListener = (newListener) => {
        if (this.recordWillChangeListeners.some(listener => listener.applet.getName() === newListener.applet.getName()) === false) {
            this.recordWillChangeListeners.push(newListener);
        }
    };

    addRecordChangedListener = (newListener) => {
        if (this.recordChangedListeners.some(listener => listener.getName() === newListener.getName()) === false) {
            this.recordChangedListeners.push(newListener);
        }
    };

    addDeleteCompletedListener = (newListener) => {
        if (this.deleteCompletedListeners.some(listener => listener.getName() === newListener.getName()) === false) {
            this.deleteCompletedListeners.push(newListener);
        }
    };

    addDeleteStartedListener = (newListener) => {
        if (this.deleteStartedListeners.some(listener => listener.getName() === newListener.getName()) === false) {
            this.deleteStartedListeners.push(newListener);
        }
    };

    addInvalidListener = (newListener) => {
        if (this.invalidListeners.some(listener => listener.getName() === newListener.getName()) === false) {
            this.invalidListeners.push(newListener);
        }
    };

    addInsertRollbackCompletedListener = (newListener) => {
        if (this.insertRollbackCompletedListeners.some(listener => listener.getName() === newListener.getName()) === false) {
            this.insertRollbackCompletedListeners.push(newListener);
        }
    };

    addLoadCompletedListener = (newListener) => {
        if (this.loadCompletedListeners.includes(newListener) === false) {
            this.loadCompletedListeners.push(newListener);
        }
    };

    addValueChangedListener = (newListener) => {
        if (this.valueChangedListeners.some(listener => listener.getName() === newListener.getName()) === false) {
            this.valueChangedListeners.push(newListener);
        }
    };

    addLoadStartedListener = (newListener) => {
        if (this.loadStartedListeners.includes(newListener) === false) {
            this.loadStartedListeners.push(newListener);
        }
    };

    addNewDeleteListener = (newListener) => {
        if (this.newDeleteListeners.some(listener => listener.getName() === newListener.getName()) === false) {
            this.newDeleteListeners.push(newListener);
        }
    };

    addNewQueryListener = (newListener) => {
        if (this.newQueryListeners.some(listener => listener.getName() === newListener.getName()) === false) {
            this.newQueryListeners.push(newListener);
        }
    };

    addStartNewRecordModeCompletedListener = (newListener) => {
        if (this.startNewRecordModeListeners.some(listener => listener.getName() === newListener.getName()) === false) {
            this.startNewRecordModeListeners.push(newListener);
        }
    };

    addStartEditRecordModeCompletedListener = (newListener) => {
        if (this.startEditRecordModeListeners.some(listener => listener.getName() === newListener.getName()) === false) {
            this.startEditRecordModeListeners.push(newListener);
        }
    };

    addSingleRecordRefreshStartedListener = (newListener) => {
        if (this.singleRecordRefreshStartedListeners.some(listener => listener.getName() === newListener.getName()) === false) {
            this.singleRecordRefreshStartedListeners.push(newListener);
        }
    };

    addSingleRecordRefreshCompletedListener = (newListener) => {
        if (this.singleRecordRefreshCompletedListeners.some(listener => listener.getName() === newListener.getName()) === false) {
            this.singleRecordRefreshCompletedListeners.push(newListener);
        }
    };

    addUpdateCompletedListener = (newListener) => {
        if (this.updateCompletedListeners.some(listener => listener.getName() === newListener.getName()) === false) {
            this.updateCompletedListeners.push(newListener);
        }
    };

    addUpdateRollbackCompletedListener = (newListener) => {
        if (this.updateRollbackCompletedListeners.some(listener => listener.getName() === newListener.getName()) === false) {
            this.updateRollbackCompletedListeners.push(newListener);
        }
    };

    addUpdateStartedListener = (newListener) => {
        if (this.updateStartedListeners.some(listener => listener.getName() === newListener.getName()) === false) {
            this.updateStartedListeners.push(newListener);
        }
    };

    addInsertCompletedListener = (newListener) => {
        if (this.insertCompletedListeners.some(listener => listener.getName() === newListener.getName()) === false) {
            this.insertCompletedListeners.push(newListener);
        }
    };

    addInsertStartedListener = (newListener) => {
        if (this.insertStartedListeners.some(listener => listener.getName() === newListener.getName()) === false) {
            this.insertStartedListeners.push(newListener);
        }
    };

    addQueryRollbackCompletedListener = (newListener) => {
        if (this.queryRollbackCompletedListeners.some(listener => listener.getName() === newListener.getName()) === false) {
            this.queryRollbackCompletedListeners.push(newListener);
        }
    };

    canChangeAttributeValue = (attributeName) => {
        if (this.mode === this.MODE_IDLE) {
            return false;
        }

        let attribute = this.getAttributes()[attributeName];

        if (attribute === null) {
            return false;
        }

        return !(this.mode === this.MODE_RESULTS && this.getBuffer().size === 0);
    };

    clearAttribute = (attributeName) => {
        if (this.canChangeAttributeValue(attributeName) === false) {
            return false;
        }

        let row = this.getBuffer()[this.getRecordPointer()];

        row["__operation"] = "query results";

        row[attributeName] = null;

        if (row[attributeName + "_original"] !== undefined) {
            delete row[attributeName + "_original"];
        }
        if (row[attributeName + "_old"] !== undefined) {
            delete row[attributeName + "_old"];
        }

        this.notifyValueChangedListeners();
    };

    deleteEnterpriseComponentFailed = () => {
        EventBus.error.emit("show-system-error");
    };

    deleteEnterpriseComponentSuccess = () => {
        if (this.getBuffer().size > 0 && this.getBuffer().get(this.getPagePointer()).length > 0) {
            this.getBuffer().get(this.getPagePointer()).splice(this.getRecordPointer(), 1);
            if (this.getBuffer().get(this.getPagePointer()).length > 0) {
                if (this.getRecordPointer() > 0) this.recordPointer = this.recordPointer - 1;
            }
            else this.recordPointer = -1;
        }
        if (this.getBuffer().size > 0 && this.getBuffer().get(this.getPagePointer()).length > 0) {
            this.getBuffer().get(this.getPagePointer())[this.getRecordPointer()].RowSelected = true;
        }
        if (this.getBuffer().size === 1 && this.getBuffer().get(this.getPagePointer()).length === 0) {
            this.totalRowcount = 0;
        } else {
            this.totalRowcount -= 1;
        }
        this.mode = this.MODE_RESULTS;

        this.isComponentValid = true;

        this.notifyDeleteCompletedListeners();
    };

    editRecord = () => {
        this.mode = this.MODE_UPDATE;

        this.notifyStartEditRecordModeListeners();
    };

    executeDelete() {
        this.performDelete();
    };

    executeQuery = (page, pageSize) => {
        if (this.waiting) {
            if (this.activeTimeout !== null) {
                clearTimeout(this.activeTimeout);
            }
            this.activeTimeout = setTimeout(() => {
                this.executeQuery(page, pageSize);
            }, 1000);
            return;
        }
        this.waiting = true;

        let foreignKey = null;
        if (this.getEnterpriseObject() === null) {
            this._executeQuery(page, pageSize, foreignKey);
        } else {
            let relationship = this.getEnterpriseObject().getRelationshipForChildEnterpriseComponent(this);
            if (relationship === null) {
                this._executeQuery(page, pageSize, foreignKey);
            } else {
                let parentEnterpriseComponent = relationship.getParentEnterpriseComponent();

                if (parentEnterpriseComponent.isValid() === false) {
                    this.waiting = false;
                    this.notifyLoadCompletedListeners();
                    return;
                }

                if (parentEnterpriseComponent.getBuffer().size === 0) {
                    this.waiting = false;
                    this.isComponentValid = true;
                    this.invalidateChildren();
                    this.notifyLoadCompletedListeners();
                } else {
                    if (parentEnterpriseComponent.getRecordPointer() !== -1) {
                        foreignKey = parentEnterpriseComponent.getBuffer().get(parentEnterpriseComponent.getPagePointer())[parentEnterpriseComponent.getRecordPointer()][relationship.getSourceAttribute()];
                    }
                }

                if (foreignKey !== null) {
                    this._executeQuery(page, pageSize, foreignKey);
                }
            }
        }
    };

    _executeQuery = (page, pageSize, foreignKey) => {
        if (page === undefined || pageSize === undefined) {
            this.resultsBuffer = new Map();
            this.performQuery(1, 100, foreignKey);
        } else {
            if (this.mode === this.MODE_QUERY) {
                this.resultsBuffer = new Map();
                this.error = null;
                this.performQuery(page, pageSize, foreignKey);
                this.mode = this.MODE_RESULTS;
            } else if (this.resultsBuffer.size === 0 && this.totalRowcount === 0) {
                this.isComponentValid = true;

                this.waiting = false;
                this.notifyLoadCompletedListeners();
            } else if (this.resultsBuffer.get(page) !== undefined) {
                this.isComponentValid = true;
                this.pagePointer = page;

                this.waiting = false;
                this.notifyLoadCompletedListeners();
            } else {
                if (this.pagePointer !== page) this.recordPointer = -1;
                this.mode = this.MODE_QUERY;
                this.error = null;
                this.performQuery(page, pageSize, foreignKey);
                this.mode = this.MODE_RESULTS;
            }
        }
    };

    executeInsert = () => {
        this.performInsert();
    };

    executeInsertRollback = () => {
        this.mode = this.MODE_RESULTS;

        if (this.getBuffer().size > 0 && this.getBuffer().get(this.getPagePointer()).length > 0) {
            let operation = this.resultsBuffer.get(this.getPagePointer())[this.getRecordPointer()]["__operation"];
            if (operation && operation === "new") {
                this.resultsBuffer.get(this.getPagePointer()).splice(this.getRecordPointer(), 1);
            }

            if (this.getBuffer().size > 0 && this.getBuffer().get(this.getPagePointer()).length > 0) {
                for (let i = 0; i < this.getBuffer().get(this.getPagePointer()).length; i++) {
                    this.getBuffer().get(this.getPagePointer())[i].RowSelected = false;
                    this.getBuffer().get(this.getPagePointer())[i].RowNum = i;
                }

                this.getBuffer().get(this.getPagePointer())[this.getRecordPointer()].RowSelected = true;
            } else {
                this.setRecordPointer(-1);
            }
        } else {
            this.setRecordPointer(-1);
        }
        this.notifyInsertRollbackCompletedListeners();
    };

    executeQueryRollback = () => {
        this.mode = this.MODE_RESULTS;

        this.notifyQueryRollbackCompletedListeners();
    };

    executeSingleRecordRefresh = () => {
        if (this.singleRecordRefreshCompletedListeners.length === 0) {
            return;
        }

        if (this.waiting) {
            if (this.activeTimeout !== null) {
                clearTimeout(this.activeTimeout);
            }
            this.activeTimeout = setTimeout(() => {
                let record = this.getCurrentRecord();
                if (record) this.performSingleRecordRefresh(record["USID"]);
            }, 1000);
            return;
        }

        let record = this.getCurrentRecord();
        if (record) {
            this.waiting = true;
            this.performSingleRecordRefresh(record["USID"]);
        }
    };

    executeUpdate = () => {
        this.performUpdate();

        let row = this.getCurrentRecord();
        if (row === undefined) return;

        for (const key of Object.keys(this.getAttributes())) {
            let attribute = this.getAttributes()[key];

            if (row[attribute.getName() + "_original"] !== undefined) {
                delete row[attribute.getName() + "_original"];
                delete row[attribute.getName() + "_old"];
            }
        }
        row["__operation"] = "query results";
    };

    executeUpdateRollback = () => {
        this.mode = this.MODE_RESULTS;

        let row = this.getCurrentRecord();
        if (row === undefined) return;

        for (const key of Object.keys(this.getAttributes())) {
            let attribute = this.getAttributes()[key];

            if (row[attribute.getName() + "_original"] !== undefined) {
                row[attribute.getName()] = row[attribute.getName() + "_original"];
                delete row[attribute.getName() + "_original"];
                delete row[attribute.getName() + "_old"];
            }
        }
        row["__operation"] = "query results";

        this.notifyUpdateRollbackCompletedListeners();
    };

    fetchEnterpriseComponentFailed = () => {
        EventBus.error.emit("show-system-error");
    };

    fetchEnterpriseComponentSuccess = (data, page, pageSize) => {
        this.mode = this.MODE_RESULTS;

        if (data["Results"].length !== 0) {
            let existingRows = this.resultsBuffer.get(page);
            if (existingRows) {
                let newArray = this.resultsBuffer.get(page).concat(data["Results"]);
                this.resultsBuffer.set(page, newArray);
            } else {
                this.resultsBuffer.set(page, data["Results"]);
            }

            let b = this.resultsBuffer.get(page);
            for (let i = 0; i < b.length; i++) {
                b[i].RowNum = i;
            }
        }

        if (data["Error"] !== undefined) {
            this.error = data["Error"];
        }

        if (data["Count"] === undefined) {
            if (data["Results"].length < pageSize) {
                this.totalRowcount = this.resultsBuffer.size;
            } else {
                this.totalRowcount = -1;
            }
        } else {
            this.totalRowcount = data["Count"];
            if (this.totalRowcount) {
                this.totalRowcount = parseInt(this.totalRowcount);
            }
        }

        this.isComponentValid = true;

        this.pagePointer = page;

        if (this.recordPointer < 1) {
            if (data["Results"].length > 0) {
                this.recordPointer = 0;
                this.resultsBuffer.get(this.getPagePointer())[0].RowSelected = true;
            }
            if (this.getEnterpriseObject() !== null) {
                let childEnterpriseComponents = this.getEnterpriseObject().getChildEnterpriseComponents(this);

                for (let key of childEnterpriseComponents.keys()) {
                    let childEnterpriseComponent = childEnterpriseComponents.get(key);

                    for (let j = 0; j < data["Results"].length; j++) {
                        let childDataResults = data["Results"][j]["ListOf" + key];

                        if (childDataResults !== undefined) {
                            childEnterpriseComponent.fetchEnterpriseComponentSuccess(childDataResults, 1, 1000);
                        } else {
                            childEnterpriseComponent.invalidate();
                        }
                    }
                }
            }
            this.notifyLoadCompletedListeners();
        } else {
            this.notifyLoadCompletedListeners();
            this.nextRecord();
        }
        this.waiting = false;
    };

    firstRecord = (enable_events = true) => {
        if (this.hasEnterpriseComponentBeenModified()) {
            return false;
        }

        if (!this.isValid()) {
            return false;
        }

        if (this.getBuffer().size === 0) return false;

        this.recordPointer = 0;
        this.pagePointer = 1;

        let row = this.getCurrentRecord();
        if (row === undefined) return false;

        if (row.RowSelected === true) {
            row.RowSelected = false;
        }

        let found = false;
        if (this.enterpriseObject !== null) {
            let relationship = this.enterpriseObject.getRelationshipForChildEnterpriseComponent(this);
            if (relationship) {
                let value = this.getSourceAttributeValueForParent(relationship.getSourceAttribute());
                for (let i = 0; i < this.getBuffer().get(this.getPagePointer()).length; i++) {
                    if (this.getBuffer().get(this.getPagePointer())[this.recordPointer][relationship.getDestinationAttribute()] === value) {
                        found = true;
                        break;
                    } else {
                        this.recordPointer++;
                    }
                }
            } else {
                found = true;
            }
        } else {
            found = true;
        }

        if (found) {
            this.getBuffer().get(this.getPagePointer())[this.recordPointer].RowSelected = true;
            if (enable_events) this.notifyRecordChangedListeners();
            this.invalidateChildren();
            return true;
        }

        return false;
    };

    forceAttributeValue = (attributeName, attributeValue) => {
        if (this.canChangeAttributeValue(attributeName) === false) {
            return false;
        }

        let row = this.getBuffer()[this.getRecordPointer()];

        if (attributeValue === "NULL") {
            attributeValue = null;
        }
        this.getBuffer()[this.getRecordPointer()][attributeName] = attributeValue;

        row["__operation"] = "update";
        row[attributeName + "_original"] = attributeValue;
        row[attributeName + "_old"] = attributeValue;

        this.notifyValueChangedListeners();
    };

    getAttributes = () => {
        return this.attributes;
    };

    getAttribute = (name) => {
        return this.getAttributes()[name];
    };

    getAttributeValue = (attributeName, type) => {
        if (this.getRecordPointer() === -1) return null;

        let value;
        let row;

        let suffix = "";

        if (type === "original") {
            suffix = "_original";
        }

        if (this.mode === this.MODE_QUERY) {
            row = this.getBuffer().get(this.getPagePointer())[0];
            value = row[attributeName + suffix];
        } else {
            row = this.getBuffer().get(this.getPagePointer())[this.getRecordPointer()];
            value = row[attributeName + suffix];
        }

        return value;
    };

    getAttributeValueAsString = (attributeName, type) => {
        let value = this.getAttributeValue(attributeName, type);
        let out;

        if (value === null || value === undefined) {
            out = null;
        } else {
            out = value.toString();
        }
        return out;
    };

    getBuffer = () => {
        if (this.mode === this.MODE_QUERY) {
            return this.queryBuffer;
        } else if (this.mode === this.MODE_RESULTS) {
            return this.resultsBuffer;
        } else if (this.mode === this.MODE_NEW) {
            return this.resultsBuffer;
        } else if (this.mode === this.MODE_IDLE) {
            return this.resultsBuffer;
        } else if (this.mode === this.MODE_UPDATE) {
            return this.resultsBuffer;
        } else if (this.mode === this.MODE_DELETE) {
            return this.resultsBuffer;
        }
        return {};
    };

    getCurrentRecord = () => {
        let page = this.getBuffer().get(this.getPagePointer());
        if (page === undefined) {
            return undefined;
        }

        try {
            return page[this.getRecordPointer()];
        } catch (error) {
            return undefined;
        }
    };

    getDefaultCacheSize = () => {
        return this.enterpriseComponentMetadata.defaultCacheSize;
    };

    getEnterpriseObject = () => {
        return this.enterpriseObject;
    };

    getError = () => {
        return this.error;
    };

    getFormattedSortSpecification = () => {
        if (this.getSortSpecification() === null) {
            return null;
        }

        let out = "";
        for (let i = 0; i < this.getSortSpecification().length; i++) {
            let sortSpec = this.getSortSpecification()[i];

            if (i !== 0) {
                out = out + ",";
            }
            out = out + sortSpec["SortAttribute"] + ":" + sortSpec["Direction"];
            i++;
        }

        if (out === "") out = null;

        return out;
    };

    getIDOCRequest = () => {
        return this.idocRequest;
    };

    getIDOCResponse = () => {
        return this.idocResponse;
    };

    getMode = () => {
        return this.mode;
    };

    getName = () => {
        return this.enterpriseComponentMetadata.getName();
    };

    getPagePointer = () => {
        if (this.mode === this.MODE_QUERY) {
            return 1;
        } else if (this.mode === this.MODE_NEW) {
            return 1;
        }
        return this.pagePointer;
    };

    getParentEnterpriseComponent = () => {
        if (this.getEnterpriseObject() === null) {
            return null;
        }
        let relationship = this.getEnterpriseObject().getRelationshipForChildEnterpriseComponent(this);

        if (relationship) {
            return relationship.getParentEnterpriseComponent();
        }
        return null;
    };

    getRecordPointer = () => {
        return this.recordPointer;
    };

    getSearchSpecification = () => {
        return this.searchSpecification;
    };

    getSelectedRecordNumber = () => {
        if (this.getBuffer().size !== 0) {
            for (let i = 0; i < this.getBuffer().get(this.getPagePointer()).length; i++) {
                if (this.getBuffer().get(this.getPagePointer())[i].RowSelected === true) {
                    return i;
                }
            }
        }
        return -1;
    };

    getSortSpecification = () => {
        return this.sortSpecification;
    };

    getSourceAttributeValueForParent = () => {
        let out = null;

        let parentEC = this.getParentEnterpriseComponent();
        if (parentEC === null) {
            return out;
        }

        let parentECRecordPointer = parentEC.getRecordPointer();
        let r = this.getEnterpriseObject().getRelationshipForChildEnterpriseComponent(this);
        return parentEC.getBuffer().get(parentEC.getPagePointer())[parentECRecordPointer][r.getSourceAttribute()];
    };

    getStore = () => {
        return this.store;
    }

    getTotalRowcount = () => {
        return this.totalRowcount;
    };

    getVisibilityMode = () => {
        return this.visibilityMode;
    };

    getVisibilityValue = () => {
        return this.visibilityValue;
    };

    hasEnterpriseComponentBeenModified = () => {
        if (this.getBuffer().size === 0) {
            return false;
        }

        for (let i = 0; i < this.getBuffer().get(this.getPagePointer()).length; i++) {
            let row = this.getBuffer().get(this.getPagePointer())[i];

            if (row["__operation"] !== "query results" && row["__operation"] !== "delete") {
                return true;
            }

            /*
            for child in self.get_enterprise_object().get_child_enterprise_components(self).values() {
                if child.has_enterprise_component_been_modified() {
                    return true;
                }
                return false;
            }
            */
        }
        return false;
    };

    insertEnterpriseComponentFailed = () => {
        EventBus.error.emit("show-system-error");
    };

    insertEnterpriseComponentSuccess = (data) => {
        if (data.Results !== undefined && data.Results.length === 1) {
            this.totalRowcount += 1;

            let keys = Object.keys(data.Results[0]);
            for (let i = 0; i < keys.length; i++) {
                let key = keys[i];

                this.getBuffer().get(this.getPagePointer())[this.getRecordPointer()][key] = data.Results[0][key];
            }
        }

        for (let i = 0; i < this.getBuffer().size; i++) {
            let row = this.getBuffer().get(this.getPagePointer())[i];

            row["RowNum"] = i;
            row["__operation"] = "query results";
        }

        let row = this.getBuffer().get(this.getPagePointer())[this.getRecordPointer()];

        let keys = Object.keys(this.getAttributes());
        for (let i = 0; i < keys.length; i++) {
            let attribute = this.getAttributes()[keys[i]];
            row[attribute.getName()] = data["Results"][0][attribute.getName()];
            delete row[attribute.getName() + "_old"];
            delete row[attribute.getName() + "_original"];
        }

        this.mode = this.MODE_RESULTS;

        this.isComponentValid = true;

        this.getBuffer().get(this.getPagePointer())[this.getRecordPointer()].RowSelected = true;

        this.notifyInsertCompletedListeners();
    };

    invalidate = () => {
        this.recordPointer = -1;
        this.resultsBuffer = new Map();
        this.queryBuffer = new Map();
        this.isComponentValid = false;
        this.totalRowcount = -1;
        this.mode = this.MODE_IDLE;
        this.error = null;

        this.invalidateChildren();

        this.notifyInvalidListeners();
    };

    invalidateChildren = () => {
        if (this.enterpriseObject !== null) {
            let children = this.enterpriseObject.getChildEnterpriseComponents(this);
            for (let key of children.keys()) {
                let childEnterpriseComponent = children.get(key);
                let relationship = this.getEnterpriseObject().getRelationshipForChildEnterpriseComponent(childEnterpriseComponent);
                if (relationship !== null) {
                    if (relationship.getInvalidateChildren() === null || relationship.getInvalidateChildren() === undefined || relationship.getInvalidateChildren() === true) {
                        childEnterpriseComponent.invalidate();
                    }
                }
            }
        }
    };

    isEmpty = () => {
        if (this.getRecordPointer() === -1) return true;

        if (!this.isValid()) {
            return true;
        }

        if (this.getBuffer().size === 0) return true;

        return this.getBuffer("0").length === 0;
    };

    isDeleteMode = () => {
        return this.mode === this.MODE_DELETE;
    };

    isQueryMode = () => {
        return this.mode === this.MODE_QUERY;
    };

    isNewMode = () => {
        return this.mode === this.MODE_NEW;
    };

    isViewMode = () => {
        return this.mode === this.MODE_RESULTS;
    };

    isPrimaryEnterpriseComponent = () => {
        return this.getParentEnterpriseComponent() === null;
    };

    isUpdateMode = () => {
        return this.mode === this.MODE_UPDATE;
    };

    isValid = () => {
        return this.isComponentValid;
    };

    isLastRecord = () => {
        return this.getRecordPointer() === this.totalRowcount - 1;
    };

    lastRecord = (enable_events = true) => {
        if (this.hasEnterpriseComponentBeenModified()) {
            return false;
        }

        if (!this.isValid()) {
            return false;
        }

        let out;

        if (this.getBuffer().size === 0 || this.getRecordPointer() === this.getBuffer().size - 1) {
            out = false;
        } else {
            if (this.getRecordPointer() && this.getBuffer()[this.getRecordPointer()].RowSelected === true) {
                this.getBuffer()[this.getRecordPointer()].RowSelected = false;
            }

            this.recordPointer = this.getBuffer().size - 1;
            this.getBuffer()[this.recordPointer].RowSelected = true;
            if (enable_events) this.notifyRecordChangedListeners();
            out = true;
        }

        if (out) this.invalidateChildren();

        return out;
    };

    newQuery = () => {
        this.mode = this.MODE_QUERY;
        this.queryBuffer = new Map();
        this.queryBuffer.set(1, [{"__operation": "query"}]);
        this.recordPointer = 0;
        this.searchSpecification = {};
        this.sortSpecification = null;
        this.error = null;
        this.notifyNewQueryListeners();
    };

    newDelete = () => {
        this.mode = this.MODE_DELETE;
        this.queryBuffer = new Map();
        this.queryBuffer.set(1, [{"__operation": "delete"}]);
        this.isComponentValid = true;
        this.error = null;
        this.notifyNewDeleteListeners();
    };

    newRecord = () => {
        this.mode = this.MODE_NEW;
        this.error = null;

        let row = {};

        let keys = Object.keys(this.getAttributes());
        for (let i = 0; i < keys.length; i++) {
            let attribute = this.getAttributes()[keys[i]];
            row[attribute.getName()] = attribute.getDefaultValue();
        }

        row["USID"] = uuidv4();
        row["RowSelected"] = true;
        row["RowNum"] = 0;
        row["__operation"] = "new";

        let foreignKey = null;
        let destinationAttribute = null;
        let realm = null;
        if (this.getEnterpriseObject() !== null) {
            let relationship = this.getEnterpriseObject().getRelationshipForChildEnterpriseComponent(this);
            if (relationship !== null) {
                destinationAttribute = relationship.getDestinationAttribute();

                let parentEnterpriseComponent = relationship.getParentEnterpriseComponent();

                if (parentEnterpriseComponent.isValid() === false) {
                    return;
                }

                if (parentEnterpriseComponent.getBuffer().size !== 0) {
                    if (parentEnterpriseComponent.getRecordPointer() !== -1) {
                        foreignKey = parentEnterpriseComponent.getBuffer().get(parentEnterpriseComponent.getPagePointer())[parentEnterpriseComponent.getRecordPointer()][relationship.getSourceAttribute()];
                        realm = parentEnterpriseComponent.getBuffer().get(parentEnterpriseComponent.getPagePointer())[parentEnterpriseComponent.getRecordPointer()]["Realm"];
                    }
                }
            } else {
                foreignKey = null;
            }
        } else {
            foreignKey = null;
        }

        if (foreignKey !== null) {
            row[destinationAttribute] = foreignKey;
            row["Realm"] = realm;
        }

        if (this.getBuffer().size > 0 && this.getBuffer().get(this.getPagePointer()).length > 0) {
            for (let i = 0; i < this.getBuffer().get(this.getPagePointer()).length; i++) {
                this.getBuffer().get(this.getPagePointer())[i].RowSelected = false;
            }
        }
        if (this.getRecordPointer() === -1) {
            this.setRecordPointer(0);
        }

        if (this.getBuffer().size === 0) {
            this.setPagePointer(1);
            this.getBuffer().set(this.getPagePointer(), [row]);
        } else {
            this.getBuffer().get(this.getPagePointer()).splice(this.getRecordPointer(), 0, row);
        }

        if (this.getBuffer().size > 0 && this.getBuffer().get(this.getPagePointer()).length > 0) {
            for (let i = 0; i < this.getBuffer().get(this.getPagePointer()).length; i++) {
                this.getBuffer().get(this.getPagePointer())[i]["RowNum"] = i;
            }
        }

        this.isComponentValid = true;

        this.notifyStartNewRecordModeListeners();
    };

    nextRecord = (enable_events = true) => {
        if (this.hasEnterpriseComponentBeenModified()) {
            return false;
        }

        if (!this.isValid()) {
            return false;
        }

        let out;
        if (this.getBuffer().size === 0 || this.getBuffer().get(this.getPagePointer()).length === 0 || this.getRecordPointer() === this.getBuffer().get(this.getPagePointer()).length - 1) {
            out = false;
        } else {
            if (this.getRecordPointer() !== -1 && this.getBuffer().get(this.getPagePointer())[this.getRecordPointer()].RowSelected === true) {
                this.getBuffer().get(this.getPagePointer())[this.getRecordPointer()].RowSelected = false;
            }

            let found = false;
            if (this.enterpriseObject !== null) {
                let relationship = this.enterpriseObject.getRelationshipForChildEnterpriseComponent(this);
                if (relationship) {
                    let value = this.getSourceAttributeValueForParent(relationship.getSourceAttribute());
                    for (let i = this.recordPointer + 1; i < this.getBuffer().get(this.getPagePointer()).length; i++) {
                        if (this.getBuffer().get(this.getPagePointer())[i][relationship.getDestinationAttribute()] === value) {
                            found = true;
                            this.recordPointer = i;
                            break;
                        } else {
                            this.recordPointer++;
                        }
                    }
                } else {
                    this.recordPointer++;
                    found = true;
                }
            } else {
                this.recordPointer++;
                found = true;
            }

            if (found) {
                this.getBuffer().get(this.getPagePointer())[this.recordPointer].RowSelected = true;
                if (enable_events) this.notifyRecordChangedListeners();
                this.invalidateChildren();
                out = true;
            } else {
                out = false;
            }
        }

        return out;
    };

    notifyDeleteStartListeners = () => {
        for (let i = 0; i < this.deleteStartedListeners.length; i++) {
            let listener = this.deleteStartedListeners[i];
            listener.enterpriseComponentDeleteStarted(this);
        }
    };

    notifyDeleteCompletedListeners = () => {
        for (let i = 0; i < this.deleteCompletedListeners.length; i++) {
            let listener = this.deleteCompletedListeners[i];
            listener.enterpriseComponentDeleteCompleted(this);
        }
    };

    notifyInsertCompletedListeners = () => {
        for (let i = 0; i < this.insertCompletedListeners.length; i++) {
            let listener = this.insertCompletedListeners[i];
            listener.enterpriseComponentInsertCompleted(this);
        }
    };

    notifyInsertRollbackCompletedListeners = () => {
        for (let i = 0; i < this.insertRollbackCompletedListeners.length; i++) {
            let listener = this.insertRollbackCompletedListeners[i];
            listener.enterpriseComponentInsertRollbackCompleted(this);
        }
    };

    notifyInsertStartListeners = () => {
        for (let i = 0; i < this.insertStartedListeners.length; i++) {
            let listener = this.insertStartedListeners[i];
            listener.enterpriseComponentInsertStarted(this);
        }
    };

    notifyInvalidListeners = () => {
        for (let i = 0; i < this.invalidListeners.length; i++) {
            let listener = this.invalidListeners[i];
            try {
                listener(this);
            } catch (error) {
                listener.enterpriseComponentInvalidated(this)
            }
        }
    };

    notifyLoadCompletedListeners = () => {
        for (let i = 0; i < this.loadCompletedListeners.length; i++) {
            let listener = this.loadCompletedListeners[i];
            try {
                listener(this);
            } catch (error) {
                listener.enterpriseComponentLoadCompleted(this)
            }
        }
    };

    notifyLoadStartListeners = () => {
        this.isComponentValid = false;

        for (let i = 0; i < this.loadStartedListeners.length; i++) {
            let listener = this.loadStartedListeners[i];
            try {
                listener(this);
            } catch (error) {
                listener.enterpriseComponentLoadStarted(this)
            }
        }
    };

    notifyNewDeleteListeners = () => {
        for (let i = 0; i < this.newDeleteListeners.length; i++) {
            let listener = this.newDeleteListeners[i];
            listener.enterpriseComponentNewDeleteStarted(this);
        }
    };

    notifyNewQueryListeners = () => {
        for (let i = 0; i < this.newQueryListeners.length; i++) {
            let listener = this.newQueryListeners[i];
            listener.enterpriseComponentNewQueryStarted(this);
        }
    };

    notifyQueryRollbackCompletedListeners = () => {
        for (let i = 0; i < this.queryRollbackCompletedListeners.length; i++) {
            let listener = this.queryRollbackCompletedListeners[i];
            listener.enterpriseComponentQueryRollbackCompleted(this);
        }
    };

    notifyRecordWillChangeListeners = () => {
        let okToChange = true;
        for (let i = 0; i < this.recordWillChangeListeners.length; i++) {
            let listener = this.recordWillChangeListeners[i];
            okToChange = okToChange && listener.enterpriseComponentRecordWillChange(this);
        }
        return okToChange;
    };

    notifyRecordChangedListeners = () => {
        for (let i = 0; i < this.recordChangedListeners.length; i++) {
            let listener = this.recordChangedListeners[i];
            listener.enterpriseComponentRecordChanged(this);
        }
    };

    notifySingleRecordRefreshStartListeners = () => {
        for (let i = 0; i < this.singleRecordRefreshStartedListeners.length; i++) {
            let listener = this.singleRecordRefreshStartedListeners[i];
            listener.enterpriseComponentSingleRecordRefreshStarted(this);
        }
    };

    notifySingleRecordRefreshCompletedListeners = () => {
        for (let i = 0; i < this.singleRecordRefreshCompletedListeners.length; i++) {
            let listener = this.singleRecordRefreshCompletedListeners[i];
            listener.enterpriseComponentSingleRecordRefreshCompleted(this);
        }
    };

    notifyStartNewRecordModeListeners = () => {
        for (let i = 0; i < this.startNewRecordModeListeners.length; i++) {
            let listener = this.startNewRecordModeListeners[i];
            listener.enterpriseComponentStartNewRecordModeCompleted(this);
        }
    };

    notifyStartEditRecordModeListeners = () => {
        for (let i = 0; i < this.startEditRecordModeListeners.length; i++) {
            let listener = this.startEditRecordModeListeners[i];
            listener.enterpriseComponentStartEditRecordModeCompleted(this);
        }
    };

    notifyUpdateStartListeners = () => {
        for (let i = 0; i < this.updateStartedListeners.length; i++) {
            let listener = this.updateStartedListeners[i];
            listener.enterpriseComponentUpdateStarted(this);
        }
    };

    notifyUpdateCompletedListeners = () => {
        for (let i = 0; i < this.updateCompletedListeners.length; i++) {
            let listener = this.updateCompletedListeners[i];
            listener.enterpriseComponentUpdateCompleted(this);
        }
    };

    notifyUpdateRollbackCompletedListeners = () => {
        for (let i = 0; i < this.updateRollbackCompletedListeners.length; i++) {
            let listener = this.updateRollbackCompletedListeners[i];
            listener.enterpriseComponentUpdateRollbackCompleted(this);
        }
    };

    notifyValueChangedListeners = () => {
        for (let i = 0; i < this.valueChangedListeners.length; i++) {
            let listener = this.valueChangedListeners[i];
            listener.enterpriseComponentValueChanged(this);
        }
    };

    performDelete() {
    };

    performInsert() {
    };

    performQuery() {
    };

    performSingleRecordRefresh = () => {
    };

    performUpdate() {
    };

    prepareDeletePayload = () => {
        let out = {};

        out["USID"] = this.getBuffer().get(this.getPagePointer())[this.getRecordPointer()]["USID"];

        return out;
    };

    preparePUTPayload = () => {
        let out = {};

        let buffer = this.getBuffer().get(this.getPagePointer());

        out["USID"] = buffer[this.getRecordPointer()]["USID"];

        let keys = Object.keys(this.getAttributes());
        for (let i = 0; i < keys.length; i++) {
            let attribute = this.getAttributes()[keys[i]];

            if (attribute.getComputed() === false) {
                let originalValue = buffer[this.getRecordPointer()][attribute.getName() + "_original"];
                if (originalValue !== undefined && attribute.getName() !== "USID") {
                    if (originalValue === buffer[this.getRecordPointer()][attribute.getName()]) {
                        continue;
                    }
                    let value = buffer[this.getRecordPointer()][attribute.getName()];
                    if (attribute.getType() === "Number") {
                        if (value === "") {
                            out[attribute.getName()] = null;
                        } else {
                            out[attribute.getName()] = parseInt(value);
                        }
                    } else if (attribute.getType() === "String") {
                        if (value === "") {
                            out[attribute.getName()] = null;
                        } else {
                            out[attribute.getName()] = value;
                        }
                    } else {
                        out[attribute.getName()] = buffer[this.getRecordPointer()][attribute.getName()];
                    }
                }
            }
        }
        return out;
    };

    previousRecord = (enable_events = true) => {
        if (this.hasEnterpriseComponentBeenModified()) {
            return false;
        }

        if (!this.isValid()) {
            return false;
        }

        let out;

        if (this.getBuffer().size === 0 || this.getRecordPointer() === 0) {
            out = false;
        } else {
            if (this.getRecordPointer() && this.getBuffer()[this.getRecordPointer()].RowSelected === true) {
                this.getBuffer()[this.getRecordPointer()].RowSelected = false;
            }

            this.recordPointer -= 1;
            this.getBuffer()[this.recordPointer].RowSelected = true;
            if (enable_events) this.notifyRecordChangedListeners();
            out = true;
        }

        if (out) {
            this.invalidateChildren();
        }

        return out;
    };

    refreshEnterpriseComponentSuccess = (data) => {
        this.mode = this.MODE_RESULTS;

        let results = data["Results"][0];

        let rows = this.resultsBuffer.get(this.getPagePointer());

        for (let i = 0; i < rows.length; i++) {
            let row = rows[i];

            if (row["USID"] === results["USID"]) {
                let attributes = this.getAttributes();

                for (const key of Object.keys(attributes)) {
                    row[key] = results[key];
                }
            }
        }

        this.notifySingleRecordRefreshCompletedListeners();
        this.waiting = false;
    };

    removeDeleteStartedListener = (obj) => {
        let arr = this.deleteStartedListeners;

        for (let i = 0; i < arr.length; i++) {
            if (arr[i] === obj) {
                arr.splice(i, 1);
                break;
            }
        }

        for (let i = 0; i < arr.length; i++) {
            if (arr[i] === obj) {
                console.error(arr[i]);
            }
        }
    };

    removeDeleteCompletedListener = (obj) => {
        let arr = this.deleteCompletedListeners;

        for (let i = 0; i < arr.length; i++) {
            if (arr[i] === obj) {
                arr.splice(i, 1);
                break;
            }
        }

        for (let i = 0; i < arr.length; i++) {
            if (arr[i] === obj) {
                console.error(arr[i]);
            }
        }
    };

    removeInsertStartedListener = (obj) => {
        let arr = this.insertStartedListeners;

        for (let i = 0; i < arr.length; i++) {
            if (arr[i] === obj) {
                arr.splice(i, 1);
                break;
            }
        }

        for (let i = 0; i < arr.length; i++) {
            if (arr[i] === obj) {
                console.error(arr[i]);
            }
        }
    };

    removeInsertCompletedListener = (obj) => {
        let arr = this.insertCompletedListeners;

        for (let i = 0; i < arr.length; i++) {
            if (arr[i] === obj) {
                arr.splice(i, 1);
                break;
            }
        }

        for (let i = 0; i < arr.length; i++) {
            if (arr[i] === obj) {
                console.error(arr[i]);
            }
        }
    };

    removeInsertRollbackCompletedListener = (obj) => {
        let arr = this.insertRollbackCompletedListeners;

        for (let i = 0; i < arr.length; i++) {
            if (arr[i] === obj) {
                arr.splice(i, 1);
                break;
            }
        }

        for (let i = 0; i < arr.length; i++) {
            if (arr[i] === obj) {
                console.error(arr[i]);
            }
        }
    };

    removeInvalidListener = (obj) => {
        let arr = this.invalidListeners;

        for (let i = 0; i < arr.length; i++) {
            if (arr[i] === obj) {
                arr.splice(i, 1);
                break;
            }
        }

        for (let i = 0; i < arr.length; i++) {
            if (arr[i] === obj) {
                console.error(arr[i]);
            }
        }
    };

    removeLoadCompletedListener = (obj) => {
        let arr = this.loadCompletedListeners;

        for (let i = 0; i < arr.length; i++) {
            if (arr[i] === obj) {
                arr.splice(i, 1);
                break;
            }
        }

        for (let i = 0; i < arr.length; i++) {
            if (arr[i] === obj) {
                console.error(arr[i]);
            }
        }
    };

    removeLoadStartedListener = (obj) => {
        let arr = this.loadStartedListeners;

        for (let i = 0; i < arr.length; i++) {
            if (arr[i] === obj) {
                arr.splice(i, 1);
                break;
            }
        }

        for (let i = 0; i < arr.length; i++) {
            if (arr[i] === obj) {
                console.error(arr[i]);
            }
        }
    };

    removeNewQueryListener = (obj) => {
        let arr = this.newQueryListeners;

        for (let i = 0; i < arr.length; i++) {
            if (arr[i] === obj) {
                arr.splice(i, 1);
                break;
            }
        }

        for (let i = 0; i < arr.length; i++) {
            if (arr[i] === obj) {
                console.error(arr[i]);
            }
        }
    };

    removeRecordChangedListener = (obj) => {
        let arr = this.recordChangedListeners;

        for (let i = 0; i < arr.length; i++) {
            if (arr[i] === obj) {
                arr.splice(i, 1);
                break;
            }
        }

        for (let i = 0; i < arr.length; i++) {
            if (arr[i] === obj) {
                console.error(arr[i]);
            }
        }
    };

    removeRecordWillChangeListener = (obj) => {
        let arr = this.recordWillChangeListeners;

        for (let i = 0; i < arr.length; i++) {
            if (arr[i] === obj) {
                arr.splice(i, 1);
                break;
            }
        }

        for (let i = 0; i < arr.length; i++) {
            if (arr[i] === obj) {
                console.error(arr[i]);
            }
        }
    };

    removeSingleRecordRefreshStartedListener = (obj) => {
        let arr = this.singleRecordRefreshStartedListeners;

        for (let i = 0; i < arr.length; i++) {
            if (arr[i] === obj) {
                arr.splice(i, 1);
                break;
            }
        }

        for (let i = 0; i < arr.length; i++) {
            if (arr[i] === obj) {
                console.error(arr[i]);
            }
        }
    };

    removeSingleRecordRefreshCompletedListener = (obj) => {
        let arr = this.singleRecordRefreshCompletedListeners;

        for (let i = 0; i < arr.length; i++) {
            if (arr[i] === obj) {
                arr.splice(i, 1);
                break;
            }
        }

        for (let i = 0; i < arr.length; i++) {
            if (arr[i] === obj) {
                console.error(arr[i]);
            }
        }
    };

    removeStartNewRecordModeCompletedListener = (obj) => {
        let arr = this.startNewRecordModeListeners;

        for (let i = 0; i < arr.length; i++) {
            if (arr[i] === obj) {
                arr.splice(i, 1);
                break;
            }
        }

        for (let i = 0; i < arr.length; i++) {
            if (arr[i] === obj) {
                console.error(arr[i]);
            }
        }
    };

    removeUpdateStartedListener = (obj) => {
        let arr = this.updateStartedListeners;

        for (let i = 0; i < arr.length; i++) {
            if (arr[i] === obj) {
                arr.splice(i, 1);
                break;
            }
        }

        for (let i = 0; i < arr.length; i++) {
            if (arr[i] === obj) {
                console.error(arr[i]);
            }
        }
    };

    removeUpdateCompletedListener = (obj) => {
        let arr = this.updateCompletedListeners;

        for (let i = 0; i < arr.length; i++) {
            if (arr[i] === obj) {
                arr.splice(i, 1);
                break;
            }
        }

        for (let i = 0; i < arr.length; i++) {
            if (arr[i] === obj) {
                console.error(arr[i]);
            }
        }
    };

    removeUpdateRollbackCompletedListener = (obj) => {
        let arr = this.updateRollbackCompletedListeners;

        for (let i = 0; i < arr.length; i++) {
            if (arr[i] === obj) {
                arr.splice(i, 1);
                break;
            }
        }

        for (let i = 0; i < arr.length; i++) {
            if (arr[i] === obj) {
                console.error(arr[i]);
            }
        }
    };

    removeQueryRollbackCompletedListener = (obj) => {
        let arr = this.queryRollbackCompletedListeners;

        for (let i = 0; i < arr.length; i++) {
            if (arr[i] === obj) {
                arr.splice(i, 1);
                break;
            }
        }

        for (let i = 0; i < arr.length; i++) {
            if (arr[i] === obj) {
                console.error(arr[i]);
            }
        }
    };

    removeValueChangedListener = (obj) => {
        let arr = this.valueChangedListeners;

        for (let i = 0; i < arr.length; i++) {
            if (arr[i] === obj) {
                arr.splice(i, 1);
                break;
            }
        }

        for (let i = 0; i < arr.length; i++) {
            if (arr[i] === obj) {
                console.error(arr[i]);
            }
        }
    };

    removeAllListeners = (obj) => {
        this.removeRecordWillChangeListener(obj);
        this.removeRecordChangedListener(obj);
        this.removeLoadStartedListener(obj);
        this.removeNewQueryListener(obj);
        this.removeLoadCompletedListener(obj);
        this.removeValueChangedListener(obj);
        this.removeInvalidListener(obj);
        this.removeUpdateStartedListener(obj);
        this.removeUpdateCompletedListener(obj);
        this.removeInsertStartedListener(obj);
        this.removeInsertCompletedListener(obj);
        this.removeInsertRollbackCompletedListener(obj);
        this.removeStartNewRecordModeCompletedListener(obj);
        this.removeSingleRecordRefreshStartedListener(obj);
        this.removeSingleRecordRefreshCompletedListener(obj);
        this.removeDeleteStartedListener(obj);
        this.removeDeleteCompletedListener(obj);
    };

    selectRecord = (rowNum) => {
        if (this.getBuffer().size === 0) {
            return;
        }
        if (rowNum === this.getRecordPointer() && this.getBuffer().get(this.getPagePointer())[rowNum].RowSelected === true) return;

        if (this.getRecordPointer() !== -1) {
            this.getBuffer().get(this.getPagePointer())[this.getRecordPointer()].RowSelected = false;
        }

        this.setRecordPointer(rowNum);
        this.getBuffer().get(this.getPagePointer())[rowNum].RowSelected = true;
        this.notifyRecordChangedListeners();
        this.invalidateChildren();
    };

    setAttributeValue = (attributeName, attributeValue, force = false, enable_events = true) => {
        if (this.canChangeAttributeValue(attributeName) === false) {
            return false;
        }

        let row = this.getBuffer().get(this.getPagePointer())[this.getRecordPointer()];

        let operation = row["__operation"];

        if (operation === "query results" || operation === "update") {
            let previousValue = row[attributeName];

            if (attributeValue === "NULL") {
                attributeValue = null;
            }
            this.getBuffer().get(this.getPagePointer())[this.getRecordPointer()][attributeName] = attributeValue;

            row["__operation"] = "update";

            if (force) {
                row[attributeName + "_original"] = previousValue ?? null; // force null because preparePUTPayload() skips undefined
                row[attributeName + "_old"] = previousValue ?? null; // force null because preparePUTPayload() skips undefined
            } else {
                if (row[attributeName + "_original"] === undefined && row[attributeName] !== previousValue) {
                    row[attributeName + "_original"] = previousValue;
                    row[attributeName + "_old"] = previousValue;
                } else {
                    row[attributeName + "_old"] = previousValue;
                }
            }
        } else if ("new" === operation) {
            if (attributeValue === "NULL") {
                attributeValue = null;
            }
            this.getBuffer().get(this.getPagePointer())[this.getRecordPointer()][attributeName] = attributeValue;
        } else if ("delete" === operation) {
            this.getBuffer().get(this.getPagePointer())[this.getRecordPointer()][attributeName] = attributeValue;
        } else if ("query" === operation) {
            if (attributeValue === "NULL") {
                attributeValue = null;
            }
            this.getBuffer().get(this.getPagePointer())[this.getRecordPointer()][attributeName] = attributeValue;
        } else if (undefined === operation) {
            if (attributeValue === "NULL") {
                attributeValue = null;
            }
            this.getBuffer().get(this.getPagePointer())[this.getRecordPointer()][attributeName] = attributeValue;
        }

        if (enable_events) this.notifyValueChangedListeners();
    };

    setBuffer = (buffer) => {
        this.resultsBuffer = buffer;
    };

    setEnterpriseObject = (eo) => {
        this.enterpriseObject = eo;
    };

    setIDOCRequest = (request) => {
        this.idocRequest = request;
    };

    setIDOCResponse = (response) => {
        this.idocResponse = response;
    };

    setPagePointer = (page) => {
        this.pagePointer = page;
    };

    setRecordPointer = (position) => {
        this.recordPointer = position;
    };

    setSearchSpecification(searchSpecification) {
        if (searchSpecification === null) {
            this.searchSpecification = {};
        } else {
            this.searchSpecification = searchSpecification;
        }
    };

    setSortSpecification(sortSpecification) {
        this.sortSpecification = sortSpecification;
    }

    setStore(value) {
        this.store = value
    };

    setSupportingData = (data) => {
        this.supportingData = data;
    };

    setUpdateMode = () => {
        this.mode = this.MODE_UPDATE;
    };

    setVisibilityMode = (mode) => {
        this.visibilityMode = mode;
    };

    setVisibilityValue = (value) => {
        this.visibilityValue = value;
    };

    updateEnterpriseComponentFailed = () => {
        EventBus.error.emit("show-system-error");
    };

    updateEnterpriseComponentSuccess = (data) => {
        let dataset = data?.Results ?? data;

        for (let rowIndex = 0; rowIndex < dataset.length; rowIndex++) {
            let _recordPointer = this._alignBuffer(dataset[rowIndex]["USID"]);
            let keys = Object.keys(dataset[rowIndex]);
            for (let keysIndex = 0; keysIndex < keys.length; keysIndex++) {
                let key = keys[keysIndex];

                if (this.getEnterpriseObject() !== null) {
                    let childEnterpriseComponents = this.getEnterpriseObject().getChildEnterpriseComponents(this);
                    let childEnterpriseComponentKeys = Object.keys(childEnterpriseComponents);

                    for (let i = 0; i < childEnterpriseComponentKeys.length; i++) {
                        let childEnterpriseComponent = childEnterpriseComponents[childEnterpriseComponentKeys[i]];

                        if (key === childEnterpriseComponent.getName()) {
                            // process a single enterprise component child - code this another time
                        } else if (key === "ListOf" + childEnterpriseComponent.getName()) {
                            childEnterpriseComponent.updateEnterpriseComponentSuccess(dataset[rowIndex][key]["Results"]);
                        }
                    }
                }
                this.getBuffer().get(this.getPagePointer())[_recordPointer][key] = dataset[rowIndex][key];
                this.getBuffer().get(this.getPagePointer())[_recordPointer]["RowNum"] = rowIndex;
                this.getBuffer().get(this.getPagePointer())[_recordPointer]["__operation"] = "query results";
            }
        }

        if (dataset.length > 0) {
            this.getBuffer().get(this.getPagePointer())[this.getRecordPointer()].RowSelected = true;
        }

        this.mode = this.MODE_RESULTS;

        this.notifyUpdateCompletedListeners();
    };

    _alignBuffer = (usidToFind) => {
        for (let i = 0; i < this.getBuffer().get(this.getPagePointer()).length; i++) {
            if (this.getBuffer().get(this.getPagePointer())[i]["USID"] === usidToFind) return i;
        }
        return -1;
    };
}

export default EnterpriseComponent;