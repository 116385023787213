import AbstractMetadata from "../AbstractMetadata";
import EnterpriseComponentAttributeMetadata from "./EnterpriseComponentAttributeMetadata";

interface IAttributeDictionary<T> {
    [id: string]: T
}

interface IVisibilityModesDictionary<T> {
    [id: string]: T
}

class EnterpriseComponentMetadata extends AbstractMetadata {
    public defaultCacheSize: number = 20;

    public attributes: IAttributeDictionary<EnterpriseComponentAttributeMetadata> = {}
    public visibilityModes: IVisibilityModesDictionary<string> = {}

    constructor() {
        super();

        this.visibilityModes = {};
        this.defaultCacheSize = 20;
    };

    getName = () => {
        return this.name;
    };

    getDefaultCacheSize = () => {
        return this.defaultCacheSize;
    };

    getAttributes = () => {
        return this.attributes;
    };
    getAttribute = (name: string) => {
        return this.getAttributes()[name];
    };

    getVisibilityModes = () => {
        return this.visibilityModes;
    };
    getVisibilityMode = (name: string) => {
        return this.getVisibilityModes()[name];
    };
}

export default EnterpriseComponentMetadata;
