import {withStyles} from "@mui/styles";
import styles, {Black, Blue3, Grey6} from "../../theme/styles";

import {EnlilTable} from "../EnlilTable";
import {withAppContext} from "../withAppContext";
import withNavigate from "../../util/useNavigate";
import withBreakpoint from "../../util/withBreakpoint";
import React from "react";
import {formatLocal} from "../../util/Formatters";

class DeveloperExceptionTable extends EnlilTable {

    componentDidMount() {
        super.componentDidMount();
    }

    componentWillUnmount() {
        super.componentWillUnmount();
    }

    getCellRenderer(name) {
        if (name === "Exception Type Cell") {
            return this.renderExceptionTypeCell;
        } else if (name === "Exception Application Cell") {
            return this.renderExceptionApplicationCell;
        } else if (name === "Exception Analysis Cell") {
            return this.renderExceptionAnalysisCell;
        }
        return super.getCellRenderer(name);
    };

    handleMenuClick(identifier) {
        super.handleMenuClick(identifier);
    }

    renderExceptionTypeCell = (___, params, __, _) => {
        if (params.row.id > this.state.buffer.length - 1) return;

        return (
            <div style={{marginTop: "-10px"}}>
                <div style={{fontSize: 14, color: Blue3, height: "20px"}}>{this.state.buffer[params.row.id]["ExceptionType"]}</div>
                <div style={{fontSize: 11, color: Grey6, height: "20px"}}>Event ID: {this.state.buffer[params.row.id]["USID"]}</div>
            </div>
        );
    };

    renderExceptionAnalysisCell = (___, params, __, _) => {
        if (params.row.id > this.state.buffer.length - 1) return;

        let value = this.state.buffer[params.row.id]["ExceptionAnalysis"]["description"]

        return (
            <div style={{marginTop: "0px"}}>
                <div style={{fontSize: 14, color: Black, height: "20px"}}>{value}</div>
            </div>
        );
    };

    renderExceptionApplicationCell = (___, params, __, _) => {
        if (params.row.id > this.state.buffer.length - 1) return;

        let value = this.state.buffer[params.row.id]["ExceptionAnalysis"]["application"]

        return (
            <div style={{marginTop: "0px"}}>
                <div style={{fontSize: 14, color: Black, height: "20px"}}>{value}</div>
            </div>
        );
    };
}

export default withNavigate(withAppContext(withStyles(styles, {withTheme: true})(withBreakpoint(DeveloperExceptionTable))));