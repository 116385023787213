import RelationshipMetadata from "../../RelationshipMetadata";

class DeveloperEndpointDeveloperConfigurationRelationshipMetadata extends RelationshipMetadata {
    constructor() {
        super();

        this.name = "DeveloperEndpointDeveloperEndpointConfiguration";
        this.parentEnterpriseComponent = "DeveloperEndpoint";
        this.childEnterpriseComponent = "DeveloperEndpointConfiguration";
        this.sourceAttribute = "USID";
        this.destinationAttribute = "DeveloperEndpointUSID";
    }
}

export default DeveloperEndpointDeveloperConfigurationRelationshipMetadata;