import {isPossiblePhoneNumber} from "libphonenumber-js";
import moment from "moment-timezone";

export const NoSpaces = value => value.toString().indexOf(" ") === -1;

export const NoLeadingSpaces = value => {
    if (value === null || value.toString().length === 0) return true;
    return !(value.toString()[0] === " ");
};

export const NoLeadingZeros = value => {
    if (value === null || value.toString().length === 0) return true;
    return !(value.toString()[0] === "0");
};

export const NoTrailingSpaces = value => {
    if (value === null || value.toString().length === 0) return true;
    return !(value[value.toString().length - 1] === " ");
};

export const NoSpecialCharacters = value => !!value.toString().match("^[a-zA-Z0-9@\\ \\-\\.\\_\\+]*$");

export const Minimum = (value, boundary) => value.toString().length >= boundary;

export const Maximum = (value, boundary) => value.toString().length <= boundary;

export const MaximumValue = (value, maximum) => value <= maximum;

export const MinimumValue = (value, minimum) => value >= minimum;

export const NotAllSpaces = value => value.trim().length !== 0;

export const Required = value => value !== undefined && value !== null && value.toString().length > 0;

export const OptionalPattern = (value, pattern) => {
    if (value !== null && value.toString().length > 0) {
        return !!value.toString().match(pattern);
    }
    return true;
};

export const Float = value => isNaN(Number(value)) !== true;

export const NumbersOnlyPattern = (value, pattern) => {
    if (value !== null && value.toString().length > 0) {
        return !!value.toString().match(pattern);
    }
    return true;
};

export const RequiredInteger = value => !value.toString().includes(".");

export const RequiredPattern = (value, pattern) => !!value.toString().match(pattern);

export const MatchValues = (value, otherFieldValue) => value === otherFieldValue;

export const AllOrNone = (value, otherFieldValues) => {
    let othersEmpty = true;
    otherFieldValues.forEach(x => othersEmpty &= (x?.length ?? 0) === 0);
    if ((value?.length ?? 0) === 0 && !othersEmpty) return false;
    return true;
};

export const EitherValues = (value, otherFieldValue) => {
    return (
        (value !== null && value.length > 0) ||
        (otherFieldValue !== null && otherFieldValue.length > 0)
    );
};

export const EitherValuesNotNULL = (value, otherFieldValue) => {
    if (((!value || value === "NULL") && (!otherFieldValue || otherFieldValue === "NULL"))) return false;
    return true;
};

export const OneCheckboxMustBeTrue = (fields, values) => {
    let found = false;
    for (let i = 0; i < fields.length; i++) {
        let field = fields[i];

        if (values[field] !== "" && values[field] !== null && values[field] !== false) {
            found = true;
        }
    }

    return found;
};

export const OneFieldMustBeProvided = (fields, values, controls) => {
    let found = false;
    for (let i = 0; i < fields.length; i++) {
        let field = fields[i];

        if (values[field] !== "" && values[field] !== null && values[field] !== false && controls[field].type === "Checkbox") {
            found = true;
        } else if (values[field] !== "" && values[field] !== null && controls[field].type !== "Checkbox") {
            found = true;
        }
    }
    return found;
};

export const CannotMatchValues = (value, otherFieldValue) => {
    if (value.length === 0 && otherFieldValue.length === 0) return true;
    return value !== otherFieldValue;
};

export const CannotMatchValuesCaseInsensitive = (value, otherFieldValue) => {
    if (value.length === 0 && otherFieldValue.length === 0) return true;
    return value.toLowerCase() !== otherFieldValue.toLowerCase();
};

export const PhoneRegex = /^\(?([2-9][0-9]{2})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/;
export const IsPhone = value => isPossiblePhoneNumber(value, "US");

export const EmailRegex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
export const IsEmail = value => EmailRegex.test(value);

export const IsSameOrBeforeDay = (value, otherFieldValue) => {
    if (!value || moment(value).isSameOrBefore(otherFieldValue, "day")) return true;
    return false;
};

export const IsSameOrAfterDay = (value, otherFieldValue) => {
    if (!value || moment(value).isSameOrAfter(otherFieldValue, "day")) return true;
    return false;
};
