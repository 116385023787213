import React from "react";
import "../Navigation/css/NavBar.scss";
import {Black} from "../../theme/styles";
import ReactJson from "react-json-view";
import approval_request_qbe_1 from '../../json/approval-request-qbe-1.json';
import approvalRequestEnterpriseObject from "../../assets/approvalRequestEnterpriseObject.png";


export default function QueryParameters() {

    return (
        <div style={{paddingLeft: "20px", paddingRight: "30px"}}>
            <p style={{fontSize: "24px", fontWeight: "400", color: Black}}>Query Parameters</p>
            <p style={{fontSize: "16px", fontWeight: "400", color: Black}}>Overview</p>
            <p style={{fontSize: "14px", fontWeight: "400", color: Black, marginLeft: "20px"}}>
                Enlil allows users to query a GET API in one of two ways, Basic Query Parameters and Advanced Query Parameters. To use a GET APIs Basic Query Parameters do not provide a value for the QBE parameter. If the QBE parameter is specified, the GET API will interpret this as a request to
                the the Advanced Query Parameters option.
            </p>
            <br/>
            <p style={{fontSize: "16px", fontWeight: "400", color: Black}}>Basic Query Parameters</p>
            <p style={{fontSize: "14px", fontWeight: "400", color: Black, marginLeft: "20px"}}>
                Each GET API has one or more parameters which may be used individually or in combination to query data. Each GET API provides documentation on the use of these parameters.
            </p>
            <ul style={{fontSize: "14px", fontWeight: "400", color: Black}}>
                <li style={{marginTop: "20px"}}>Limit</li>
                <li style={{marginTop: "10px", marginLeft: "40px"}}>This parameter controls the number of records returned by the API in a single call. Individual IDOCs have upper limits which will serve as both a default (if limit is not provided) and an upper limit on the number of rows returned
                    by a GET API. This parameters in conjunction with the Offset parameter can be used to control pagination of a result set.
                </li>
                <li style={{marginTop: "20px"}}>
                    Offset
                </li>
                <li style={{marginTop: "10px", marginLeft: "40px"}}>This parameter controls the starting place in the result. The default is 0 which is the first row of the result set. This parameters in conjunction with the Limit parameter can be used to control pagination of a result set. For
                    example, this is now a user can paginate a result setup across multiple API calls.
                </li>
                <li style={{marginTop: "10px", marginLeft: "60px"}}>
                    API call 1 - gives the first 40 rows (0-40):<br/>
                    limit 40<br/>
                    offset 0<br/>
                </li>
                <li style={{marginTop: "10px", marginLeft: "60px"}}>
                    API call 2 - gives the next 40 rows (41-80):<br/>
                    limit 40<br/>
                    offset 41<br/>
                </li>
                <li style={{marginTop: "10px", marginLeft: "60px"}}>
                    API call 3 - gives the next 40 rows (81-120):<br/>
                    limit 40<br/>
                    offset 81<br/>
                </li>
                <li style={{marginTop: "20px"}}>
                    Sort
                </li>
                <li style={{marginTop: "10px", marginLeft: "40px"}}>This parameter allows a user to control the sorting of the result set. Multiple sort parameters can be specified. Each GET API will provide examples.</li>
            </ul>
            <br/>
            <p style={{fontSize: "16px", fontWeight: "400", color: Black}}>Advanced Query Parameters</p>
            <p style={{fontSize: "14px", fontWeight: "400", color: Black, marginLeft: "20px"}}>
                The Advanced Query Parameters gives the user the ability to form complex queries. For example, in the case of the Approval Request GET API, the user can structure the query based on the Enterprise Object structure for Approval Request (below).
                <img src={approvalRequestEnterpriseObject} alt={"Model"}/>
                <p style={{fontFamily: "Courier New", fontSize: "12px"}}>
                    <ReactJson src={approval_request_qbe_1} collapseStringsAfterLength={75} collapsed={4}/>
                </p>
            </p>
        </div>
    )
}