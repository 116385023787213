import {withStyles} from "@mui/styles";
import styles, {Black, Green4, Grey17, Grey6, Red3} from "../../theme/styles";

import {EnlilTable} from "../EnlilTable";
import {withAppContext} from "../withAppContext";
import withNavigate from "../../util/useNavigate";
import withBreakpoint from "../../util/withBreakpoint";
import {Check, Close, Edit, MoreVert} from "@mui/icons-material";
import {IconButton, Menu, MenuItem, Typography} from "@mui/material";
import css_self from "../Navigation/css/NavBar.module.scss";
import React from "react";
import EventBus from "../../util/EventBus";
import DeveloperCredentialAuthorizationFormApplet from "../../eoec/applet/impl/RestManagement/DeveloperCredentialAuthorizationFormApplet";

class DeveloperCredentialAuthorizationTable extends EnlilTable {

    componentDidMount() {
        super.componentDidMount();

        EventBus.components.addListener("developer-credential-authorization-form-applet-cancel", this.onCancelHandler);
        EventBus.components.addListener("developer-credential-authorization-form-applet-save", this.onCancelHandler);
    };

    componentWillUnmount() {
        super.componentWillUnmount();

        EventBus.components.removeListener("developer-credential-authorization-form-applet-cancel", this.onCancelHandler);
        EventBus.components.removeListener("developer-credential-authorization-form-applet-save", this.onCancelHandler);
    };

    getCellRenderer(name) {
        if (name === "Permission Cell") {
            return this.renderPermissionCell;
        } else if (name === "Entity Cell") {
            return this.renderEntityCell;
        } else if (name === "Action Cell") {
            return this.renderActionCell;
        }

        return super.getCellRenderer(name);
    };

    onCancelHandler = () => {
        this.setState({renderTarget: null});
    };

    renderEntityCell = (___, params, __, _) => {
        if (params.row.id > this.state.buffer.length - 1) return;

        let applicationActive = this.applet.getEnterpriseComponent().getEnterpriseObject().getEnterpriseComponent("DeveloperApplication").getAttributeValue("Active");
        let credentialActive = this.applet.getEnterpriseComponent().getEnterpriseObject().getEnterpriseComponent("DeveloperCredential").getAttributeValue("Active");
        let textColor = applicationActive && credentialActive === true ? Black : Grey6

        return (
            <div style={{marginTop: "0px"}}>
                <div style={{fontSize: 14, color: textColor, height: "20px"}}>{this.state.buffer[params.row.id]["Entity"]}</div>
            </div>
        );
    };

    renderExtension() {
        if (this.state.renderTarget === "EditAuthorization") {
            return <DeveloperCredentialAuthorizationFormApplet enterpriseComponent={this.applet.getEnterpriseComponent()} availableModes={["Edit", "New", "View"]} defaultMode="Edit" />;
        }
    };

    renderActionCell = (___, params, columnId, _) => {
        if (params.row.id > this.state.buffer.length - 1) return;

        let action = (
            <div style={{width: "50px", marginTop: "-4px", backgroundColor: "#FFFFFF", marginLeft: "-10px", marginRight: "-20px", paddingBottom: "5px", paddingLeft: "4px"}}>
                <IconButton onClick={(event) => {
                    if (this.state.tableActionMenuTarget === null) {
                        this.setState({tableActionMenuTarget: event.currentTarget});
                    } else {
                        this.setState({tableActionMenuTarget: null});
                    }
                }}>
                    <MoreVert/>
                </IconButton>
                <Menu anchorEl={this.state.tableActionMenuTarget}
                      anchorOrigin={{vertical: "bottom", horizontal: "right"}}
                      transformOrigin={{vertical: -10, horizontal: "center"}}
                      open={this.state.tableActionMenuTarget !== null && this.state.tableActionMenuTarget !== undefined}
                      onClose={() => this.setState({tableActionMenuTarget: null})}
                      TransitionProps={{
                          onExited: () => this.setState({tableActionMenuTarget: null}),
                      }}
                      MenuListProps={{
                          ...{"data-cc-component": "NavMenu"},
                          disablePadding: true,
                          style: {"minWidth": "200px"},
                      }}
                      PaperProps={{
                          elevation: 2,
                      }}
                >
                    <MenuItem divider key={"1"} className={css_self.Standard} onClick={() => {
                        this.setState({tableActionMenuTarget: null, renderTarget: "EditAuthorization"});
                    }}>
                        <Edit style={{color: Grey6}}/>
                        <Typography style={{fontSize: "15px", fontWeight: "400", color: Grey17}}>Edit Authorization</Typography>
                    </MenuItem>
                </Menu>
            </div>
        )

        if (this.applet.getEnterpriseComponent().getEnterpriseObject().getEnterpriseComponent("DeveloperApplication").getAttributeValue("Active") === false) {
            action = null;
        }
        if (this.applet.getEnterpriseComponent().getEnterpriseObject().getEnterpriseComponent("DeveloperCredential").getAttributeValue("Active") === false) {
            action = null;
        }

        return (
            <>
                {action}
            </>
        )
    }

    renderPermissionCell = (___, params, columnId, _) => {
        if (params.row.id > this.state.buffer.length - 1) return;

        let applicationActive = this.applet.getEnterpriseComponent().getEnterpriseObject().getEnterpriseComponent("DeveloperApplication").getAttributeValue("Active");
        let credentialActive = this.applet.getEnterpriseComponent().getEnterpriseObject().getEnterpriseComponent("DeveloperCredential").getAttributeValue("Active");
        let permissionActive = this.state.buffer[params.row.id][columnId];

        let checkColor = applicationActive && credentialActive && permissionActive === true ? Green4 : applicationActive && credentialActive && !permissionActive === true ? Red3 : Grey6

        let status = null
        if (this.state.buffer[params.row.id][columnId] === true) {
            status = <Check style={{color: checkColor}}/>
        } else {
            status = <Close style={{color: checkColor}}/>
        }
        return (
            <div style={{display: "flex", flexDirection: "row", justifyContent: "center", marginTop: "8px"}}>{status}</div>
        );
    };

    shouldRenderMenuItem(identifier) {
        if (identifier === "Refresh") {
            return this.applet.getEnterpriseComponent().getEnterpriseObject().getEnterpriseComponent("DeveloperApplication").getTotalRowcount() > 0 && this.applet.getEnterpriseComponent().getEnterpriseObject().getEnterpriseComponent("DeveloperCredential").getTotalRowcount() > 0;
        }
        return true;
    }

}

export default withNavigate(withAppContext(withStyles(styles, {withTheme: true})(withBreakpoint(DeveloperCredentialAuthorizationTable))));