import RelationshipMetadata from "../../RelationshipMetadata";

class DeveloperApplicationDeveloperCredentialRelationshipMetadata extends RelationshipMetadata {
    constructor() {
        super();

        this.name = "DeveloperApplicationDeveloperCredential";
        this.parentEnterpriseComponent = "DeveloperApplication";
        this.childEnterpriseComponent = "DeveloperCredential";
        this.sourceAttribute = "USID";
        this.destinationAttribute = "ApplicationUSID";
    }
}

export default DeveloperApplicationDeveloperCredentialRelationshipMetadata;