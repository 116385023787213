import {withStyles} from "@mui/styles";
import styles, {Grey17, Grey6} from "../../theme/styles";

import {EnlilTable} from "../EnlilTable";
import {withAppContext} from "../withAppContext";
import withNavigate from "../../util/useNavigate";
import withBreakpoint from "../../util/withBreakpoint";
import EventBus from "../../util/EventBus";
import {IconButton, Menu, MenuItem, Typography} from "@mui/material";
import {Edit, MoreVert} from "@mui/icons-material";
import css_self from "../Navigation/css/NavBar.module.scss";
import React from "react";
import DeveloperEndpointFormApplet from "../../eoec/applet/impl/EndpointManagement/DeveloperEndpointFormApplet";

class DeveloperEndpointTable extends EnlilTable {

    componentDidMount() {
        super.componentDidMount();

        EventBus.components.addListener("developer-endpoint-form-applet-cancel", this.onCancelHandler);
        EventBus.components.addListener("developer-endpoint-form-applet-save", this.onCancelHandler);
    }

    componentWillUnmount() {
        super.componentWillUnmount();

        EventBus.components.removeListener("developer-endpoint-form-applet-cancel", this.onCancelHandler);
        EventBus.components.removeListener("developer-endpoint-form-applet-save", this.onCancelHandler);
    }

    enterpriseComponentInsertCompleted(ec) {
        super.enterpriseComponentInsertCompleted(ec)

        ec.invalidateChildren();
    }

    enterpriseComponentDeleteCompleted(ec) {
        super.enterpriseComponentDeleteCompleted(ec)

        ec.invalidateChildren();
    }

    getCellRenderer(name) {
        if (name === "Action Cell") {
            return this.renderActionCell;
        }

        return super.getCellRenderer(name);
    };

    handleMenuClick(identifier) {
        if (identifier === "New Endpoint") {
            this.setState({renderTarget: "New Endpoint"});
        }
        super.handleMenuClick(identifier);
    }

    renderExtension() {
        if (this.state.renderTarget === "Edit Endpoint") {
            return <DeveloperEndpointFormApplet enterpriseComponent={this.applet.getEnterpriseComponent()} defaultMode="Edit" />;
        } else if (this.state.renderTarget === "New Endpoint") {
            return <DeveloperEndpointFormApplet enterpriseComponent={this.applet.getEnterpriseComponent()} defaultMode="New" />;
        } else if (this.state.renderTarget === "Delete Endpoint") {
            return <DeveloperEndpointFormApplet enterpriseComponent={this.applet.getEnterpriseComponent()} defaultMode="Delete" />;
        }
    };

    onCancelHandler = () => {
        this.setState({renderTarget: null});
    };

    renderActionCell = (___, params, __, _) => {
        if (params.row.id > this.state.buffer.length - 1) return;

        return (
            <div style={{width: "50px", marginTop: "-4px", backgroundColor: "#FFFFFF", marginLeft: "-10px", marginRight: "-20px", paddingBottom: "5px", paddingLeft: "4px"}}>
                <IconButton onClick={(event) => {
                    if (this.state.tableActionMenuTarget === null) {
                        this.setState({tableActionMenuTarget: event.currentTarget});
                    } else {
                        this.setState({tableActionMenuTarget: null});
                    }
                }}>
                    <MoreVert />
                </IconButton>
                <Menu anchorEl={this.state.tableActionMenuTarget}
                      anchorOrigin={{vertical: "bottom", horizontal: "right"}}
                      transformOrigin={{vertical: -10, horizontal: "center"}}
                      open={this.state.tableActionMenuTarget !== null && this.state.tableActionMenuTarget !== undefined}
                      onClose={() => this.setState({tableActionMenuTarget: null})}
                      TransitionProps={{
                          onExited: () => this.setState({tableActionMenuTarget: null}),
                      }}
                      MenuListProps={{
                          ...{"data-cc-component": "NavMenu"},
                          disablePadding: true,
                          style: {"minWidth": "200px"},
                      }}
                      PaperProps={{
                          elevation: 2,
                      }}
                >
                    <MenuItem divider key={"1"} className={css_self.Standard} onClick={() => {
                        this.setState({tableActionMenuTarget: null, renderTarget: "Edit Endpoint"});
                    }}>
                        <Edit style={{color: Grey6}} />
                        <Typography style={{fontSize: "15px", fontWeight: "400", color: Grey17}}>Edit Endpoint</Typography>
                    </MenuItem>
                    <MenuItem divider key={"2"} className={css_self.Standard} onClick={() => {
                        this.setState({tableActionMenuTarget: null, renderTarget: "Delete Endpoint"});
                    }}>
                        <Edit style={{color: Grey6}} />
                        <Typography style={{fontSize: "15px", fontWeight: "400", color: Grey17}}>Delete Endpoint</Typography>
                    </MenuItem>
                </Menu>
            </div>
        )
    };
}

export default withNavigate(withAppContext(withStyles(styles, {withTheme: true})(withBreakpoint(DeveloperEndpointTable))));