export function prepareBuffer(ec) {
    let buffer = ec?.getBuffer() ?? null;
    if (buffer !== null) {
        if (buffer.size > 0) buffer = buffer.get(ec.getPagePointer());
        else if (buffer.size === 0) buffer = [];
    }
    return buffer ?? [];
}

export function getActiveRecord(ec) {
    let buffer = ec?.getBuffer() ?? null;
    if (buffer === null || buffer.size === 0) return null;
    return buffer.get(ec.getPagePointer())[ec.getRecordPointer()];
}