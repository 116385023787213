import React from "react";
import {Navigate, Route, Routes, useLocation} from 'react-router-dom';
import Logout from "./components/Logout/Logout";
import Home from "./pages/Home";
import ApiAndTools from "./pages/ApiAndTools";
import Community from "./pages/Community";
import Reference from "./components/APIDocsTools/Reference";
import Dashboard from "./pages/Dashboard";
import useAppContext from "./util/AppContext";


export default function AppRoutes() {
    const location = useLocation();
    const appContext = useAppContext();

    return (
        <Routes>
            <Route exact path="/" element={<Home />} />
            <Route exact path="/logout" element={<Logout />} />
            <Route exact path="/home" element={<Home />} />
            <Route exact path="/dashboard" element={appContext?.sessionContext.valid() ? <Dashboard/> : <Home/>} />
            <Route path="/api-docs-tools/*" element={<ApiAndTools />} />
            <Route exact path="/community" element={<Community />} />
            <Route exact path="/community/aiplayground" element={<Reference />} />
            <Route exact path="/community/discussions" element={<Reference />} />
            <Route exact path="/community/blogs" element={<Reference />} />
            <Route exact path="/community/enlilanswers" element={<Reference />} />
            <Route exact path="/community/enlilanswers/ask" element={<Reference />} />
            <Route exact path="/community/enlilanswers/answer" element={<Reference />} />
            <Route exact path="/community/enlilanswers/browse" element={<Reference />} />
            <Route exact path="/community/enlilanswers/contributors" element={<Reference />} />
            <Route exact path="/community/enlilanswers/recentactivity" element={<Reference />} />
            <Route exact path="/community/enlilanswers/help" element={<Reference />} />
            <Route path="/*" element={<Navigate to="/" replace state={{
                referrer: {
                    pathname: location.pathname,
                    search: location.search,
                },
            }} />} />
        </Routes>
    );
}