const memoryContext = new Proxy(
    {},
    {
        isExtensible: (target) => false,
        enumerate: (target) => Reflect.enumerate(target),
        has: (target, key) => Reflect.has(target, key),
        get: (target, key) => {
            if (!(key in target)) target[key] = {};
            return target[key];
        },
        set: (target, key, value) => {
            target[key] = value;
            return true;
        },
    },
);

export default memoryContext;