import * as jwt from "jsonwebtoken";
import _ from "lodash";
import {useEffect, useState} from "react";
import {v4 as uuidv4} from "uuid";
import EventBus from "./EventBus";
import {useHandler} from "./useHandler";

const SessionSchema = {
    sessionUSID: String,
    identityToken: String,
    accessToken: String,
    refreshToken: String,
    pickValues: Object,
    showLoginDialog: Boolean
};

const IdentityTokenSchema = {
    sessionUSID: {type: String, attribute: "session"},
    companyName: {type: String, attribute: "companyName"},
    employeeUSID: {type: String, attribute: "employeeUSID"},
    userUSID: {type: String, attribute: "userUSID"},
    name: {type: String, attribute: "name"},
    realm: {type: String, attribute: "realm"},
};

export class SessionContext {

    constructor(data) {

        this.data = data;

        this._identityToken = null;
        this._identityTokenClaims = null;

        this._accessToken = null;
        this._accessTokenClaims = null;

        Object.keys({...SessionSchema, ...IdentityTokenSchema}).forEach((key) =>
            Object.defineProperty(this, key, {
                get: () => this.__getattr__(key),
                set: (value) => this.__setattr__(key, value),
                enumerable: true,
                configurable: true,
            }),
        );

        this.sessionId = SessionContext.loadSessionId();
        this.instanceId = uuidv4();
    }

    static loadSessionId() {
        let sessionId = sessionStorage.getItem("sessionId");
        if (sessionId === null) {
            sessionId = uuidv4();
            sessionStorage.setItem("sessionId", sessionId);
        }
        return sessionId;
    }

    __hasattr__(key) {
        const value = this.__getattr__(key);
        return value !== null && value !== undefined;
    }

    __getattr__(key) {

        if (key in SessionSchema) {
            let value = this.data ? this.data[key] : sessionStorage.getItem(key);
            value = this.convert(SessionSchema[key], value);
            value = this.mutate(key, value, "read");
            return value;
        }

        if (key in IdentityTokenSchema) {
            const identityTokenClaims = this.identityTokenClaims();
            const attribute = IdentityTokenSchema[key].attribute;
            let value = identityTokenClaims ? identityTokenClaims[attribute] : undefined;
            value = this.convert(IdentityTokenSchema[key].type, value);
            value = this.mutate(key, value, "read");
            return value;
        }

        return undefined;
    }

    __setattr__(key, value) {

        if (key in SessionSchema) {
            this.update(Object.fromEntries([[key, value]]));
            return true;
        }

        return false;
    }

    update(items) {

        let updated = {};

        Object.entries(items).forEach(([key, value]) => {

            if (key in SessionSchema) {

                if (value === null) {

                    this.data
                        ? (this.data[key] = undefined)
                        : sessionStorage.removeItem(key);

                } else {

                    if (SessionSchema[key] === Object) {
                        value = JSON.stringify(value);
                    }

                    value = this.mutate(key, value, "write");

                    this.data
                        ? (this.data[key] = value)
                        : sessionStorage.setItem(key, value);
                }

                updated[key] = value;
            }
        });

        if (!this.data && Object.keys(updated).length > 0) {
            EventBus.session.emit("update", updated);
        }

        return updated;
    }

    ttl() {
        const accessTokenClaims = this.accessTokenClaims();
        if (!accessTokenClaims) return 0;
        return Date.now() / 1000 - accessTokenClaims.exp;
    }

    valid() {
        return Boolean(
            this.identityTokenClaims() &&
            this.accessTokenClaims(),
        );
    }

    dump() {
        return _.reduce(
            SessionSchema,
            (result, value, key) => {
                value = this.__getattr__(key);
                if (value !== null && value !== undefined) {
                    result[key] = value;
                }
                return result;
            },
            {},
        );
    }

    size() {
        return _.size(this.dump());
    }

    reset() {

        const updated = this.update(
            Object.fromEntries(Object.keys(SessionSchema).map((k) => [k, null])),
        );

        if (!this.data) {
            sessionStorage.removeItem("sessionId");
        }

        EventBus.session.emit("update");

        return updated;
    }

    convert(type, value) {
        if (value === "null") value = null;
        if (value === null) return null;
        if (value === undefined) return undefined;
        if (typeof value === "string") {
            if (type === Boolean) return value === "true";
            if (type === Object) return JSON.parse(value);
        }
        return value;
    }

    mutate(key, value, mode) {

        if (mode === "read") {
            if (key === "activeVisibility" && (value ?? false)) {
                let [visibilityMode, visibilityValue] = value.split(":");
                value = {mode: visibilityMode, value: visibilityValue};
            }
        }

        if (mode === "write") {
            if (key === "activeVisibility" && (value ?? false)) {
                let [visibilityMode, visibilityValue] = value;
                value = `${visibilityMode}:${visibilityValue}`;
            }
        }

        return value;
    }

    jwtDecode(token) {
        try {
            return jwt.decode(token, {complete: false, ignoreExpiration: true});
        } catch (e) {
            return null;
        }
    }

    identityTokenClaims() {
        const identityToken = this.__getattr__("identityToken");
        if (this._identityToken !== identityToken) {
            this._identityTokenClaims = this.jwtDecode(identityToken);
            this._identityToken = identityToken;
        }
        return this._identityTokenClaims;
    }

    accessTokenClaims() {
        const accessToken = this.__getattr__("accessToken");
        if (this._accessToken !== accessToken) {
            this._accessTokenClaims = this.jwtDecode(accessToken);
            this._accessToken = accessToken;
        }
        return this._accessTokenClaims;
    }

    getName() {
        return this.__getattr__("name");
    }
    getCompanyName() {
        return this.__getattr__("companyName");
    }

    getEmployeeUSID() {
        return this.__getattr__("employeeUSID");
    }

    getPickValues() {
        return this.__getattr__("pickValues");
    }

}

export function useSessionContext() {

    const [sessionContext, setSessionContext] = useState(new SessionContext());

    const updateListener = useHandler(
        () => setSessionContext(new SessionContext()),
        [sessionContext],
    );

    useEffect(() => {
        EventBus.session.addListener("update", updateListener);
        return () => EventBus.session.removeListener("update", updateListener);
    }, [updateListener]);

    return sessionContext;
}
