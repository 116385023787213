import {prepareBuffer} from "../../util/Buffer";
import enterpriseComponentClassFactory from "../EnterpriseComponentClassFactory";


class DynamicLookupDefaultValue {
    constructor() {
        this.picklistEC = null;
        this.listener = null;
        this.field = null;
        this.formElementId = null;
    }

    enterpriseComponentLoadCompleted = (ec) => {
        let buffer = prepareBuffer(ec);
        this.listener.defaultValueFetchCompleted(this.formElementId, buffer);
    };

    fetch = (detail, value, value2, field, formElementId, attribute) => {
        let picklist = attribute.getPicklist();

        if (picklist !== null && picklist.getStatic() === false) {
            this.field = field;
            this.listener = detail;
            this.formElementId = formElementId;
            this.picklistEC = enterpriseComponentClassFactory(picklist.getEnterpriseComponent());

            this.picklistEC.addLoadCompletedListener(this);
            this.picklistEC.newQuery();
            this.picklistEC.setSearchSpecification(picklist.getSearchSpecification());
            this.picklistEC.setAttributeValue(picklist.getTypeValue(), value);
            if (value2 !== null) {
                this.picklistEC.setAttributeValue(picklist.getTypeValue2(), value2);
            }
            this.picklistEC.executeQuery();
        }
    };
}

export default DynamicLookupDefaultValue;