import React, {createContext, useContext, useReducer} from "react";
import {useLocation} from "react-router-dom";
import {parseQueryParams} from "../../util/Location";
import LoginHelp1 from "./LoginHelp1";
import LoginHelp2 from "./LoginHelp2";
import LoginHelp3 from "./LoginHelp3";

export const LoginHelpContext = createContext(null);

export default function LoginHelp() {

    const location = useLocation();

    const queryParams = location.pathname === "/forgotPassword" && location.search ? parseQueryParams(location.search) : {};

    const [helpState, updateHelpState] = useReducer(
        (state, props) => {
            return {...state, ...props};
        },
        {
            email: undefined,
            key: queryParams.key,
            code: queryParams.code,
        },
    );

    const LoginHelpContextExports = {
        helpState, updateHelpState,
    };

    return (
        <LoginHelpContext.Provider value={LoginHelpContextExports}>
            {helpState.code ? (
                <LoginHelp3 />
            ) : helpState.key ? (
                <LoginHelp2 />
            ) : (
                <LoginHelp1 />
            )}
        </LoginHelpContext.Provider>
    );
}

export function useLoginHelpContext() {
    return useContext(LoginHelpContext);
}
