import React from "react";
import "../Navigation/css/NavBar.scss";
import {Black} from "../../theme/styles";
import bom001 from '../../json/bom-001.json';
import ReactJson from 'react-json-view'


export default function BomsGet() {

    return (
        <div style={{paddingLeft: "20px", paddingRight: "30px"}}>
            <p style={{fontSize: "24px", fontWeight: "400", color: Black}}>BOMs GET</p>
            <p style={{fontSize: "16px", fontWeight: "400", color: Black}}>BOMs GET REST API</p>

            <p style={{fontSize: "14px", fontWeight: "400", color: Black}}>
                In Enlil, a revision of a part may participate in bill of materials (BOM). Membership in a BOM is hierarchical with all parts (except the root part) having a parent and zero or more children.
            </p>
            <ul style={{fontSize: "14px", fontWeight: "400", color: Black}}>
                <li style={{marginTop: "20px"}}>To download an existing BOM, the boms GET API (v1.Bom-001) will return the entire membership of a BOM starting with the provided part revision USID.</li>
            </ul>
            <br/>
            <span style={{textDecoration: "underline"}}><b>v1.Bom-001</b></span>
            <p style={{fontSize: "14px", fontWeight: "400", color: Black}}>
                v1.Bom-001 is an IDOC designed to return a comprehensive view of the provided Part Revision. Included relations include, Note (1-1) and Inventory Task Assignment (M-1).
            </p>
            <p style={{fontSize: "12px", fontWeight: "400", color: Black, fontFamily: "Courier New"}}>
                <ReactJson src={bom001} collapseStringsAfterLength={75} collapsed={3}/>
            </p>
        </div>
    )
}