import React from "react";

import Applet from "../../Applet";
import DeveloperEventTable from "../../../../components/EventManagement/DeveloperEventTable";

class DeveloperEventTableApplet extends Applet {

    render() {
        return <DeveloperEventTable applet={this}
                               pageSize={this.state.PageSize}
                               placement={this.state.Placement}
                               columns={this.state.Columns}
                               title="Events"
                               enterpriseComponent={this.getEnterpriseComponent()}
                               sort={this.state.Sort}
                               menu={this.state.Menu}
                               rowHeight={this.state.RowHeight}
                               enableSort={true}
        />;
    }

    state = {
        Name: "DeveloperEventTableApplet",
        Menu: {
            options: [
                {
                    displayValue: "New Event",
                    value: "New Event",
                },
                {
                    displayValue: "Refresh",
                    value: "Refresh",
                },
            ],
        },
        RowHeight: 50,
        PageSize: {
            "default": 5,
            "xs": 3,
            "sm": 3,
            "md": 10,
            "lg": 10,
            "lx": 10,
        },
        Placement: {
            "xl": [
                {
                    id: "RecordPointer",
                },
                {
                    id: "EventType",
                },
                {
                    id: "EndpointName",
                },
                {
                    id: "Actions",
                },
            ],
        },
        Columns: {
            "RecordPointer": {
                id: "RecordPointer",
                width: 25,
                cellRenderer: "Row Selection Indicator Cell",
                header: null,
                headerRenderer: "",
                headerTitle: "",
            },
            "EventType": {
                id: "EventType",
                width: 260,
                cellRenderer: "Standard Single Cell",
                header: "enabled",
                headerRenderer: "Standard Single Header",
                headerTitle: "Event Type",
            },
            "EndpointName": {
                id: "EndpointName",
                width: 260,
                cellRenderer: "Standard Single Cell",
                header: "enabled",
                headerRenderer: "Standard Single Header",
                headerTitle: "Endpoint",
            },
            "Actions": {
                id: "Actions",
                width: 50,
                cellRenderer: "Action Cell",
                header: "enabled",
                headerRenderer: "Standard Single Header",
                headerTitle: "",
                pinned: "right",
                hideable: false,
            }
        }
    };
}

export default DeveloperEventTableApplet;
