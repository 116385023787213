import DeveloperApplicationEnterpriseObjectMetadata from "./eo/impl/RestManagement/DeveloperApplicationEnterpriseObjectMetadata";
import DeveloperEndpointEnterpriseObjectMetadata from "./eo/impl/EndpointManagement/DeveloperEndpointEnterpriseObjectMetadata";
import DeveloperEventEnterpriseObjectMetadata from "./eo/impl/EventManagement/DeveloperEventEnterpriseObjectMetadata";
import DeveloperExceptionEnterpriseObjectMetadata from "./eo/impl/ExceptionManagement/DeveloperExceptionEnterpriseObjectMetadata";
import DeveloperLogEnterpriseObjectMetadata from "./eo/impl/LogManagement/DeveloperLogEnterpriseObjectMetadata";

const enterpriseObjectMetadataClasses: Map<string, object> = new Map();

enterpriseObjectMetadataClasses.set("DeveloperApplicationEnterpriseObjectMetadata", DeveloperApplicationEnterpriseObjectMetadata);
enterpriseObjectMetadataClasses.set("DeveloperEndpointEnterpriseObjectMetadata", DeveloperEndpointEnterpriseObjectMetadata);
enterpriseObjectMetadataClasses.set("DeveloperEventEnterpriseObjectMetadata", DeveloperEventEnterpriseObjectMetadata);
enterpriseObjectMetadataClasses.set("DeveloperExceptionEnterpriseObjectMetadata", DeveloperExceptionEnterpriseObjectMetadata);
enterpriseObjectMetadataClasses.set("DeveloperLogEnterpriseObjectMetadata", DeveloperLogEnterpriseObjectMetadata);

export default function EnterpriseObjectMetadataClassFactory(name: string): object | undefined{
    return enterpriseObjectMetadataClasses.get(name + "EnterpriseObjectMetadata");
}