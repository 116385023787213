import EnterpriseObjectMemberMetadata from "../../EnterpriseObjectMemberMetadata";
import EnterpriseObjectMetadata from "../../EnterpriseObjectMetadata";

class DeveloperApplicationEnterpriseObjectMetadata extends EnterpriseObjectMetadata {

    constructor() {
        super();

        this.name = "DeveloperApplication";
        this.primaryEnterpriseComponent = "DeveloperApplication";

        let eom: EnterpriseObjectMemberMetadata = new EnterpriseObjectMemberMetadata();
        eom.name = "DeveloperApplication";
        eom.enterpriseComponent = "DeveloperApplication";
        eom.relationship = undefined;
        this.enterpriseComponentMembers.set(eom.name, eom);

        eom = new EnterpriseObjectMemberMetadata();
        eom.name = "DeveloperCredential";
        eom.enterpriseComponent = "DeveloperCredential";
        eom.relationship = "DeveloperApplicationDeveloperCredential";
        this.enterpriseComponentMembers.set(eom.name, eom);

        eom = new EnterpriseObjectMemberMetadata();
        eom.name = "DeveloperCredentialAuthorization";
        eom.enterpriseComponent = "DeveloperCredentialAuthorization";
        eom.relationship = "DeveloperCredentialDeveloperCredentialAuthorization";
        this.enterpriseComponentMembers.set(eom.name, eom);

        eom = new EnterpriseObjectMemberMetadata();
        eom.name = "DeveloperCredentialAuthorizationDetail";
        eom.enterpriseComponent = "DeveloperCredentialAuthorizationDetail";
        eom.relationship = "DeveloperCredentialAuthorizationDeveloperCredentialAuthorizationDetail";
        this.enterpriseComponentMembers.set(eom.name, eom);
    }
}

export default DeveloperApplicationEnterpriseObjectMetadata;