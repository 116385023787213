import EnlilAxios from "../../../../util/EnlilAxios";
import {V1DeveloperCredentialAuthorizationDetailIDOCToArray} from "../../../../util/Payload";
import EnterpriseComponent from "../../EnterpriseComponent";


class DeveloperCredentialAuthorizationDetailEnterpriseComponent extends EnterpriseComponent {
    performQuery = (page, pageSize, foreignKey) => {
        page = page || 1;
        pageSize = pageSize || this.getDefaultCacheSize();

        this.notifyLoadStartListeners();

        let fk = {};
        if (this.enterpriseObject !== null) {
            let relationship = this.enterpriseObject.getRelationshipForEnterpriseComponent(this);

            fk[relationship.getDestinationAttribute()] = foreignKey;
        }

        EnlilAxios({
            method: "GET",
            url: "t2/v1/developer_credential_authorization_details",
            json: true,
            params: {
                "Limit": pageSize,
                "Offset": (page - 1) * pageSize,
                "Sort": this.getFormattedSortSpecification(),
                ...(foreignKey ? fk : {}),
                ...this.getSearchSpecification(),
            },
            headers: {
                "idoc-response": this.getIDOCResponse() ?? "v1.DeveloperCredentialAuthorizationDetail-001",
            },
        }).then(response => {
            this.fetchEnterpriseComponentSuccess(V1DeveloperCredentialAuthorizationDetailIDOCToArray(response.data), page, pageSize);
        }).catch(error => {
            this.fetchEnterpriseComponentFailed(error);
        });
    };

    enterpriseComponentPutIDOC = () => {
        let payload = {};

        payload.ShifamedMessage = {};
        payload.ShifamedMessage["-idoc"] = "v1.DeveloperCredentialAuthorizationDetail-001";
        payload.ShifamedMessage.DeveloperCredentialAuthorizationDetail = this.preparePUTPayload();

        return payload;
    };

    performUpdate() {
        let payload = this.enterpriseComponentPutIDOC();

        let options = {
            method: "PUT",
            url: "t2/v1/developer_credential_authorization_details",
            json: true,
            data: payload,
            headers: {
                "idoc-request": "v1.DeveloperCredentialAuthorizationDetail-001",
                "idoc-response": "v1.DeveloperCredentialAuthorizationDetail-001",
            },
        };
        this.notifyUpdateStartListeners();
        EnlilAxios(options)
            .then(response => {
                this.updateEnterpriseComponentSuccess(V1DeveloperCredentialAuthorizationDetailIDOCToArray(response.data));
            })
            .catch(error => {
                this.updateEnterpriseComponentFailed(error);
            });
    };

}

export default DeveloperCredentialAuthorizationDetailEnterpriseComponent;