import React from "react";
import "../Navigation/css/NavBar.scss";
import {Black} from "../../theme/styles";

export default function Parts() {

    return (
        <div style={{paddingLeft: "20px", paddingRight: "30px"}}>
            <p style={{fontSize: "24px", fontWeight: "400", color: Black}}>Document Type</p>
            <p style={{fontSize: "16px", fontWeight: "400", color: Black}}>REST APIs support the handling of document types in Enlil.</p>
            <ul style={{fontSize: "14px", fontWeight: "400", color: Black}}>
                <li style={{marginTop: "20px"}}>In Enlil, document types are foundational to the information model that enables all aspects of the product suite.</li>
                <li style={{marginTop: "20px"}}>Document Type consists of the following attributes</li>
                <li style={{marginTop: "20px", marginLeft: "40px"}}>Document Type Name</li>
                <li style={{marginTop: "20px", marginLeft: "40px"}}>Document Type Acronym</li>
                <li style={{marginTop: "20px", marginLeft: "40px"}}>Private Number Pool</li>
                <li style={{marginTop: "20px", marginLeft: "40px"}}>Starting Number</li>
                <li style={{marginTop: "20px", marginLeft: "40px"}}>Group</li>
                <li style={{marginTop: "20px", marginLeft: "40px"}}>Is QMS</li>
                <li style={{marginTop: "20px", marginLeft: "40px"}}>Default Training</li>
                <li style={{marginTop: "20px", marginLeft: "40px"}}>Needs Signature</li>
                <li style={{marginTop: "20px", marginLeft: "40px"}}>Can Redline</li>
                <li style={{marginTop: "20px", marginLeft: "40px"}}>Category</li>
                <li style={{marginTop: "20px", marginLeft: "40px"}}>Active</li>
                <li style={{marginTop: "20px", marginLeft: "40px"}}>Default Revision Stage</li>
                <li style={{marginTop: "20px", marginLeft: "40px"}}>Equipment Enforce Prod</li>
                <li style={{marginTop: "20px", marginLeft: "40px"}}>Number Of Doc Digits</li>
                <li style={{marginTop: "20px", marginLeft: "40px"}}>Number Of Documents</li>
                <li style={{marginTop: "20px", marginLeft: "40px"}}>Document Type Group Options</li>
            </ul>
            <br/>
        </div>
    )
}