import React from "react";
import "../Navigation/css/NavBar.scss";
import {Black} from "../../theme/styles";
import css_self from "../../pages/css/Pages.module.scss";
import {Link} from "@mui/material";
import {useNavigate} from "react-router-dom";

export default function Boms() {

    const navigate = useNavigate();

    return (
        <div style={{paddingLeft: "20px", paddingRight: "30px"}}>
            <p style={{fontSize: "24px", fontWeight: "400", color: Black}}>Bill of Materials</p>
            <p style={{fontSize: "16px", fontWeight: "400", color: Black}}>REST APIs support the handling of BOMs in Enlil.</p>
            <ul style={{fontSize: "14px", fontWeight: "400", color: Black}}>
                <li style={{marginTop: "20px"}}>In Enlil, attachments may be associated to a particular revision of a Part document. This family of APIs allows for the retrieval, creation and updating of the BOM.</li>
                <li style={{marginTop: "20px"}}>To download an existing BOM for a part revision, already constructed in Enlil, use the <Link className={css_self.Link4} onClick={(event) => {navigate("/apidocstools/api/boms_get")}}>boms GET</Link> API.
                    <li style={{marginTop: "20px", marginLeft: "20px"}}>v1.Bom-001</li>
                </li>
            </ul>
        </div>
    )
}