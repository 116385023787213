import EnterpriseObjectMemberMetadata from "../../EnterpriseObjectMemberMetadata";
import EnterpriseObjectMetadata from "../../EnterpriseObjectMetadata";

class DeveloperExceptionEnterpriseObjectMetadata extends EnterpriseObjectMetadata {

    constructor() {
        super();

        this.name = "DeveloperException";
        this.primaryEnterpriseComponent = "DeveloperException";

        let eom: EnterpriseObjectMemberMetadata = new EnterpriseObjectMemberMetadata();
        eom.name = "DeveloperException";
        eom.enterpriseComponent = "DeveloperException";
        eom.relationship = undefined;
        this.enterpriseComponentMembers.set(eom.name, eom);
    }
}

export default DeveloperExceptionEnterpriseObjectMetadata;