import {Button} from "@mui/material";
import React from "react";
import {useStyles} from "../../util/useStyles";
import CCPulse from "./CCPulse";
import css_self from "./css/CCButton.module.scss";
import "./css/CCButton.scss";

export default function CCButton({
    color, // primary | secondary | red | yellow | green
    variant, // contained | outlined | text
    animate, // true | false
    margin, // none | normal
    className,
    children,
    ...props
}) {

    const classes = useStyles();

    let colorClass;
    if (color === "primary") colorClass = classes.basicButton;
    else if (color === "secondary") colorClass = classes.secondaryButton;
    else if (color === "red") colorClass = classes.redButton;
    else if (color === "red-outline") colorClass = classes.redOutlinedButton;
    else if (color === "yellow") colorClass = classes.yellowButton;
    else if (color === "green") colorClass = classes.greenButton;
    else if (color === "none") colorClass = undefined;
    else colorClass = classes.basicButton;

    let {onClick, ...rest} = props;

    if (animate) onClick = null;

    return (
        <Button
            {...{"data-cc-component": "CCButton"}}
            {...{"data-animate": animate}}
            {...{"data-margin": margin ? margin : "normal"}}
            variant={variant !== undefined ? variant : "contained"}
            className={[
                ...(colorClass !== undefined ? [colorClass] : []),
                ...(className !== undefined ? [className] : []),
            ].join(" ")}
            onClick={onClick}
            {...rest}
        >
            <span style={animate ? {visibility: "hidden"} : undefined}>{children}</span>
            {animate ? <CCPulse className={css_self.Pulse} /> : undefined}
        </Button>
    );
}
