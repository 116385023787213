import {Typography} from "@mui/material";
import {DatePicker, LocalizationProvider} from "@mui/x-date-pickers";
import {AdapterMoment} from "@mui/x-date-pickers/AdapterMoment";
import React from "react";
import "./css/CCTextField.scss";

export default function CCDatePicker({
    fullWidth, // true | false
    margin, // none | dense | normal
    variant, // standard | outlined | filled
    format,
    minDate,
    onChange,
    value,
    children,
    ...props
}) {

    margin = margin !== undefined ? margin : props.label ? "dense" : "none";
    format = format !== undefined ? format : "MM/DD/YYYY";
    let helperText = null;
    if (props.helperText !== null) {
        helperText = props.helperText;
    }
    return (
        <LocalizationProvider dateAdapter={AdapterMoment}>
            <div>
                <DatePicker
                    {...{"data-cc-component": "CCDatePicker"}}
                    format={format}
                    minDate={minDate}
                    margin={margin}
                    disableToolbar
                    autoOk
                    variant="inline"
                    fullWidth={fullWidth !== undefined ? fullWidth : true}
                    onChange={onChange ? (date) => {
                        date = date ? date.format("YYYY-MM-DD") : null;
                        return onChange(date);
                    } : undefined}
                    value={value}
                    {...props}
                >
                    {children}
                </DatePicker>
                <div><Typography style={{fontSize: 14, fontWeight: 400, color: "#FF0000", marginLeft: "16px", marginTop: "2px"}}>{helperText}</Typography></div>
            </div>
        </LocalizationProvider>
    );
}
