import RelationshipMetadata from "../../RelationshipMetadata";

class DeveloperEventDeveloperConfigurationRelationshipMetadata extends RelationshipMetadata {
    constructor() {
        super();

        this.name = "DeveloperEventDeveloperEventConfiguration";
        this.parentEnterpriseComponent = "DeveloperEvent";
        this.childEnterpriseComponent = "DeveloperEventConfiguration";
        this.sourceAttribute = "USID";
        this.destinationAttribute = "DeveloperEventUSID";
    }
}

export default DeveloperEventDeveloperConfigurationRelationshipMetadata;