import React from "react";
import "../Navigation/css/NavBar.scss";
import {Black} from "../../theme/styles";
import {Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow} from "@mui/material";


export default function ErrorCodes() {

    return (
        <div style={{paddingLeft: "20px", paddingRight: "30px"}}>
            <p style={{fontSize: "24px", fontWeight: "400", color: Black}}>Error Codes</p>
            <p style={{fontSize: "16px", fontWeight: "400", color: Black}}>Enlil has the following error catalog.</p>

            <TableContainer component={Paper}>
                <Table sx={{ minWidth: 900, border: "2px solid #EEEEEE"}}>
                    <TableHead>
                        <TableRow style={{height: "40px", backgroundColor: "#FAFAFA", borderRight: "1px solid #FAFAFA"}}>
                            <TableCell size='small' width="30%" style={{maxWidth: "100px", borderRight: "2px solid #EEEEEE", color: "#000000"}}><b>Code</b></TableCell>
                            <TableCell size='small' width="70%" align="left"><b>Description</b></TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        <TableRow key={1} sx={{ '&:last-child td, &:last-child th': { border: 1 } }}>
                            <TableCell size='small' component="th" scope="row" style={{borderRight: "2px solid #EEEEEE"}}>CC-11C9-41C9</TableCell>
                            <TableCell size='small' align="left"></TableCell>
                        </TableRow>
                        <TableRow key={2} sx={{ '&:last-child td, &:last-child th': { border: 1 } }}>
                            <TableCell size='small' component="th" scope="row" style={{borderRight: "2px solid #EEEEEE"}}>CC-19FB-4270</TableCell>
                            <TableCell size='small' align="left"></TableCell>
                        </TableRow>
                        <TableRow key={3} sx={{ '&:last-child td, &:last-child th': { border: 1 } }}>
                            <TableCell size='small' component="th" scope="row" style={{borderRight: "2px solid #EEEEEE"}}>CC-26F6-48FA</TableCell>
                            <TableCell size='small' align="left"></TableCell>
                        </TableRow>
                        <TableRow key={4} sx={{ '&:last-child td, &:last-child th': { border: 1 } }}>
                            <TableCell size='small' component="th" scope="row" style={{borderRight: "2px solid #EEEEEE"}}>CC-4500-4E51</TableCell>
                            <TableCell size='small' align="left"></TableCell>
                        </TableRow>
                        <TableRow key={5} sx={{ '&:last-child td, &:last-child th': { border: 1 } }}>
                            <TableCell size='small' component="th" scope="row" style={{borderRight: "2px solid #EEEEEE"}}>CC-49B5-419B</TableCell>
                            <TableCell size='small' align="left"></TableCell>
                        </TableRow>
                        <TableRow key={6} sx={{ '&:last-child td, &:last-child th': { border: 1 } }}>
                            <TableCell size='small' component="th" scope="row" style={{borderRight: "2px solid #EEEEEE"}}>CC-5C82-CE38</TableCell>
                            <TableCell size='small' align="left"></TableCell>
                        </TableRow>
                        <TableRow key={7} sx={{ '&:last-child td, &:last-child th': { border: 1 } }}>
                            <TableCell size='small' component="th" scope="row" style={{borderRight: "2px solid #EEEEEE"}}>CC-5DB6-48BF</TableCell>
                            <TableCell size='small' align="left"></TableCell>
                        </TableRow>
                        <TableRow key={8} sx={{ '&:last-child td, &:last-child th': { border: 1 } }}>
                            <TableCell size='small' component="th" scope="row" style={{borderRight: "2px solid #EEEEEE"}}>CC-5FC8-48E9</TableCell>
                            <TableCell size='small' align="left"></TableCell>
                        </TableRow>
                        <TableRow key={9} sx={{ '&:last-child td, &:last-child th': { border: 1 } }}>
                            <TableCell size='small' component="th" scope="row" style={{borderRight: "2px solid #EEEEEE"}}>CC-67RT-AQ23</TableCell>
                            <TableCell size='small' align="left"></TableCell>
                        </TableRow>
                        <TableRow key={10} sx={{ '&:last-child td, &:last-child th': { border: 1 } }}>
                            <TableCell size='small' component="th" scope="row" style={{borderRight: "2px solid #EEEEEE"}}>CC-746A-4E73</TableCell>
                            <TableCell size='small' align="left"></TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
            </TableContainer>
        </div>
    )
}
/*
CC-788C-4725
CC-7A2C-48DF
CC-895C-4107
CC-8E36-4CDC
CC-9CBC-957B
CC-9I8U-POSW
CC-A23E-D45S
CC-A4CB-4944
CC-AED2-HY78
CC-ALE4-W23R
CC-AOED-QW23
CC-AP96-LKW3
CC-AW23-O908
CC-B652-40B8
CC-CA62-4B92
CC-D324-4DF2
CC-DJ78-AQ23
CC-EEW2-AQWQ
CC-F1AD-4641
CC-F235-458C
CC-FB3A-4B3E
CC-HE56-KED0
CC-HQ23-HE56
CC-HW45-JUED
CC-IE45-PP00
CC-IKU7-AW78
CC-IO89-AWWW
CC-IOIU-90IO
CC-IR45-KIT5
CC-IUU7-IE63
CC-J63E-ZMA0
CC-JD29-PO09
CC-JDER-KDEO
CC-JE76-I890
CC-JFH4-IR78
CC-JQ23-KO89
CC-JUE4-94RT
CC-JW89-PE45
CC-KE40-4OP3
CC-KE89-LOPK
CC-KI23-AW23
CC-KIED-QW23
CC-KJER-OI89
CC-KU63-ALLO
CC-L56T-OI45
CC-LAO4-POE4
CC-LERD-Q23L
CC-LO23-DBX5
CC-LO34-OOD4
CC-LO89-P034
CC-LO90-AQ23
CC-LO90-JE23
CC-LO90-NMDE
CC-LOE4-OERD
CC-NY89-BY67
CC-O90I-I8U7
CC-O9E3-AQ12
CC-OE45-W323
CC-OIO9-UIE4
CC-OK78-AWHB
CC-OOO9-KI78
CC-QWES-98ER
CC-SHED-NH78
CC-UE78-IO34
CC-UEDF-SJNF
CC-UEUE-JEDE
CC-UI12-I89D
CC-UIE4-UE45
CC-UIER-RRO3
CC-UJED-XDCV
CC-UJUE-NBDR
CC-VS34-VD78
CC-WE23-A234
CC-WE34-AW34
*/