import {withStyles} from "@mui/styles";
import EnlilDetail from "../../../../components/EnlilDetail";
import EventBus from "../../../../util/EventBus";
import withNavigate from "../../../../util/useNavigate";
import Applet from "../../Applet";
import {withAppContext} from "../../../../components/withAppContext";
import styles from "../../../../theme/styles";

class DeveloperEventConfigurationFormApplet extends Applet {
    state = {
        Name: "DeveloperEventConfigurationFormApplet",
        Modes: {
            View: {
                Groups: [
                    {
                        "identifier": "group1",
                        "type": "standard",
                        "selector": null,
                        "characteristics": [],
                        "membership": [
                            {
                                id: "ConfigurationName",
                            },
                            {
                                id: "ConfigurationValue",
                            }
                        ]
                    }
                ]
            },
            New: {
                Groups: [
                    {
                        "identifier": "group1",
                        "type": "standard",
                        "selector": null,
                        "characteristics": [],
                        "membership": [
                            {
                                id: "ConfigurationName",
                            },
                            {
                                id: "ConfigurationValue",
                            }
                        ]
                    }
                ]
            },
            Edit: {
                Groups: [
                    {
                        "identifier": "group1",
                        "type": "standard",
                        "selector": null,
                        "characteristics": [],
                        "membership": [
                            {
                                id: "ConfigurationName",
                            },
                            {
                                id: "ConfigurationValue",
                            }
                        ]
                    }
                ]
            }
        },
        Layout: {
            totalWidth: "18em",
        },
        Controls: {
            ConfigurationName: {
                id: "ConfigurationName",
                enterpriseComponentAttribute: "ConfigurationName",
                placement: {
                    "View": {
                        xs: 12,
                        sm: 12,
                        md: 12,
                        lg: 12,
                        xl: 12,
                    },
                    "Edit": {
                        xs: 12,
                        sm: 12,
                        md: 12,
                        lg: 12,
                        xl: 12,
                    },
                    "New": {
                        xs: 12,
                        sm: 12,
                        md: 12,
                        lg: 12,
                        xl: 12,
                    },
                },
                errors: [
                    {
                        validator: "No Special Characters",
                        value: null,
                        errorText: "Field must not have special characters.",
                    },
                    {
                        validator: "No Leading Spaces",
                        value: null,
                        errorText: "Field must not have leading spaces.",
                    },
                    {
                        validator: "Not All Spaces",
                        value: null,
                        errorText: "Field must not have all spaces.",
                    },

                ],
                csp: {
                    autoFocus: false,
                    multiline: false,
                    placeholder: null,
                    label: "Name",
                    margin: "dense",
                    disabled: false,
                },
                ip: {},
                type: "TextField",
                requiresConfirmation: false,
            },
            ConfigurationValue: {
                id: "ConfigurationValue",
                enterpriseComponentAttribute: "ConfigurationValue",
                placement: {
                    "View": {
                        xs: 12,
                        sm: 12,
                        md: 12,
                        lg: 12,
                        xl: 12,
                    },
                    "Edit": {
                        xs: 12,
                        sm: 12,
                        md: 12,
                        lg: 12,
                        xl: 12,
                    },
                    "New": {
                        xs: 12,
                        sm: 12,
                        md: 12,
                        lg: 12,
                        xl: 12,
                    },
                },
                errors: [
                    {
                        validator: "No Leading Spaces",
                        value: null,
                        errorText: "Field must not have leading spaces.",
                    },
                    {
                        validator: "Not All Spaces",
                        value: null,
                        errorText: "Field must not have all spaces.",
                    },

                ],
                csp: {
                    autoFocus: false,
                    multiline: false,
                    placeholder: null,
                    label: "Value",
                    margin: "dense",
                    disabled: false,
                },
                ip: {},
                type: "TextField",
                requiresConfirmation: false,
            }
        }
    };

    cancel() {
        super.cancel();

        EventBus.components.emit("developer-event-configuration-form-applet-cancel");
    };

    setMode(mode) {
        super.setMode(mode);

        if (mode === "Save Completed") {
            EventBus.components.emit("developer-event-configuration-form-applet-save");
        }
    };

    render() {
        return <EnlilDetail applet={this}
                                layout={this.state.Layout}
                                controls={this.state.Controls}
                                modes={this.state.Modes}
                                title="Event Configuration"
                                enterpriseComponent={this.getEnterpriseComponent()}
                                mode={this.props.defaultMode}
                                errorReportingMethod="RealTime" />;
    }
}

export default withNavigate(withAppContext(withStyles(styles, {withTheme: true})(DeveloperEventConfigurationFormApplet)));
