import {withStyles} from "@mui/styles";
import EnlilDetail from "../../../../components/EnlilDetail";
import EventBus from "../../../../util/EventBus";
import withNavigate from "../../../../util/useNavigate";
import Applet from "../../Applet";
import {withAppContext} from "../../../../components/withAppContext";
import styles from "../../../../theme/styles";

class DeveloperCredentialAuthorizationFormApplet extends Applet {
    state = {
        Name: "DeveloperCredentialAuthorizationFormApplet",
        Modes: {
            View: {
                Groups: [
                    {
                        "identifier": "group1",
                        "type": "standard",
                        "selector": null,
                        "characteristics": [],
                        "membership": [
                            {
                                id: "ReadPermission",
                            },
                            {
                                id: "CreatePermission",
                            },
                            {
                                id: "UpdatePermission",
                            },
                        ],
                    },
                ],
            },
            New: {
                Groups: [
                    {
                        "identifier": "group1",
                        "type": "standard",
                        "selector": null,
                        "characteristics": [],
                        "membership": [
                            {
                                id: "ReadPermission",
                            },
                            {
                                id: "CreatePermission",
                            },
                            {
                                id: "UpdatePermission",
                            }
                        ],
                    },
                ],
            },
            Edit: {
                Groups: [
                    {
                        "identifier": "group1",
                        "type": "standard",
                        "selector": null,
                        "characteristics": [],
                        "membership": [
                            {
                                id: "ReadPermission",
                            },
                            {
                                id: "CreatePermission",
                            },
                            {
                                id: "UpdatePermission",
                            },
                        ],
                    },
                ],
            },
        },
        Layout: {
            totalWidth: "36em",
        },
        Controls: {
            ReadPermission: {
                id: "ReadPermission",
                enterpriseComponentAttribute: "ReadPermission",
                placement: {
                    "View": {
                        xs: 12,
                        sm: 12,
                        md: 12,
                        lg: 12,
                        xl: 12,
                    },
                    "Edit": {
                        xs: 12,
                        sm: 12,
                        md: 12,
                        lg: 12,
                        xl: 12,
                    },
                    "New": {
                        xs: 12,
                        sm: 12,
                        md: 12,
                        lg: 12,
                        xl: 12,
                    },
                },
                errors: [
                ],
                csp: {
                    autoFocus: false,
                    multiline: false,
                    placeholder: null,
                    label: "Read Permission",
                    margin: "dense",
                    disabled: false,
                },
                ip: {},
                type: "Checkbox",
                requiresConfirmation: false,
            },
            CreatePermission: {
                id: "CreatePermission",
                enterpriseComponentAttribute: "CreatePermission",
                placement: {
                    "View": {
                        xs: 12,
                        sm: 12,
                        md: 12,
                        lg: 12,
                        xl: 12,
                    },
                    "Edit": {
                        xs: 12,
                        sm: 12,
                        md: 12,
                        lg: 12,
                        xl: 12,
                    },
                    "New": {
                        xs: 12,
                        sm: 12,
                        md: 12,
                        lg: 12,
                        xl: 12,
                    },
                },
                errors: [
                ],
                csp: {
                    autoFocus: false,
                    multiline: false,
                    placeholder: null,
                    label: "Create Permission",
                    margin: "dense",
                    disabled: false,
                },
                ip: {},
                type: "Checkbox",
                requiresConfirmation: false,
            },
            UpdatePermission: {
                id: "UpdatePermission",
                enterpriseComponentAttribute: "UpdatePermission",
                placement: {
                    "View": {
                        xs: 12,
                        sm: 12,
                        md: 12,
                        lg: 12,
                        xl: 12,
                    },
                    "Edit": {
                        xs: 12,
                        sm: 12,
                        md: 12,
                        lg: 12,
                        xl: 12,
                    },
                    "New": {
                        xs: 12,
                        sm: 12,
                        md: 12,
                        lg: 12,
                        xl: 12,
                    },
                },
                errors: [
                ],
                csp: {
                    autoFocus: false,
                    multiline: false,
                    placeholder: null,
                    label: "Update Permission",
                    margin: "dense",
                    disabled: false,
                },
                ip: {},
                type: "Checkbox",
                requiresConfirmation: false,
            },
        },
    };

    cancel() {
        super.cancel();

        EventBus.components.emit("developer-credential-authorization-form-applet-cancel");
    };

    setMode(mode) {
        super.setMode(mode);

        if (mode === "Save Completed") {
            EventBus.components.emit("developer-credential-authorization-form-applet-save");
        }
    };

    render() {
        return <EnlilDetail applet={this}
                                layout={this.state.Layout}
                                controls={this.state.Controls}
                                modes={this.state.Modes}
                                title="Authorization"
                                enterpriseComponent={this.getEnterpriseComponent()}
                                mode={this.props.defaultMode}
                                errorReportingMethod="RealTime" />;
    }
}

export default withNavigate(withAppContext(withStyles(styles, {withTheme: true})(DeveloperCredentialAuthorizationFormApplet)));
