import React from "react";
import "../Navigation/css/NavBar.scss";
import {Black} from "../../theme/styles";

export default function Equipments() {

    return (
        <div style={{paddingLeft: "20px", paddingRight: "30px"}}>
            <p style={{fontSize: "24px", fontWeight: "400", color: Black}}>Equipment</p>
            <p style={{fontSize: "16px", fontWeight: "400", color: Black}}>REST APIs support the handling of equipment in Enlil.</p>
            <ul style={{fontSize: "14px", fontWeight: "400", color: Black}}>
                <li style={{marginTop: "20px"}}>In Enlil, equipment are documents whose DocumentTypeGroup attribute is set to "EQUIPMENT". This family of APIs allows for the retrieval, creation and updating of equipment content.</li>
            </ul>
            <br/>
            <br/>
            <p style={{fontSize: "16px", fontWeight: "400", color: Black}}>The logical ERD below helps visualize the equipment relationships in Enlil with regard to Equipment, Equipment Revisions, Attachments, .</p>
        </div>
    )
}