import EmployeePicklistMetadata from "./picklist/impl/EmployeePicklistMetadata";
import ProtocolPicklistMetadata from "./picklist/impl/ProtocolPicklistMetadata";
import EndpointPicklistMetadata from "./picklist/impl/EndpointPicklistMetadata";
import EventTypePicklistMetadata from "./picklist/impl/EventTypePicklistMetadata";

const picklistMetadataClasses: Map<string, object> = new Map();

picklistMetadataClasses.set("EmployeePicklistMetadata", EmployeePicklistMetadata);
picklistMetadataClasses.set("ProtocolPicklistMetadata", ProtocolPicklistMetadata);
picklistMetadataClasses.set("EndpointPicklistMetadata", EndpointPicklistMetadata);
picklistMetadataClasses.set("EventTypePicklistMetadata", EventTypePicklistMetadata);

export default function PicklistMetadataClassFactory(name: string): object | undefined{
    return picklistMetadataClasses.get(name + "PicklistMetadata");
}