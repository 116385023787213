import React from "react";
import "../Navigation/css/NavBar.scss";
import {Black} from "../../theme/styles";
import attachment001 from '../../json/attachment-001.json';
import attachment002 from '../../json/attachment-002.json';
import attachment003 from '../../json/attachment-003.json';
import ReactJson from 'react-json-view'
import {Link} from "@mui/material";
import css_self from "../../pages/css/Pages.module.scss";
import {useNavigate} from "react-router-dom";
import qbe_attachments_1 from "../../json/qbe-attachments-1.json";
import qbe_attachments_1_result from "../../json/qbe-attachments-1-result.json";


export default function AttachmentsGet() {

    const navigate = useNavigate();

    return (
        <div style={{paddingLeft: "20px", paddingRight: "30px"}}>
            <p style={{fontSize: "24px", fontWeight: "400", color: Black}}>Attachments GET</p>
            <p style={{fontSize: "16px", fontWeight: "400", color: Black}}>Overview</p>

            <p style={{fontSize: "14px", fontWeight: "400", color: Black, marginLeft: "20px"}}>
                In Enlil, attachments may be associated to each document (Part, Equipment, etc.) as well as with each revision of those documents. The attachments GET API allows for the retrieval of attachment content.
            </p>
            <ul style={{fontSize: "14px", fontWeight: "400", color: Black}}>
                <li style={{marginTop: "20px"}}>To download an existing attachment, the attachments GET API (v1.Attachment-001) will return a JSON payload that contains a signed GET URL. The user may then directly download the attachment by executing a GET request on this URL.</li>
                <li style={{marginTop: "20px"}}>To download an attachments associated to a given Enlil entity, reference the following APIs.
                    <li style={{marginTop: "20px", marginLeft: "20px"}}>PART</li>
                    <li style={{marginTop: "10px", marginLeft: "40px"}}><Link className={css_self.Link4} onClick={() => {
                        navigate("/api-docs-tools/api-explorer/parts-get")
                    }}>parts GET</Link> (v1.Part-002 and v1.Part-003)
                    </li>
                    <li style={{marginTop: "10px", marginLeft: "20px"}}>PART REVISION</li>
                    <li style={{marginTop: "10px", marginLeft: "40px"}}><Link className={css_self.Link4} onClick={() => {
                        navigate("/api-docs-tools/api-explorer/part-revisions-get")
                    }}>part_revisions GET</Link> (v1.PartRevision-002 and v1.PartRevision-003)
                    </li>
                </li>
            </ul>
            <br/>
            <p style={{fontSize: "16px", fontWeight: "400", color: Black}}>Parameters</p>
            <ul style={{fontSize: "14px", fontWeight: "400", color: Black}}>
                <li style={{marginTop: "20px"}}>Header Parameters
                    <li style={{marginTop: "20px", marginLeft: "20px"}}>X-Enlil-AccessToken (Required String)</li>
                    <li style={{marginTop: "10px", marginLeft: "40px"}}>The X-Enlil-AccessToken is obtained during the authentication process and expires after 90 days.</li>
                    <li style={{marginTop: "20px", marginLeft: "20px"}}>X-Enlil-RefreshToken (Optional String)</li>
                    <li style={{marginTop: "10px", marginLeft: "40px"}}>The X-Enlil-RefreshToken is obtained during the authentication process and never expires. This token is provided is the caller wants a refreshed X-Enlil-AccessToken</li>
                    <li style={{marginTop: "20px", marginLeft: "20px"}}>idoc-response (Required)</li>
                    <li style={{marginTop: "10px", marginLeft: "40px"}}>The name of the IDOC being requested.</li>
                </li>
                <li style={{marginTop: "20px"}}>Basic Query Parameters
                    <li style={{marginTop: "20px", marginLeft: "20px"}}>USID (Required)</li>
                    <li style={{marginTop: "10px", marginLeft: "40px"}}>The primary key of the attachment entity</li>
                    <li style={{marginTop: "20px", marginLeft: "20px"}}>Limit (Optional: Default 5)</li>
                    <li style={{marginTop: "10px", marginLeft: "40px"}}>The number of rows to return in the primary integration component in the IDOC</li>
                    <li style={{marginTop: "20px", marginLeft: "20px"}}>Offset (Optional: Default 0)</li>
                    <li style={{marginTop: "10px", marginLeft: "40px"}}>The number of the row to start with in the result set</li>
                    <li style={{marginTop: "20px", marginLeft: "20px"}}>Sort (Optional: Default None)</li>
                    <li style={{marginTop: "10px", marginLeft: "40px"}}>The column or columns to sort the results set with in the primary integration component in the IDOC. Example: [Column1: DESC, Column1: ASC]</li>
                </li>
                <li style={{marginTop: "20px"}}>Advanced Query Parameters</li>
                <li style={{marginTop: "20px", marginLeft: "20px"}}>Examples</li>
                <li style={{marginTop: "10px", marginLeft: "40px"}}>1. Query the Approval Request and the Document Type</li>
                <li style={{marginTop: "10px", marginLeft: "73px"}}>Query
                    <p style={{fontFamily: "Courier New", fontSize: "12px"}}>
                        <ReactJson src={qbe_attachments_1} collapseStringsAfterLength={75} collapsed={4}/>
                    </p>
                </li>
                <li style={{marginTop: "10px", marginLeft: "73px"}}>Result
                    <p style={{fontFamily: "Courier New", fontSize: "12px"}}>
                        <ReactJson src={qbe_attachments_1_result} collapseStringsAfterLength={75} collapsed={4}/>
                    </p>
                </li>
            </ul>
            <br/>
            <p style={{fontSize: "16px", fontWeight: "400", color: Black}}>Error Codes</p>
            <ul style={{fontSize: "14px", fontWeight: "400", color: Black}}>
                <li style={{marginTop: "20px"}}>400 CC-HQ23-HE56 - Invalid parameter: USID</li>
            </ul>
            <ul style={{fontSize: "14px", fontWeight: "400", color: Black}}>
                <li style={{marginTop: "20px"}}>400 CC-26F6-48FA - Invalid parameter: Limit</li>
            </ul>
            <ul style={{fontSize: "14px", fontWeight: "400", color: Black}}>
                <li style={{marginTop: "20px"}}>400 CC-A4CB-4944 - Invalid parameter: Offset</li>
            </ul>
            <br/>
            <p style={{fontSize: "16px", fontWeight: "400", color: Black}}>Examples</p>
            <p style={{fontSize: "14px", fontWeight: "400", color: Black, marginLeft: "20px"}}>
                Enlil provides several IDOC formats to retrieve attachment related data using the attachments GET API. For example, if the user executes this request:<br/><br/>

                <span style={{fontFamily: "Courier New", fontSize: "14px",}}>curl -G -d "USID=88888888-4444-4444-4444-121212121212&limit=5&offset=0" -H "idoc-response: v1.Attachment-001" -H "X-Enlil-AccessToken: &#60;token&#62;" http://integrate.enlil.io/t3/v1/attachments</span>
                <br/><br/>
                The response will resemble:<br/><br/>

                <p style={{fontFamily: "Courier New", fontSize: "12px"}}>
                    <ReactJson src={attachment002} collapseStringsAfterLength={75}/>
                </p>
            </p>
            <br/>
            <p style={{fontSize: "16px", fontWeight: "400", color: Black}}>IDOCs</p>
            <p style={{fontSize: "14px", fontWeight: "400", color: Black, marginLeft: "20px"}}>
                <span style={{textDecoration: "underline"}}><b>v1.Attachment-001</b></span><br/><br/>
                v1.Attachment-001 is an IDOC designed to return single attachment record enabled with a signed GET URL to allow for the retrieval of an attachment.

                <p style={{fontSize: "12px", fontWeight: "400", color: Black, fontFamily: "Courier New"}}>
                    <ReactJson src={attachment001} collapseStringsAfterLength={75}/>
                </p>
            </p>
            <br/>
            <p style={{fontSize: "14px", fontWeight: "400", color: Black, marginLeft: "20px"}}>
                <span style={{textDecoration: "underline"}}><b>v1.Attachment-002</b></span><br/><br/>
                v1.Attachment-001 is an IDOC designed to return single attachment record enabled with a signed PUT URL to allow for the upload of an attachment.

                <p style={{fontSize: "12px", fontWeight: "400", color: Black, fontFamily: "Courier New"}}>
                    <ReactJson src={attachment002} collapseStringsAfterLength={75}/>
                </p>
            </p>
            <br/>
            <p style={{fontSize: "14px", fontWeight: "400", color: Black, marginLeft: "20px"}}>
                <span style={{textDecoration: "underline"}}><b>v1.Attachment-003</b></span><br/><br/>
                v1.Attachment-003 is an IDOC designed to return a comprehensive view of attachment data and its relationships.

                <p style={{fontSize: "12px", fontWeight: "400", color: Black, fontFamily: "Courier New"}}>
                    <ReactJson src={attachment003} collapseStringsAfterLength={75}/>
                </p>
            </p>
        </div>
    )
}