import DeveloperApplicationEnterpriseComponentMetadata from "./ec/impl/RestManagement/DeveloperApplicationEnterpriseComponentMetadata";
import DeveloperCredentialEnterpriseComponentMetadata from "./ec/impl/RestManagement/DeveloperCredentialEnterpriseComponentMetadata";
import DeveloperCredentialAuthorizationEnterpriseComponentMetadata from "./ec/impl/RestManagement/DeveloperCredentialAuthorizationEnterpriseComponentMetadata";
import DeveloperCredentialAuthorizationDetailEnterpriseComponentMetadata from "./ec/impl/RestManagement/DeveloperCredentialAuthorizationDetailEnterpriseComponentMetadata";
import DeveloperEndpointEnterpriseComponentMetadata from "./ec/impl/EndpointManagement/DeveloperEndpointEnterpriseComponentMetadata";
import DeveloperEndpointConfigurationEnterpriseComponentMetadata from "./ec/impl/EndpointManagement/DeveloperEndpointConfigurationEnterpriseComponentMetadata";
import DeveloperEventEnterpriseComponentMetadata from "./ec/impl/EventManagement/DeveloperEventEnterpriseComponentMetadata";
import DeveloperEventConfigurationEnterpriseComponentMetadata from "./ec/impl/EventManagement/DeveloperEventConfigurationEnterpriseComponentMetadata";
import EmployeeEnterpriseComponentMetadata from "./ec/impl/RestManagement/EmployeeEnterpriseComponentMetadata";
import DeveloperExceptionEnterpriseComponentMetadata from "./ec/impl/ExceptionManagement/DeveloperExceptionEnterpriseComponentMetadata";
import DeveloperLogEnterpriseComponentMetadata from "./ec/impl/LogManagement/DeveloperLogEnterpriseComponentMetadata";


const enterpriseComponentMetadataClasses: Map<string, object> = new Map();

enterpriseComponentMetadataClasses.set("DeveloperApplicationEnterpriseComponentMetadata", DeveloperApplicationEnterpriseComponentMetadata);
enterpriseComponentMetadataClasses.set("DeveloperCredentialEnterpriseComponentMetadata", DeveloperCredentialEnterpriseComponentMetadata);
enterpriseComponentMetadataClasses.set("DeveloperCredentialAuthorizationEnterpriseComponentMetadata", DeveloperCredentialAuthorizationEnterpriseComponentMetadata);
enterpriseComponentMetadataClasses.set("DeveloperCredentialAuthorizationDetailEnterpriseComponentMetadata", DeveloperCredentialAuthorizationDetailEnterpriseComponentMetadata);
enterpriseComponentMetadataClasses.set("DeveloperEndpointEnterpriseComponentMetadata", DeveloperEndpointEnterpriseComponentMetadata);
enterpriseComponentMetadataClasses.set("DeveloperEndpointConfigurationEnterpriseComponentMetadata", DeveloperEndpointConfigurationEnterpriseComponentMetadata);
enterpriseComponentMetadataClasses.set("DeveloperEventConfigurationEnterpriseComponentMetadata", DeveloperEventConfigurationEnterpriseComponentMetadata);
enterpriseComponentMetadataClasses.set("DeveloperEventEnterpriseComponentMetadata", DeveloperEventEnterpriseComponentMetadata);
enterpriseComponentMetadataClasses.set("EmployeeEnterpriseComponentMetadata", EmployeeEnterpriseComponentMetadata);
enterpriseComponentMetadataClasses.set("DeveloperExceptionEnterpriseComponentMetadata", DeveloperExceptionEnterpriseComponentMetadata);
enterpriseComponentMetadataClasses.set("DeveloperLogEnterpriseComponentMetadata", DeveloperLogEnterpriseComponentMetadata);


export default function enterpriseObjectMetadataClassFactory(name: string): Object | undefined {
    return enterpriseComponentMetadataClasses.get(name + "EnterpriseComponentMetadata");
}