import React, {Suspense} from 'react';
import './App.css';
import {useSessionContext} from "./util/SessionContext";
import {createDefaultTheme} from "./theme";
import {StyledEngineProvider, ThemeProvider} from '@mui/material';
import {AppContextProvider} from "./util/AppContext";
import Layout from "./components/Layout";
import InactiveLogout from "./components/Logout/InactiveLogout";

function App() {
    const sessionContext = useSessionContext();

    const theme = createDefaultTheme();
/*
    useEffect(() => {
        if (!sessionContext.valid()) {
            if (sessionContext.size() > 0) {
                console.log(`session is not valid but has content... resetting. ${JSON.stringify(sessionContext.dump())}`);
                sessionContext.reset();
            }
        }
    }, [sessionContext]);
*/
    return (
        <StyledEngineProvider injectFirst>
            <ThemeProvider theme={theme}>
                <Suspense fallback={<div>Loading...</div>}>
                    <AppContextProvider value={{sessionContext}}>
                        <Layout/>
                        <InactiveLogout/>
                    </AppContextProvider>
                </Suspense>
            </ThemeProvider>
        </StyledEngineProvider>
    );
}

export default App;
