import EnterpriseObjectMemberMetadata from "../../EnterpriseObjectMemberMetadata";
import EnterpriseObjectMetadata from "../../EnterpriseObjectMetadata";

class DeveloperEventEnterpriseObjectMetadata extends EnterpriseObjectMetadata {

    constructor() {
        super();

        this.name = "DeveloperEvent";
        this.primaryEnterpriseComponent = "DeveloperEvent";

        let eom: EnterpriseObjectMemberMetadata = new EnterpriseObjectMemberMetadata();
        eom.name = "DeveloperEvent";
        eom.enterpriseComponent = "DeveloperEvent";
        eom.relationship = undefined;
        this.enterpriseComponentMembers.set(eom.name, eom);

        eom = new EnterpriseObjectMemberMetadata();
        eom.name = "DeveloperEventConfiguration";
        eom.enterpriseComponent = "DeveloperEventConfiguration";
        eom.relationship = "DeveloperEventDeveloperEventConfiguration";
        this.enterpriseComponentMembers.set(eom.name, eom);

    }
}

export default DeveloperEventEnterpriseObjectMetadata;