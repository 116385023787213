
import DeveloperApplicationEnterpriseComponent from "./ec/impl/RestManagement/DeveloperApplicationEnterpriseComponent";
import DeveloperCredentialEnterpriseComponent from "./ec/impl/RestManagement/DeveloperCredentialEnterpriseComponent";
import DeveloperCredentialAuthorizationEnterpriseComponent from "./ec/impl/RestManagement/DeveloperCredentialAuthorizationEnterpriseComponent";
import DeveloperCredentialAuthorizationDetailEnterpriseComponent from "./ec/impl/RestManagement/DeveloperCredentialAuthorizationDetailEnterpriseComponent";
import DeveloperEndpointEnterpriseComponent from "./ec/impl/EndpointManagement/DeveloperEndpointEnterpriseComponent";
import DeveloperEndpointConfigurationEnterpriseComponent from "./ec/impl/EndpointManagement/DeveloperEndpointConfigurationEnterpriseComponent";
import DeveloperEventEnterpriseComponent from "./ec/impl/EventManagement/DeveloperEventEnterpriseComponent";
import DeveloperEventConfigurationEnterpriseComponent from "./ec/impl/EventManagement/DeveloperEventConfigurationEnterpriseComponent";
import EmployeeEnterpriseComponent from "./ec/impl/RestManagement/EmployeeEnterpriseComponent";
import DeveloperExceptionEnterpriseComponent from "./ec/impl/ExceptionManagement/DeveloperExceptionEnterpriseComponent";
import DeveloperLogEnterpriseComponent from "./ec/impl/LogManagement/DeveloperLogEnterpriseComponent";

const enterpriseComponentClasses: Map<string, object> = new Map();

enterpriseComponentClasses.set("DeveloperEventEnterpriseComponent", DeveloperEventEnterpriseComponent);
enterpriseComponentClasses.set("DeveloperEventConfigurationEnterpriseComponent", DeveloperEventConfigurationEnterpriseComponent);
enterpriseComponentClasses.set("DeveloperEndpointConfigurationEnterpriseComponent", DeveloperEndpointConfigurationEnterpriseComponent);
enterpriseComponentClasses.set("DeveloperEndpointEnterpriseComponent", DeveloperEndpointEnterpriseComponent);
enterpriseComponentClasses.set("DeveloperExceptionEnterpriseComponent", DeveloperExceptionEnterpriseComponent);
enterpriseComponentClasses.set("DeveloperLogEnterpriseComponent", DeveloperLogEnterpriseComponent);
enterpriseComponentClasses.set("DeveloperApplicationEnterpriseComponent", DeveloperApplicationEnterpriseComponent);
enterpriseComponentClasses.set("DeveloperCredentialEnterpriseComponent", DeveloperCredentialEnterpriseComponent);
enterpriseComponentClasses.set("DeveloperCredentialAuthorizationEnterpriseComponent", DeveloperCredentialAuthorizationEnterpriseComponent);
enterpriseComponentClasses.set("DeveloperCredentialAuthorizationDetailEnterpriseComponent", DeveloperCredentialAuthorizationDetailEnterpriseComponent);
enterpriseComponentClasses.set("EmployeeEnterpriseComponent", EmployeeEnterpriseComponent);

export default function enterpriseComponentClassFactory(name: string): object | undefined{
    return enterpriseComponentClasses.get(name + "EnterpriseComponent");
}