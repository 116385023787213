import React from "react";
import "../Navigation/css/NavBar.scss";
import {Black} from "../../theme/styles";

export default function Learn() {

    return (
        <div style={{paddingLeft: "20px", paddingRight: "30px"}}>
            <p style={{fontSize: "24px", fontWeight: "400", color: Black}}>Learn</p>
            <p style={{fontSize: "16px", fontWeight: "400", color: Black}}>In this section of this portal, Enlil will discuss important topics related to our REST APIs</p>
            <p style={{fontSize: "16px", fontWeight: "400", color: Black, marginTop: "30px"}}>Step 1: Create a user in Enlil.</p>
            <p style={{fontSize: "14px", fontWeight: "400", color: Black, marginTop: "30px", marginLeft: "30px", lineHeight: "1.9"}}>To login to the Enlil portal, you must have an account in Enlil. Your Enlil administrator can create this for you. Additionally, you must be a member of the
                Integration group.<br/><br/>
                The Developer Application will require an association to an Enlil user. When integrating to Enlil through the REST API, this user will be the identity assumed by the traffic you initiate. It is recommended that your Enlil administrator create a dedicated user for this purpose. it is
                not recommended that you use your Enlil account that you login to the Developer portal for this purpose.
            </p>

            <p style={{fontSize: "16px", fontWeight: "400", color: Black, marginTop: "30px"}}>Step 2: Login to the Developer Portal</p>
            <p style={{fontSize: "16px", fontWeight: "400", color: Black}}>Step 3: Create an application</p>
            <p style={{fontSize: "14px", fontWeight: "400", color: Black, marginTop: "30px", marginLeft: "30px", lineHeight: "1.9"}}>
                Navigate to the Dashboard<br/>
                Click on the button "Create an Application"<br/>
                Follow the prompts<br/>
            </p>
        </div>
    )
}