import _ from "lodash";

export function omitDeepBy(o, predicate) {
    Object.keys(o)
        .filter((i) => o[i] !== null && typeof o[i] === "object")
        .forEach((i) => {
            o[i] = omitDeepBy(o[i], predicate);
        });
    o = _.omitBy(o, predicate);
    const size = Object.keys(o).filter((i) => o[i] !== null).length;
    return size > 0 ? o : null;
}
