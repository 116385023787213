import React from "react";
import "../Navigation/css/NavBar.scss";
import {Black} from "../../theme/styles";
import partEnterpriseObject from "../../assets/partEnterpriseObject.png";
import partRevisionEnterpriseObject from "../../assets/partRevisionEnterpriseObject.png";

export default function Parts() {

    return (
        <div style={{paddingLeft: "20px", paddingRight: "30px"}}>
            <p style={{fontSize: "24px", fontWeight: "400", color: Black}}>Parts</p>
            <p style={{fontSize: "16px", fontWeight: "400", color: Black}}>REST APIs support the handling of parts in Enlil.</p>
            <ul style={{fontSize: "14px", fontWeight: "400", color: Black}}>
                <li style={{marginTop: "20px"}}>In Enlil, parts are documents whose document type group attribute is set to "PART". This family of APIs allows for the retrieval, creation and updating of parts.</li>
            </ul>
            <br/>
            <p style={{fontSize: "16px", fontWeight: "400", color: Black}}>The Enterprise Object Model for Parts is detailed below. Other Enlil entities (Equipment, Material, etc.) have a similar Enterprise Object Model.</p>
            <br/>
            <img src={partEnterpriseObject} alt={"Model"}/>
            <p style={{fontSize: "16px", fontWeight: "400", color: Black}}>The Enterprise Object Model for Part Revisions is detailed below. Other Enlil entities (Equipment Revision, Supplier Revision, etc.) have a similar Enterprise Object Model.</p>
            <br/>
            <img src={partRevisionEnterpriseObject} alt={"Model"}/>
        </div>
    )
}