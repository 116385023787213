import AbstractMetadata from "../AbstractMetadata";

class RelationshipMetadata extends AbstractMetadata {
    public name: string = "";
    public parentEnterpriseComponent: string = "";
    public childEnterpriseComponent: string = "";
    public sourceAttribute: string = "";
    public destinationAttribute: string = "";
    public invalidateChildren: boolean = true;

    getParentEnterpriseComponent = () => {
        return this.parentEnterpriseComponent;
    };

    getChildEnterpriseComponent = () => {
        return this.childEnterpriseComponent;
    };

    getSourceAttribute = () => {
        return this.sourceAttribute;
    };

    getDestinationAttribute = () => {
        return this.destinationAttribute;
    };

    getInvalidateChildren(): boolean {
        return this.invalidateChildren;
    };
}

export default RelationshipMetadata;
