import EnterpriseComponentAttributeMetadata from "../../EnterpriseComponentAttributeMetadata";
import EnterpriseComponentMetadata from "../../EnterpriseComponentMetadata";

class DeveloperCredentialAuthorizationEnterpriseComponentMetadata extends EnterpriseComponentMetadata {

    constructor() {
        super();

        this.name = "DeveloperCredentialAuthorization";
        this.defaultCacheSize = 50;

        this.attributes["CredentialUSID"] = new EnterpriseComponentAttributeMetadata(
            "CredentialUSID", // name
            false, // NoCopy
            false, // NoUpdate
            false, // Required
            false, // RequiredForQuery
            null, // Picklist
            null, // Scale
            null, // Precision
            36, // length
            null, // minLength
            null, // pattern
            "USID", // Type
            null, // default value
            false, // computed
        );
        this.attributes["Entity"] = new EnterpriseComponentAttributeMetadata(
            "Entity", // name
            false, // NoCopy
            false, // NoUpdate
            false, // Required
            false, // RequiredForQuery
            null, // Picklist
            null, // Scale
            null, // Precision
            100, // length
            null, // minLength
            null, // pattern
            "String", // Type
            null, // default value
            false, // computed
        );
        this.attributes["ReadPermission"] = new EnterpriseComponentAttributeMetadata(
            "ReadPermission", // name
            false, // NoCopy
            false, // NoUpdate
            false, // Required
            false, // RequiredForQuery
            null, // Picklist
            null, // Scale
            null, // Precision
            null, // length
            null, // minLength
            null, // pattern
            "Boolean", // Type
            null, // default value
            false, // computed
        );
        this.attributes["CreatePermission"] = new EnterpriseComponentAttributeMetadata(
            "CreatePermission", // name
            false, // NoCopy
            false, // NoUpdate
            false, // Required
            false, // RequiredForQuery
            null, // Picklist
            null, // Scale
            null, // Precision
            null, // length
            null, // minLength
            null, // pattern
            "Boolean", // Type
            null, // default value
            false, // computed
        );
        this.attributes["UpdatePermission"] = new EnterpriseComponentAttributeMetadata(
            "UpdatePermission", // name
            false, // NoCopy
            false, // NoUpdate
            false, // Required
            false, // RequiredForQuery
            null, // Picklist
            null, // Scale
            null, // Precision
            null, // length
            null, // minLength
            null, // pattern
            "Boolean", // Type
            null, // default value
            false, // computed
        );
        this.attributes["DeletePermission"] = new EnterpriseComponentAttributeMetadata(
            "DeletePermission", // name
            false, // NoCopy
            false, // NoUpdate
            false, // Required
            false, // RequiredForQuery
            null, // Picklist
            null, // Scale
            null, // Precision
            null, // length
            null, // minLength
            null, // pattern
            "Boolean", // Type
            null, // default value
            false, // computed
        );
        this.attributes["Action"] = new EnterpriseComponentAttributeMetadata(
            "Action", // name
            false, // NoCopy
            true, // NoUpdate
            true, // Required
            false, // RequiredForQuery
            null, // Picklist
            null, // Scale
            null, // Precision
            null, // length
            null, // minLength
            null, // pattern
            null, // Type
            null, // default value
            false, // computed
        );
        this.attributes["CreatedDatetime"] = new EnterpriseComponentAttributeMetadata(
            "CreatedDatetime", // name
            false, // NoCopy
            true, // NoUpdate
            true, // Required
            false, // RequiredForQuery
            null, // Picklist
            null, // Scale
            null, // Precision
            null, // length
            null, // minLength
            null, // pattern
            "Datetime", // Type
            null, // default value
            false, // computed
        );
        this.attributes["USID"] = new EnterpriseComponentAttributeMetadata(
            "USID", // name
            false, // NoCopy
            true, // NoUpdate
            true, // Required
            false, // RequiredForQuery
            null, // Picklist
            null, // Scale
            null, // Precision
            36, // length
            null, // minLength
            null, // pattern
            "UUID", // Type
            null, // default value
            false, // computed
        );
    }
}

export default DeveloperCredentialAuthorizationEnterpriseComponentMetadata;
