import AbstractMetadata from "../AbstractMetadata";

class EnterpriseObjectMemberMetadata extends AbstractMetadata {
    public enterpriseComponent: string | undefined = undefined;
    public relationship: string | undefined = undefined;

    getEnterpriseComponent(): string | undefined {
        return this.enterpriseComponent;
    };

    getRelationship(): string | undefined {
        return this.relationship;
    };

}

export default EnterpriseObjectMemberMetadata;
