import React from "react";

import Applet from "../../Applet";
import DeveloperEndpointTable from "../../../../components/EndpointManagement/DeveloperEndpointTable";

class DeveloperEndpointTableApplet extends Applet {

    render() {
        return <DeveloperEndpointTable applet={this}
                               pageSize={this.state.PageSize}
                               placement={this.state.Placement}
                               columns={this.state.Columns}
                               title="Endpoints"
                               enterpriseComponent={this.getEnterpriseComponent()}
                               sort={this.state.Sort}
                               menu={this.state.Menu}
                               rowHeight={this.state.RowHeight}
                               enableSort={true}
        />;
    }

    state = {
        Name: "DeveloperEndpointTableApplet",
        Menu: {
            options: [
                {
                    displayValue: "New Endpoint",
                    value: "New Endpoint",
                },
                {
                    displayValue: "Refresh",
                    value: "Refresh",
                },
            ],
        },
        RowHeight: 50,
        PageSize: {
            "default": 5,
            "xs": 3,
            "sm": 3,
            "md": 10,
            "lg": 10,
            "lx": 10,
        },
        Placement: {
            "xl": [
                {
                    id: "RecordPointer",
                },
                {
                    id: "Name",
                },
                {
                    id: "Protocol",
                },
                {
                    id: "Actions",
                },
            ],
        },
        Columns: {
            "RecordPointer": {
                id: "RecordPointer",
                width: 25,
                cellRenderer: "Row Selection Indicator Cell",
                header: null,
                headerRenderer: "",
                headerTitle: "",
            },
            "Name": {
                id: "Name",
                width: 260,
                cellRenderer: "Standard Single Cell",
                header: "enabled",
                headerRenderer: "Standard Single Header",
                headerTitle: "Endpoint Name",
            },
            "Protocol": {
                id: "Protocol",
                width: 175,
                cellRenderer: "Standard Single Cell",
                header: "enabled",
                headerRenderer: "Standard Single Header",
                headerTitle: "Protocol",
            },
            "Actions": {
                id: "Actions",
                width: 50,
                cellRenderer: "Action Cell",
                header: "enabled",
                headerRenderer: "Standard Single Header",
                headerTitle: "",
                pinned: "right",
                hideable: false,
            }
        },
        Sort: [
            {
                "Identifier": 1,
                "Visible": true,
                "Default": true,
                "Options": [{
                    "Direction": "ASC",
                    "SortAttribute": "Name",
                    "DisplayName": "Name",
                }],
            },
        ],
    };
}

export default DeveloperEndpointTableApplet;
