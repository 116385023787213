import EnlilAxios from "../../../../util/EnlilAxios";
import {V1DeveloperApplicationIDOCToArray} from "../../../../util/Payload";
import EnterpriseComponent from "../../EnterpriseComponent";


class DeveloperApplicationEnterpriseComponent extends EnterpriseComponent {
    performQuery = (page, pageSize, foreignKey) => {
        page = page || 1;
        pageSize = pageSize || this.getDefaultCacheSize();

        this.notifyLoadStartListeners();

        EnlilAxios({
            method: "GET",
            url: "t2/v1/developer_applications",
            json: true,
            params: {
                "Limit": pageSize,
                "Offset": (page - 1) * pageSize,
                "Sort": this.getFormattedSortSpecification(),
                ...this.getSearchSpecification(),
            },
            headers: {
                "idoc-response": this.getIDOCResponse() ?? "v1.DeveloperApplication-001",
            },
        }).then(response => {
            this.fetchEnterpriseComponentSuccess(V1DeveloperApplicationIDOCToArray(response.data), page, pageSize);
        }).catch(error => {
            this.fetchEnterpriseComponentFailed(error);
        });
    };

    enterpriseComponentPutIDOC = () => {
        let payload = {};

        payload.ShifamedMessage = {};
        payload.ShifamedMessage["-idoc"] = "v1.DeveloperApplication-001";
        payload.ShifamedMessage.DeveloperApplication = this.preparePUTPayload();

        return payload;
    };

    performUpdate() {
        let payload = this.enterpriseComponentPutIDOC();

        let options = {
            method: "PUT",
            url: "t2/v1/developer_applications",
            json: true,
            data: payload,
            headers: {
                "idoc-request": "v1.DeveloperApplication-001",
                "idoc-response": "v1.DeveloperApplication-001",
            },
        };
        this.notifyUpdateStartListeners();
        EnlilAxios(options)
            .then(response => {
                this.updateEnterpriseComponentSuccess(V1DeveloperApplicationIDOCToArray(response.data));
            })
            .catch(error => {
                this.updateEnterpriseComponentFailed(error);
            });
    };

    enterpriseComponentPostIDOC = () => {
        let payload = {};

        let developerApplication = {};

        developerApplication["USID"] = this.getAttributeValue("USID");
        developerApplication["Name"] = this.getAttributeValue("Name");
        developerApplication["EmployeeUSID"] = this.getAttributeValue("EmployeeUSID");
        developerApplication["Active"] = true;
        developerApplication["Environment"] = this.store["Environment"];

        payload.ShifamedMessage = {};
        payload.ShifamedMessage["-idoc"] = "v1.DeveloperApplication-001";
        payload.ShifamedMessage.DeveloperApplication = developerApplication;

        return payload;
    };

    performInsert() {
        let payload = this.enterpriseComponentPostIDOC();

        let options = {
            method: "POST",
            url: "t2/v1/developer_applications",
            json: true,
            data: payload,
            headers: {
                "idoc-request": "v1.DeveloperApplication-001",
                "idoc-response": "v1.DeveloperApplication-001",
                "environment": this.store["Environment"]
            },
        };
        this.notifyInsertStartListeners();
        EnlilAxios(options)
            .then(response => {
                this.insertEnterpriseComponentSuccess(V1DeveloperApplicationIDOCToArray(response.data));
            })
            .catch(error => {
                this.insertEnterpriseComponentFailed(error);
            });
    };

}

export default DeveloperApplicationEnterpriseComponent;