import React from "react";
import style from "./css/CCPulse.module.scss";

export default function CCPulse({className, DotProps, ...props}) {

    const {
        className: dotClassName,
        ...dotProps
    } = DotProps !== undefined ? DotProps : {};

    return (
        <div
            className={[
                style.Pulse,
                ...(className !== undefined ? [className] : []),
            ].join(" ")}
            {...props}
        >
            <div
                className={[
                    style.PulseDot1,
                    ...(dotClassName !== undefined ? [dotClassName] : []),
                ].join(" ")}
                {...dotProps}
            />
            <div
                className={[
                    style.PulseDot2,
                    ...(dotClassName !== undefined ? [dotClassName] : []),
                ].join(" ")}
                {...dotProps}
            />
            <div
                className={[
                    style.PulseDot3,
                    ...(dotClassName !== undefined ? [dotClassName] : []),
                ].join(" ")}
                {...dotProps}
            />
        </div>
    );
}
