import enterpriseComponentClassFactory from "../EnterpriseComponentClassFactory";


class UniqueValidator {
    constructor() {
        this.picklistEC = null;
        this.listener = null;
        this.field = null;
        this.formElementId = null;
    }

    enterpriseComponentLoadCompleted = (ec) => {
        this.listener.validatorFetchCompleted(this.formElementId, ec);
    };

    validate = (detail, value, value2, field, formElementId, attribute) => {
        let picklist = attribute.getPicklist();

        if (picklist !== null && picklist.getStatic() === false) {
            this.field = field;
            this.listener = detail;
            this.formElementId = formElementId;
            this.picklistEC = enterpriseComponentClassFactory(picklist.getEnterpriseComponent());

            this.picklistEC.setSearchSpecification(picklist.getSearchSpecification());

            this.listener.validatorFetchInitiated(this.formElementId, value);
            this.picklistEC.addLoadCompletedListener(this);
            this.picklistEC.newQuery();
            this.picklistEC.setAttributeValue(picklist.getTypeValue(), value);
            if (value2 !== null) {
                this.picklistEC.setAttributeValue(picklist.getTypeValue2(), value2);
            }
            this.picklistEC.executeQuery();
        }
    };
}

export default UniqueValidator;