import React from "react";

import Applet from "../../Applet";
import DeveloperApplicationTable from "../../../../components/RestManagement/DeveloperApplicationTable";

class DeveloperApplicationTableApplet extends Applet {

    render() {
        return <DeveloperApplicationTable applet={this}
                               pageSize={this.state.PageSize}
                               placement={this.state.Placement}
                               columns={this.state.Columns}
                               title="Applications"
                               enterpriseComponent={this.getEnterpriseComponent()}
                               sort={this.state.Sort}
                               menu={this.state.Menu}
                               rowHeight={this.state.RowHeight}
                               enableSort={true}
        />;
    }

    state = {
        Name: "DeveloperApplicationTableApplet",
        Menu: {
            options: [
                {
                    displayValue: "New Application",
                    value: "New Application",
                },
                {
                    displayValue: "Refresh",
                    value: "Refresh",
                },
            ],
        },
        RowHeight: 70,
        PageSize: {
            "default": 5,
            "xs": 3,
            "sm": 3,
            "md": 10,
            "lg": 10,
            "lx": 10,
        },
        Placement: {
            "xl": [
                {
                    id: "RecordPointer",
                },
                {
                    id: "Name",
                },
                {
                    id: "Status",
                },
                {
                    id: "EmployeeName",
                },
                {
                    id: "Actions",
                },
            ],
        },
        Columns: {
            "RecordPointer": {
                id: "RecordPointer",
                width: 25,
                cellRenderer: "Row Selection Indicator Cell",
                header: null,
                headerRenderer: "",
                headerTitle: "",
            },
            "Name": {
                id: "Name",
                width: 260,
                cellRenderer: "Application Name Cell",
                header: "enabled",
                headerRenderer: "Standard Single Header",
                headerTitle: "App Name",
            },
            "Status": {
                id: "Status",
                width: 185,
                cellRenderer: "Status Cell",
                header: "enabled",
                headerRenderer: "Standard Single Header",
                headerTitle: "Status",
            },
            "EmployeeName": {
                id: "EmployeeName",
                width: 180,
                cellRenderer: "Employee Cell",
                header: "enabled",
                headerRenderer: "Standard Single Header",
                headerTitle: "Employee",
            },
            "Actions": {
                id: "Actions",
                width: 50,
                cellRenderer: "Action Cell",
                header: "enabled",
                headerRenderer: "Standard Single Header",
                headerTitle: "",
                pinned: "right",
                hideable: false,
            }
        }
    }
}

export default DeveloperApplicationTableApplet;
