import RelationshipMetadata from "../../RelationshipMetadata";

class DeveloperCredentialDeveloperCredentialAuthorizationRelationshipMetadata extends RelationshipMetadata {
    constructor() {
        super();

        this.name = "DeveloperCredentialDeveloperCredentialAuthorization";
        this.parentEnterpriseComponent = "DeveloperCredential";
        this.childEnterpriseComponent = "DeveloperCredentialAuthorization";
        this.sourceAttribute = "USID";
        this.destinationAttribute = "CredentialUSID";
    }
}

export default DeveloperCredentialDeveloperCredentialAuthorizationRelationshipMetadata;