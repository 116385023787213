import {Grid} from "@mui/material";
import React from "react";


export default function Home() {
    return (
        <React.Fragment>
            <Grid container item direction={"row"} justifyContent={"flex-start"}>
            </Grid>
        </React.Fragment>
    )
}