import { createTheme, responsiveFontSizes, Theme } from '@mui/material';
import { Blue3, Blue4, Grey1b } from './styles';
import type {} from '@mui/lab/themeAugmentation';
import React from "react";

declare module '@mui/material' {
  interface TypographyVariants {
    Sample: React.CSSProperties;
  }

  // allow configuration using `createTheme`
  interface TypographyVariantsOptions {
    Sample?: React.CSSProperties;
  }
}

// Update the Typography's variant prop options
declare module '@mui/material/Typography' {
  interface TypographyPropsVariantOverrides {
    Sample: true;
  }
}

export function createDefaultTheme() {

    let theme: Theme = createTheme({
        spacing: 4,
        breakpoints: {
            values: {
                xs: 0,
                sm: 600,
                md: 960,
                lg: 1280,
                xl: 1920,
            },
        },
        typography: {
            fontFamily: [
                "Roboto",
                "Helvetica Neue",
                "Arial",
                "sans-serif",
            ].join(","),
            fontSize: 16,
            fontWeightLight: 300,
            fontWeightRegular: 400,
            fontWeightMedium: 500,
            fontWeightBold: 600,
            h1: {
                fontSize: "22px",
                fontWeight: 600,
            },
            h2: {
                fontSize: "18px",
                fontWeight: 600,
            },
            h3: {
                fontSize: "16px",
                fontWeight: 600,
            },
            h4: {
                fontSize: "14px",
                fontWeight: 400,
            },
            h5: {
                fontSize: "14px",
                fontWeight: 400,
            },
            h6: {
                fontSize: "14px",
                fontWeight: 400,
            },
            body1: {
                fontSize: 16,
            },
            body2: {
                fontSize: 14,
            },
            button: {
                textTransform: "none",
            },
            subtitle1: {
                fontSize: "22px",
                fontWeight: 600,
            },
            subtitle2: {
                fontSize: "18px",
                fontWeight: 600,
            }
        },
        palette: {
            mode: "light",
            primary: {
                main: Blue4,
                contrastText: "#FFFFFF",
            },
            secondary: {
                main: Blue3,
                contrastText: "#FFFFFF",
            },
            background: {
                default: Grey1b,
                paper: "#FFFFFF",
            },
        },
    });

    theme.components = {
        MuiButtonBase: {
            defaultProps: {
                disableRipple: true,
            },
        },
        MuiButton: {
        },
        MuiLink: {
            defaultProps: {
                underline: "hover", // mui5 uses 'always'
            },
        },
        MuiTextField: {
            defaultProps: {
                variant: "outlined", // mui5 uses 'outlined'
            },
        },
        MuiDialogTitle: {
            styleOverrides: {
                root: {
                    padding: "20px 8px 10px 16px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    fontSize: "1.125rem",
                    fontWeight: 600,
                },
            },
        },
        MuiDialogContent: {
            styleOverrides: {
                root: {
                    [theme.breakpoints.up("sm")]: {
                        minWidth: 410,
                    },
                    padding: "8px 16px",
                    "& .MuiGrid-root": {
                        paddingTop: "8px",
                    },
                    "& form": {
                        width: "100%",
                    },
                },
            },
        },
        MuiDialog: {
            styleOverrides: {
                paper: {
                    [theme.breakpoints.up("sm")]: {
                        marginLeft: 60,
                        marginRight: 60,
                        minWidth: 600,
                    },
                },
            },
        },
        MuiInputLabel: {
            styleOverrides: {
                shrink: {
                    backgroundColor: "#FFFFFF",
                    paddingLeft: 4,
                    paddingRight: 4,
                },
            },
        },
        MuiInputBase: {
            styleOverrides: {
                root: {
                    "& legend": {
                        maxWidth: 0,
                    },
                },
            },
        },
        MuiFormGroup: {
            styleOverrides: {
                root: {
                    marginTop: 12,
                },
            },
        },
        MuiRadio: {
            styleOverrides: {
                root: {
                    padding: 4,
                    paddingLeft: 10,
                },
            },
        },
        MuiCheckbox: {
            styleOverrides: {
                root: {
                    padding: 0,
                    paddingLeft: 10,
                },
            },
        },
        MuiMenuItem: {
            styleOverrides: {
                root: {
                    minHeight: "36px !important",
                },
            },
        },
        MuiTypography: {
            variants: [
                {
                    props: { variant : 'Sample'},
                    style: {
                        fontSize: 24,
                        fontWeight: 400,
                        color: Blue3,
                    }
                }
            ]
        },
    }

    theme = responsiveFontSizes(theme);

    return theme;
}