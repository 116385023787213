import {Checkbox, FormControlLabel, Link, Tooltip, Typography, useMediaQuery} from "@mui/material";
import axios from "axios";
import React, {useEffect, useState} from "react";
import Countdown, {zeroPad} from "react-countdown";
import * as formatters from "../../util/Formatters";
import {sleep} from "../../util/Sleep";
import * as validators from "../../util/Validators";
import CCButton from "../Common/CCButton";
import CCInputLabel from "../Common/CCInputLabel";
import CCTextField from "../Common/CCTextField";
import css_self from "./css/LoginSecure2.module.scss";
import {useLoginContext} from "./Login";

export default function LoginSecure2(props) {

    const [resentAt, setResentAt] = useState(undefined);
    const [submitting, setSubmitting] = useState(false);
    const [rememberDevice, setRememberDevice] = useState(false);
    const [errorText, setErrorText] = useState(undefined);
    const [secureCode, setSecureCode] = useState("");

    const {
        preSessionState,
        updatePreSessionState,
        abortLogin,
        login,
    } = useLoginContext();

    const smallerWidth = useMediaQuery("(max-width:320px)");

    const userIdentifierType = preSessionState.secureLogin.userIdentifierType;
    const userIdentifier = preSessionState.secureLogin[userIdentifierType];
    const usedEmail = validators.IsEmail(userIdentifier);

    useEffect(() => {
        updatePreSessionState(state => {
            return {
                ...state,
                secureLogin: {
                    ...state.secureLogin,
                    rememberDevice: rememberDevice,
                    secureCode: secureCode,
                },
            };
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [rememberDevice, secureCode]);

    async function submit() {

        const startAt = Date.now();

        setSubmitting(true);

        const response = await login();

        if (response instanceof Error && response.message === "Network Error") {
            setSubmitting(false);
            setErrorText("Connection not available");
            return;
        }

        if (response.status === 200) {
            return;
        }

        const waitDiff = 2000 - (Date.now() - startAt);
        waitDiff > 0 && (await sleep(waitDiff));

        setSubmitting(false);

        if (response.data.Code === "CC-11F3-4C3B") {
            setErrorText("Expired verification code");
            return;
        }

        if (response.data.Code === "CC-C395-433E") {
            setErrorText("Incorrect verification code");
            return;
        }

        setErrorText("Service temporarily unavailable");
    }

    function resend() {
        const type = userIdentifierType.endsWith("Phone") ? "Phone" : "Email";
        return axios({
            method: "PUT",
            baseURL: process.env.REACT_APP_API_URL,
            url: "s6/v1/authenticate_send_code",
            json: true,
            headers: {
                "X-Cloudcath-AccessToken": preSessionState.accessToken,
            },
            params: {
                Type: type,
            },
        }).catch(e => e.response || e);
    }

    const emailMaxLength = smallerWidth ? 12 : 25;
    const displayValue = !usedEmail ? userIdentifier : formatters.EmailWithEllipsis(userIdentifier, emailMaxLength);

    return (
        <form onSubmit={e => e.preventDefault()}>
            <Typography className={css_self.InstructionText}>
                We have sent the verification code to{" "}
                {usedEmail && displayValue !== userIdentifier ? (
                    <Tooltip arrow title={userIdentifier} disableInteractive>
                        <Typography component="span" className={css_self.Bolder}>{displayValue}</Typography>
                    </Tooltip>
                ) : (
                    <Typography component="span" className={css_self.Bolder}>{displayValue}</Typography>
                )}
                . Please check your {usedEmail ? "email" : "phone"} and enter the code below.
            </Typography>
            <Typography className={css_self.RedoText}>
                <Link variant="inherit" color="inherit" href="#" onClick={e => {
                    e.preventDefault();
                    updatePreSessionState(state => {
                        return {...state, secureLogin: {...state.secureLogin, userIdentifierType: undefined}};
                    });
                }}>Select another option</Link>
            </Typography>
            {resentAt ? (
                <Typography className={css_self.RedoDisabledText}>
                    Resend the code
                    <Countdown
                        date={resentAt + 60000}
                        onComplete={() => setResentAt(undefined)}
                        renderer={({hours, minutes, seconds, completed}) => {
                            return ` (${zeroPad(minutes * 60 + seconds)}s)`;
                        }}
                    />
                </Typography>
            ) : (
                <Typography className={css_self.RedoText}>
                    <Link variant="inherit" color="inherit" href="#" onClick={async e => {
                        e.preventDefault();
                        resend();
                        setResentAt(Date.now());
                    }}>Resend the code</Link>
                </Typography>
            )}
            <CCInputLabel className={css_self.MuiInputLabel}>Verification code</CCInputLabel>
            <CCTextField
                placeholder="Enter your verification code"
                value={secureCode}
                onChange={async e => {
                    const pattern = /^([a-zA-Z]){0,8}$/i;
                    if (e.target.value === "" || pattern.test(e.target.value)) {
                        setSecureCode(e.target.value.toUpperCase());
                    }
                }}
                inputProps={{
                    className: css_self["MuiInputBase-input"],
                    maxLength: 8,
                }}
                helperText={errorText ? <span className="Mui-error">{errorText}</span> : undefined}
            />
            <Typography className={css_self.VerificationNoticeText}>Your verification code may take a few moments to arrive. Do not share this code with anyone.</Typography>
            <br />
            <div>
                <FormControlLabel
                    control={<Checkbox size="small" color="primary" onChange={e => setRememberDevice(e.target.checked)} />}
                    label={<Typography className={css_self.RememberThisDeviceText}>Remember this device</Typography>}
                />
            </div>
            <CCButton
                className={errorText ? css_self.ButtonWithErrorShown : css_self.Button}
                margin="none"
                animate={submitting}
                disabled={submitting || secureCode.length !== 8}
                onClick={() => {
                    setErrorText(undefined);
                    submit();
                }}
            >
                Continue
            </CCButton>
            <Typography className={css_self.LogoutInsteadText}>
                Having trouble?{" "}
                <Link href="#" onClick={async e => {
                    e.preventDefault();
                    abortLogin();
                }}>
                    Logout
                </Link>
            </Typography>
        </form>
    );
}
