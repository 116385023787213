import PicklistMetadata from "../../metadata/picklist/PicklistMetadata";
import picklistMetadataClassFactory from "../../metadata/PicklistMetadataClassFactory";

class Picklist {
    public picklistMetadata: PicklistMetadata = new PicklistMetadata();
    public exclusionList: string[] = [];

    constructor(name: string) {
        let metadataClass = picklistMetadataClassFactory(name);
        // @ts-ignore
        this.picklistMetadata = new metadataClass() as PicklistMetadata;
    };

    getName = () => {
        return this.picklistMetadata.getName();
    };

    getBounded = () => {
        return this.picklistMetadata.getBounded();
    };

    getEnterpriseComponent = () => {
        return this.picklistMetadata.getEnterpriseComponent();
    };

    getLongList = () => {
        return this.picklistMetadata.getLongList();
    };

    getNoDelete = () => {
        return this.picklistMetadata.getNoDelete();
    };

    getNoUpdate = () => {
        return this.picklistMetadata.getNoUpdate();
    };

    getNoInsert = () => {
        return this.picklistMetadata.getNoInsert();
    };

    getNoMerge = () => {
        return this.picklistMetadata.getNoMerge();
    };

    getSearchSpecification = () => {
        return this.picklistMetadata.getSearchSpecification();
    };

    getSortSpecification = () => {
        return this.picklistMetadata.getSortSpecification();
    };

    getStatic = () => {
        return this.picklistMetadata.getStatic();
    };

    getTypeField = () => {
        return this.picklistMetadata.getTypeField();
    };

    getTypeValue = () => {
        return this.picklistMetadata.getTypeValue();
    };

    getVisibilityType = () => {
        return this.picklistMetadata.getVisibilityType();
    };

    getAllowNoSelection = () => {
        return this.picklistMetadata.getAllowNoSelection();
    };

    getAllowNoSelectionDisplayValue = () => {
        return this.picklistMetadata.getAllowNoSelectionDisplayValue();
    };

    getAllowNoSelectionValue = () => {
        return this.picklistMetadata.getAllowNoSelectionValue();
    };

    getExclusionList = () => {
        return this.exclusionList;
    };

    setExclusionList = (valuesToExclude: string[]) => {
        this.exclusionList = valuesToExclude;
    };

    setSearchSpecification = (searchSpecification: string) => {
        this.picklistMetadata.searchSpecification = searchSpecification;
    };
}

export default Picklist;