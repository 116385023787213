import React from "react";
import AppRoutes from "../AppRoutes";
import NavBar from "./Navigation/NavBar";
import {SystemError} from "./Error/SystemError";

export default function Layout() {
    return (
        <div style={{display: "flex", flexDirection: "column"}}>
            <NavBar />
            <AppRoutes />
            <SystemError />
        </div>
    );
}

