import React from "react";

import Applet from "../../Applet";
import DeveloperEndpointConfigurationTable from "../../../../components/EndpointManagement/DeveloperEndpointConfigurationTable";

class DeveloperEndpointConfigurationTableApplet extends Applet {

    render() {
        return <DeveloperEndpointConfigurationTable applet={this}
                               pageSize={this.state.PageSize}
                               placement={this.state.Placement}
                               columns={this.state.Columns}
                               title="Endpoint Configuation"
                               enterpriseComponent={this.getEnterpriseComponent()}
                               sort={this.state.Sort}
                               menu={this.state.Menu}
                               rowHeight={this.state.RowHeight}
                               enableSort={true}
        />;
    }

    state= {
        Name: "DeveloperEndpointTableApplet",
        Menu: {
            options: [
                {
                    displayValue: "New Endpoint Configuration",
                    value: "New Endpoint Configuration",
                },
                {
                    displayValue: "Refresh",
                    value: "Refresh",
                },
            ],
        },
        RowHeight: 50,
        PageSize: {
            "default": 5,
            "xs": 3,
            "sm": 3,
            "md": 10,
            "lg": 10,
            "lx": 10,
        },
        Placement: {
            "xl": [
                {
                    id: "RecordPointer",
                },
                {
                    id: "ConfigurationName",
                },
                {
                    id: "ConfigurationValue",
                },
                {
                    id: "Actions",
                },
            ],
        },
        Columns: {
            "RecordPointer": {
                id: "RecordPointer",
                width: 25,
                cellRenderer: "Row Selection Indicator Cell",
                header: null,
                headerRenderer: "",
                headerTitle: "",
            },
            "ConfigurationName": {
                id: "ConfigurationName",
                width: 250,
                cellRenderer: "Standard Single Cell",
                header: "enabled",
                headerRenderer: "Standard Single Header",
                headerTitle: "Name",
            },
            "ConfigurationValue": {
                id: "ConfigurationValue",
                width: 550,
                cellRenderer: "Standard Single Cell",
                header: "enabled",
                headerRenderer: "Standard Single Header",
                headerTitle: "Value",
            },
            "Actions": {
                id: "Actions",
                width: 50,
                cellRenderer: "Action Cell",
                header: "enabled",
                headerRenderer: "Standard Single Header",
                headerTitle: "",
                pinned: "right",
                hideable: false,
            }
        },
        Sort: [
            {
                "Identifier": 1,
                "Visible": true,
                "Default": true,
                "Options": [{
                    "Direction": "ASC",
                    "SortAttribute": "ConfigurationName",
                    "DisplayName": "Name",
                }],
            },
        ],
    }
}

export default DeveloperEndpointConfigurationTableApplet;
