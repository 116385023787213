import {Typography, IconButton, Menu, Grid} from "@mui/material";
import {ArrowDropDown, ArrowRight, Menu as MenuIcon} from "@mui/icons-material";
import React from "react";
import {White} from "../theme/styles";
import "../components/Navigation/css/NavBar.scss";

type MenuSelection = EventTarget & HTMLButtonElement;
type ContentsSelection = Boolean;

export default function Community() {
    const [appsMenuTarget, setAppsMenuTarget] = React.useState<MenuSelection | null>(null);
    const [enlilAnswersVisibility, setEnlilAnswersVisibility] = React.useState<ContentsSelection>(false);
    const [discussionsVisibility, setDiscussionsVisibility] = React.useState<ContentsSelection>(false);
    const [aiPlaygroundVisibility, setAiPlaygroundVisibility] = React.useState<ContentsSelection>(false);
    const [blogsVisibility, setBlogsVisibility] = React.useState<ContentsSelection>(false);

    let contents = (
        <div>
            <div style={{paddingLeft: "20px", marginRight: "20px"}}>
                <div style={{display: "flex", flexDirection: "row", justifyContent: "flex-start", marginTop: "30px", width: "100%", borderBottom: "1px solid #DDDDDD"}}>
                    <div style={{marginRight: "3px"}}>
                        {enlilAnswersVisibility ? (
                            <IconButton style={{height: "15px", width: "15px"}} onClick={(event) => setEnlilAnswersVisibility(!enlilAnswersVisibility)} onMouseDown={e => e.preventDefault()}>
                                <ArrowDropDown fontSize="medium"/>
                            </IconButton>
                        ) : (
                            <IconButton style={{height: "15px", width: "15px"}} onClick={(event) => setEnlilAnswersVisibility(!enlilAnswersVisibility)} onMouseDown={e => e.preventDefault()}>
                                <ArrowRight fontSize="medium"/>
                            </IconButton>
                        )}
                    </div>
                    <Typography style={{fontSize: 14, fontWeight: 500, color: "#000000"}}>Enlil Answers</Typography>
                </div>
                <div style={{display: "flex", flexDirection: "row", justifyContent: "flex-start", marginTop: "30px", width: "100%", borderBottom: "1px solid #DDDDDD"}}>
                    <div style={{marginRight: "3px"}}>
                        {aiPlaygroundVisibility ? (
                            <IconButton style={{height: "15px", width: "15px"}} onClick={(event) => setAiPlaygroundVisibility(!aiPlaygroundVisibility)} onMouseDown={e => e.preventDefault()}>
                                <ArrowDropDown fontSize="medium"/>
                            </IconButton>
                        ) : (
                            <IconButton style={{height: "15px", width: "15px"}} onClick={(event) => setAiPlaygroundVisibility(!aiPlaygroundVisibility)} onMouseDown={e => e.preventDefault()}>
                                <ArrowRight fontSize="medium"/>
                            </IconButton>
                        )}
                    </div>
                    <Typography style={{fontSize: 14, fontWeight: 500, color: "#000000"}}>AI Playground</Typography>
                </div>
                <div style={{display: "flex", flexDirection: "row", justifyContent: "flex-start", marginTop: "30px", width: "100%", borderBottom: "1px solid #DDDDDD"}}>
                    <div style={{marginRight: "3px"}}>
                        {discussionsVisibility ? (
                            <IconButton style={{height: "15px", width: "15px"}} onClick={(event) => setDiscussionsVisibility(!discussionsVisibility)} onMouseDown={e => e.preventDefault()}>
                                <ArrowDropDown fontSize="medium"/>
                            </IconButton>
                        ) : (
                            <IconButton style={{height: "15px", width: "15px"}} onClick={(event) => setDiscussionsVisibility(!discussionsVisibility)} onMouseDown={e => e.preventDefault()}>
                                <ArrowRight fontSize="medium"/>
                            </IconButton>
                        )}
                    </div>
                    <Typography style={{fontSize: 14, fontWeight: 500, color: "#000000"}}>Discussions</Typography>
                </div>
                <div style={{display: "flex", flexDirection: "row", justifyContent: "flex-start", marginTop: "30px", width: "100%", borderBottom: "1px solid #DDDDDD"}}>
                    <div style={{marginRight: "3px"}}>
                        {blogsVisibility ? (
                            <IconButton style={{height: "15px", width: "15px"}} onClick={(event) => setBlogsVisibility(!blogsVisibility)} onMouseDown={e => e.preventDefault()}>
                                <ArrowDropDown fontSize="medium"/>
                            </IconButton>
                        ) : (
                            <IconButton style={{height: "15px", width: "15px"}} onClick={(event) => setBlogsVisibility(!blogsVisibility)} onMouseDown={e => e.preventDefault()}>
                                <ArrowRight fontSize="medium"/>
                            </IconButton>
                        )}
                    </div>
                    <Typography style={{fontSize: 14, fontWeight: 500, color: "#000000"}}>Blogs</Typography>
                </div>
            </div>
        </div>
    );

    return (
        <Grid container direction={"column"}>
            <Grid container item style={{height: 52, backgroundColor: "#1498D8"}}>
                <Grid container item direction={"row"} justifyContent={"space-between"}>
                    <Grid item style={{paddingTop: "9px", paddingLeft: "20px"}}>
                        <Typography style={{fontSize: 24, fontWeight: 400, color: "#FFFFFF"}}>Community</Typography>
                    </Grid>
                    <Grid item>
                        <IconButton
                            onClick={(event) => {
                                event.stopPropagation();
                                if (appsMenuTarget === null) {
                                    setAppsMenuTarget(event.currentTarget);
                                } else {
                                    setAppsMenuTarget(null);
                                }
                            }}
                            onMouseDown={e => e.preventDefault()}>
                            <MenuIcon sx={{width: 35, height: 35, color: White}}/>
                        </IconButton>
                        {appsMenuTarget !== null ? (
                            <Menu anchorEl={appsMenuTarget}
                                  anchorOrigin={{vertical: "bottom", horizontal: "right"}}
                                  transformOrigin={{vertical: -10, horizontal: "center"}}
                                  open={true}
                                  onClose={() => setAppsMenuTarget(null)}
                                  TransitionProps={{
                                      onExited: () => setAppsMenuTarget(null),
                                  }}
                                  MenuListProps={{
                                      ...{"data-cc-component": "NavMenu"},
                                      disablePadding: true,
                                      style: {"minWidth": "200px"},
                                  }}
                                  PaperProps={{
                                      elevation: 2,
                                  }}
                            >
                            </Menu>
                        ) : null}
                    </Grid>
                </Grid>
            </Grid>
            <Grid container item direction={"column"}>
                <Grid container item direction={"row"} justifyContent={"flex-start"}>
                    <div style={{display: "flex", flexDirection: "column", justifyContent: "flex-start", paddingLeft: "5px", width: "300px", height: "100vh", borderRight: "1px solid #DDDDDD", backgroundColor: "#FAFAFA"}}>
                        {contents}
                    </div>
                </Grid>
            </Grid>
        </Grid>
    )
}