import {ArrowRight, Check, DensityMedium, ExpandMore, Search, Sort} from "@mui/icons-material";
import {Alert, Button, ClickAwayListener, Dialog, DialogActions, DialogContent, DialogTitle, Grid, IconButton, ListItemIcon, ListItemText, Menu, MenuItem, Popper, TextField, Tooltip, Typography} from "@mui/material";
import Pagination from "@mui/material/Pagination";
import {withStyles} from "@mui/styles";
import {DataGridPro} from "@mui/x-data-grid-pro";
import {LicenseInfo} from "@mui/x-license";
import React, {Component} from "react";
import {withAppContext} from "./withAppContext";
import styles from "../theme/styles";
import {prepareBuffer} from "../util/Buffer";
import memoryContext from "../util/MemoryContext";
import {formatLocal, formatLocalDate} from "../util/Formatters";
import CCButton from "./Common/CCButton";
import css_self from "./Common/css/EnlilTable.module.scss";
import withBreakpoint from "../util/withBreakpoint";

LicenseInfo.setLicenseKey(
    "8c35f5398c904769a90be38309ec3d5aTz04NjMzNCxFPTE3NDE4OTI2NjAwMDAsUz1wcm8sTE09c3Vic2NyaXB0aW9uLEtWPTI=",
);

export class EnlilTable extends Component {
    state = {
        fetchBackgroundOperation: false,
        buffer: [],
        showRowsToDisplayButtons: false,
        showColumnSelection: false,
        columnSelection: {},
        showMenu: false,
        sortMenuTarget: null,
        searchMenuTarget: null,
        menuTarget: null,
        showSearch: false,
        sortSpecification: null,
    };

    constructor(props) {
        super(props);

        this.applet = props.applet;

        this.searchSpecification = null;

        this.sortSpecification = {Options: []};
        this.sortSelections = null;
        this.activeSort = null;

        this.memoryState = memoryContext[`EnlilTable-${this.getName()}`];
        if (this.memoryState.pageSize === undefined) this.memoryState.pageSize = this.props.pageSize?.default ?? 5;
        if (this.memoryState.numberOfPages === undefined) this.memoryState.numberOfPages = 1;
        if (this.memoryState.pageToDisplay === undefined) this.memoryState.pageToDisplay = 1;
    }

    componentDidMount() {
        this.applet.getEnterpriseComponent().addRecordChangedListener(this);
        this.applet.getEnterpriseComponent().addNewQueryListener(this);
        this.applet.getEnterpriseComponent().addLoadStartedListener(this);
        this.applet.getEnterpriseComponent().addLoadCompletedListener(this);
        this.applet.getEnterpriseComponent().addValueChangedListener(this);
        this.applet.getEnterpriseComponent().addInvalidListener(this);
        this.applet.getEnterpriseComponent().addUpdateStartedListener(this);
        this.applet.getEnterpriseComponent().addUpdateCompletedListener(this);
        this.applet.getEnterpriseComponent().addDeleteCompletedListener(this);
        this.applet.getEnterpriseComponent().addInsertCompletedListener(this);
        this.applet.getEnterpriseComponent().addInsertRollbackCompletedListener(this);
        this.applet.getEnterpriseComponent().addQueryRollbackCompletedListener(this);
        this.applet.getEnterpriseComponent().addStartNewRecordModeCompletedListener(this);
        this.applet.getEnterpriseComponent().addSingleRecordRefreshStartedListener(this);
        this.applet.getEnterpriseComponent().addSingleRecordRefreshCompletedListener(this);

        this.makeSortSpecification(null);

        if (this.mode === "New") {
            this.applet.getEnterpriseComponent().newRecord();
        } else {
            this.applet.getEnterpriseComponent().setSortSpecification(this.getSortSpecification()?.Options);
            if (this.searchSpecification) this.applet.getEnterpriseComponent().setSearchSpecification(this.searchSpecification);
            this.applet.getEnterpriseComponent().executeQuery(this.memoryState.pageToDisplay, this.memoryState.pageSize);
        }
    }

    makeSortSpecification = (sortModel) => {
        let sortSelections = [];
        let sortSpecification = {};
        let sortSelection = {};
        sortSelection["Identifier"] = "Row Identity";
        sortSelection["Options"] = [{
            "DisplayName": "Creation Date/Time",
            "Direction": "DESC",
            "SortAttribute": "RowIdentity",
        }];
        sortSelection["Visible"] = true;
        sortSelection["Default"] = false;
        sortSelections.push(sortSelection);

        if (this.props.sort !== undefined) {
            for (let i = 0; i < this.props.sort.length; i++) {
                sortSelection = {};
                sortSelection["Identifier"] = this.props.sort[i].Identifier;
                sortSelection["Visible"] = this.props.sort[i].Visible;
                sortSelection["Options"] = this.props.sort[i].Options;
                sortSelection["Default"] = this.props.sort[i].Default === true;
                sortSelections.push(sortSelection);
            }
        }

        if (sortModel !== null) {
            if (sortModel.length === 0) {
                sortSpecification = this.sortSpecification;
                if (sortSpecification["Options"][0]["Direction"] === "desc") {
                    sortSpecification["Options"][0]["Direction"] = "asc";
                } else {
                    sortSpecification["Options"][0]["Direction"] = "desc";
                }
                sortSelections = this.sortSelections;
            } else {
                for (let i = 0; i < sortModel.length; i++) {
                    for (let j = 0; j < sortSelections.length; j++) {
                        for (let t = 0; t < sortSelections[j]["Options"].length; t++) {
                            if (sortSelections[j].Options[t]["SortAttribute"] === sortModel[i]["field"]) {
                                sortSelections[j].Options[t]["Direction"] = sortModel[i]["sort"];
                            }
                        }
                    }
                }
            }
        }

        if (sortSelections.length === 1) {
            sortSelections[0].Default = true;
            sortSelections[0].Visible = false;
        }

        for (let i = 0; i < sortSelections.length; i++) {
            if (sortSelections[i].Default === true) {
                sortSpecification["Identifier"] = sortSelections[i].Identifier;
                sortSpecification["Visible"] = sortSelections[i].Visible;
                sortSpecification["Options"] = sortSelections[i].Options;
            }
        }

        this.setSortSelections(sortSelections);
        this.activeSort = sortSpecification;
        this.setSortSpecification(sortSpecification);
        this.setState({sortSpecification: sortSpecification});
    };

    componentWillUnmount() {
        this.applet.getEnterpriseComponent().removeAllListeners(this);
    }

    doneColumnSelectionHandler = () => {
        this.setState({showColumnSelection: false});
    };

    doneSortSelectionHandler = () => {
        this.setState({showSortSelection: false});
    };

    enterpriseComponentInsertCompleted(ec){
        let row = {};

        let newBuffer = [...this.state.buffer];
        let buffer = prepareBuffer(ec);

        for (let i = 0; i < newBuffer.length; i++) {
            if (newBuffer[i]["USID"] === buffer["USID"]) {
                row = newBuffer[i];
                break;
            }
        }

        let keys = Object.keys(this.applet.getEnterpriseComponent().getAttributes());
        for (let i = 0; i < keys.length; i++) {
            let attribute = this.applet.getEnterpriseComponent().getAttributes()[keys[i]];
            row[attribute.getName()] = buffer[0][attribute.getName()];
        }

        this.memoryState.numberOfPages = Math.max(1, Math.ceil((this.applet.getEnterpriseComponent().getTotalRowcount() ?? 0) / this.memoryState.pageSize));
        this.memoryState.pageToDisplay = Math.min(this.memoryState.pageToDisplay, this.memoryState.numberOfPages);
    };

    enterpriseComponentDeleteCompleted(ec) {
        this.memoryState.numberOfPages = Math.max(1, Math.ceil((this.applet.getEnterpriseComponent().getTotalRowcount() - 1 ?? 0) / this.memoryState.pageSize));
        this.memoryState.pageToDisplay = Math.min(this.memoryState.pageToDisplay, this.memoryState.numberOfPages);

        this.setState({buffer: prepareBuffer(ec), fetchBackgroundOperation: false});
    };

    enterpriseComponentStartNewRecordModeCompleted(ec) {

        this.memoryState.numberOfPages = Math.max(1, Math.ceil((this.applet.getEnterpriseComponent().getTotalRowcount() ?? 0) / this.memoryState.pageSize));
        this.memoryState.pageToDisplay = Math.min(this.memoryState.pageToDisplay, this.memoryState.numberOfPages);

        let buffer = prepareBuffer(ec);
        buffer = buffer.slice(this.memoryState.pageToDisplay * this.memoryState.pageSize, this.memoryState.pageToDisplay * this.memoryState.pageSize + this.memoryState.pageSize);
        this.setState({buffer: buffer});
    };

    enterpriseComponentInvalidated(ec) {
        this.memoryState.pageToDisplay = 1;

        this.setState({buffer: prepareBuffer(ec)});

        this.applet.getEnterpriseComponent().setSortSpecification(this.getSortSpecification()?.Options);
        if (this.searchSpecification) this.applet.getEnterpriseComponent().setSearchSpecification(this.searchSpecification);
        this.applet.getEnterpriseComponent().executeQuery(1, this.memoryState.pageSize);
    };

    enterpriseComponentInsertRollbackCompleted(ec) {
        this.setState({buffer: prepareBuffer(ec), fetchBackgroundOperation: false});
    };

    enterpriseComponentLoadCompleted(ec) {

        this.memoryState.numberOfPages = Math.max(1, Math.ceil((this.applet.getEnterpriseComponent().getTotalRowcount() ?? 0) / this.memoryState.pageSize));
        this.memoryState.pageToDisplay = Math.min(this.memoryState.pageToDisplay, this.memoryState.numberOfPages);

        this.setState({buffer: prepareBuffer(ec), fetchBackgroundOperation: false});
    };

    enterpriseComponentLoadStarted(ec) {
        this.setState({buffer: [], fetchBackgroundOperation: true});
    };

    enterpriseComponentValueChanged(ec) {
        this.setState({buffer: prepareBuffer(ec), fetchBackgroundOperation: false});
    };

    enterpriseComponentNewQueryStarted() {
        this.setState({showSearch: true});
    };

    enterpriseComponentQueryRollbackCompleted() {
        this.setSearchSpecification(null);
        this.applet.getEnterpriseComponent().invalidate();
        this.setState({showSearch: false, searchMenuTarget: null});
    };

    enterpriseComponentRecordChanged(ec){
        this.setState({buffer: prepareBuffer(ec)});
    };

    enterpriseComponentUpdateCompleted(ec){

        this.memoryState.numberOfPages = Math.max(1, Math.ceil((this.applet.getEnterpriseComponent().getTotalRowcount() ?? 0) / this.memoryState.pageSize));
        this.memoryState.pageToDisplay = Math.min(this.memoryState.pageToDisplay, this.memoryState.numberOfPages);

        this.setState({buffer: prepareBuffer(ec), fetchBackgroundOperation: false});
    };

    enterpriseComponentUpdateStarted(ec) {
    };

    enterpriseComponentSingleRecordRefreshStarted(ec) {
    };

    enterpriseComponentSingleRecordRefreshCompleted(ec) {

        this.memoryState.numberOfPages = Math.max(1, Math.ceil((this.applet.getEnterpriseComponent().getTotalRowcount() ?? 0) / this.memoryState.pageSize));
        this.memoryState.pageToDisplay = Math.min(this.memoryState.pageToDisplay, this.memoryState.numberOfPages);

        let buffer = prepareBuffer(ec);
        this.setState({buffer: buffer});
    };

    getCellRenderer(name) {
        if (name === "Row Selection Indicator Cell") {
            return this.renderRowSelectionIndicatorCell;
        } else if (name === "Standard Single Cell") {
            return this.renderStandardSingleCell;
        } else if (name === "Local Date Cell") {
            return this.renderLocalDateCell;
        } else if (name === "Local Datetime Cell") {
            return this.renderLocalDatetimeCell;
        } else if (name === "Timing Cell") {
            return this.renderTimingCell;
        } else if (name === "Edit Cell") {
            return this.renderEditCell;
        }

        return null;
    }

    _getColumns = (breakpoint) => {
        let columns = [];

        for (let columnIndex = 0; columnIndex < this.props.placement[breakpoint].length; columnIndex++) {
            let columnMetadata = this.props.columns[this.props.placement[breakpoint][columnIndex].id];
            let headerRenderer = columnMetadata.headerRenderer;

            if (headerRenderer === null) console.log("Header renderer not found");
            else {
                let sortable = false;
                if (this.getSortSpecification() !== null && this.getSortSpecification().Options !== undefined) {
                    for (let i = 0; i < this.getSortSpecification().Options.length; i++) {
                        if (columnMetadata.id === this.getSortSpecification().Options[i].SortAttribute) {
                            sortable = true;
                        }
                    }
                }
                let headerAlign = columnMetadata.headerAlign;
                if (headerAlign === undefined) {
                    headerAlign = "Left"
                }
                let column = {
                    field: columnMetadata.id,
                    headerName: columnMetadata.headerTitle,
                    editable: false,
                    sortable: sortable,
                    filterable: columnMetadata.filterable,
                    headerAlign: headerAlign,
                    resizable: columnMetadata.resizable,
                    pinnable: false,
                    hideable: columnMetadata.hideable,
                };
                if (columnMetadata.flex !== undefined) {
                    column["flex"] = columnMetadata.flex;
                } else if (columnMetadata.width !== undefined) {
                    column["width"] = columnMetadata.width;
                }
                if (this.state.buffer.length === 0) {
                    column["renderCell"] = null;
                } else {
                    column["renderCell"] = (params) => this.getCellRenderer(columnMetadata.cellRenderer)(this, params, this.props.placement[breakpoint][columnIndex].id, breakpoint);
                }
                columns.push(column);
            }
        }
        return columns;
    };

    getColumns = (breakpoint) => {
        let columns = [];

        if (breakpoint === "xs") {
            if (this.props.placement[breakpoint] === undefined) {
                return this.getColumns("sm");
            } else {
                columns = this._getColumns(breakpoint);
            }
        } else if (breakpoint === "sm") {
            if (this.props.placement[breakpoint] === undefined) {
                return this.getColumns("md");
            } else {
                columns = this._getColumns(breakpoint);
            }
        } else if (breakpoint === "md") {
            if (this.props.placement[breakpoint] === undefined) {
                return this.getColumns("lg");
            } else {
                columns = this._getColumns(breakpoint);
            }
        } else if (breakpoint === "lg") {
            if (this.props.placement[breakpoint] === undefined) {
                return this.getColumns("xl");
            } else {
                columns = this._getColumns(breakpoint);
            }
        } else if (breakpoint === "xl") {
            columns = this._getColumns(breakpoint);
        }

        return columns;
    };

    getColumnCount = (breakpoint) => {
        let columnCount = 0;

        if (breakpoint === "xs") {
            if (this.props.placement[breakpoint] === undefined) {
                return this.getColumnCount("sm");
            } else {
                columnCount = this.props.placement["xs"].length;
            }
        } else if (breakpoint === "sm") {
            if (this.props.placement[breakpoint] === undefined) {
                return this.getColumnCount("md");
            } else {
                columnCount = this.props.placement["sm"].length;
            }
        } else if (breakpoint === "md") {
            if (this.props.placement[breakpoint] === undefined) {
                return this.getColumnCount("lg");
            } else {
                columnCount = this.props.placement["md"].length;
            }
        } else if (breakpoint === "lg") {
            if (this.props.placement[breakpoint] === undefined) {
                return this.getColumnCount("xl");
            } else {
                columnCount = this.props.placement["lg"].length;
            }
        } else if (breakpoint === "xl") {
            columnCount = this.props.placement["xl"].length;
        }

        return columnCount;
    };

    getHeaderRenderer = (name) => {
        return null;
    };

    _getHeaderCells = (breakpoint) => {
        let cells = [];

        for (let columnIndex = 0; columnIndex < this.props.placement[breakpoint].length; columnIndex++) {
            let headerRenderer = this.props.columns[this.props.placement[breakpoint][columnIndex].id].headerRenderer;

            if (headerRenderer === null) console.log("Header renderer not found");
            else {
                cells.push(this.getHeaderRenderer(headerRenderer)(this.props.placement[breakpoint][columnIndex].id));
            }
        }

        return cells;
    };

    getHeaderCells = (breakpoint) => {
        let cells = [];

        if (breakpoint === "xs") {
            if (this.props.placement[breakpoint] === undefined) {
                return this.getHeaderCells("sm");
            } else {
                cells = this._getHeaderCells(breakpoint);
            }
        } else if (breakpoint === "sm") {
            if (this.props.placement[breakpoint] === undefined) {
                return this.getHeaderCells("md");
            } else {
                cells = this._getHeaderCells(breakpoint);
            }
        } else if (breakpoint === "md") {
            if (this.props.placement[breakpoint] === undefined) {
                return this.getHeaderCells("lg");
            } else {
                cells = this._getHeaderCells(breakpoint);
            }
        } else if (breakpoint === "lg") {
            if (this.props.placement[breakpoint] === undefined) {
                return this.getHeaderCells("xl");
            } else {
                cells = this._getHeaderCells(breakpoint);
            }
        } else if (breakpoint === "xl") {
            cells = this._getHeaderCells(breakpoint);
        }

        return cells;
    };

    getHeader = () => {
        return (
            <React.Fragment key="HeaderFragment">
            </React.Fragment>
        );
    };

    getName = () => {
        return this.applet.getName();
    };

    _getPinnedColumns = (breakpoint) => {
        let leftPinnableColumns = [];
        let rightPinnableColumns = [];

        for (let columnIndex = 0; columnIndex < this.props.placement[breakpoint].length; columnIndex++) {
            let columnMetadata = this.props.columns[this.props.placement[breakpoint][columnIndex].id];
            if (columnMetadata.pinned === undefined || columnMetadata.pinned === false) {
                continue;
            }
            let headerRenderer = columnMetadata.headerRenderer;

            if (headerRenderer === null) console.log("Header renderer not found");
            else {
                let sortable = false;
                for (let i = 0; i < this.getSortSpecification().Options.length; i++) {
                    if (columnMetadata.id === this.getSortSpecification().Options[i].SortAttribute) {
                        sortable = true;
                    }
                }
                let column = {
                    field: columnMetadata.id,
                    headerName: columnMetadata.headerTitle,
                    editable: false,
                    sortable: sortable,
                    filterable: columnMetadata.filterable,
                    headerAlign: columnMetadata.align,
                    resizable: columnMetadata.resizable,
                    hideable: columnMetadata.hideable,
                };
                if (columnMetadata.flex !== undefined) {
                    column["flex"] = columnMetadata.flex;
                } else if (columnMetadata.width !== undefined) {
                    column["width"] = columnMetadata.width;
                }
                if (this.state.buffer.length === 0) {
                    column["renderCell"] = null;
                } else {
                    column["renderCell"] = (params) => this.getCellRenderer(columnMetadata.cellRenderer)(this, params, this.props.placement[breakpoint][columnIndex].id, breakpoint);
                }
                if (columnMetadata.pinned.toLowerCase() === "right") {
                    rightPinnableColumns.push(column);
                } else if (columnMetadata.pinned.toLowerCase() === "left") {
                    leftPinnableColumns.push(column);
                }
            }
        }
        let out = {};
        if (leftPinnableColumns.length > 0) {
            out["left"] = [];
        }
        for (let i = 0; i < leftPinnableColumns.length; i++) {
            out["left"].push(leftPinnableColumns[i].field);
        }
        if (rightPinnableColumns.length > 0) {
            out["right"] = [];
        }
        for (let i = 0; i < rightPinnableColumns.length; i++) {
            out["right"].push(rightPinnableColumns[i].field);
        }
        return out;

    };

    getPinnedColumns = (breakpoint) => {
        let columns = {};

        if (breakpoint === "xs") {
            if (this.props.placement[breakpoint] === undefined) {
                return this.getPinnedColumns("sm");
            } else {
                columns = this._getPinnedColumns(breakpoint);
            }
        } else if (breakpoint === "sm") {
            if (this.props.placement[breakpoint] === undefined) {
                return this.getPinnedColumns("md");
            } else {
                columns = this._getPinnedColumns(breakpoint);
            }
        } else if (breakpoint === "md") {
            if (this.props.placement[breakpoint] === undefined) {
                return this.getPinnedColumns("lg");
            } else {
                columns = this._getPinnedColumns(breakpoint);
            }
        } else if (breakpoint === "lg") {
            if (this.props.placement[breakpoint] === undefined) {
                return this.getPinnedColumns("xl");
            } else {
                columns = this._getPinnedColumns(breakpoint);
            }
        } else if (breakpoint === "xl") {
            columns = this._getPinnedColumns(breakpoint);
        }

        return columns;
    };

    _getRowCells = (rowIndex, breakpoint) => {
        let cells = [];

        for (let columnIndex = 0; columnIndex < this.props.placement[breakpoint].length; columnIndex++) {
            let cellRenderer = this.props.columns[this.props.placement[breakpoint][columnIndex].id].cellRenderer;
            cells.push(this.getCellRenderer(cellRenderer)(this, rowIndex, this.props.placement[breakpoint][columnIndex].id, breakpoint));
        }

        return cells;
    };

    getRowCells = (rowIndex, breakpoint) => {
        let cells = [];

        if (breakpoint === "xs") {
            if (this.props.placement[breakpoint] === undefined) {
                return this.getRowCells(rowIndex, "sm");
            } else {
                cells = this._getRowCells(rowIndex, breakpoint);
            }
        } else if (breakpoint === "sm") {
            if (this.props.placement[breakpoint] === undefined) {
                return this.getRowCells(rowIndex, "md");
            } else {
                cells = this._getRowCells(rowIndex, breakpoint);
            }
        } else if (breakpoint === "md") {
            if (this.props.placement[breakpoint] === undefined) {
                return this.getRowCells(rowIndex, "lg");
            } else {
                cells = this._getRowCells(rowIndex, breakpoint);
            }
        } else if (breakpoint === "lg") {
            if (this.props.placement[breakpoint] === undefined) {
                return this.getRowCells(rowIndex, "xl");
            } else {
                cells = this._getRowCells(rowIndex, breakpoint);
            }
        } else if (breakpoint === "xl") {
            cells = this._getRowCells(rowIndex, breakpoint);
        }

        return cells;
    };

    getRow = (rowIndex) => {
        return (
            <React.Fragment key={rowIndex + "Fragment"}>
            </React.Fragment>
        );
    };

    getRows = (breakpoint, columns) => {
        let rows = [];

        for (let i = 0; i < this.state.buffer.length; i++) {
            let row = {
                id: i,
            };
            if (i === this.getSelectedRecordNumber()) {
                row["RowSelected"] = true;
            } else {
                row["RowSelected"] = false;
            }
            for (let columnIndex = 0; columnIndex < columns.length; columnIndex++) {
                let columnMetadata = this.props.columns[columns[columnIndex].field];

                row[columnMetadata.id] = this.state.buffer[i][columnMetadata.id];
            }
            rows.push(row);
        }
        return rows;
    };

    getSearchSpecification = () => {
        return this.searchSpecification;
    };

    getSelectedRecordNumber = () => {
        return this.applet.getEnterpriseComponent().getSelectedRecordNumber();
    };

    getSortSelections = () => {
        if (this.sortSelections === null) return [];

        return this.sortSelections;
    };

    getSortSpecification = () => {
        return this.sortSpecification;
    };

    _getWidth = (columnId, breakpoint) => {
        let width = this.props.columns[columnId].width[breakpoint];
        if (!width) {
            width = this.props.columns[columnId].width["default"];
        }

        return width;
    };

    handleSortMenuClick = (event, sort) => {
        this.setState({sortMenuTarget: null});

        this.activeSort = sort;
        this.setSortSpecification(sort);

        this.applet.getEnterpriseComponent().newQuery();
        this.applet.getEnterpriseComponent().setSortSpecification(this.getSortSpecification()?.Options);
        if (this.searchSpecification) this.applet.getEnterpriseComponent().setSearchSpecification(this.searchSpecification);
        this.applet.getEnterpriseComponent().executeQuery(this.memoryState.pageToDisplay, this.memoryState.pageSize);
    };

    handleMenuClick(identifier) {
        if (identifier === "Refresh") {
            this.applet.getEnterpriseComponent().invalidate();
        }
    };

    handlePageChange = (event, value) => {
        this.memoryState.pageToDisplay = value;
        this.applet.getEnterpriseComponent().executeQuery(this.memoryState.pageToDisplay, this.memoryState.pageSize);
    };

    onFilterModelChange = (model, details) => {
    };

    onPageSizeChange = (pageSize) => {
        this.memoryState.pageSize = pageSize;
    };

    onRowClick(params) {
        this.applet.getEnterpriseComponent().selectRecord(params.row.id);
    };

    onRowDoubleClick(params) {
        this.applet.getEnterpriseComponent().selectRecord(params.row.id);
    };

    onSortModelChange = (model) => {
        this.makeSortSpecification(model);
    };

    onStateChange = (state, event, details) => {
    };

    openRow = (event, rowIndex) => {
        event.preventDefault();
        event.stopPropagation();

        let buffer = this.state.buffer;
        let status = buffer[rowIndex].openRow;
        if (status === undefined) status = false;

        buffer[rowIndex].openRow = !status;

        this.setState({openRow: !this.state.openRow});
    };

    pageSizeHandler = (event) => {
        event.preventDefault();

        this.memoryState.pageSize = event.target.value;

        this.query();
    };

    query = (searchSpecification) => {
        this.setSearchSpecification(searchSpecification);

        this.applet.getEnterpriseComponent().invalidate();
    };

    render() {
        let loading = this.state.fetchBackgroundOperation === true;

        let columns = this.getColumns(this.props.breakpoint.getBreakPointName());
        let pinnedColumns = this.getPinnedColumns(this.props.breakpoint.getBreakPointName());
        let rows = loading ? [] : this.getRows(this.props.breakpoint.getBreakPointName(), columns);

        let headerClass = this.props.classes.AppletHeaderContainerFocus;
        let footerClass = this.props.classes.AppletFooterContainerFocus;

        let initialState = {
            sorting: {
                sortModel: [],
            },
        };

        if (this.getSortSpecification() !== null && this.getSortSpecification().Options !== undefined) {
            for (let i = 0; i < this.getSortSpecification().Options.length; i++) {
                let option = this.getSortSpecification().Options[i];
                let direction = "desc";
                if (this.getSortSpecification().Options[i].Direction.toLowerCase() === "asc") direction = "asc";

                initialState.sorting.sortModel.push({
                    field: option.SortAttribute,
                    sort: direction,
                });
            }
        }
        return (
            <>
                {this.renderExtension()}
                <Grid key={1} container item direction="column">
                    <div className={headerClass}>
                        <div style={{display: "flex", flexDirection: "row", justifyContent: "flex-start"}}>
                            <div className={this.props.classes.AppletHeaderControlsLeft}>
                                <Typography style={{fontSize: 22, fontWeight: 500, color: "#1498D8"}}>{this.props.title}</Typography>
                            </div>
                            <div className={this.props.classes.AppletHeaderControlsLeft}>
                                {this.renderMenu()}
                            </div>
                        </div>
                        <div style={{display: "flex", flexDirection: "row", justifyContent: "flex-end"}}>
                            {this.props.enableSort ? (
                                <div style={{marginTop: "-9px"}}>
                                    <Tooltip title={"Sort"}>
                                        <IconButton disableRipple={true}
                                                    onClick={(event) => {
                                                        event.stopPropagation();
                                                        this.setState({sortMenuTarget: event.currentTarget});
                                                    }}>
                                            <Sort />
                                        </IconButton>
                                    </Tooltip>
                                    {this.renderSortSelector()}
                                </div>
                            ) : null}
                            {this.props.enableSearch ? (
                                <div style={{marginTop: "-9px"}}>
                                    <Tooltip title={"Search"}>
                                        <IconButton disableRipple={true}
                                                    onClick={(event) => {
                                                        event.stopPropagation();
                                                        if (this.state.searchMenuTarget) {
                                                            this.setState({searchMenuTarget: null});
                                                            this.applet.cancel();
                                                        } else {
                                                            this.setState({searchMenuTarget: event.currentTarget});
                                                            this.applet.getEnterpriseComponent().newQuery();
                                                        }
                                                    }}>
                                            <Search />
                                        </IconButton>
                                    </Tooltip>
                                </div>
                            ) : null}
                        </div>
                    </div>
                    {this.props.enableSearch ? (
                        <div style={{width: "100%"}}>
                            {this.renderSearch()}
                        </div>
                    ) : null}
                    <div style={{width: "100%"}}>
                        <DataGridPro columns={columns}
                                     pinnedColumns={pinnedColumns}
                                     rows={rows}
                                     density="compact"
                                     filterMode="client"
                                     hideFooter={true}
                                     hideFooterRowCount={true}
                                     hideFooterPagination={true}
                                     hideFooterSelectedRowCount={true}
                                     onRowDoubleClick={(params) => this.onRowDoubleClick(params)}
                                     onRowClick={(params) => this.onRowClick(params)}
                                     onSortModelChange={(model) => this.onSortModelChange(model)}
                                     onFilterModelChange={(model, details) => this.onFilterModelChange(model, details)}
                                     onStateChange={(state, event, details) => this.onStateChange(state, event, details)}
                                     initialState={initialState}
                                     loading={loading}
                                     autoHeight={true}
                                     {...(this.props.rowHeight ? {rowHeight: this.props.rowHeight} : {})}
                        />
                    </div>
                    <div className={footerClass}>
                        {this.renderPagination()}
                    </div>
                </Grid>
            </>
        );
    }

    renderExtension() {
        return null;
    };

    renderLocalDateCell = (___, params, __, _) => {
        if (params.row.id > this.state.buffer.length - 1) return;
        if (params.value) params.value = formatLocalDate(params.value);
        return params.value ?? "--";
    };

    renderLocalDatetimeCell = (___, params, __, _) => {
        if (params.row.id > this.state.buffer.length - 1) return;
        if (params.value) params.value = formatLocal(params.value);
        return params.value ?? "--";
    };

    renderMenu = () => {
        let menu = null;
        let content = null;

        if (this.props.menu && this.props.menu.options.length !== 0) {
            let elementConfig = {
                options: [],
            };
            for (let i = 0; i < this.props.menu.options.length; i++) {
                let requiredPrivileges = this.props.menu.options[i].privileges;
                let option = {
                    displayValue: this.props.menu.options[i].displayValue,
                    value: this.props.menu.options[i].value,
                    active: this.shouldRenderMenuItem(this.props.menu.options[i].value)
                };
                if (requiredPrivileges === undefined) {
                    elementConfig.options.push(option);
                } else {
                    let roles = this.props.appContext.sessionContext.roles;
                    let assignedPrivileges = [];
                    for (let i = 0; i < roles.length; i++) {
                        assignedPrivileges = assignedPrivileges.concat(roles[i]["Privileges"]);
                    }
                    for (let i = 0; i < requiredPrivileges.length; i++) {
                        for (let j = 0; j < assignedPrivileges.length; j++) {
                            if (requiredPrivileges[i].name === assignedPrivileges[j].Name) elementConfig.options.push(option);
                        }
                    }
                }
            }

            let menuItems = [];

            for (let i = 0; i < elementConfig.options.length; i++) {
                menuItems.push(
                    <MenuItem disabled={!elementConfig.options[i].active} key={"menuItem" + i} onClick={(e) => {
                        e.stopPropagation();
                        this.handleMenuClick(elementConfig.options[i].value);
                        this.setState({showMenu: false});
                    }}>
                        {elementConfig.options[i].displayValue}
                    </MenuItem>,
                );
            }

            menu = (
                <Menu key={"menu"} id="tableMenu" anchorEl={this.state.menuTarget} open={true} onClose={() => this.setState({menuTarget: null, showMenu: false})} elevation={2}>
                    {menuItems}
                </Menu>
            );
            if (this.state.showMenu === false) {
                menu = null;
            }

            content = (
                <>
                    <Button className={css_self.MenuButton} style={{marginTop: "2px"}} variant="text"
                            onClick={(event) => {
                                event.stopPropagation();
                                this.setState({menuTarget: event.currentTarget, showMenu: true});
                            }}
                            endIcon={<DensityMedium />}>
                    </Button>
                    {menu}
                </>
            );
        }
        return content;
    };

    renderPagination = () => {
        let pageSizeSelector = (
            <div style={{display: "flex", alignItems: "center", marginLeft: "20px", marginTop: "-5px"}}>
                <Typography component="div" style={{fontSize: "13px", color: "#7b8291"}}>Results per page:</Typography>
                <TextField
                    select={true}
                    SelectProps={{
                        MenuProps: {
                            PaperProps: {
                                elevation: 1,
                            },
                        },
                        className: this.props.classes.selectPage,
                        IconComponent: ExpandMore,
                    }}
                    InputProps={{}}
                    key="1"
                    margin="dense"
                    InputLabelProps={{shrink: true}}
                    id="PageSizeSelector"
                    onChange={(event) => this.pageSizeHandler(event)}
                    value={this.memoryState.pageSize}
                    variant={"standard"}
                >
                    <MenuItem key={1} value={5}>{5}</MenuItem>
                    <MenuItem key={2} value={10}>{10}</MenuItem>
                    <MenuItem key={3} value={20}>{20}</MenuItem>
                    <MenuItem key={4} value={40}>{40}</MenuItem>
                </TextField>
            </div>
        );

        let pagination = null;
        if (this.applet.getEnterpriseComponent().getTotalRowcount() > 0) {
            let recordDisplayString;
            if (this.state.buffer.length === 0) {
                recordDisplayString = "0 of 0";
            } else {
                let firstRecordOnDisplay = (this.memoryState.pageToDisplay - 1) * this.memoryState.pageSize + 1;
                let lastRecordOnDisplay = firstRecordOnDisplay + this.memoryState.pageSize - 1;
                if (lastRecordOnDisplay >= this.applet.getEnterpriseComponent().getTotalRowcount()) {
                    lastRecordOnDisplay = this.applet.getEnterpriseComponent().getTotalRowcount();
                }
                if (this.applet.isRowcountKnown() === true) {
                    if (lastRecordOnDisplay > this.applet.getEnterpriseComponent().getTotalRowcount()) {
                        lastRecordOnDisplay = this.applet.getEnterpriseComponent().getTotalRowcount();
                    }
                    recordDisplayString = firstRecordOnDisplay + " - " + lastRecordOnDisplay + " of " + this.applet.getEnterpriseComponent().getTotalRowcount();
                } else {
                    if (lastRecordOnDisplay > this.state.buffer.length) {
                        lastRecordOnDisplay = this.state.buffer.length;
                    }
                    recordDisplayString = firstRecordOnDisplay + " - " + lastRecordOnDisplay + " of " + this.state.buffer.length;
                }
            }

            let recordDisplay = (
                <div style={{marginTop: "2px"}}>
                    <div>
                        <span>{recordDisplayString}</span>
                    </div>
                </div>
            );

            pagination = (
                <React.Fragment>
                    {pageSizeSelector}
                    <div style={{width: "20px"}} />
                    <Pagination color="primary"
                                count={this.memoryState.numberOfPages}
                                defaultPage={1}
                                page={this.memoryState.pageToDisplay}
                                size="small"
                                boundaryCount={2}
                                showFirstButton
                                showLastButton
                                shape="rounded"
                                onChange={(object, page) => this.handlePageChange(object, page)} />
                    <div style={{width: "20px"}} />
                    <div style={{marginTop: "2px"}}>
                        <Typography component="div" style={{fontSize: "13px", color: "#7b8291"}}>{recordDisplay}</Typography>
                    </div>
                </React.Fragment>
            );
        }
        return pagination;
    };

    shouldRenderMenuItem(identifier) {
        return true;
    };

    renderRowSelectionIndicatorCell = (___, params, __, _) => {
        if (params.row["RowSelected"] !== true) return;
        return (
            <div style={{display: "flex", flexDirection: "column", justifyContent: "center", height: "100%"}}>
                <ArrowRight/>
            </div>
        );
    };

    renderSearch() {
        return (
            <ClickAwayListener onClickAway={() => this.setState({searchMenuTarget: null})}>
                <Popper id={1} style={{zIndex: "4000"}} open={this.state.searchMenuTarget !== null} placement="bottom" anchorEl={this.state.searchMenuTarget}>
                    <Alert severity="info">
                        Search not available!
                    </Alert>
                </Popper>
            </ClickAwayListener>
        );
    };

    renderSortSelector = () => {
        let menu = null;
        let menuItems = [];

        for (let i = 0; i < this.getSortSelections().length; i++) {
            let sortSelection = this.getSortSelections()[i];
            if (sortSelection.Identifier === undefined) continue;
            if (sortSelection.Options === undefined) continue;

            let sortSelectionOptions = sortSelection.Options;

            let sortContent = [];
            for (let j = 0; j < sortSelectionOptions.length; j++) {
                let sortSelectionOption = sortSelectionOptions[j];
                /*
                "DisplayName": "Serial Number",
                "Direction": "ASC",
                "SortAttribute": "SerialNumber"
                */
                sortContent.push(sortSelectionOption.DisplayName);
            }
            let sortContentString = sortContent.join(" | ");

            menuItems.push(
                <MenuItem key={"sortMenuItem" + i} onClick={(event) => {
                    event.stopPropagation();
                    this.handleSortMenuClick(event, sortSelection);
                }}>
                    <ListItemIcon>
                        {this.activeSort.Identifier === sortSelection.Identifier ? <Check /> : null}
                    </ListItemIcon>
                    <ListItemText primary={sortContentString} />
                </MenuItem>,
            );
        }

        menu = (
            <Menu id="sortMenu" anchorEl={this.state.sortMenuTarget} open={true} onClose={() => this.setState({sortMenuTarget: null})} elevation={2}>
                {menuItems}
            </Menu>
        );
        if (this.state.sortMenuTarget === null) {
            menu = null;
        }
        return menu;
    };

    renderSortSelectionContainer = () => {
        let content = null;

        if (this.getSortSelections() !== null) {
            let rowContent = [];
            for (let i = 0; i < this.getSortSelections().length; i++) {
                let sortSelection = this.getSortSelections()[i];
                let sortSelectionOptions = sortSelection.Options;
                let keys = Object.keys(sortSelectionOptions);

                let sortContent = keys.join(", ");

                let radio = <input type="radio" id={sortSelection.Identifier} name="sort" value={sortSelection.Identifier} />;
                if (this.getSortSpecification().Identifier === sortSelection.Identifier) {
                    radio = <input checked type="radio" id={sortSelection.Identifier} name="sort" value={sortSelection.Identifier} />;
                }
                let row = (
                    <div key={sortSelection.Identifier}>
                        <div>
                            {radio}
                        </div>
                        <div>{sortContent}</div>
                    </div>
                );
                rowContent.push(row);
            }

            let modalContent = (
                <div>
                    {rowContent}
                </div>
            );

            if (this.state.showSortSelection === true) {
                content = (
                    <Dialog open={true} scroll="body" PaperProps={{style: {minWidth: 500}}}>
                        <DialogTitle>Select Sort</DialogTitle>
                        <DialogContent style={{padding: "0 16px"}}>
                            {modalContent}
                        </DialogContent>
                        <DialogActions>
                            <CCButton color="red" onClick={this.cancel2Handler}>
                                Done
                            </CCButton>
                        </DialogActions>
                    </Dialog>
                );
            }
        }

        return (
            <React.Fragment>
                {content}
            </React.Fragment>
        );

    };

    renderStandardSingleCell = (___, params, __, _) => {
        if (params.row.id > this.state.buffer.length - 1) return;
        return params.value ?? "--";
    };

    renderTimingCell = (___, params, __, _) => {
        return (
            <div style={{display: "flex", flexDirection: "column", justifyContent: "flex-start"}}>
                <div><span style={{color: "#EEEEEE"}}>Created Datetime:</span></div>
                <div>{this.state.buffer[params.row.id]["CreatedDatetime"]}</div>
                <div><span style={{color: "#EEEEEE"}}>End Datetime:</span></div>
                <div>{this.state.buffer[params.row.id]["EndDatetime"]}</div>
            </div>
        );
    };

    selectSortHandler = () => {
        this.setState({showSortSelection: true});
    };

    setSearchSpecification = (searchSpecification) => {
        this.searchSpecification = searchSpecification;
    };

    setSortSelections = (sortSelections) => {
        this.sortSelections = sortSelections;
    };

    setSortSpecification = (sortSpecification) => {
        this.sortSpecification = sortSpecification;
    };

}

export default withAppContext(withStyles(styles, {withTheme: true})(withBreakpoint(EnlilTable)));