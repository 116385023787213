import _ from "lodash";
import moment from "moment-timezone";
import {useEffect} from "react";
import {v4 as uuidv4} from "uuid";
import EnlilAxios from "./EnlilAxios";
import {omitDeepBy} from "./LodashExtended";
import {SessionContext} from "./SessionContext";

// Analytics.record("some-event-name");
//
// Analytics.record("SomeButton_Click", {
//   a: "A",
//   b: "B",
// });

const queue = {};

export const record = (name, attributes) => submit("web:s6", {
    ...attributes,
    "Event Type": name,
    "Local ID": uuidv4(),
    "Session ID": SessionContext.loadSessionId(),
    "Timezone": moment.tz.guess(),
    "App Variant": process.env.REACT_APP_VARIANT,
    "App Version": process.env.REACT_APP_VERSION,
    "App Deployed At": moment
        .utc(process.env.REACT_APP_DATE, "YYYY-MM-DD , hh:mm:ss [UTC]")
        .format(),
});

export const audit = (code, name, attributes) => submit("audit", {
    ...attributes,
    "event_id": code,
    "event_type": name,
    "web_id": uuidv4(),
});

function submit(index, event) {

    // remove null or undefined values before we merge
    event = omitDeepBy(event, _.isNil);

    queue[index] = queue[index] ?? {};
    queue[index][uuidv4()] = event;

    return event;
}

export function useAnalyticsClient() {

    function send() {
        const _queue = queue;
        Object.keys(_queue).forEach(index => {

            const queue = _queue[index];
            if (_.size(queue) === 0) return;

            const events = Object.keys(queue).reduce((result, key) => {
                result.push(queue[key]);
                delete queue[key];
                return result;
            }, []);

            if (events.length === 0) return;

            EnlilAxios({
                method: "POST",
                url: "s6/v1/log",
                params: {
                    Index: index,
                },
                json: true,
                data: events,
            });
        });
    }

    useEffect(() => {
        const interval = setInterval(() => {
            // avoid: 'setInterval' handler took <N>ms
            Promise.resolve().then(send);
        }, 3000);
        return () => clearInterval(interval);
    }, []);

    return {};
}
