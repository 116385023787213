import React from "react";
import "../Navigation/css/NavBar.scss";
import {Black} from "../../theme/styles";
import approvalRequest002_put_1 from '../../json/approval-request-002-put-1.json';
import approvalRequest002_put_2 from '../../json/approval-request-002-put-2.json';
import approvalRequest002_put_form_input_external_1 from '../../json/approval-request-002-put-form-input-external-1.json';
import approvalRequest002_put_form_input_external_2 from '../../json/approval-request-002-put-form-input-external-2.json';
import ReactJson from 'react-json-view'

export default function ApprovalRequestsPut() {

    return (
        <div style={{paddingLeft: "20px", paddingRight: "30px"}}>
            <p style={{fontSize: "24px", fontWeight: "400", color: Black}}>Approval Requests PUT</p>
            <p style={{fontSize: "16px", fontWeight: "400", color: Black}}>Overview</p>

            <p style={{fontSize: "14px", fontWeight: "400", color: Black, marginLeft: "20px"}}>
                In Enlil, the approval requests PUT API allows for the update of AR content.
            </p>
            <br/>
            <p style={{fontSize: "16px", fontWeight: "400", color: Black}}>Parameters</p>
            <ul style={{fontSize: "14px", fontWeight: "400", color: Black}}>
                <li style={{marginTop: "20px"}}>Header Parameters
                    <li style={{marginTop: "20px", marginLeft: "20px"}}>X-Enlil-AccessToken (Required String)</li>
                    <li style={{marginTop: "10px", marginLeft: "40px"}}>The X-Enlil-AccessToken is obtained during the authentication process and expires after 90 days.</li>
                    <li style={{marginTop: "20px", marginLeft: "20px"}}>X-Enlil-RefreshToken (Optional String)</li>
                    <li style={{marginTop: "10px", marginLeft: "40px"}}>The X-Enlil-RefreshToken is obtained during the authentication process and never expires. This token is provided is the caller wants a refreshed X-Enlil-AccessToken</li>
                    <li style={{marginTop: "20px", marginLeft: "20px"}}>idoc-request (Required String: must equal v1.ApprovalRequest-002)</li>
                    <li style={{marginTop: "10px", marginLeft: "40px"}}>The name of the IDOC being specified in the PUT request.</li>
                    <li style={{marginTop: "20px", marginLeft: "20px"}}>idoc-response (Optional String).</li>
                    <li style={{marginTop: "10px", marginLeft: "40px"}}>v1.ApprovalRequest-001, v1.ApprovalRequest-002 and v1.ApprovalRequest-003 are permitted values.</li>
                    <li style={{marginTop: "10px", marginLeft: "40px"}}>If idoc-response is provided, the response payload (HTTP Code: 201) will match the specified format, else no response (HTTP Code: 204) will be provided.</li>
                </li>
            </ul>
            <br/>
            <p style={{fontSize: "16px", fontWeight: "400", color: Black}}>Scenarios</p>
            <p style={{fontSize: "14px", fontWeight: "400", color: Black, marginLeft: "20px"}}>
                <span style={{lineHeight: "1.5em"}}>
                Enlil requires Approval Request PUT content to be in the v1.ApprovalRequest-002 format. For example, the user executes this PUT using idoc-request=v1.ApprovalRequest-002 (below) with idoc-response=v1.ApprovalRequest-003, Enlil will respond (HTTP Code: 200) with a payload of the
                v1.ApprovalRequest-003 below format<br/>
                </span>
                <ul style={{fontSize: "14px", fontWeight: "400", color: Black}}>
                    <li style={{marginTop: "20px"}}>Adding items to an approval request
                        <li style={{marginTop: "20px", marginLeft: "20px"}}>Items represent the scope of the AR. Any number of items may be added provided the AR is in a DRAFT state. To add one or more items to an AR submit the following representative payload.</li><br/>
                        <p style={{fontFamily: "Courier New", fontSize: "12px", marginLeft: "20px"}}>
                            <ReactJson src={approvalRequest002_put_1} collapseStringsAfterLength={75} collapsed={4}/>
                        </p>
                        <li style={{marginTop: "20px", marginLeft: "20px"}}>The content of the FormInputExternal field must resemble the following and must be base64 encoded. One or more DocumentRevision items may be included.</li>
                        <br/>
                        <p style={{fontFamily: "Courier New", fontSize: "12px", marginLeft: "20px"}}>
                            <ReactJson src={approvalRequest002_put_form_input_external_1} collapseStringsAfterLength={75} collapsed={4}/>
                        </p>
                        <li style={{marginTop: "20px", marginLeft: "40px"}}>ShifamedMessage.ListOfDocumentRevision.DocumentRevision[].USID: This is the primary key of any item (Part Revision, Equipment Revision, etc.)</li>
                        <li style={{marginTop: "20px", marginLeft: "40px"}}>ShifamedMessage.ListOfDocumentRevision.DocumentRevision[].JustificationForChange: A string giving justification for items inclusion.</li>
                        <li style={{marginTop: "20px", marginLeft: "40px"}}>ShifamedMessage.ListOfDocumentRevision.DocumentRevision[].DescriptionOfChange: A string giving a description of the change being made to the item being included.</li>
                    </li>
                </ul>
                <ul style={{fontSize: "14px", fontWeight: "400", color: Black}}>
                    <li style={{marginTop: "20px"}}>Adding approvers to an approval request
                        <li style={{marginTop: "20px", marginLeft: "20px"}}>An AR must be approved by a member of each group as proscribed by the Document Approval Matrix (DAM). To add one or more approvers to an AR, submit the following representative payload.</li><br/>
                        <p style={{fontFamily: "Courier New", fontSize: "12px", marginLeft: "20px"}}>
                            <ReactJson src={approvalRequest002_put_1} collapseStringsAfterLength={75} collapsed={4}/>
                        </p>
                        <li style={{marginTop: "20px", marginLeft: "20px"}}>The content of the FormInputExternal field must resemble the following and must be base64 encoded. One or more Approver items may be included.</li>
                        <br/>
                        <p style={{fontFamily: "Courier New", fontSize: "12px", marginLeft: "20px"}}>
                            <ReactJson src={approvalRequest002_put_form_input_external_2} collapseStringsAfterLength={75} collapsed={4}/>
                        </p>
                        <li style={{marginTop: "20px", marginLeft: "40px"}}>ShifamedMessage.ListOfApprover.Approver[].USID: This is the primary key of an employee in the company. The total list of appovers must satisify the DAM before the AR can be transitioned into the IN_REVIEW state.</li>
                    </li>
                </ul>
                <ul style={{fontSize: "14px", fontWeight: "400", color: Black}}>
                    <li style={{marginTop: "20px"}}>Modifying the attributes of Description and Title.
                        <li style={{marginTop: "20px", marginLeft: "20px"}}>An AR may be changed by its owner while in the DRAFT state. However, once the AR goes into IN_REVIEW state, the permission of AR_IN_REVIEW_EDIT is required. No new approvers or new items may be added/removed after the AR has moved into the IN_REVIEW state. To add one or more approvers to an AR, submit the following representative payload.</li><br/>
                        <p style={{fontFamily: "Courier New", fontSize: "12px", marginLeft: "20px"}}>
                            <ReactJson src={approvalRequest002_put_2} collapseStringsAfterLength={75} collapsed={4}/>
                        </p>
                        <li style={{marginTop: "20px", marginLeft: "40px"}}>ShifamedMessage.ListOfDocumentRevision.DocumentRevision[].USID: This is the primary key of any item (Part Revision, Equipment Revision, etc., and in the DRAFT state.</li>
                        <li style={{marginTop: "20px", marginLeft: "40px"}}>ShifamedMessage.ListOfDocumentRevision.DocumentRevision[].Title: A string giving a title to the CR.</li>
                        <li style={{marginTop: "20px", marginLeft: "40px"}}>ShifamedMessage.ListOfDocumentRevision.DocumentRevision[].Description: A string giving a description of the AR.</li>
                    </li>
                </ul>
            </p>
            <br/>
            <p style={{fontSize: "16px", fontWeight: "400", color: Black}}>Business Rules</p>
            <p style={{fontSize: "14px", fontWeight: "400", color: Black}}>
                <ul style={{fontSize: "14px", fontWeight: "400", color: Black}}>
                    <li style={{marginTop: "20px"}}>If the user provides a document revision that does not exist."
                        <li style={{marginTop: "10px", marginLeft: "40px"}}>Error Code: CC-JDJU-JSJE</li>
                        <li style={{marginTop: "10px", marginLeft: "40px"}}>Description: Invalid value for UUID</li>
                        <li style={{marginTop: "10px", marginLeft: "40px"}}>HTTP Code: 400</li>
                    </li>
                    <li style={{marginTop: "20px"}}>If the user provides a document revision that does not exist."
                        <li style={{marginTop: "10px", marginLeft: "40px"}}>Error Code: CC-KIDE-AWSW</li>
                        <li style={{marginTop: "10px", marginLeft: "40px"}}>Description: Invalid value for ShifamedMessage.ListOfDocumentRevision.DocumentRevision.USID</li>
                        <li style={{marginTop: "10px", marginLeft: "40px"}}>HTTP Code: 400</li>
                    </li>
                    <li style={{marginTop: "20px"}}>If the user provides an approver that does not exist as an employee in the company."
                        <li style={{marginTop: "10px", marginLeft: "40px"}}>Error Code: CC-JJED-9853</li>
                        <li style={{marginTop: "10px", marginLeft: "40px"}}>Description: Invalid value for ShifamedMessage.ListOfApprover.Approver.USID</li>
                        <li style={{marginTop: "10px", marginLeft: "40px"}}>HTTP Code: 400</li>
                    </li>
                    <li style={{marginTop: "20px"}}>If the user provides an approver that does not exist as an employee in the company."
                        <li style={{marginTop: "10px", marginLeft: "40px"}}>Error Code: CC-WXZE-IED4</li>
                        <li style={{marginTop: "10px", marginLeft: "40px"}}>Description: Invalid value for ShifamedMessage.ListOfApprover.Approver.USID</li>
                        <li style={{marginTop: "10px", marginLeft: "40px"}}>HTTP Code: 400</li>
                    </li>
                    <li style={{marginTop: "20px"}}>If the user transitions the AR to IN_REVIEW but does not have any approvers associated to the AR."
                        <li style={{marginTop: "10px", marginLeft: "40px"}}>Error Code: CC-ODOE-09UI</li>
                        <li style={{marginTop: "10px", marginLeft: "40px"}}>Description: Required number of approvers not added to AR.</li>
                        <li style={{marginTop: "10px", marginLeft: "40px"}}>HTTP Code: 400</li>
                    </li>
                    <li style={{marginTop: "20px"}}>If the user transitions the AR to IN_REVIEW but does not have at least one item associated to the AR."
                        <li style={{marginTop: "10px", marginLeft: "40px"}}>Error Code: CC-DD90-KI45</li>
                        <li style={{marginTop: "10px", marginLeft: "40px"}}>Description: At least one document must be associated with the AR before transitioning it to IN_REVIEW.</li>
                        <li style={{marginTop: "10px", marginLeft: "40px"}}>HTTP Code: 400</li>
                    </li>
                    <li style={{marginTop: "20px"}}>If the user provides a value for DocumentTypeUSID, it must exist, have active=true and group="CHANGE_REQUEST"
                        <li style={{marginTop: "10px", marginLeft: "40px"}}>Error Code: CC-JJ77-KI74</li>
                        <li style={{marginTop: "10px", marginLeft: "40px"}}>Description: Invalid DocumentTypeUSID</li>
                        <li style={{marginTop: "10px", marginLeft: "40px"}}>HTTP Code: 400</li>
                    </li>
                    <li style={{marginTop: "20px"}}>If the user transitions the AR to IN_REVIEW but does not have the required approvers as proscribed by the DAM."
                        <li style={{marginTop: "10px", marginLeft: "40px"}}>Error Code: CC-LLOI-AW45</li>
                        <li style={{marginTop: "10px", marginLeft: "40px"}}>Description: Cannot transition AR to IN_REVIEW until the required number of approvers are added.</li>
                        <li style={{marginTop: "10px", marginLeft: "40px"}}>HTTP Code: 400</li>
                    </li>
                    <li style={{marginTop: "20px"}}>If the user provides a value for FormDocumentRevisionUSID that is not a permitted form for the given DocumentTypeUSID.
                        <li style={{marginTop: "10px", marginLeft: "40px"}}>Error Code: CC-UI12-I89D</li>
                        <li style={{marginTop: "10px", marginLeft: "40px"}}>Description: The give FormDocumentRevisionUSID is not a permitted form for the given DocumentTypeUSID.</li>
                        <li style={{marginTop: "10px", marginLeft: "40px"}}>HTTP Code: 400</li>
                    </li>
                    <li style={{marginTop: "20px"}}>If the user provides a value for FormDocumentRevisionUSID it must be accompanied by a DocumentTypeUSID.
                        <li style={{marginTop: "10px", marginLeft: "40px"}}>Error Code: CC-LOP0-IIED</li>
                        <li style={{marginTop: "10px", marginLeft: "40px"}}>Description: FormDocumentRevision USID must be accompanied by DocumentTypeUSID</li>
                        <li style={{marginTop: "10px", marginLeft: "40px"}}>HTTP Code: 400</li>
                    </li>
                    <li style={{marginTop: "20px"}}>If the user attempts to create a new document revision through the Approval Request PUT API.
                        <li style={{marginTop: "10px", marginLeft: "40px"}}>Error Code: CC-KI56-LLI0</li>
                        <li style={{marginTop: "10px", marginLeft: "40px"}}>Description: New document revisions may not be inserted through the Approval Request PUT API</li>
                        <li style={{marginTop: "10px", marginLeft: "40px"}}>HTTP Code: 400</li>
                    </li>
                    <li style={{marginTop: "20px"}}>If the user attempts to improperly transition the AR State.
                        <li style={{marginTop: "10px", marginLeft: "40px"}}>Error Code: CC-I889-H555</li>
                        <li style={{marginTop: "10px", marginLeft: "40px"}}>Description: Improper state change. State must transition from DRAFT to IN_REVIEW.</li>
                        <li style={{marginTop: "10px", marginLeft: "40px"}}>HTTP Code: 400</li>
                    </li>
                    <li style={{marginTop: "20px"}}>If the user attempts to improperly transition the AR State.
                        <li style={{marginTop: "10px", marginLeft: "40px"}}>Error Code: CC-OO90-JU76</li>
                        <li style={{marginTop: "10px", marginLeft: "40px"}}>Description: Improper state change. State must transition from IN_REVIEW to APPROVED.</li>
                        <li style={{marginTop: "10px", marginLeft: "40px"}}>HTTP Code: 400</li>
                    </li>
                    <li style={{marginTop: "20px"}}>If the user attempts to improperly transition the AR State.
                        <li style={{marginTop: "10px", marginLeft: "40px"}}>Error Code: CC-IY56-AQ23</li>
                        <li style={{marginTop: "10px", marginLeft: "40px"}}>Description: Improper state change. State must transition from APPROVED to CLOSED.</li>
                        <li style={{marginTop: "10px", marginLeft: "40px"}}>HTTP Code: 400</li>
                    </li>
                    <li style={{marginTop: "20px"}}>If the user attempts to improperly transition the AR State.
                        <li style={{marginTop: "10px", marginLeft: "40px"}}>Error Code: CC-JJJR-JJ78</li>
                        <li style={{marginTop: "10px", marginLeft: "40px"}}>Description: Improper state change. State must transition from IN_REVIEW to DRAFT.</li>
                        <li style={{marginTop: "10px", marginLeft: "40px"}}>HTTP Code: 400</li>
                    </li>
                    <li style={{marginTop: "20px"}}>If the user attempts to improperly transition the AR State.
                        <li style={{marginTop: "10px", marginLeft: "40px"}}>Error Code: CC-UUE3-OO90</li>
                        <li style={{marginTop: "10px", marginLeft: "40px"}}>Description: Improper state change. State must transition from DRAFT to VOIDED.</li>
                        <li style={{marginTop: "10px", marginLeft: "40px"}}>HTTP Code: 400</li>
                    </li>
                    <li style={{marginTop: "20px"}}>If the user attempts to change FormDocumentRevisionUSID and DocumentTypeUSID when in the IN_REVIEW state.
                        <li style={{marginTop: "10px", marginLeft: "40px"}}>Error Code: CC-WW34-Q111</li>
                        <li style={{marginTop: "10px", marginLeft: "40px"}}>Description: Changes to FormDocumentRevisionUSID and DocumentTypeUSID are not permitted if the AR is in the IN_REVIEW state.</li>
                        <li style={{marginTop: "10px", marginLeft: "40px"}}>HTTP Code: 400</li>
                    </li>
                    <li style={{marginTop: "20px"}}>If the user attempts to change the AR but does not have the AR_IN_REVIEW_EDIT permission.
                        <li style={{marginTop: "10px", marginLeft: "40px"}}>Error Code: CC-JDJ8-PO09</li>
                        <li style={{marginTop: "10px", marginLeft: "40px"}}>Description: To change an Approval Request (AR) in the IN_REVIEW state, either the user must be the owner of the AR or must have the AR_IN_REVIEW_EDIT permission.</li>
                        <li style={{marginTop: "10px", marginLeft: "40px"}}>HTTP Code: 400</li>
                    </li>
                    <li style={{marginTop: "20px"}}>If the user attempts to provide a FormDocumentRevisionUSID but no DocumentTypeUSID.
                        <li style={{marginTop: "10px", marginLeft: "40px"}}>Error Code: CC-OOPP-78ER</li>
                        <li style={{marginTop: "10px", marginLeft: "40px"}}>Description: FormDocumentRevisionUSID must be accompanied by DocumentTypeUSID.</li>
                        <li style={{marginTop: "10px", marginLeft: "40px"}}>HTTP Code: 400</li>
                    </li>
                    <li style={{marginTop: "20px"}}>If the user attempts to update the EffectiveDatetime.
                        <li style={{marginTop: "10px", marginLeft: "40px"}}>Error Code: CC-LLOE-AWSW</li>
                        <li style={{marginTop: "10px", marginLeft: "40px"}}>Description: ClosedDatetime may not be updated directly. Enlil maintains this value when the AR changes state.</li>
                        <li style={{marginTop: "10px", marginLeft: "40px"}}>HTTP Code: 400</li>
                    </li>
                    <li style={{marginTop: "20px"}}>If the user provides a value for FormDocumentRevisionUSID it must be accompanied by a DocumentTypeUSID.
                        <li style={{marginTop: "10px", marginLeft: "40px"}}>Error Code: CC-HHEE-KEID</li>
                        <li style={{marginTop: "10px", marginLeft: "40px"}}>Description: EffectiveDatetime may not be updated directly. Enlil maintains this value when the AR changes state.</li>
                        <li style={{marginTop: "10px", marginLeft: "40px"}}>HTTP Code: 400</li>
                    </li>
                    <li style={{marginTop: "20px"}}>If the user attempts to create a new document revision through the Approval Request PUT API.
                        <li style={{marginTop: "10px", marginLeft: "40px"}}>Error Code: CC-KKI8-KKKU</li>
                        <li style={{marginTop: "10px", marginLeft: "40px"}}>Description: New document revisions may not be inserted through the Approval Request PUT API</li>
                        <li style={{marginTop: "10px", marginLeft: "40px"}}>HTTP Code: 400</li>
                    </li>
                    <li style={{marginTop: "20px"}}>If the user attempts to add document revisions to an Approval Request when it is not in DRAFT state.
                        <li style={{marginTop: "10px", marginLeft: "40px"}}>Error Code: CC-LLUI-09UI</li>
                        <li style={{marginTop: "10px", marginLeft: "40px"}}>Description: Item may only be added to an Approval Request (AR) when it is in DRAFT state.</li>
                        <li style={{marginTop: "10px", marginLeft: "40px"}}>HTTP Code: 400</li>
                    </li>
                    <li style={{marginTop: "20px"}}>User attempts to transition an AR into IN_REVIEW, but the title is not set.
                        <li style={{marginTop: "10px", marginLeft: "40px"}}>Error Code: CC-JEU4-DD55</li>
                        <li style={{marginTop: "10px", marginLeft: "40px"}}>Description: Title is required before an AR can be transitioned to IN_REVIEW.</li>
                        <li style={{marginTop: "10px", marginLeft: "40px"}}>HTTP Code: 400</li>
                    </li>
                    <li style={{marginTop: "20px"}}>User attempts to transition an AR into IN_REVIEW, but the description is not set.
                        <li style={{marginTop: "10px", marginLeft: "40px"}}>Error Code: CC-IO94-OOO8</li>
                        <li style={{marginTop: "10px", marginLeft: "40px"}}>Description: Description is required before an AR can be transitioned to IN_REVIEW.</li>
                        <li style={{marginTop: "10px", marginLeft: "40px"}}>HTTP Code: 400</li>
                    </li>
                    <li style={{marginTop: "20px"}}>User attempts to transition an AR into IN_REVIEW, but no document revisions are associated to the AR.
                        <li style={{marginTop: "10px", marginLeft: "40px"}}>Error Code: CC-DD90-KI45</li>
                        <li style={{marginTop: "10px", marginLeft: "40px"}}>Description: At least one document must be associated with the AR before transitioning it to IN_REVIEW.</li>
                        <li style={{marginTop: "10px", marginLeft: "40px"}}>HTTP Code: 400</li>
                    </li>
                    <li style={{marginTop: "20px"}}>User attempts to transition an AR into IN_REVIEW, but no values are provided for FormDocumentRevisionUSID and DocumentTypeUSID.
                        <li style={{marginTop: "10px", marginLeft: "40px"}}>Error Code: CC-DDDE-QW45</li>
                        <li style={{marginTop: "10px", marginLeft: "40px"}}>Description: FormDocumentRevisionUSID and DocumentTypeUSID must contain values before moving out of DRAFT state.</li>
                        <li style={{marginTop: "10px", marginLeft: "40px"}}>HTTP Code: 400</li>
                    </li>
                    <li style={{marginTop: "20px"}}>If the user attempts to create a new document revision through the Approval Request PUT API.
                        <li style={{marginTop: "10px", marginLeft: "40px"}}>Error Code: CC-KEIE-89TY</li>
                        <li style={{marginTop: "10px", marginLeft: "40px"}}>Description: New document revisions may not be inserted through the Approval Request PUT API</li>
                        <li style={{marginTop: "10px", marginLeft: "40px"}}>HTTP Code: 400</li>
                    </li>
                </ul>
            </p>
        </div>
    )
}