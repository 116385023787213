import React from "react";
import "../Navigation/css/NavBar.scss";
import {Black} from "../../theme/styles";
import {Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow} from "@mui/material";


export default function HTTPHeaders() {

    return (
        <div style={{paddingLeft: "20px", paddingRight: "30px"}}>
            <p style={{fontSize: "24px", fontWeight: "400", color: Black}}>HTTP Headers</p>
            <p style={{fontSize: "16px", fontWeight: "400", color: Black}}>REST APIs support the following HTTP headers.</p>

            <TableContainer component={Paper}>
                <Table sx={{ minWidth: 450, border: "2px solid #EEEEEE"}}>
                    <TableHead>
                        <TableRow style={{height: "40px", backgroundColor: "#FAFAFA", borderRight: "1px solid #FAFAFA"}}>
                            <TableCell size='small' width="20%" style={{maxWidth: "100px", borderRight: "2px solid #EEEEEE", color: "#000000"}}><b>Header</b></TableCell>
                            <TableCell size='small' width="100%" align="left"><b>Description</b></TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        <TableRow key={1} sx={{ '&:last-child td, &:last-child th': { border: 1 } }}>
                            <TableCell size='small' component="th" scope="row" style={{borderRight: "2px solid #EEEEEE"}}>Accept</TableCell>
                            <TableCell size='small' align="left">Content-Type that the response can accept</TableCell>
                        </TableRow>
                        <TableRow key={2} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                            <TableCell size='small' component="th" scope="row" style={{borderRight: "2px solid #EEEEEE"}}>Accept-Encoding</TableCell>
                            <TableCell size='small' align="left">Encoding that the response can accept</TableCell>
                        </TableRow>
                        <TableRow key={3} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                            <TableCell size='small' component="th" scope="row" style={{borderRight: "2px solid #EEEEEE"}}>Cache-Control</TableCell>
                            <TableCell size='small' align="left">The REST API configures this header for every HTTP response so that it can avoid using an intermediate proxy when it caches or stores the framework payload. It can include one of the following values:
                                <ul>
                                    <li>no-cache</li>
                                    <li>no-store</li>
                                    <li>must-revalidate</li>
                                </ul>
                            </TableCell>
                        </TableRow>
                        <TableRow key={4} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                            <TableCell size='small' component="th" scope="row" style={{borderRight: "2px solid #EEEEEE"}}>Content-Type</TableCell>
                            <TableCell size='small' align="left">Media type of the body of the request. The REST API requires this header for each POST or PUT request. For example:<br/><br/><span style={{fontFamily: "Courier New", color: "#666666"}}>Content-Type: application/json</span></TableCell>
                        </TableRow>
                        <TableRow key={5} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                            <TableCell size='small' component="th" scope="row" style={{borderRight: "2px solid #EEEEEE"}}>ETag</TableCell>
                            <TableCell size='small' align="left">If the resource changes, then the resource creates the ETag. The client can use ETag to control caching</TableCell>
                        </TableRow>
                        <TableRow key={6} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                            <TableCell size='small' component="th" scope="row" style={{borderRight: "2px solid #EEEEEE"}}>If-Match</TableCell>
                            <TableCell size='small' align="left">Allows you to run a conditional request</TableCell>
                        </TableRow>
                        <TableRow key={7} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                            <TableCell size='small' component="th" scope="row" style={{borderRight: "2px solid #EEEEEE"}}>If-None-Match</TableCell>
                            <TableCell size='small' align="left">Allows you to run a conditional request</TableCell>
                        </TableRow>
                        <TableRow key={8} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                            <TableCell size='small' component="th" scope="row" style={{borderRight: "2px solid #EEEEEE"}}>Location</TableCell>
                            <TableCell size='small' align="left">Location in the response that identifies the URL of the new resource. The REST API adds this header when you create a new resource</TableCell>
                        </TableRow>
                        <TableRow key={9} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                            <TableCell size='small' component="th" scope="row" style={{borderRight: "2px solid #EEEEEE"}}>X-HTTP-Method-Override</TableCell>
                            <TableCell size='small' align="left">Contains the name of the HTTP method. You can use this value to define the HTTP method. The REST API considers this header only in a POST method. This is a modified header that is not part of the HTTP specifications</TableCell>
                        </TableRow>
                        <TableRow key={10} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                            <TableCell size='small' component="th" scope="row" style={{borderRight: "2px solid #EEEEEE"}}>X-ID-TENANT-NAME</TableCell>
                            <TableCell size='small' align="left">Identity domain name that the REST API uses to authenticate. For example: X-ID-TENANT-NAME:ExampleIdentityDomain</TableCell>
                        </TableRow>
                        <TableRow key={11} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                            <TableCell size='small' component="th" scope="row" style={{borderRight: "2px solid #EEEEEE"}}>X-Requested-By</TableCell>
                            <TableCell size='small' align="left">If you enable antiCSRF, then the REST API requires this header for every request, except with the following methods:
                                <ul>
                                    <li>no-cache</li>
                                    <li>no-store</li>
                                    <li>must-revalidate</li>
                                </ul>
                                If the REST API cannot find this header, then it returns response 400, Bad Request.
                            </TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
            </TableContainer>
        </div>
    )
}