import React, {createContext, useContext} from "react";
import {SessionContext} from "./SessionContext";

type AppState = {
    sessionContext: SessionContext
}

export type AppContextValue = AppState & {
}

export const AppContext = createContext<AppContextValue | null>(null);

export type AppContextProviderProps = {
    value: AppContextValue;
    children: React.ReactNode;
}
export function AppContextProvider(props: AppContextProviderProps) {
    const ctx: AppContextValue = {
        sessionContext: props.value.sessionContext
    }
    return <AppContext.Provider value={ctx}>{props.children}</AppContext.Provider>;
}

export default function useAppContext() {
    return useContext(AppContext);
}