import EnterpriseComponentAttributeMetadata from "../../EnterpriseComponentAttributeMetadata";
import EnterpriseComponentMetadata from "../../EnterpriseComponentMetadata";

class DeveloperLogEnterpriseComponentMetadata extends EnterpriseComponentMetadata {

    constructor() {
        super();

        this.name = "DeveloperLog";
        this.defaultCacheSize = 50;

        this.attributes["EventCode"] = new EnterpriseComponentAttributeMetadata(
            "EventCode", // name
            false, // NoCopy
            false, // NoUpdate
            false, // Required
            false, // RequiredForQuery
            null, // Picklist
            null, // Scale
            null, // Precision
            null, // length
            null, // minLength
            null, // pattern
            "String", // Type
            null, // default value
            false, // computed
        );
        this.attributes["EventDatetime"] = new EnterpriseComponentAttributeMetadata(
            "EventDatetime", // name
            false, // NoCopy
            false, // NoUpdate
            false, // Required
            false, // RequiredForQuery
            null, // Picklist
            null, // Scale
            null, // Precision
            null, // length
            null, // minLength
            null, // pattern
            "Datetime", // Type
            null, // default value
            false, // computed
        );
        this.attributes["EventType"] = new EnterpriseComponentAttributeMetadata(
            "EventType", // name
            false, // NoCopy
            false, // NoUpdate
            false, // Required
            false, // RequiredForQuery
            null, // Picklist
            null, // Scale
            null, // Precision
            null, // length
            null, // minLength
            null, // pattern
            "String", // Type
            null, // default value
            false, // computed
        );
        this.attributes["EventPayload"] = new EnterpriseComponentAttributeMetadata(
            "EventPayload", // name
            false, // NoCopy
            false, // NoUpdate
            false, // Required
            false, // RequiredForQuery
            null, // Picklist
            null, // Scale
            null, // Precision
            null, // length
            null, // minLength
            null, // pattern
            "String", // Type
            null, // default value
            false, // computed
        );
        this.attributes["EventPayloadJSON"] = new EnterpriseComponentAttributeMetadata(
            "EventPayloadJSON", // name
            false, // NoCopy
            false, // NoUpdate
            false, // Required
            false, // RequiredForQuery
            null, // Picklist
            null, // Scale
            null, // Precision
            null, // length
            null, // minLength
            null, // pattern
            "String", // Type
            null, // default value
            false, // computed
        );
        this.attributes["USID"] = new EnterpriseComponentAttributeMetadata(
            "USID", // name
            false, // NoCopy
            true, // NoUpdate
            true, // Required
            false, // RequiredForQuery
            null, // Picklist
            null, // Scale
            null, // Precision
            36, // length
            null, // minLength
            null, // pattern
            "UUID", // Type
            null, // default value
            false, // computed
        );
    }
}

export default DeveloperLogEnterpriseComponentMetadata;
