import EnlilAxios from "../../../../util/EnlilAxios";
import {sleep} from "../../../../util/Sleep";
import {V1DeveloperExceptionIDOCToArray} from "../../../../util/Payload";
import EnterpriseComponent from "../../EnterpriseComponent";


class DeveloperExceptionEnterpriseComponent extends EnterpriseComponent {
    performQuery = (page, pageSize, foreignKey) => {
        page = page || 1;
        pageSize = pageSize || this.getDefaultCacheSize();

        this.notifyLoadStartListeners();

        const send_request = (queryId) =>
            EnlilAxios({
                method: "GET",
                url: "t2/v1/developer_exceptions",
                json: true,
                params: {
                    "Limit": 5,
                    "Offset": 0,
                    ...this.getSearchSpecification(),
                    ...(queryId ? {"QueryID": queryId} : {}),
                },
            }).then(response => {
                if (response.data.CheckComplete === false) {
                    let queryId = response.data.QueryID;
                    sleep(10000).then(() => send_request(queryId));
                } else {
                    this.fetchEnterpriseComponentSuccess(V1DeveloperExceptionIDOCToArray(response.data), page, pageSize);
                }
            }).catch(error => {
                this.fetchEnterpriseComponentFailed(error);
            });

        send_request();
    };
}

export default DeveloperExceptionEnterpriseComponent;