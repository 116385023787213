import React from "react";
import "../Navigation/css/NavBar.scss";
import {Black} from "../../theme/styles";
import auditLog from "../../assets/audit_log.png";

export default function Lots() {

    return (
        <div style={{paddingLeft: "20px", paddingRight: "30px"}}>
            <p style={{fontSize: "24px", fontWeight: "400", color: Black}}>Audit Logs</p>
            <p style={{fontSize: "16px", fontWeight: "400", color: Black}}>REST APIs support the handling of audit logs in Enlil.</p>
            <ul style={{fontSize: "14px", fontWeight: "400", color: Black}}>
                <li style={{marginTop: "20px"}}>In Enlil, all actions that result in a change to persisted data are themselves persisted in the Enlil database. This API enables the retrieval of that information.</li>
                <li style={{marginTop: "20px"}}>Typically, the log will record the before and after values of a particular element of data. As such, the information written to the audit log is very voluminous.</li>
                <li style={{marginTop: "20px"}}>Users of Enlil, may view information in the Audit Log by clicking on the History icon on the drawer to the right of any detail screen.</li>
                <li style={{marginTop: "40px"}}><img style={{width: "300px", height: "400px"}} src={auditLog} alt={"History"}/></li>
            </ul>
            <br/>
        </div>
    )
}