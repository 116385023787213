import React from "react";
import "../Navigation/css/NavBar.scss";
import {Black} from "../../theme/styles";

export default function Parts() {

    return (
        <div style={{paddingLeft: "20px", paddingRight: "30px"}}>
            <p style={{fontSize: "24px", fontWeight: "400", color: Black}}>Parts</p>
            <p style={{fontSize: "16px", fontWeight: "400", color: Black}}>REST APIs support the handling of part revisions in Enlil.</p>
            <ul style={{fontSize: "14px", fontWeight: "400", color: Black}}>
                <li style={{marginTop: "20px"}}>In Enlil, part revisions represent the revisions of documents whose document type group attribute is set to "PARTS". This family of APIs allows for the retrieval, creation and updating of part revisions.</li>
            </ul>
            <br/>
            <br/>
            <p style={{fontSize: "16px", fontWeight: "400", color: Black}}>The logical ERD below helps visualize the part revision relationships in Enlil with regard to Parts, Part Revisions, Attachments, .</p>
        </div>
    )
}