import React from "react";
import "../Navigation/css/NavBar.scss";
import {Black, Blue3, Grey7} from "../../theme/styles";
import lot_004_put_attachment_request_1 from '../../json/lot-004-put-attachment-request-1.json';
import lot_004_put_attachment_request_2 from '../../json/lot-004-put-attachment-request-2.json';
import lot_004_put_reference_request_1 from '../../json/lot-004-put-reference-request-1.json';
import lot_004_put_employee_request_1 from '../../json/lot-004-put-employee-request-1.json';
import lot_004_put_group_request_1 from '../../json/lot-004-put-group-request-1.json';
import lot_004_delete_group_request_1 from '../../json/lot-004-delete-group-request-1.json';
import lot_004_delete_employee_request_1 from '../../json/lot-004-delete-employee-request-1.json';
import lot_004_delete_reference_request_1 from '../../json/lot-004-delete-reference-request-1.json';
import lot_004_delete_attachment_request_1 from '../../json/lot-004-delete-attachment-request-1.json';
import lot_004_delete_attachment_request_2 from '../../json/lot-004-delete-attachment-request-2.json';
import lot_002_put_group_response_1 from '../../json/lot-002-put-group-response-1.json';
import lot_002_put_employee_response_1 from '../../json/lot-002-put-employee-response-1.json';
import lot_002_put_reference_response_1 from '../../json/lot-002-put-reference-response-1.json';
import lot_003_put_attachment_response_1 from '../../json/lot-003-put-attachment-response-1.json';
import lot_003_put_attachment_response_2 from '../../json/lot-003-put-attachment-response-2.json';
import lot_form_input_template_1 from '../../json/lot-revision-form-input-template-1.json'
import lot_form_input_1 from '../../json/lot-revision-form-input-1.json'
import ReactJson from 'react-json-view'
import {Accordion, AccordionDetails, AccordionSummary, Avatar, Typography} from "@mui/material";
import {ExpandMore} from "@mui/icons-material";

export default function ApprovalRequestsPut() {

    return (
        <div style={{paddingLeft: "20px", paddingRight: "30px"}}>
            <p style={{fontSize: "24px", fontWeight: "400", color: Black}}>Lots PUT</p>
            <p style={{fontSize: "16px", fontWeight: "400", color: Black}}>Overview</p>

            <p style={{fontSize: "14px", fontWeight: "400", color: Black, marginLeft: "20px"}}>
                In Enlil, the parts PUT API allows for the update of part content.
            </p>
            <br/>
            <p style={{fontSize: "16px", fontWeight: "400", color: Black}}>Parameters</p>
            <ul style={{fontSize: "14px", fontWeight: "400", color: Black}}>
                <li style={{marginTop: "20px"}}>Header Parameters
                    <li style={{marginTop: "20px", marginLeft: "20px"}}>X-Enlil-AccessToken (Required String)</li>
                    <li style={{marginTop: "10px", marginLeft: "40px"}}>The X-Enlil-AccessToken is obtained during the authentication process and expires after 90 days.</li>
                    <li style={{marginTop: "20px", marginLeft: "20px"}}>X-Enlil-RefreshToken (Optional String)</li>
                    <li style={{marginTop: "10px", marginLeft: "40px"}}>The X-Enlil-RefreshToken is obtained during the authentication process and never expires. This token is provided is the caller wants a refreshed X-Enlil-AccessToken</li>
                    <li style={{marginTop: "20px", marginLeft: "20px"}}>idoc-request (Required String: must equal v1.Lot-004)</li>
                    <li style={{marginTop: "10px", marginLeft: "40px"}}>The name of the IDOC being specified in the PUT request.</li>
                    <li style={{marginTop: "20px", marginLeft: "20px"}}>idoc-response (Optional String).</li>
                    <li style={{marginTop: "10px", marginLeft: "40px"}}>v1.Lot-001, v1.Lot-002, v1.Lot-003, and v1.Lot-004 are permitted values.</li>
                    <li style={{marginTop: "10px", marginLeft: "40px"}}>If idoc-response is provided, the response payload (HTTP Code: 201) will match the specified format, else no response (HTTP Code: 204) will be provided.</li>
                </li>
            </ul>
            <br/>
            <p style={{fontSize: "16px", fontWeight: "400", color: Black}}>Scenarios</p>
            <p style={{fontSize: "14px", fontWeight: "400", color: Black, marginLeft: "20px"}}>
                <span style={{lineHeight: "1.5em"}}>
                Enlil requires Lot PUT content to be in the v1.Lot-004 format. For example, the user executes this PUT using idoc-request=v1.Lot-003 (below) with idoc-response=v1.Lot-003, Enlil will respond (HTTP Code: 200) with a payload of the
                v1.Part-003 below format<br/>
                </span>
                <Accordion style={{marginTop: "20px", backgroundColor: "#EEEEEE"}}>
                    <AccordionSummary expandIcon={<ExpandMore />} aria-controls="panel1-content" id="panel1-header">
                        <Avatar sx={{marginTop: "2px", bgcolor: Blue3, width: 25, height: 25, fontSize: "14px", marginRight: "10px"}}>1</Avatar>
                        <Typography style={{paddingTop: "3px"}}><b>Adding/Removing an attachment to/from a Lot</b></Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <p style={{marginLeft: "20px"}}>
                            <span style={{lineHeight: "1.5em"}}>An attachment may be added to a Lot through the use of the /t3/v1/lots PUT API and the /t3/v1/lot_revisions API. The following payload may be used to perform the upsert operation. The user can upload the actual attachment to Enlil by using the signed PUT url in the ShifamedMessage.ListOfLot.Lot[i].ListOfAttachment.Attachment[j].PutURL</span>
                        </p>
                        <p style={{fontFamily: "Courier New", fontSize: "12px", marginLeft: "20px"}}>
                            <ReactJson src={lot_004_put_attachment_request_1} collapseStringsAfterLength={75} collapsed={4}/>
                        </p>
                        <li style={{marginTop: "20px", marginLeft: "20px"}}>The following is a representative response.</li>
                        <br/>
                        <p style={{fontFamily: "Courier New", fontSize: "12px", marginLeft: "20px"}}>
                            <ReactJson src={lot_003_put_attachment_response_1} collapseStringsAfterLength={75} collapsed={4}/>
                        </p>
                        <li style={{marginTop: "20px", marginLeft: "20px"}}>To remove an attachment from a lot execute the /t3/v1/lots DELETE API with the following payload. Attachments are not permanently deleted and may be recovered by contacting Enlil Professional Services.</li>
                        <br/>
                        <p style={{fontFamily: "Courier New", fontSize: "12px", marginLeft: "20px"}}>
                            <ReactJson src={lot_004_delete_attachment_request_1} collapseStringsAfterLength={75} collapsed={4}/>
                        </p>
                        <li style={{marginTop: "5px", marginLeft: "20px"}}>The delete operation requires two pieces of information which may be obtained from v1.Lot-002, v1.Lot-003 or v1.Lot-004.</li>
                        <li style={{marginTop: "5px", marginLeft: "40px"}}>ShifamedMessage.ListOfLot.Lot[n].USID</li>
                        <li style={{marginTop: "5px", marginLeft: "40px"}}>ShifamedMessage.ListOfLot.Lot[n].ListOfLotAttachment.LotAttachment[m].USID</li>
                        <br/>
                        <br/>
                    </AccordionDetails>
                </Accordion>
                <Accordion style={{backgroundColor: "#EEEEEE"}}>
                    <AccordionSummary expandIcon={<ExpandMore/>} aria-controls="panel1-content" id="panel1-header">
                        <Avatar sx={{marginTop: "2px", bgcolor: Blue3, width: 25, height: 25, fontSize: "14px", marginRight: "10px"}}>2</Avatar>
                        <Typography style={{paddingTop: "3px"}}><b>Adding/Removing an attachment to/from a Lot Revision</b></Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <p style={{marginLeft: "20px"}}>
                            <span style={{lineHeight: "1.5em"}}>An attachment may be added to a Lot Revision through the use of the /t3/v1/lots PUT API or the /t3/v1/lot_revisions API. The following payload may be used to perform the upsert operation. The user can upload the actual attachment to Enlil by using the signed PUT url in the ShifamedMessage.ListOfLot.Lot[i].ListOfAttachment.Attachment[j].PutURL</span>
                        </p>
                        <p style={{fontFamily: "Courier New", fontSize: "12px", marginLeft: "20px"}}>
                            <ReactJson src={lot_004_put_attachment_request_2} collapseStringsAfterLength={75} collapsed={4}/>
                        </p>
                        <li style={{marginTop: "20px", marginLeft: "20px"}}>The following is a representative response.</li>
                        <br/>
                        <p style={{fontFamily: "Courier New", fontSize: "12px", marginLeft: "20px"}}>
                            <ReactJson src={lot_003_put_attachment_response_2} collapseStringsAfterLength={75} collapsed={4}/>
                        </p>
                        <li style={{marginTop: "20px", marginLeft: "20px"}}>To remove an attachment from a Lot Revision execute the /t3/v1/lots DELETE API with the following payload. Attachments are not permanently deleted and may be recovered by contacting Enlil Professional Services.</li>
                        <br/>
                        <p style={{fontFamily: "Courier New", fontSize: "12px", marginLeft: "20px"}}>
                            <ReactJson src={lot_004_delete_attachment_request_2} collapseStringsAfterLength={75} collapsed={4}/>
                        </p>
                        <li style={{marginTop: "5px", marginLeft: "20px"}}>The delete operation requires two pieces of information which may be obtained v1.Lot-002, v1.Lot-003 or v1.Lot-004.</li>
                        <li style={{marginTop: "5px", marginLeft: "40px"}}>ShifamedMessage.ListOfLot.Lot[n].USID</li>
                        <li style={{marginTop: "5px", marginLeft: "40px"}}>ShifamedMessage.ListOfLot.Lot[n].ListOfLotRevision.LotRevision[m].USID</li>
                        <li style={{marginTop: "5px", marginLeft: "40px"}}>ShifamedMessage.ListOfLot.Lot[n].ListOfLotRevision.LotRevision[m].ListOfLotRevisionAttachment.LotRevisionAttachment[p].USID</li>
                        <br/>
                        <br/>
                    </AccordionDetails>
                </Accordion>
                <Accordion style={{backgroundColor: "#EEEEEE"}}>
                    <AccordionSummary expandIcon={<ExpandMore/>} aria-controls="panel1-content" id="panel1-header">
                        <Avatar sx={{marginTop: "2px", bgcolor: Blue3, width: 25, height: 25, fontSize: "14px", marginRight: "10px"}}>3</Avatar>
                        <Typography style={{paddingTop: "3px"}}><b>Modifying Form Input values</b></Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <span style={{lineHeight: "1.5em"}}>Each Lot created by an Enlil user may have a different form template, although typically they each use the same form template or share from a subset of a few form templates particularly intended to support the customers requirements.</span>
                        <span style={{lineHeight: "1.5em"}}>When an Enlil user creates a lot, the form template becomes a form and is used by each revision of the lot until the user decides to change the form by adding or removing form elements on the form.</span>
                        <span style={{lineHeight: "1.5em"}}>From that revision going forward, the new form will be used. To perform a put on a lot revision that modifies these form values the user of the Enlil API must know the current schema of the lot revision record.</span>
                        <span style={{lineHeight: "1.5em"}}>The schema may be retrieved from the following path in v1.Lot-002, v1.Lot-003 and v1.Lot-004. The value is base64 encoded and will need to be decoded. </span><br/><br/>
                        v1.Lot-002/v1.Lot-003/v1.Lot-004: ShifamedMessage.ListOfLot.Lot[n].ListOfLotRevision.LotRevision[m].FormInputTemplate<br/><br/>
                        An example of this template is provided below.
                        <p style={{fontFamily: "Courier New", fontSize: "12px"}}>
                            <ReactJson src={lot_form_input_template_1} collapseStringsAfterLength={75} collapsed={4}/>
                        </p>
                        <br/>
                        <span style={{lineHeight: "1.5em"}}>To obtain the current values of the form used by the lot revision, access the attribute FormInput. The value is base64 encoded and will need to be decoded. An example of this value is provide below.</span>

                        <p style={{fontFamily: "Courier New", fontSize: "12px"}}>
                            <ReactJson src={lot_form_input_1} collapseStringsAfterLength={75} collapsed={4}/>
                        </p>
                        <br/>
                        <span style={{lineHeight: "1.5em"}}>To change the current form values of the form used by the lot revision, post changes to the FormInputExternal attribute. The value must be base64 encoded.</span>

                        <p style={{fontFamily: "Courier New", fontSize: "12px"}}>
                            <ReactJson src={lot_form_input_1} collapseStringsAfterLength={75} collapsed={4}/>
                        </p>
                        <br/>
                        <span style={{lineHeight: "1.5em"}}>The following will help explain where to get the values required by the various fields.</span><br/>

                        <div style={{display: "flex", flexDirection: "row", marginTop: "10px"}}>
                            <Avatar sx={{bgcolor: Grey7, width: 25, height: 25, fontSize: "14px", marginRight: "10px"}}>1</Avatar>
                            <Typography style={{paddingTop: "3px", fontSize: "14px"}}>ShifamedMessage.LotRevision.PartRevisionUSID</Typography>
                        </div>
                        <div style={{display: "flex", flexDirection: "row", marginTop: "10px", marginLeft: "35px"}}>
                            <span style={{lineHeight: "1.5em"}}>
                                To obtain this value, the user can access the /t3/v1/parts GET API. Only Part Revisions in the RELEASED state may be selected.<br/>
                                v1.Part-002 (ShifamedMessage.ListOfPart.Part[n].ListOfPartRevision.PartRevision[k].USID)<br/>
                                v1.Part-003 (ShifamedMessage.ListOfPart.Part[n].ListOfPartRevision.PartRevision[k].USID)<br/>
                                v1.Part-004 (ShifamedMessage.ListOfPart.Part[n].ListOfPartRevision.PartRevision[k].USID)<br/><br/>

                                Also, the user can access the /t3/v1/part_revisions GET API<br/>
                                v1.PartRevision-001 (ShifamedMessage.ListOfPartRevision.PartRevision[n].USID)<br/>
                                v1.PartRevision-002 (ShifamedMessage.ListOfPartRevision.PartRevision[n].USID)<br/>
                                v1.PartRevision-003 (ShifamedMessage.ListOfPartRevision.PartRevision[n].USID)<br/>
                                v1.PartRevision-004 (ShifamedMessage.ListOfPartRevision.PartRevision[n].USID)<br/>
                            </span>
                        </div>
                        <div style={{display: "flex", flexDirection: "row", marginTop: "10px"}}>
                            <Avatar sx={{bgcolor: Grey7, width: 25, height: 25, fontSize: "14px", marginRight: "10px"}}>2</Avatar>
                            <Typography style={{paddingTop: "3px", fontSize: "14px"}}>ShifamedMessage.LotRevision.LocationUSID</Typography>
                        </div>
                        <div style={{display: "flex", flexDirection: "row", marginTop: "10px", marginLeft: "35px"}}>
                            <span style={{lineHeight: "1.5em"}}>
                                To obtain this value, the user can access the /t3/v1/company GET API<br/>
                                v1.Company-001 (ShifamedMessage.Company.Locations)<br/>
                            </span>
                        </div>
                        <div style={{display: "flex", flexDirection: "row", marginTop: "10px"}}>
                            <Avatar sx={{bgcolor: Grey7, width: 25, height: 25, fontSize: "14px", marginRight: "10px"}}>3</Avatar>
                            <Typography style={{paddingTop: "3px", fontSize: "14px"}}>ShifamedMessage.LotRevision.LocationUSID</Typography>
                        </div>
                        <div style={{display: "flex", flexDirection: "row", marginTop: "10px", marginLeft: "35px"}}>
                            <span style={{lineHeight: "1.5em"}}>
                                To obtain this value, the user can access the /t3/v1/company GET API<br/>
                                v1.Company-001 (ShifamedMessage.Company.Locations)<br/>
                            </span>
                        </div>
                        <div style={{display: "flex", flexDirection: "row", marginTop: "10px"}}>
                            <Avatar sx={{bgcolor: Grey7, width: 25, height: 25, fontSize: "14px", marginRight: "10px"}}>4</Avatar>
                            <Typography style={{paddingTop: "3px", fontSize: "14px"}}>ShifamedMessage.LotRevision.EquipmentRevisionUSID</Typography>
                        </div>
                        <div style={{display: "flex", flexDirection: "row", marginTop: "10px", marginLeft: "35px"}}>
                            <span style={{lineHeight: "1.5em"}}>
                                To obtain this value, the user can access the /t3/v1/equipments GET API. Only Equipment Revisions in the RELEASED state may be selected.<br/>
                                v1.Equipment-002 (ShifamedMessage.ListOfEquipment.Equipment[n].ListOfEquipmentRevision.EquipmentRevision[k].USID)<br/>
                                v1.Equipment-003 (ShifamedMessage.ListOfEquipment.Equipment[n].ListOfEquipmentRevision.EquipmentRevision[k].USID)<br/>
                                v1.Equipment-004 (ShifamedMessage.ListOfEquipment.Equipment[n].ListOfEquipmentRevision.EquipmentRevision[k].USID)<br/><br/>

                                Also, the user can access the /t3/v1/equipment_revisions GET API<br/>
                                v1.EquipmentRevision-001 (ShifamedMessage.ListOfEquipmentRevision.EquipmentRevision[n].USID)<br/>
                                v1.EquipmentRevision-002 (ShifamedMessage.ListOfEquipmentRevision.EquipmentRevision[n].USID)<br/>
                                v1.EquipmentRevision-003 (ShifamedMessage.ListOfEquipmentRevision.EquipmentRevision[n].USID)<br/>
                                v1.EquipmentRevision-004 (ShifamedMessage.ListOfEquipmentRevision.EquipmentRevision[n].USID)<br/>
                            </span>
                        </div>
                        <div style={{display: "flex", flexDirection: "row", marginTop: "10px"}}>
                            <Avatar sx={{bgcolor: Grey7, width: 25, height: 25, fontSize: "14px", marginRight: "10px"}}>5</Avatar>
                            <Typography style={{paddingTop: "3px", fontSize: "14px"}}>ShifamedMessage.LotRevision.ListOfApproval.Approval[n].EmployeeUSID</Typography>
                        </div>
                        <div style={{display: "flex", flexDirection: "row", marginTop: "10px", marginLeft: "35px"}}>
                            <span style={{lineHeight: "1.5em"}}>
                                To obtain this value, the user can access the /t3/v1/employees GET API<br/>
                                v1.Employee-001 (ShifamedMessage.ListOfEmployee.Employee[n].USID)<br/>
                            </span>
                        </div>
                        <div style={{display: "flex", flexDirection: "row", marginTop: "10px"}}>
                            <Avatar sx={{bgcolor: Grey7, width: 25, height: 25, fontSize: "14px", marginRight: "10px"}}>6</Avatar>
                            <Typography style={{paddingTop: "3px", fontSize: "14px"}}>ShifamedMessage.LotRevision.ListOfApproval.Approval[n].EmployeeUSID</Typography>
                        </div>
                        <div style={{display: "flex", flexDirection: "row", marginTop: "10px", marginLeft: "35px"}}>
                            <span style={{lineHeight: "1.5em"}}>
                                To obtain this value, the user can access the /t3/v1/employees GET API<br/>
                                v1.Employee-001 (ShifamedMessage.ListOfEmployee.Employee[n].USID)<br/>
                            </span>
                        </div>
                        <div style={{display: "flex", flexDirection: "row", marginTop: "10px"}}>
                            <Avatar sx={{bgcolor: Grey7, width: 25, height: 25, fontSize: "14px", marginRight: "10px"}}>7</Avatar>
                            <Typography style={{paddingTop: "3px", fontSize: "14px"}}>ShifamedMessage.LotRevision.ListOfMaterialTransfer.MaterialTransfer[n].type</Typography>
                        </div>
                        <div style={{display: "flex", flexDirection: "row", marginTop: "10px", marginLeft: "35px"}}>
                            <span style={{lineHeight: "1.5em"}}>
                                Possible values are:<br/>
                                all, allocate, transfer, return, move, issue, backflush, scrap<br/>
                            </span>
                        </div>
                        <div style={{display: "flex", flexDirection: "row", marginTop: "10px"}}>
                            <Avatar sx={{bgcolor: Grey7, width: 25, height: 25, fontSize: "14px", marginRight: "10px"}}>8</Avatar>
                            <Typography style={{paddingTop: "3px", fontSize: "14px"}}>ShifamedMessage.LotRevision.ListOfMaterialTransfer.MaterialTransfer[n].From and To</Typography>
                        </div>
                        <div style={{display: "flex", flexDirection: "row", marginTop: "10px", marginLeft: "35px"}}>
                            <span style={{lineHeight: "1.5em"}}>
                                To obtain this value, the user can access the /t3/v1/company GET API<br/>
                                v1.Company-001 (ShifamedMessage.Company.Locations)<br/>
                            </span>
                        </div>
                        <div style={{display: "flex", flexDirection: "row", marginTop: "10px"}}>
                            <Avatar sx={{bgcolor: Grey7, width: 25, height: 25, fontSize: "14px", marginRight: "10px"}}>9</Avatar>
                            <Typography style={{paddingTop: "3px", fontSize: "14px"}}>ShifamedMessage.LotRevision.ListOfMaterialTransfer.MaterialTransfer[n].BuildAllocated</Typography>
                        </div>
                        <div style={{display: "flex", flexDirection: "row", marginTop: "10px", marginLeft: "35px"}}>
                            <span style={{lineHeight: "1.5em"}}>
                                To obtain this value, the user can access the /t3/v1/paper_lot_history_records GET API. Only Paper Lot History Records in the RELEASED state may be selected.<br/>
                                v1.PaperLotHistoryRecord-002 (ShifamedMessage.ListOfPaperLotHistoryRecord.PaperLotHistoryRecord[n].ListOfPaperLotHistoryRecord.PaperLotHistoryRecord[k].USID)<br/>
                                v1.PaperLotHistoryRecord-003 (ShifamedMessage.ListOfPaperLotHistoryRecord.PaperLotHistoryRecord[n].ListOfPaperLotHistoryRecord.PaperLotHistoryRecord[k].USID)<br/>
                                v1.PaperLotHistoryRecord-004 (ShifamedMessage.ListOfPaperLotHistoryRecord.PaperLotHistoryRecord[n].ListOfPaperLotHistoryRecord.PaperLotHistoryRecord[k].USID)<br/><br/>

                                Also, the user can access the /t3/v1/paper_lot_history_record_revisions GET API<br/>
                                v1.PaperLotHistoryRecordRevision-001 (ShifamedMessage.ListOfPaperLotHistoryRecord.PaperLotHistoryRecord[n].USID)<br/>
                                v1.PaperLotHistoryRecordRevision-002 (ShifamedMessage.ListOfPaperLotHistoryRecord.PaperLotHistoryRecord[n].USID)<br/>
                                v1.PaperLotHistoryRecordRevision-003 (ShifamedMessage.ListOfPaperLotHistoryRecord.PaperLotHistoryRecord[n].USID)<br/>
                                v1.PaperLotHistoryRecordRevision-004 (ShifamedMessage.ListOfPaperLotHistoryRecord.PaperLotHistoryRecord[n].USID)<br/>
                            </span>
                        </div>
                        <div style={{display: "flex", flexDirection: "row", marginTop: "10px"}}>
                            <Avatar sx={{bgcolor: Grey7, width: 25, height: 25, fontSize: "14px", marginRight: "10px"}}>10</Avatar>
                            <Typography style={{paddingTop: "3px", fontSize: "14px"}}>ShifamedMessage.LotRevision.ListOfMaterialTransfer.MaterialTransfer[n].By</Typography>
                        </div>
                        <div style={{display: "flex", flexDirection: "row", marginTop: "10px", marginLeft: "35px"}}>
                            <span style={{lineHeight: "1.5em"}}>
                                To obtain this value, the user can access the /t3/v1/employees GET API<br/>
                                v1.Employee-001 (ShifamedMessage.ListOfEmployee.Employee[n].USID)<br/>
                            </span>
                        </div>
                        <div style={{display: "flex", flexDirection: "row", marginTop: "10px"}}>
                            <Avatar sx={{bgcolor: Grey7, width: 25, height: 25, fontSize: "14px", marginRight: "10px"}}>11</Avatar>
                            <Typography style={{paddingTop: "3px", fontSize: "14px"}}>ShifamedMessage.LotRevision.ListOfMaterialTransfer.MaterialTransfer[n].USID</Typography>
                        </div>
                        <div style={{display: "flex", flexDirection: "row", marginTop: "10px", marginLeft: "35px"}}>
                            <span style={{lineHeight: "1.5em"}}>
                                To obtain this value, the user must pick any value UUID
                            </span>
                        </div>
                    </AccordionDetails>
                </Accordion>
                <Accordion style={{backgroundColor: "#EEEEEE"}}>
                    <AccordionSummary expandIcon={<ExpandMore />} aria-controls="panel1-content" id="panel1-header">
                        <Avatar sx={{marginTop: "2px", bgcolor: Blue3, width: 25, height: 25, fontSize: "14px", marginRight: "10px"}}>4</Avatar>
                        <Typography style={{paddingTop: "3px"}}><b>Adding/Removing a group to/from a Lot</b></Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <p style={{marginLeft: "20px"}}>
                            <span style={{lineHeight: "1.5em"}}>A group may be added to a Lot through the use of the /t3/v1/lots PUT API. The following payload may be used to perform the upsert operation.</span>
                        </p>
                        <p style={{fontFamily: "Courier New", fontSize: "12px", marginLeft: "20px"}}>
                            <ReactJson src={lot_004_put_group_request_1} collapseStringsAfterLength={75} collapsed={4}/>
                        </p>
                        <li style={{marginTop: "20px", marginLeft: "20px"}}>The following is a representative response.</li>
                        <br/>
                        <p style={{fontFamily: "Courier New", fontSize: "12px", marginLeft: "20px"}}>
                            <ReactJson src={lot_002_put_group_response_1} collapseStringsAfterLength={75} collapsed={4}/>
                        </p>
                        <li style={{marginTop: "20px", marginLeft: "20px"}}>To remove a group from a Lot, execute the /t3/v1/lots DELETE API with the following payload.</li>
                        <br/>
                        <p style={{fontFamily: "Courier New", fontSize: "12px", marginLeft: "20px"}}>
                            <ReactJson src={lot_004_delete_group_request_1} collapseStringsAfterLength={75} collapsed={4}/>
                        </p>
                        <li style={{marginTop: "5px", marginLeft: "20px"}}>The delete operation requires two pieces of information which may be obtained from v1.Lot-002 or v1.Lot-004.</li>
                        <li style={{marginTop: "5px", marginLeft: "40px"}}>ShifamedMessage.ListOfLot.Lot[n].USID</li>
                        <li style={{marginTop: "5px", marginLeft: "40px"}}>ShifamedMessage.ListOfLot.Lot[n].ListOfGroup.Group[m].USID</li>
                        <br/>
                        <br/>
                    </AccordionDetails>
                </Accordion>
                <Accordion style={{backgroundColor: "#EEEEEE"}}>
                    <AccordionSummary expandIcon={<ExpandMore/>} aria-controls="panel1-content" id="panel1-header">
                        <Avatar sx={{marginTop: "2px", bgcolor: Blue3, width: 25, height: 25, fontSize: "14px", marginRight: "10px"}}>5</Avatar>
                        <Typography style={{paddingTop: "3px"}}><b>Adding/Removing an employee to/from a Lot</b></Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <p style={{marginLeft: "20px"}}>
                            <span style={{lineHeight: "1.5em"}}>An employee may be added to a Lot through the use of the /t3/v1/lots PUT API. The following payload may be used to perform the upsert operation.</span>
                        </p>
                        <p style={{fontFamily: "Courier New", fontSize: "12px", marginLeft: "20px"}}>
                            <ReactJson src={lot_004_put_employee_request_1} collapseStringsAfterLength={75} collapsed={4}/>
                        </p>
                        <li style={{marginTop: "20px", marginLeft: "20px"}}>The following is a representative response.</li>
                        <br/>
                        <p style={{fontFamily: "Courier New", fontSize: "12px", marginLeft: "20px"}}>
                            <ReactJson src={lot_002_put_employee_response_1} collapseStringsAfterLength={75} collapsed={4}/>
                        </p>
                        <li style={{marginTop: "20px", marginLeft: "20px"}}>To remove an employee from a Lot, execute the /t3/v1/lots DELETE API with the following payload.</li>
                        <br/>
                        <p style={{fontFamily: "Courier New", fontSize: "12px", marginLeft: "20px"}}>
                            <ReactJson src={lot_004_delete_employee_request_1} collapseStringsAfterLength={75} collapsed={4}/>
                        </p>
                        <li style={{marginTop: "5px", marginLeft: "20px"}}>The delete operation requires two pieces of information which may be obtained from v1.Lot-002 or v1.Lot-004.</li>
                        <li style={{marginTop: "5px", marginLeft: "40px"}}>ShifamedMessage.ListOfLot.Lot[n].USID</li>
                        <li style={{marginTop: "5px", marginLeft: "40px"}}>ShifamedMessage.ListOfLot.Lot[n].ListOfEmployee.Employee[m].USID</li>
                        <br/>
                        <br/>
                    </AccordionDetails>
                </Accordion>
                <Accordion style={{backgroundColor: "#EEEEEE"}}>
                    <AccordionSummary expandIcon={<ExpandMore/>} aria-controls="panel1-content" id="panel1-header">
                        <Avatar sx={{marginTop: "2px", bgcolor: Blue3, width: 25, height: 25, fontSize: "14px", marginRight: "10px"}}>6</Avatar>
                        <Typography style={{paddingTop: "3px"}}><b>Adding/Removing a reference to/from a Lot Revision</b></Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <p style={{marginLeft: "20px"}}>
                            <span style={{lineHeight: "1.5em"}}>A reference may be added to a Lot Revision via this API. References added to a Lot Revision must be in the RELEASED state.</span>
                        </p>
                        <p style={{marginLeft: "20px"}}>
                            <span style={{lineHeight: "1.5em"}}>A reference may be added to a Lot Revision through the use of the /t3/v1/lots PUT API or the /t3/v1/lot_revisions PUT API. The following payload may be used to perform the upsert operation.</span>
                        </p>
                        <p style={{fontFamily: "Courier New", fontSize: "12px", marginLeft: "20px"}}>
                            <ReactJson src={lot_004_put_reference_request_1} collapseStringsAfterLength={75} collapsed={4}/>
                        </p>
                        <li style={{marginTop: "20px", marginLeft: "20px"}}>The following is a representative response.</li>
                        <br/>
                        <p style={{fontFamily: "Courier New", fontSize: "12px", marginLeft: "20px"}}>
                            <ReactJson src={lot_002_put_reference_response_1} collapseStringsAfterLength={75} collapsed={4}/>
                        </p>
                        <li style={{marginTop: "20px", marginLeft: "20px"}}>To remove a reference from a Lot, execute the /t3/v1/lots DELETE API with the following payload.</li>
                        <br/>
                        <p style={{fontFamily: "Courier New", fontSize: "12px", marginLeft: "20px"}}>
                            <ReactJson src={lot_004_delete_reference_request_1} collapseStringsAfterLength={75} collapsed={4}/>
                        </p>
                        <br/>
                        <li style={{marginTop: "5px", marginLeft: "20px"}}>The delete operation requires three pieces of information which may be obtained from v1.Lot-002.</li>
                        <li style={{marginTop: "5px", marginLeft: "40px"}}>ShifamedMessage.ListOfLot.Lot[n].USID</li>
                        <li style={{marginTop: "5px", marginLeft: "40px"}}>ShifamedMessage.ListOfLot.Lot[n].ListOfLotRevision.LotRevision[m].USID</li>
                        <li style={{marginTop: "5px", marginLeft: "40px"}}>ShifamedMessage.ListOfLot.Lot[n].ListOfLotRevision.LotRevision[m].ListOfLotRevisionReference.LotRevisionReference[z].USID</li>
                        <br/>
                        <br/>
                    </AccordionDetails>
                </Accordion>
                <Accordion style={{backgroundColor: "#EEEEEE"}}>
                    <AccordionSummary expandIcon={<ExpandMore/>} aria-controls="panel1-content" id="panel1-header">
                        <Avatar sx={{marginTop: "2px", bgcolor: Blue3, width: 25, height: 25, fontSize: "14px", marginRight: "10px"}}>7</Avatar>
                        <Typography style={{paddingTop: "3px"}}><b>Releasing a Lot</b></Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <p style={{marginLeft: "20px"}}>
                            <span style={{lineHeight: "1.5em"}}></span>
                        </p>
                    </AccordionDetails>
                </Accordion>
            </p>
            <br/>
            <p style={{fontSize: "16px", fontWeight: "400", color: Black}}>Business Rules</p>
            <p style={{fontSize: "14px", fontWeight: "400", color: Black}}>
                <ul style={{fontSize: "14px", fontWeight: "400", color: Black}}>
                    <li style={{marginTop: "20px"}}>External users may not modify the ENLIL_FORM_INPUT field."
                        <li style={{marginTop: "10px", marginLeft: "40px"}}>Error Code: CC-JDU34-A12W</li>
                        <li style={{marginTop: "10px", marginLeft: "40px"}}>Description: Only Enlil can modify this field.</li>
                        <li style={{marginTop: "10px", marginLeft: "40px"}}>HTTP Code: 400</li>
                    </li>
                    <li style={{marginTop: "20px"}}>External users may not modify the PRE_CALC field."
                        <li style={{marginTop: "10px", marginLeft: "40px"}}>Error Code: CC-KEID-A12W</li>
                        <li style={{marginTop: "10px", marginLeft: "40px"}}>Description: Only Enlil can modify this field.</li>
                        <li style={{marginTop: "10px", marginLeft: "40px"}}>HTTP Code: 400</li>
                    </li>
                    <li style={{marginTop: "20px"}}>Invalid value for PartRevisionUSID"
                        <li style={{marginTop: "10px", marginLeft: "40px"}}>Error Code: CC-JDEA-OEOO.</li>
                        <li style={{marginTop: "10px", marginLeft: "40px"}}>Description: Invalid value for PartRevisionUSID</li>
                        <li style={{marginTop: "10px", marginLeft: "40px"}}>HTTP Code: 400</li>
                    </li>
                    <li style={{marginTop: "20px"}}>Invalid location."
                        <li style={{marginTop: "10px", marginLeft: "40px"}}>Error Code: CC-HDHD-HEHD</li>
                        <li style={{marginTop: "10px", marginLeft: "40px"}}>Description: The user has picked a location that is not in the locations field from Company</li>
                        <li style={{marginTop: "10px", marginLeft: "40px"}}>HTTP Code: 400</li>
                    </li>
                    <li style={{marginTop: "20px"}}>AcceptedQuantity must not be negative."
                        <li style={{marginTop: "10px", marginLeft: "40px"}}>Error Code: CC-ASWA-AASA</li>
                        <li style={{marginTop: "10px", marginLeft: "40px"}}>Description: The user gave a value that was less than zero.</li>
                        <li style={{marginTop: "10px", marginLeft: "40px"}}>HTTP Code: 400</li>
                    </li>
                    <li style={{marginTop: "20px"}}>MaterialTransfer.Type is required."
                        <li style={{marginTop: "10px", marginLeft: "40px"}}>Error Code: CC-YR45-PP1Q</li>
                        <li style={{marginTop: "10px", marginLeft: "40px"}}>Description: MaterialTransfer.Type is a required value.</li>
                        <li style={{marginTop: "10px", marginLeft: "40px"}}>HTTP Code: 400</li>
                    </li>
                    <li style={{marginTop: "20px"}}>MaterialTransfer.Quantity is required."
                        <li style={{marginTop: "10px", marginLeft: "40px"}}>Error Code: CC-BDHY-QAQE</li>
                        <li style={{marginTop: "10px", marginLeft: "40px"}}>Description: MaterialTransfer.Quantity is a required value.</li>
                        <li style={{marginTop: "10px", marginLeft: "40px"}}>HTTP Code: 400</li>
                    </li>
                    <li style={{marginTop: "20px"}}>MaterialTransfer.By is required."
                        <li style={{marginTop: "10px", marginLeft: "40px"}}>Error Code: CC-IOUJ-KDIU</li>
                        <li style={{marginTop: "10px", marginLeft: "40px"}}>Description: MaterialTransfer.By is a required value.</li>
                        <li style={{marginTop: "10px", marginLeft: "40px"}}>HTTP Code: 400</li>
                    </li>
                    <li style={{marginTop: "20px"}}>MaterialTransfer.From is required."
                        <li style={{marginTop: "10px", marginLeft: "40px"}}>Error Code: CC-QW23-09OP</li>
                        <li style={{marginTop: "10px", marginLeft: "40px"}}>Description: MaterialTransfer.From is a required value.</li>
                        <li style={{marginTop: "10px", marginLeft: "40px"}}>HTTP Code: 400</li>
                    </li>
                    <li style={{marginTop: "20px"}}>Invalid location MaterialTransfer.From"
                        <li style={{marginTop: "10px", marginLeft: "40px"}}>Error Code: CC-BDYF-ZMXC</li>
                        <li style={{marginTop: "10px", marginLeft: "40px"}}>Description: Invalid location MaterialTransfer.From</li>
                        <li style={{marginTop: "10px", marginLeft: "40px"}}>HTTP Code: 400</li>
                    </li>
                    <li style={{marginTop: "20px"}}>Invalid location MaterialTransfer.To"
                        <li style={{marginTop: "10px", marginLeft: "40px"}}>Error Code: CC-AWAW-SDVB</li>
                        <li style={{marginTop: "10px", marginLeft: "40px"}}>Description: Invalid location MaterialTransfer.To</li>
                        <li style={{marginTop: "10px", marginLeft: "40px"}}>HTTP Code: 400</li>
                    </li>
                    <li style={{marginTop: "20px"}}>MaterialTransfer From and To cannot be the same value."
                        <li style={{marginTop: "10px", marginLeft: "40px"}}>Error Code: CC-DDAS-DWEW</li>
                        <li style={{marginTop: "10px", marginLeft: "40px"}}>Description: MaterialTransfer From and To cannot be the same value.</li>
                        <li style={{marginTop: "10px", marginLeft: "40px"}}>HTTP Code: 400</li>
                    </li>
                    <li style={{marginTop: "20px"}}>Invalid value for MaterialTransfer.Type."
                        <li style={{marginTop: "10px", marginLeft: "40px"}}>Error Code: CC-AW45-KDJ8</li>
                        <li style={{marginTop: "10px", marginLeft: "40px"}}>Description: MaterialTransfer.Type must be equal to "all", "allocate", "transfer", "return", "move", "issue", "backflush", or "scrap"</li>
                        <li style={{marginTop: "10px", marginLeft: "40px"}}>HTTP Code: 400</li>
                    </li>
                    <li style={{marginTop: "20px"}}>Invalid value for MaterialTransfer.By."
                        <li style={{marginTop: "10px", marginLeft: "40px"}}>Error Code: CC-MDJU-JDU6</li>
                        <li style={{marginTop: "10px", marginLeft: "40px"}}>Description: Invalid value for MaterialTransfer.By</li>
                        <li style={{marginTop: "10px", marginLeft: "40px"}}>HTTP Code: 400</li>
                    </li>
                    <li style={{marginTop: "20px"}}>Invalid value for MaterialTransfer.To."
                        <li style={{marginTop: "10px", marginLeft: "40px"}}>Error Code: CC-WESE-DVVC</li>
                        <li style={{marginTop: "10px", marginLeft: "40px"}}>Description: Invalid value for MaterialTransfer.To</li>
                        <li style={{marginTop: "10px", marginLeft: "40px"}}>HTTP Code: 400</li>
                    </li>
                    <li style={{marginTop: "20px"}}>Material transfer calculations are indicating that the quantity on hand is greater than accepted quantity."
                        <li style={{marginTop: "10px", marginLeft: "40px"}}>Error Code: CC-MMID-EI87</li>
                        <li style={{marginTop: "10px", marginLeft: "40px"}}>Description: Material transfer calculations are indicating that the quantity on hand is greater than accepted quantity.</li>
                        <li style={{marginTop: "10px", marginLeft: "40px"}}>HTTP Code: 400</li>
                    </li>
                    <li style={{marginTop: "20px"}}>Material transfer calculations are indicating that the quantity on hand is less than zero."
                        <li style={{marginTop: "10px", marginLeft: "40px"}}>Error Code: CC-BSHD-BBXD</li>
                        <li style={{marginTop: "10px", marginLeft: "40px"}}>Description: Material transfer calculations are indicating that the quantity on hand is less than zero.</li>
                        <li style={{marginTop: "10px", marginLeft: "40px"}}>HTTP Code: 400</li>
                    </li>
                    <li style={{marginTop: "20px"}}>CycleCount.By is required."
                        <li style={{marginTop: "10px", marginLeft: "40px"}}>Error Code: CC-HD23-0OPI</li>
                        <li style={{marginTop: "10px", marginLeft: "40px"}}>Description: CycleCount.By is required.</li>
                        <li style={{marginTop: "10px", marginLeft: "40px"}}>HTTP Code: 400</li>
                    </li>
                    <li style={{marginTop: "20px"}}>Invalid CycleCount.By"
                        <li style={{marginTop: "10px", marginLeft: "40px"}}>Error Code: CC-NDJD-ZSDW</li>
                        <li style={{marginTop: "10px", marginLeft: "40px"}}>Description: Invalid CycleCount.By.</li>
                        <li style={{marginTop: "10px", marginLeft: "40px"}}>HTTP Code: 400</li>
                    </li>
                    <li style={{marginTop: "20px"}}>CycleCount.AmountOnHand is required."
                        <li style={{marginTop: "10px", marginLeft: "40px"}}>Error Code: CC-YETR-I89D</li>
                        <li style={{marginTop: "10px", marginLeft: "40px"}}>Description: CycleCount.AmountOnHand is required.</li>
                        <li style={{marginTop: "10px", marginLeft: "40px"}}>HTTP Code: 400</li>
                    </li>
                    <li style={{marginTop: "20px"}}>Invalid value for StartingQuantityUnit."
                        <li style={{marginTop: "10px", marginLeft: "40px"}}>Error Code: CC-JDUE-IIEE</li>
                        <li style={{marginTop: "10px", marginLeft: "40px"}}>Description: Invalid value for StartingQuantityUnit. Valid values are "Each", "Roll(s)", "Spool(s)", "Set(s)", "Box(s)", "Case(s)", "Lot(s)", "Pack(s)"</li>
                        <li style={{marginTop: "10px", marginLeft: "40px"}}>HTTP Code: 400</li>
                    </li>
                    <li style={{marginTop: "20px"}}>Invalid value for AcceptedQuantityUnit."
                        <li style={{marginTop: "10px", marginLeft: "40px"}}>Error Code: CC-AMSE-8434</li>
                        <li style={{marginTop: "10px", marginLeft: "40px"}}>Description: Invalid value for AcceptedQuantityUnit. Valid values are "Each", "Roll(s)", "Spool(s)", "Set(s)", "Box(s)", "Case(s)", "Lot(s)", "Pack(s)"</li>
                        <li style={{marginTop: "10px", marginLeft: "40px"}}>HTTP Code: 400</li>
                    </li>
                    <li style={{marginTop: "20px"}}>The Accepted Quantity cannot be greater than the Starting Quantity."
                        <li style={{marginTop: "10px", marginLeft: "40px"}}>Error Code: CC-HEDE-ODIE</li>
                        <li style={{marginTop: "10px", marginLeft: "40px"}}>Description: The Accepted Quantity cannot be greater than the Starting Quantity.</li>
                        <li style={{marginTop: "10px", marginLeft: "40px"}}>HTTP Code: 400</li>
                    </li>
                    <li style={{marginTop: "20px"}}>Invalid value for Equipment Selection."
                        <li style={{marginTop: "10px", marginLeft: "40px"}}>Error Code: CC-ZZSD-XXS3</li>
                        <li style={{marginTop: "10px", marginLeft: "40px"}}>Description: Invalid value for Equipment Selection.</li>
                        <li style={{marginTop: "10px", marginLeft: "40px"}}>HTTP Code: 400</li>
                    </li>
                    <li style={{marginTop: "20px"}}>Invalid value for EmployeeUSID."
                        <li style={{marginTop: "10px", marginLeft: "40px"}}>Error Code: CC-TTXZ-DS4E</li>
                        <li style={{marginTop: "10px", marginLeft: "40px"}}>Description: Invalid value for EmployeeUSID.</li>
                        <li style={{marginTop: "10px", marginLeft: "40px"}}>HTTP Code: 400</li>
                    </li>
                    <li style={{marginTop: "20px"}}>The Accepted Quantity cannot be greater than the Starting Quantity."
                        <li style={{marginTop: "10px", marginLeft: "40px"}}>Error Code: CC-HEDE-ODIE</li>
                        <li style={{marginTop: "10px", marginLeft: "40px"}}>Description: The Accepted Quantity cannot be greater than the Starting Quantity.</li>
                        <li style={{marginTop: "10px", marginLeft: "40px"}}>HTTP Code: 400</li>
                    </li>
                    <li style={{marginTop: "20px"}}>The Accepted Quantity cannot be greater than the Starting Quantity."
                        <li style={{marginTop: "10px", marginLeft: "40px"}}>Error Code: CC-HEDE-ODIE</li>
                        <li style={{marginTop: "10px", marginLeft: "40px"}}>Description: The Accepted Quantity cannot be greater than the Starting Quantity.</li>
                        <li style={{marginTop: "10px", marginLeft: "40px"}}>HTTP Code: 400</li>
                    </li>
                    <li style={{marginTop: "20px"}}>User must be the record owner or have the EDIT_LOT_TRANSFERS permission to edit material transfer data for a lot."
                        <li style={{marginTop: "10px", marginLeft: "40px"}}>Error Code: CC-POXX-DOCX</li>
                        <li style={{marginTop: "10px", marginLeft: "40px"}}>Description: User must be the record owner or have the EDIT_LOT_TRANSFERS permission to edit material transfer data for a lot.</li>
                        <li style={{marginTop: "10px", marginLeft: "40px"}}>HTTP Code: 400</li>
                    </li>
                    <li style={{marginTop: "20px"}}>User must be the record owner or have the EDIT_CYCLE_COUNT permission to edit cycle count data for a lot."
                        <li style={{marginTop: "10px", marginLeft: "40px"}}>Error Code: CC-JDUE-WE34</li>
                        <li style={{marginTop: "10px", marginLeft: "40px"}}>Description: User must be the record owner or have the EDIT_CYCLE_COUNT permission to edit cycle count data for a lot.</li>
                        <li style={{marginTop: "10px", marginLeft: "40px"}}>HTTP Code: 400</li>
                    </li>
                    <li style={{marginTop: "20px"}}>User must be the record owner or have the EDIT_LOT_STATUS permission to change the status of a lot."
                        <li style={{marginTop: "10px", marginLeft: "40px"}}>Error Code: CC-YYED-AWSW</li>
                        <li style={{marginTop: "10px", marginLeft: "40px"}}>Description: User must be the record owner or have the EDIT_LOT_STATUS permission to change the status of a lot.</li>
                        <li style={{marginTop: "10px", marginLeft: "40px"}}>HTTP Code: 400</li>
                    </li>
                    <li style={{marginTop: "20px"}}>Cannot transition LOT to IN_REVIEW until the required number of approvers are added."
                        <li style={{marginTop: "10px", marginLeft: "40px"}}>Error Code: CC-ODOO-PP90</li>
                        <li style={{marginTop: "10px", marginLeft: "40px"}}>Description: Cannot transition LOT to IN_REVIEW until the required number of approvers are added.</li>
                        <li style={{marginTop: "10px", marginLeft: "40px"}}>HTTP Code: 400</li>
                    </li>

                </ul>
            </p>
        </div>
    )
}