import RelationshipMetadata from "../../RelationshipMetadata";

class DeveloperCredentialAuthorizationDeveloperCredentialAuthorizationDetailRelationshipMetadata extends RelationshipMetadata {
    constructor() {
        super();

        this.name = "DeveloperCredentialAuthorizationDeveloperCredentialAuthorizationDetail";
        this.parentEnterpriseComponent = "DeveloperCredentialAuthorization";
        this.childEnterpriseComponent = "DeveloperCredentialAuthorizationDetail";
        this.sourceAttribute = "USID";
        this.destinationAttribute = "AuthorizationUSID";
    }
}

export default DeveloperCredentialAuthorizationDeveloperCredentialAuthorizationDetailRelationshipMetadata;