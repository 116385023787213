import React from "react";
import "../Navigation/css/NavBar.scss";
import {Black} from "../../theme/styles";
import ar_image from '../../assets/AR-Lifecycle.png';

export default function ApprovalRequests() {

    return (
        <div style={{paddingLeft: "20px", paddingRight: "30px"}}>
            <p style={{fontSize: "24px", fontWeight: "400", color: Black}}>Approval Requests</p>
            <p style={{fontSize: "16px", fontWeight: "400", color: Black}}>REST APIs support the handling of the lifecycle of approval requests in Enlil.</p>
            <ul style={{fontSize: "14px", fontWeight: "400", color: Black}}>
                <li style={{marginTop: "20px"}}>An Approval Request is the mechanism by which Enlil enables customers to perform Document Change Control as guided by the FDA and ISO 13485.</li>
                <li style={{marginTop: "20px"}}>In Enlil, a Document has a lifecycle that can undergo multiple revisions over time. Examples include, but are not limited to, procedures, protocols, parts, manufacturing instructions.</li>
                <li style={{marginTop: "20px"}}>A grouping of individual changes to heterogeneous documents, is called an Approval Request and it has a lifecycle as detailed below.</li>
            </ul>
            <br/>
            <br/>
            <img src={ar_image} style={{width: "790px", height: "430px"}} alt={"Lifecycle"}/>
        </div>
    )
}