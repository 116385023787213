import {Link, Typography} from "@mui/material";
import axios from "axios";
import React, {useState} from "react";
import {Link as RouterLink} from "react-router-dom";
import {sleep} from "../../util/Sleep";
import * as validators from "../../util/Validators";
import CCButton from "../Common/CCButton";
import CCInputLabel from "../Common/CCInputLabel";
import CCTextField from "../Common/CCTextField";
import css_shared from "./css/LoginCommon.module.scss";
import css_self from "./css/LoginHelp1.module.scss";
import {useLoginHelpContext} from "./LoginHelp";

export default function LoginHelp1() {

    const {helpState, updateHelpState} = useLoginHelpContext();

    const [submitting, setSubmitting] = useState(false);
    const [errorText, setErrorText] = useState(undefined);

    const [email, setEmail] = useState(helpState.email || "");

    async function submit() {

        const startAt = Date.now();

        setSubmitting(true);

        const response = await axios({
            method: "POST",
            baseURL: process.env.REACT_APP_API_URL,
            url: "s6/v1/forgot_password_send_code",
            json: true,
            data: {
                Email: email,
            },
        }).catch(e => e.response || e);

        if (response instanceof Error && response.message === "Network Error") {
            setSubmitting(false);
            setErrorText("Connection not available");
            return;
        }

        const waitDiff = 2000 - (Date.now() - startAt);
        waitDiff > 0 && (await sleep(waitDiff));

        if (response.status !== 200) {
            setSubmitting(false);
            setErrorText("Service temporarily unavailable");
            return;
        }

        updateHelpState({
            key: response.data.Key,
            email: email,
        });
    }

    function validateEmail(value, text) {

        if (
            value.length === 0 ||
            validators.IsEmail(value)
        ) {

            if (text && errorText === text) {
                setErrorText(undefined);
            }

            return true;

        } else if (text && errorText === undefined) {
            setErrorText(text);
        }

        return false;
    }

    return (
        <form onSubmit={e => e.preventDefault()}>
            <Typography className={css_shared.Title}>Let's get you into your account</Typography>
            <CCInputLabel>Email address</CCInputLabel>
            <CCTextField
                value={email}
                placeholder="Enter your email"
                onValidate={e =>
                    validateEmail(
                        e.target.value,
                        "Invalid email address",
                    )
                }
                onChange={async e => setEmail(e.target.value)}
                helperText={<span className="Mui-error" style={{visibility: errorText ? undefined : "hidden"}}>{errorText}</span>}
            />
            <CCButton
                className={css_self.Button}
                margin="none"
                animate={submitting}
                disabled={
                    submitting ||
                    email.length === 0 ||
                    !validateEmail(email)
                }
                onClick={() => {
                    setErrorText(undefined);
                    submit();
                }}
            >
                Continue
            </CCButton>
            <Typography className={css_shared.LoginInsteadText}>
                Already have an account?{" "}
                <Link component={RouterLink} to="/" replace={true}>Login</Link>
            </Typography>
        </form>
    );
}
