import RelationshipMetadata from "../../metadata/relationship/RelationshipMetadata";
import EnterpriseComponent from "../ec/EnterpriseComponent";
import relationshipMetadataClassFactory from "../../metadata/RelationshipMetadataClassFactory";

class Relationship {
    public relationshipMetadata: RelationshipMetadata = new RelationshipMetadata();
    public parentEnterpriseComponent: EnterpriseComponent | undefined = undefined;
    public childEnterpriseComponent: EnterpriseComponent | undefined = undefined;

    constructor(name: string) {
        let metadataClass = relationshipMetadataClassFactory(name);
        // @ts-ignore
        this.relationshipMetadata = new metadataClass() as RelationshipMetadata;
    };

    getName = () => {
        return this.relationshipMetadata.getName();
    };

    getParentEnterpriseComponentName(): string {
        return this.relationshipMetadata.getParentEnterpriseComponent();
    };

    getChildEnterpriseComponentName(): string {
        return this.relationshipMetadata.childEnterpriseComponent;
    };

    getParentEnterpriseComponent(): EnterpriseComponent | undefined {
        return this.parentEnterpriseComponent;
    };

    setParentEnterpriseComponent(parent: EnterpriseComponent | undefined) {
        this.parentEnterpriseComponent = parent;
    };

    getChildEnterpriseComponent(): EnterpriseComponent | undefined {
        return this.childEnterpriseComponent;
    };

    setChildEnterpriseComponent(child: EnterpriseComponent | undefined) {
        this.childEnterpriseComponent = child;
    };

    getInvalidateChildren(): boolean {
        return this.relationshipMetadata.getInvalidateChildren();
    };

    getSourceAttribute = () => {
        return this.relationshipMetadata.getSourceAttribute();
    };

    getDestinationAttribute = () => {
        return this.relationshipMetadata.getDestinationAttribute();
    };
}

export default Relationship;