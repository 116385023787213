import React from "react";
import "../Navigation/css/NavBar.scss";
import {Black} from "../../theme/styles";
import css_self from "../../pages/css/Pages.module.scss";
import {Link} from "@mui/material";
import {useNavigate} from "react-router-dom";

export default function Authenticate() {

    const navigate = useNavigate();

    return (
        <div style={{paddingLeft: "20px", paddingRight: "30px"}}>
            <p style={{fontSize: "24px", fontWeight: "400", color: Black}}>Authenticate</p>
            <p style={{fontSize: "16px", fontWeight: "400", color: Black}}>REST APIs support for authenticating an API Key.</p>
            <ul style={{fontSize: "14px", fontWeight: "400", color: Black}}>
                <li style={{marginTop: "20px"}}>In Enlil, all requests must be accompanied by a valid X-Enlil-AccessToken in the header.</li>
                <li style={{marginTop: "20px"}}>After Enlil issues a X-Enlil-AccessToken, it will expire in 129,600 minutes (90 days).</li>
                <li style={{marginTop: "20px"}}>A X-Enlil-AccessToken may be refreshed by using the X-Enlil-RefreshToken which never expires.</li>
                <li style={{marginTop: "20px"}}>The X-Enlil-ApiKey and X-Enlil-SecretKey</li>
                <li style={{marginTop: "20px"}}>To obtain a X-Enlil-AccessToken, the following two-step procedure should be followed:
                    <li style={{marginTop: "20px", marginLeft: "20px"}}>
                        Execute the <Link className={css_self.Link4} onClick={() => {
                        navigate("/apidocstools/api/authenticate_get")
                    }}>authenticate GET</Link> API with the following command: <span style={{fontFamily: "Courier New", fontSize: "14px",}}><br/><br/>curl -G -d "type=preauth" "https://api.integrate.dev2.enlil.io/t3/v1/authenticate"</span><br/><br/>
                        It will respond with a payload similar to the following.<br/><br/>
                        <span style={{fontFamily: "Courier New", fontSize: "14px",}}>
                            &#123;<br/>
                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;"Code": "CC-7A2C-48DF",<br/>
                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;"X-Enlil-AccessToken": &#60;token1&#62;
                            <br/>&#125;<br/>
                        </span>
                    </li>
                    <li style={{marginTop: "20px", marginLeft: "20px"}}>
                        Execute the <Link className={css_self.Link4} onClick={() => {
                        navigate("/apidocstools/api/authenticate_post")
                    }}>authenticate POST</Link> API with the following command: <span style={{fontFamily: "Courier New", fontSize: "14px",}}><br/><br/>curl -d '&#123;"X-Enlil-ApiKey":"&#60;key&#62;", "X-Enlil-SecretKey":"&#60;key&#62;"&#125;' -H "X-Enlil-AccessToken: &#60;token1&#62; -X POST "https://api.integrate.dev2.enlil.io/t3/v1/authenticate"</span><br/><br/>
                        It will respond with a payload similar to the following.<br/><br/>
                        <span style={{fontFamily: "Courier New", fontSize: "14px",}}>
                            &#123;<br/>
                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;"Code": "CC-F1AD-4641",<br/>
                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;"Session": 9f3e6492-205d-4f6e-87c6-4b1a7ac7c898,<br/>
                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;"X-Enlil-AccessToken": &#60;token2&#62;,<br/>
                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;"X-Enlil-RefreshToken": &#60;token3&#62;<br/>
                            &#125;<br/>
                        </span>
                    </li>
                </li>
                <li style={{marginTop: "20px"}}>To obtain a new X-Enlil-AccessToken, the following options are available:
                    <li style={{marginTop: "20px", marginLeft: "20px"}}>
                        Execute the <Link className={css_self.Link4} onClick={() => {
                        navigate("/apidocstools/api/authenticate_put")
                    }}>authenticate PUT</Link> API with the following command: <span style={{fontFamily: "Courier New", fontSize: "14px",}}><br/><br/>curl -d '&#123;"X-Enlil-RefreshToken":"&#60;key&#62;"&#125;' -X PUT "https://api.integrate.dev2.enlil.io/t3/v1/authenticate"</span><br/><br/>
                        It will respond with a payload similar to the following.<br/><br/>
                        <span style={{fontFamily: "Courier New", fontSize: "14px",}}>
                            &#123;<br/>
                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;"X-Enlil-AccessToken": &#60;token3&#62;<br/>
                            &#125;<br/>
                        </span>
                    </li>
                    <li style={{marginTop: "20px", marginLeft: "20px"}}>
                        Execute any API with the header X-Enlil-RefreshToken and Enlil will return a new X-Enlil-AccessToken
                    </li>
                </li>
            </ul>
            <br/>
            <br/>
        </div>
    )
}