import PicklistMetadata from "../PicklistMetadata";

class EmployeePicklistMetadata extends PicklistMetadata {
    constructor() {
        super();

        this.bounded = true;
        this.enterpriseComponent = "Employee";
        this.longList = false;
        this.noDelete = true;
        this.noUpdate = true;
        this.noInsert = true;
        this.noMerge = true;
        this.useRealm = false;
        this.sortSpecification = [
            {
                "SortAttribute": "UserName",
                "Direction": "ASC"
            }
        ];
        this.static = false;
        this.typeField = "UserName";
        this.typeValue = "USID";
        this.typeValue2 = null;
        this.visibilityType = null;
        this.allowNoSelection = false;
        this.allowNoSelectionValue = null;
        this.allowNoSelectionDisplayValue = null;
    }
}

export default EmployeePicklistMetadata;
