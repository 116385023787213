import {Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Typography} from "@mui/material";
import React, {useEffect, useRef, useState} from "react";
import Countdown, {zeroPad} from "react-countdown";
import {useNavigate} from "react-router-dom";
import {useHandler} from "../../util/useHandler";
import CCButton from "../Common/CCButton";
import css_self from "./css/InactiveLogout.module.scss";
import useAppContext from "../../util/AppContext";

export default function InactiveLogout(props) {

    const [activeAt, setActiveAt] = useState(Date.now());
    const [visible, setVisible] = useState(false);
    const [visibleAt, setVisibleAt] = useState(undefined);

    const navigate = useNavigate();

    const isMounted = useRef(true);
    const appContext = useAppContext();

    useEffect(() => {
        isMounted.current = true;
        return () => (isMounted.current = false);
    }, []);

    const evaluate = useHandler(async () => {

        if (!isMounted.current) return;
        if (visible) return;

        if (appContext?.sessionContext.valid() === false) return;

        let timeout = localStorage.getItem("inactive-timeout");
        timeout = timeout ? parseInt(timeout) : 55;

        if (localStorage.getItem("inactive-timeout-logging") === "true") {
            console.log(`[inactive-timeout] inactive for ${((Date.now() - activeAt) / 60000).toFixed(2)} minutes`);
        }

        if (Date.now() - activeAt >= 60000 * timeout) {
            setVisible(true);
            setVisibleAt(Date.now());
        }

    }, [activeAt, visible]);

    const eventListener = useHandler(e => {

            if (!isMounted.current) return;
            if (visible) return;

            if (localStorage.getItem("inactive-timeout-logging") === "true") {
                console.log(`[inactive-timeout] activity detected: ${e.type}`);
            }

            setActiveAt(Date.now());
        }, [activeAt, visible],
    );

    useEffect(() => {

        const interval = setInterval(() => {
            // avoid: 'setInterval' handler took <N>ms
            Promise.resolve().then(evaluate);
        }, 60000);

        window.addEventListener("mousemove", eventListener);
        window.addEventListener("scroll", eventListener);
        window.addEventListener("keydown", eventListener);
        window.addEventListener("resize", eventListener);

        return () => {
            clearInterval(interval);
            window.removeEventListener("mousemove", eventListener);
            window.removeEventListener("scroll", eventListener);
            window.removeEventListener("keydown", eventListener);
            window.removeEventListener("resize", eventListener);
        };

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return visible ? (
        <Dialog open={true} scroll="body" {...props}>
            <DialogTitle>Need more time?</DialogTitle>
            <DialogContent style={{padding: "0 16px"}}>
                <DialogContentText component="div">
                    <Typography>
                        Your session has been inactive for 55 minutes. You will be automatically logged out in...
                    </Typography>
                    <Typography component="div" className={css_self.CountdownText}>
                        <Countdown
                            date={visibleAt + 60000 * 5}
                            onComplete={() => {
                                setVisible(false);
                                navigate("/logout")
                            }}
                            renderer={({hours, minutes, seconds, completed}) => {
                                return `${zeroPad(minutes)}:${zeroPad(seconds)}`;
                            }}
                        />
                    </Typography>
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <CCButton
                    color="secondary"
                    onClick={async () => {
                        setVisible(false);
                        navigate("/logout")
                    }}
                >
                    Logout
                </CCButton>
                <CCButton
                    onClick={async () => {
                        setActiveAt(Date.now());
                        setVisible(false);
                        setVisibleAt(undefined);
                    }}
                >
                    Stay Logged in
                </CCButton>
            </DialogActions>
        </Dialog>
    ) : null;
}
