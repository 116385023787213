import React from "react";
import "../Navigation/css/NavBar.scss";
import {Black} from "../../theme/styles";
import auditlog001 from '../../json/audit-log-001.json';
import ReactJson from 'react-json-view'


export default function AuditLogsGet() {

    return (
        <div style={{paddingLeft: "20px", paddingRight: "30px"}}>
            <p style={{fontSize: "24px", fontWeight: "400", color: Black}}>AuditLogs GET</p>
            <p style={{fontSize: "16px", fontWeight: "400", color: Black}}>Overview</p>

            <p style={{fontSize: "14px", fontWeight: "400", color: Black, marginLeft: "20px"}}>
                In Enlil, all actions that result in a change to persisted data are themselves persisted in the Enlil database. This API enables the retrieval of that information.
            </p>
            <br/>
            <p style={{fontSize: "16px", fontWeight: "400", color: Black}}>Parameters</p>
            <ul style={{fontSize: "14px", fontWeight: "400", color: Black}}>
                <li style={{marginTop: "20px"}}>Header Parameters
                    <li style={{marginTop: "20px", marginLeft: "20px"}}>X-Enlil-AccessToken (Required String)</li>
                    <li style={{marginTop: "10px", marginLeft: "40px"}}>The X-Enlil-AccessToken is obtained during the authentication process and expires after 90 days.</li>
                    <li style={{marginTop: "20px", marginLeft: "20px"}}>X-Enlil-RefreshToken (Optional String)</li>
                    <li style={{marginTop: "10px", marginLeft: "40px"}}>The X-Enlil-RefreshToken is obtained during the authentication process and never expires. This token is provided is the caller wants a refreshed X-Enlil-AccessToken</li>
                    <li style={{marginTop: "20px", marginLeft: "20px"}}>idoc-response (Optional String: Default v1.AuditLog-001)</li>
                    <li style={{marginTop: "10px", marginLeft: "40px"}}>The name of the IDOC being requested.</li>
                </li>
                <li style={{marginTop: "20px"}}>Basic Query Parameters
                    <li style={{marginTop: "20px", marginLeft: "20px"}}>USID (Optional String)</li>
                    <li style={{marginTop: "10px", marginLeft: "40px"}}>The primary key of the part entity</li>

                    <li style={{marginTop: "20px", marginLeft: "20px"}}>DocumentUSID (Optional String)</li>
                    <li style={{marginTop: "10px", marginLeft: "40px"}}>The USID of the Document associated with the modified item</li>

                    <li style={{marginTop: "20px", marginLeft: "20px"}}>DocumentRevisionID (Optional String)</li>
                    <li style={{marginTop: "10px", marginLeft: "40px"}}>The USID of the Document Revision associated with the modified item</li>

                    <li style={{marginTop: "20px", marginLeft: "20px"}}>GroupUSID (Optional String)</li>
                    <li style={{marginTop: "10px", marginLeft: "40px"}}>The USID of the Group associated with the modified item</li>

                    <li style={{marginTop: "20px", marginLeft: "20px"}}>ImplementationPlanUSID (Optional String)</li>
                    <li style={{marginTop: "10px", marginLeft: "40px"}}>The USID of the Implementation Plan associated with the modified item</li>

                    <li style={{marginTop: "20px", marginLeft: "20px"}}>ApprovalRequestUSID (Optional String)</li>
                    <li style={{marginTop: "10px", marginLeft: "40px"}}>The USID of the Approval Request associated with the modified item</li>

                    <li style={{marginTop: "20px", marginLeft: "20px"}}>EmployeeUSID (Optional String)</li>
                    <li style={{marginTop: "10px", marginLeft: "40px"}}>The USID of the Employee associated with the modified item</li>

                    <li style={{marginTop: "20px", marginLeft: "20px"}}>ApprovalUSID (Optional String)</li>
                    <li style={{marginTop: "10px", marginLeft: "40px"}}>The USID of the Approval associated with the modified item</li>

                    <li style={{marginTop: "20px", marginLeft: "20px"}}>JustificationUSID (Optional String)</li>
                    <li style={{marginTop: "10px", marginLeft: "40px"}}>The USID of the Justification associated with the modified item</li>

                    <li style={{marginTop: "20px", marginLeft: "20px"}}>AssessmentUSID (Optional String)</li>
                    <li style={{marginTop: "10px", marginLeft: "40px"}}>The USID of the Assessment associated with the modified item</li>

                    <li style={{marginTop: "20px", marginLeft: "20px"}}>OwnerUSID (Optional String)</li>
                    <li style={{marginTop: "10px", marginLeft: "40px"}}>The USID of the record owner associated with the modified item</li>

                    <li style={{marginTop: "20px", marginLeft: "20px"}}>TaskUSID (Optional String)</li>
                    <li style={{marginTop: "10px", marginLeft: "40px"}}>The USID of the Task associated with the modified item</li>

                    <li style={{marginTop: "20px", marginLeft: "20px"}}>AssessmentItemUSID (Optional String)</li>
                    <li style={{marginTop: "10px", marginLeft: "40px"}}>The USID of the Assessment Item associated with the modified item</li>

                    <li style={{marginTop: "20px", marginLeft: "20px"}}>OwnerName (Optional String)</li>
                    <li style={{marginTop: "10px", marginLeft: "40px"}}>The Name of the record owner associated with the modified item</li>

                    <li style={{marginTop: "20px", marginLeft: "20px"}}>OwnerEmail (Optional String)</li>
                    <li style={{marginTop: "10px", marginLeft: "40px"}}>The Email of the record owner associated with the modified item</li>

                    <li style={{marginTop: "20px", marginLeft: "20px"}}>UserUSID (Optional String)</li>
                    <li style={{marginTop: "10px", marginLeft: "40px"}}>The USID of the User record owner associated with the modified item</li>

                    <li style={{marginTop: "20px", marginLeft: "20px"}}>FormTemplateUSID (Optional String)</li>
                    <li style={{marginTop: "10px", marginLeft: "40px"}}>The USID of the Form Template record associated with the modified item</li>

                    <li style={{marginTop: "20px", marginLeft: "20px"}}>DocumentTypeUSID (Optional String)</li>
                    <li style={{marginTop: "10px", marginLeft: "40px"}}>The USID of the Document Type USID record associated with the modified item</li>

                    <li style={{marginTop: "20px", marginLeft: "20px"}}>Limit (Optional Integer: Default 100)</li>
                    <li style={{marginTop: "10px", marginLeft: "40px"}}>The number of rows to return in the primary integration component in the IDOC</li>
                    <li style={{marginTop: "20px", marginLeft: "20px"}}>Offset (Optional Integer: Default 0)</li>
                    <li style={{marginTop: "10px", marginLeft: "40px"}}>The number of the row to start with in the result set</li>
                    <li style={{marginTop: "20px", marginLeft: "20px"}}>Sort (Optional String)</li>
                    <li style={{marginTop: "10px", marginLeft: "40px"}}>The column or columns to sort the results set with in the primary integration component in the IDOC. Example: [Column1: DESC, Column1: ASC]</li>
                </li>

            </ul>
            <br/>
            <p style={{fontSize: "16px", fontWeight: "400", color: Black}}>Error Codes</p>
            <ul style={{fontSize: "14px", fontWeight: "400", color: Black}}>
                <li style={{marginTop: "20px"}}>400 CC-MDJU-ASWS - Invalid parameter: Limit</li>
            </ul>
            <ul style={{fontSize: "14px", fontWeight: "400", color: Black}}>
                <li style={{marginTop: "20px"}}>400 CC-NDHD-XSXS - Invalid parameter: Offset</li>
            </ul>
            <br/>
            <p style={{fontSize: "16px", fontWeight: "400", color: Black}}>IDOCs</p>
            <p style={{fontSize: "14px", fontWeight: "400", color: Black, marginLeft: "20px", lineHeight: "1.2"}}>
                <span style={{textDecoration: "underline"}}><b>v1.AuditLog-001</b></span><br/><br/>
                <span style={{lineHeight: "1.5em"}}>v1.AuditLog-001 is an IDOC designed to return information about audit records. v1.AuditLog-001 is limited to a maximum of 100 records per request. To retrieve more, users are advised to paginate using the Limit and Offset query parameters.</span>

                <p style={{fontSize: "12px", fontWeight: "400", color: Black, fontFamily: "Courier New"}}>
                    <ReactJson src={auditlog001} collapseStringsAfterLength={75} collapsed={4}/>
                </p>
            </p>
            <br/>
        </div>
    )
}