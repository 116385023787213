import React from "react";
import "../Navigation/css/NavBar.scss";
import {Black} from "../../theme/styles";
import approval_request_001 from '../../json/approval-request-001.json';
import approval_request_002 from '../../json/approval-request-002.json';
import approval_request_003 from '../../json/approval-request-003.json';
import approval_request_qbe_1 from '../../json/approval-request-qbe-1.json';
import approval_request_qbe_1_result from '../../json/approval-request-qbe-1-result.json';
import ReactJson from 'react-json-view'


export default function ApprovalRequestsGet() {

    return (
        <div style={{paddingLeft: "20px", paddingRight: "30px"}}>
            <p style={{fontSize: "24px", fontWeight: "400", color: Black}}>Approval Requests GET</p>
            <p style={{fontSize: "16px", fontWeight: "400", color: Black}}>Overview</p>

            <p style={{fontSize: "14px", fontWeight: "400", color: Black, marginLeft: "20px"}}>
                In Enlil, the approval requests GET API allows for the retrieval of AR content.
            </p>
            <br/>
            <p style={{fontSize: "16px", fontWeight: "400", color: Black}}>Parameters</p>
            <ul style={{fontSize: "14px", fontWeight: "400", color: Black}}>
                <li style={{marginTop: "20px"}}>Header Parameters
                    <li style={{marginTop: "20px", marginLeft: "20px"}}>X-Enlil-AccessToken (Required String)</li>
                    <li style={{marginTop: "10px", marginLeft: "40px"}}>The X-Enlil-AccessToken is obtained during the authentication process and expires after 90 days.</li>
                    <li style={{marginTop: "20px", marginLeft: "20px"}}>X-Enlil-RefreshToken (Optional String)</li>
                    <li style={{marginTop: "10px", marginLeft: "40px"}}>The X-Enlil-RefreshToken is obtained during the authentication process and never expires. This token is provided is the caller wants a refreshed X-Enlil-AccessToken</li>
                    <li style={{marginTop: "20px", marginLeft: "20px"}}>idoc-response (Optional String: Default v1.ApprovalRequest-001)</li>
                    <li style={{marginTop: "10px", marginLeft: "40px"}}>The name of the IDOC being requested.</li>
                </li>
                <li style={{marginTop: "20px"}}>Basic Query Parameters</li>
                    <li style={{marginTop: "20px", marginLeft: "20px"}}>USID (Optional String)</li>
                    <li style={{marginTop: "10px", marginLeft: "40px"}}>The primary key of the part entity</li>
                    <li style={{marginTop: "20px", marginLeft: "20px"}}>ApprovalRequestID (Optional String)</li>
                    <li style={{marginTop: "10px", marginLeft: "40px"}}>The name of the document type</li>
                    <li style={{marginTop: "20px", marginLeft: "20px"}}>Limit (Optional: Default 5)</li>
                    <li style={{marginTop: "10px", marginLeft: "40px"}}>The number of rows to return in the primary integration component in the IDOC</li>
                    <li style={{marginTop: "20px", marginLeft: "20px"}}>Offset (Optional: Default 0)</li>
                    <li style={{marginTop: "10px", marginLeft: "40px"}}>The number of the row to start with in the result set</li>
                    <li style={{marginTop: "20px", marginLeft: "20px"}}>Sort (Optional: Default None)</li>
                    <li style={{marginTop: "10px", marginLeft: "40px"}}>The column or columns to sort the results set with in the primary integration component in the IDOC. Example: [Column1: DESC, Column1: ASC]</li>
                <li style={{marginTop: "20px"}}>Advanced Query Parameters</li>
                    <li style={{marginTop: "20px", marginLeft: "20px"}}>QBE (Optional Base64 encoded string)</li>
                    <li style={{marginTop: "10px", marginLeft: "40px"}}>If this parameter is provided, the Basic Query Parameters will be ignored.</li>
                    <li style={{marginTop: "20px", marginLeft: "20px"}}>Examples</li>
                    <li style={{marginTop: "10px", marginLeft: "40px"}}>1. Query the Approval Request and the Document Type</li>
                    <li style={{marginTop: "10px", marginLeft: "73px"}}>Query
                        <p style={{fontFamily: "Courier New", fontSize: "12px"}}>
                            <ReactJson src={approval_request_qbe_1} collapseStringsAfterLength={75} collapsed={4}/>
                        </p>
                    </li>
                    <li style={{marginTop: "10px", marginLeft: "73px"}}>Result
                        <p style={{fontFamily: "Courier New", fontSize: "12px"}}>
                            <ReactJson src={approval_request_qbe_1_result} collapseStringsAfterLength={75} collapsed={4}/>
                        </p>
                    </li>
            </ul>
            <br/>
            <p style={{fontSize: "16px", fontWeight: "400", color: Black}}>Examples</p>
            <p style={{fontSize: "14px", fontWeight: "400", color: Black, marginLeft: "20px"}}>
            Enlil provides several IDOC formats to retrieve approval request related data using the approval_requests GET API. For example, if the user executes this request:<br/><br/>

                <span style={{fontFamily: "Courier New", fontSize: "14px",}}>curl -G -d "USID=88888888-4444-4444-4444-121212121212&limit=5&offset=0" -H "idoc-response: v1.ApprovalRequest-001" -H "X-Enlil-AccessToken: &#60;token&#62;" http://integrate.enlil.io/t3/v1/approval_requests</span>
                <br/><br/>
                The response will resemble:<br/><br/>

                <p style={{fontFamily: "Courier New", fontSize: "12px"}}>
                    <ReactJson src={approval_request_001} collapseStringsAfterLength={75} collapsed={4}/>
                </p>
            </p>
            <br/>
            <p style={{fontSize: "16px", fontWeight: "400", color: Black}}>Error Codes</p>
            <ul style={{fontSize: "14px", fontWeight: "400", color: Black}}>
                <li style={{marginTop: "20px"}}>400 CC-KE89-LOPK - Invalid parameter: Limit</li>
            </ul>
            <ul style={{fontSize: "14px", fontWeight: "400", color: Black}}>
                <li style={{marginTop: "20px"}}>400 CC-OOO9-KI78 - Invalid parameter: Offset</li>
            </ul>
            <br/>
            <p style={{fontSize: "16px", fontWeight: "400", color: Black}}>IDOCs</p>
            <p style={{fontSize: "14px", fontWeight: "400", color: Black, marginLeft: "20px"}}>
                <span style={{textDecoration: "underline"}}><b>v1.ApprovalRequest-001</b></span><br/><br/>
                v1.ApprovalRequest-001 is an IDOC designed to return a list of document type records. v1.ApprovalRequest-001 is limited to a maximum of 40 records per request. To retrieve more, users are advised to paginate using the Limit and Offset query parameters.

                <p style={{fontSize: "12px", fontWeight: "400", color: Black, fontFamily: "Courier New"}}>
                    <ReactJson src={approval_request_001} collapseStringsAfterLength={75} collapsed={4}/>
                </p>
            </p>
            <br/>
            <p style={{fontSize: "14px", fontWeight: "400", color: Black, marginLeft: "20px"}}>
                <span style={{textDecoration: "underline"}}><b>v1.ApprovalRequest-002</b></span><br/><br/>
                v1.ApprovalRequest-002 is an IDOC designed to return a focused get of a approval request type record as specified in the USID parameter and all of its related information such as Document Revision. v1.ApprovalRequest-002 is limited to a maximum of 1 AR record per request.

                <p style={{fontSize: "12px", fontWeight: "400", color: Black, fontFamily: "Courier New"}}>
                    <ReactJson src={approval_request_002} collapseStringsAfterLength={75} collapsed={4}/>
                </p>
            </p>
            <br/>
            <p style={{fontSize: "14px", fontWeight: "400", color: Black, marginLeft: "20px"}}>
                <span style={{textDecoration: "underline"}}><b>v1.ApprovalRequest-003</b></span><br/><br/>
                v1.ApprovalRequest-003 is an IDOC designed to return a focused get of a approval request type record as specified in the USID parameter and all of its related information such as Document Revision and Document Type. v1.ApprovalRequest-003 is limited to a maximum of 1 AR record per request.

                <p style={{fontSize: "12px", fontWeight: "400", color: Black, fontFamily: "Courier New"}}>
                    <ReactJson src={approval_request_003} collapseStringsAfterLength={75} collapsed={4}/>
                </p>
            </p>
        </div>
    )
}