import EnterpriseObjectMemberMetadata from "../../EnterpriseObjectMemberMetadata";
import EnterpriseObjectMetadata from "../../EnterpriseObjectMetadata";

class DeveloperEndpointEnterpriseObjectMetadata extends EnterpriseObjectMetadata {

    constructor() {
        super();

        this.name = "DeveloperEndpoint";
        this.primaryEnterpriseComponent = "DeveloperEndpoint";

        let eom: EnterpriseObjectMemberMetadata = new EnterpriseObjectMemberMetadata();
        eom.name = "DeveloperEndpoint";
        eom.enterpriseComponent = "DeveloperEndpoint";
        eom.relationship = undefined;
        this.enterpriseComponentMembers.set(eom.name, eom);

        eom = new EnterpriseObjectMemberMetadata();
        eom.name = "DeveloperEndpointConfiguration";
        eom.enterpriseComponent = "DeveloperEndpointConfiguration";
        eom.relationship = "DeveloperEndpointDeveloperEndpointConfiguration";
        this.enterpriseComponentMembers.set(eom.name, eom);
    }
}

export default DeveloperEndpointEnterpriseObjectMetadata;