import React from "react";
import "../Navigation/css/NavBar.scss";
import {Black} from "../../theme/styles";
import lot001 from '../../json/lot-001.json';
import lot002 from '../../json/lot-002.json';
import lot003 from '../../json/lot-003.json';
import lot004 from '../../json/lot-004.json';
import part_request_qbe_1 from '../../json/part-request-qbe-1.json';
import part_request_qbe_1_result from '../../json/part-request-qbe-1-result.json';
import v1_Lot_001 from "../../assets/v1.Lot-001.png";
import v1_Lot_002 from "../../assets/v1.Lot-002.png";
import v1_Lot_003 from "../../assets/v1.Lot-003.png";
import v1_Lot_004 from "../../assets/v1.Lot-004.png";
import partRequestQBE from "../../assets/partRequestQBE1.png";
import ReactJson from 'react-json-view'


export default function LotsGet() {

    return (
        <div style={{paddingLeft: "20px", paddingRight: "30px"}}>
            <p style={{fontSize: "24px", fontWeight: "400", color: Black}}>Lots GET</p>
            <p style={{fontSize: "16px", fontWeight: "400", color: Black}}>Overview</p>

            <p style={{fontSize: "14px", fontWeight: "400", color: Black, marginLeft: "20px"}}>
                In Enlil, lots are documents with a document type group attribute equal to "LOT". The Lots GET API allows for the retrieval of lot content.
            </p>
            <br/>
            <p style={{fontSize: "16px", fontWeight: "400", color: Black}}>Parameters</p>
            <ul style={{fontSize: "14px", fontWeight: "400", color: Black}}>
                <li style={{marginTop: "20px"}}>Header Parameters
                    <li style={{marginTop: "20px", marginLeft: "20px"}}>X-Enlil-AccessToken (Required String)</li>
                    <li style={{marginTop: "10px", marginLeft: "40px"}}>The X-Enlil-AccessToken is obtained during the authentication process and expires after 90 days.</li>
                    <li style={{marginTop: "20px", marginLeft: "20px"}}>X-Enlil-RefreshToken (Optional String)</li>
                    <li style={{marginTop: "10px", marginLeft: "40px"}}>The X-Enlil-RefreshToken is obtained during the authentication process and never expires. This token is provided is the caller wants a refreshed X-Enlil-AccessToken</li>
                    <li style={{marginTop: "20px", marginLeft: "20px"}}>idoc-response (Optional String: Default v1.Lot-001)</li>
                    <li style={{marginTop: "10px", marginLeft: "40px"}}>The name of the IDOC being requested.</li>
                </li>
                <li style={{marginTop: "20px"}}>Basic Query Parameters
                    <li style={{marginTop: "20px", marginLeft: "20px"}}>USID (Optional String)</li>
                    <li style={{marginTop: "10px", marginLeft: "40px"}}>The primary key of the lot entity</li>
                    <li style={{marginTop: "20px", marginLeft: "20px"}}>DocumentID (Optional String)</li>
                    <li style={{marginTop: "10px", marginLeft: "40px"}}>The ID of the lot, such as SI-10002</li>
                    <li style={{marginTop: "20px", marginLeft: "20px"}}>DocumentAcronym (Optional String)</li>
                    <li style={{marginTop: "10px", marginLeft: "40px"}}>The Acronym of the lot, such as "LOT-"</li>
                    <li style={{marginTop: "20px", marginLeft: "20px"}}>DocumentTypeName (Optional String)</li>
                    <li style={{marginTop: "10px", marginLeft: "40px"}}>The Name of the document type, such as "Lot"</li>
                    <li style={{marginTop: "20px", marginLeft: "20px"}}>Limit (Optional Integer: Default 40)</li>
                    <li style={{marginTop: "10px", marginLeft: "40px"}}>The number of rows to return in the primary integration component in the IDOC</li>
                    <li style={{marginTop: "20px", marginLeft: "20px"}}>Offset (Optional Integer: Default 0)</li>
                    <li style={{marginTop: "10px", marginLeft: "40px"}}>The number of the row to start with in the result set</li>
                    <li style={{marginTop: "20px", marginLeft: "20px"}}>Sort (Optional String)</li>
                    <li style={{marginTop: "10px", marginLeft: "40px"}}>The column or columns to sort the results set with in the primary integration component in the IDOC. Example: [Column1: DESC, Column1: ASC]</li>
                </li>
                <li style={{marginTop: "20px"}}>Advanced Query Parameters</li>
                <li style={{marginTop: "20px", marginLeft: "20px"}}>QBE (Optional Base64 encoded string)</li>
                <li style={{marginTop: "10px", marginLeft: "40px"}}>If this parameter is provided, the Basic Query Parameters will be ignored.</li>
                <li style={{marginTop: "20px", marginLeft: "20px"}}>Examples</li>
                <li style={{marginTop: "20px", marginLeft: "40px"}}>In this example, a QBE is run against Lot, LotRevision and DocumentType.</li>
                <br/><br/>
                <p style={{fontFamily: "Courier New", fontSize: "12px", marginLeft: "40px"}}>
                    <img src={partRequestQBE} alt={"Model"}/>
                </p>
                <span style={{marginLeft: "40px"}}>The request will be :</span><br/><br/>

                <p style={{fontFamily: "Courier New", fontSize: "12px", marginLeft: "40px"}}>
                    <ReactJson src={part_request_qbe_1} collapseStringsAfterLength={75} collapsed={4}/>
                </p><br/><br/>
                <span style={{marginLeft: "40px"}}>The response will be :</span><br/><br/>

                <p style={{fontFamily: "Courier New", fontSize: "12px", marginLeft: "40px"}}>
                    <ReactJson src={part_request_qbe_1_result} collapseStringsAfterLength={75} collapsed={4}/>
                </p>

            </ul>
            <br/>
            <p style={{fontSize: "16px", fontWeight: "400", color: Black}}>Examples</p>
            <p style={{fontSize: "14px", fontWeight: "400", color: Black, marginLeft: "20px"}}>
                Enlil provides several IDOC formats to retrieve attachment related data using the attachments GET API. For example, if the user executes this request:<br/><br/>

                <span style={{fontFamily: "Courier New", fontSize: "14px",}}>curl -G -d "USID=88888888-4444-4444-4444-121212121212&limit=5&offset=0" -H "idoc-response: v1.Lot-001" -H "X-Enlil-AccessToken: &#60;token&#62;" http://integrate.enlil.io/t3/v1/lots</span>
                <br/><br/>
                The response will resemble:<br/><br/>

                <p style={{fontFamily: "Courier New", fontSize: "12px"}}>
                    <ReactJson src={lot001} collapseStringsAfterLength={75} collapsed={4}/>
                </p>
            </p>
            <br/>
            <p style={{fontSize: "16px", fontWeight: "400", color: Black}}>Error Codes</p>
            <ul style={{fontSize: "14px", fontWeight: "400", color: Black}}>
                <li style={{marginTop: "20px"}}>400 CC-IDID-HE45 - Invalid parameter: Limit</li>
            </ul>
            <ul style={{fontSize: "14px", fontWeight: "400", color: Black}}>
                <li style={{marginTop: "20px"}}>400 CC-AW23-ODK8 - Invalid parameter: Offset</li>
            </ul>
            <br/>
            <p style={{fontSize: "16px", fontWeight: "400", color: Black}}>IDOCs</p>
            <p style={{fontSize: "14px", fontWeight: "400", color: Black, marginLeft: "20px", lineHeight: "1.2"}}>
                <span style={{textDecoration: "underline"}}><b>v1.Lot-001</b></span><br/><br/>
                <span style={{lineHeight: "1.5em"}}>v1.Lot-001 is an IDOC designed to return information about lot records only without any related information. v1.Lot-001 is limited to a maximum of 40 records per request. To retrieve more, users are advised to paginate using the Limit and Offset query parameters. The scope of v1.Lot-001 is highlighted below.</span>

                <p style={{fontSize: "12px", fontWeight: "400", color: Black, fontFamily: "Courier New"}}>
                    <img src={v1_Lot_001} alt={"v1.Lot-001"}/>
                </p>

                <p style={{fontSize: "12px", fontWeight: "400", color: Black, fontFamily: "Courier New"}}>
                    <ReactJson src={lot001} collapseStringsAfterLength={75} collapsed={4}/>
                </p>
            </p>
            <br/>
            <p style={{fontSize: "14px", fontWeight: "400", color: Black, marginLeft: "20px"}}>
                <span style={{textDecoration: "underline"}}><b>v1.Lot-002</b></span><br/><br/>
                <span style={{lineHeight: "1.5em"}}>v1.Lot-002 is an IDOC designed to return a focused get of a single lot record as specified in the USID parameter and all of its related information such as Document Type, Change Request, Attachment, Groups, etc. v1.Lot-002 is limited to a maximum of 1 lot record per request. The scope of v1.Lot-002 is highlighted below.</span>

                <p style={{fontSize: "12px", fontWeight: "400", color: Black, fontFamily: "Courier New"}}>
                    <img src={v1_Lot_002} alt={"v1.Lot-002"}/>
                </p>

                <p style={{fontSize: "12px", fontWeight: "400", color: Black, fontFamily: "Courier New"}}>
                    <ReactJson src={lot002} collapseStringsAfterLength={75} collapsed={4}/>
                </p>
            </p>
            <br/>
            <p style={{fontSize: "14px", fontWeight: "400", color: Black, marginLeft: "20px"}}>
                <span style={{textDecoration: "underline"}}><b>v1.Lot-003</b></span><br/><br/>
                <span style={{lineHeight: "1.5em"}}>v1.Lot-003 is an IDOC designed to return a focused get of a single lot record as specified in the USID parameter and its related Attachment information. v1.Lot-003 is limited to a maximum of 1 lot record per request. The scope of v1.Lot-003 is highlighted below.</span>

                <p style={{fontSize: "12px", fontWeight: "400", color: Black, fontFamily: "Courier New"}}>
                    <img src={v1_Lot_003} alt={"v1.Lot-003"}/>
                </p>

                <p style={{fontSize: "12px", fontWeight: "400", color: Black, fontFamily: "Courier New"}}>
                    <ReactJson src={lot003} collapseStringsAfterLength={75} collapsed={4}/>
                </p>
            </p>
            <br/>
            <p style={{fontSize: "14px", fontWeight: "400", color: Black, marginLeft: "20px"}}>
                <span style={{textDecoration: "underline"}}><b>v1.Lot-004</b></span><br/><br/>
                <span style={{lineHeight: "1.5em"}}>v1.Lot-004 is an IDOC designed to return a focused get of a single lot record as specified in the USID parameter and its related Lot Revision and Group information. v1.Lot-004 is limited to a maximum of 1 lot record per request. The scope of v1.Lot-004 is highlighted below.</span>

                <p style={{fontSize: "12px", fontWeight: "400", color: Black, fontFamily: "Courier New"}}>
                    <img src={v1_Lot_004} alt={"v1.Lot-004"}/>
                </p>

                <p style={{fontSize: "12px", fontWeight: "400", color: Black, fontFamily: "Courier New"}}>
                    <ReactJson src={lot004} collapseStringsAfterLength={75} collapsed={4}/>
                </p>
            </p>
        </div>
    )
}