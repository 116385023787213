import EnterpriseComponentAttributeMetadata from "../../EnterpriseComponentAttributeMetadata";
import EnterpriseComponentMetadata from "../../EnterpriseComponentMetadata";

class EmployeeEnterpriseComponentMetadata extends EnterpriseComponentMetadata {

    constructor() {
        super();

        this.name = "Employee";
        this.defaultCacheSize = 50;

        this.attributes["SlackUserID"] = new EnterpriseComponentAttributeMetadata(
            "SlackUserID", // name
            false, // NoCopy
            false, // NoUpdate
            false, // Required
            false, // RequiredForQuery
            null, // Picklist
            null, // Scale
            null, // Precision
            null, // length
            null, // minLength
            null, // pattern
            "String", // Type
            null, // default value
            false, // computed
        );
        this.attributes["Active"] = new EnterpriseComponentAttributeMetadata(
            "Active", // name
            false, // NoCopy
            false, // NoUpdate
            false, // Required
            false, // RequiredForQuery
            null, // Picklist
            null, // Scale
            null, // Precision
            null, // length
            null, // minLength
            null, // pattern
            "Boolean", // Type
            null, // default value
            false, // computed
        );
        this.attributes["Locked"] = new EnterpriseComponentAttributeMetadata(
            "Locked", // name
            false, // NoCopy
            false, // NoUpdate
            false, // Required
            false, // RequiredForQuery
            null, // Picklist
            null, // Scale
            null, // Precision
            null, // length
            null, // minLength
            null, // pattern
            "Boolean", // Type
            null, // default value
            false, // computed
        );
        this.attributes["Confirmed"] = new EnterpriseComponentAttributeMetadata(
            "Confirmed", // name
            false, // NoCopy
            true, // NoUpdate
            true, // Required
            false, // RequiredForQuery
            null, // Picklist
            null, // Scale
            null, // Precision
            null, // length
            null, // minLength
            null, // pattern
            "Boolean", // Type
            null, // default value
            false, // computed
        );
        this.attributes["UserName"] = new EnterpriseComponentAttributeMetadata(
            "UserName", // name
            false, // NoCopy
            true, // NoUpdate
            true, // Required
            false, // RequiredForQuery
            null, // Picklist
            null, // Scale
            null, // Precision
            null, // length
            null, // minLength
            null, // pattern
            "String", // Type
            null, // default value
            false, // computed
        );
        this.attributes["Email"] = new EnterpriseComponentAttributeMetadata(
            "Email", // name
            false, // NoCopy
            true, // NoUpdate
            true, // Required
            false, // RequiredForQuery
            null, // Picklist
            null, // Scale
            null, // Precision
            null, // length
            null, // minLength
            null, // pattern
            "String", // Type
            null, // default value
            false, // computed
        );
        this.attributes["CreatedDatetime"] = new EnterpriseComponentAttributeMetadata(
            "CreatedDatetime", // name
            false, // NoCopy
            true, // NoUpdate
            true, // Required
            false, // RequiredForQuery
            null, // Picklist
            null, // Scale
            null, // Precision
            null, // length
            null, // minLength
            null, // pattern
            "Datetime", // Type
            null, // default value
            false, // computed
        );
        this.attributes["UserUSID"] = new EnterpriseComponentAttributeMetadata(
            "UserUSID", // name
            false, // NoCopy
            true, // NoUpdate
            true, // Required
            false, // RequiredForQuery
            null, // Picklist
            null, // Scale
            null, // Precision
            36, // length
            null, // minLength
            null, // pattern
            "UUID", // Type
            null, // default value
            false, // computed
        );
        this.attributes["USID"] = new EnterpriseComponentAttributeMetadata(
            "USID", // name
            false, // NoCopy
            true, // NoUpdate
            true, // Required
            false, // RequiredForQuery
            null, // Picklist
            null, // Scale
            null, // Precision
            36, // length
            null, // minLength
            null, // pattern
            "UUID", // Type
            null, // default value
            false, // computed
        );
    }
}

export default EmployeeEnterpriseComponentMetadata;
