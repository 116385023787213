import React from "react";

import Applet from "../../Applet";
import DeveloperCredentialTable from "../../../../components/RestManagement/DeveloperCredentialTable";

class DeveloperCredentialTableApplet extends Applet {

    state= {
        Name: "DeveloperCredentialTableApplet",
        Menu: {
            options: [
                {
                    displayValue: "New Credential",
                    value: "New Credential",
                },
                {
                    displayValue: "Refresh",
                    value: "Refresh",
                },
            ],
        },
        RowHeight: 70,
        PageSize: {
            "default": 5,
            "xs": 3,
            "sm": 3,
            "md": 10,
            "lg": 10,
            "lx": 10,
        },
        Placement: {
            "xl": [
                {
                    id: "RecordPointer",
                },
                {
                    id: "APIKey",
                },
                {
                    id: "Status",
                },
                {
                    id: "Actions",
                },
            ],
        },
        Columns: {
            "RecordPointer": {
                id: "RecordPointer",
                width: 25,
                cellRenderer: "Row Selection Indicator Cell",
                header: null,
                headerRenderer: "",
                headerTitle: "",
            },
            "Status": {
                id: "Status",
                width: 175,
                cellRenderer: "Status Cell",
                header: "enabled",
                headerRenderer: "Status Cell",
                headerTitle: "Status",
            },
            "APIKey": {
                id: "APIKey",
                width: 360,
                cellRenderer: "API Key Cell",
                header: "enabled",
                headerRenderer: "Standard Single Header",
                headerTitle: "API Key",
            },
            "Actions": {
                id: "Actions",
                width: 50,
                cellRenderer: "Action Cell",
                header: "enabled",
                headerRenderer: "Standard Single Header",
                headerTitle: "",
                pinned: "right",
                hideable: false,
            }
        }
    };

    render() {
        return <DeveloperCredentialTable applet={this}
                               pageSize={this.state.PageSize}
                               placement={this.state.Placement}
                               columns={this.state.Columns}
                               title="Credentials"
                               enterpriseComponent={this.getEnterpriseComponent()}
                               sort={this.state.Sort}
                               menu={this.state.Menu}
                               rowHeight={this.state.RowHeight}
        />;
    }

}

export default DeveloperCredentialTableApplet;
