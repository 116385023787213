import AbstractMetadata from "../AbstractMetadata";

class PicklistMetadata extends AbstractMetadata {
    bounded: boolean = false;
    enterpriseComponent: string | null = null;
    longList: boolean = false;
    noDelete: boolean = true;
    noUpdate: boolean = true;
    noInsert: boolean = true;
    noMerge: boolean = true;
    useRealm: boolean = true;
    searchSpecification: string | null = null;
    sortSpecification: string | null = null;
    static: boolean = true;
    typeField: string | null = "Pick Type";
    typeValue: string | null = null;
    typeValue2: string | null = null;
    visibilityType: string | null = null;
    allowNoSelection: boolean = false;
    allowNoSelectionDisplayValue: string | null= "None";
    allowNoSelectionValue: boolean = false;

    getBounded(): boolean {
        return this.bounded;
    };

    getEnterpriseComponent(): string | null {
        return this.enterpriseComponent;
    };

    getLongList(): boolean {
        return this.longList;
    };

    getNoDelete(): boolean {
        return this.noDelete;
    };

    getNoInsert(): boolean {
        return this.noInsert;
    };

    getNoUpdate(): boolean {
        return this.noUpdate;
    };

    getNoMerge() : boolean {
        return this.noMerge;
    };

    getUseRealm() : boolean {
        return this.useRealm;
    };

    getSearchSpecification(): string | null {
        return this.searchSpecification;
    };

    getSortSpecification(): string | null {
        return this.sortSpecification;
    };

    getStatic(): boolean {
        return this.static;
    };

    getTypeField(): string | null {
        return this.typeField;
    };

    getTypeValue(): string | null {
        return this.typeValue;
    };

    getTypeValue2(): string | null {
        return this.typeValue2;
    };

    getVisibilityType(): string | null {
        return this.visibilityType;
    };

    getAllowNoSelection(): boolean {
        return this.allowNoSelection;
    };

    getAllowNoSelectionDisplayValue(): string | null {
        return this.allowNoSelectionDisplayValue;
    };

    getAllowNoSelectionValue(): boolean {
        return this.allowNoSelectionValue;
    };
}

export default PicklistMetadata;
